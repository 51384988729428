import { type AutomationEntity } from '@principle-theorem/ng-automations';
import {
  Appointment,
  Event,
  Invoice,
  Patient,
  SchedulingEvent,
  stafferToNamedDoc,
  TimezoneResolver,
} from '@principle-theorem/principle-core';
import {
  AppointmentStatus,
  AppointmentSummary,
  ChecklistType,
  FormStatus,
  InteractionType,
  InvoiceStatus,
  ISchedulingEventData,
  isEventable,
  ParticipantType,
  PatientForm,
  TreatmentStepStatus,
  type IAppointment,
  type IEvent,
  type IPatient,
  type IStaffer,
  type ITreatmentStep,
} from '@principle-theorem/principle-core/interfaces';
import {
  Firestore,
  IReffable,
  snapshot,
  toNamedDocument,
  type DocumentReference,
  type WithRef,
} from '@principle-theorem/shared';
import { combineLatest, of, type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  convertWaitListDetailsToItem,
  type IWaitListDetails,
} from '../waitlist-details';
import { AppointmentRescheduleInteractionBuilder } from './appointment-reschedule-interaction-builder';

export interface IWithWaitList {
  waitListItem: IWaitListDetails;
}

export interface IWithEvent {
  event: IEvent;
}

export interface IWithAutomations {
  automations: AutomationEntity[];
}

export interface IRescheduleAppointmentRequest
  extends IWithEvent,
    IWithWaitList,
    IWithAutomations {
  treatmentStep?: ITreatmentStep;
  schedulingEventData: ISchedulingEventData;
}

export class AppointmentManager {
  static async move(
    staffer: WithRef<IStaffer>,
    patient: WithRef<IPatient>,
    appointment: WithRef<IAppointment>,
    data: IRescheduleAppointmentRequest
  ): Promise<DocumentReference<IAppointment>> {
    const owner = stafferToNamedDoc(staffer);
    const timezone = await TimezoneResolver.fromEvent(appointment);

    const oldAppointment = await Firestore.getDoc(appointment.ref);
    const eventBefore = SchedulingEvent.buildEventSnapshot(oldAppointment);

    const startTime =
      AppointmentRescheduleInteractionBuilder.getStartTimeChange(
        appointment,
        data.event
      );

    const event = Event.addParticipants(data.event, [
      {
        ...toNamedDocument(patient),
        type: ParticipantType.Patient,
      },
    ]);
    Appointment.replaceEvent(appointment, { ...event });

    if (!isEventable(appointment)) {
      throw new Error('Appointment cannot be left without an event time');
    }

    appointment.waitListItem = convertWaitListDetailsToItem(
      data.waitListItem,
      timezone
    );

    if (startTime.hasChanged) {
      await Appointment.updateStatus(
        appointment,
        AppointmentStatus.Scheduled,
        InteractionType.AppointmentReschedule
      );
    }

    if (data.automations.length) {
      await Appointment.addAutomations(appointment, data.automations);
    }

    const eventAfter = SchedulingEvent.buildEventSnapshot(appointment);
    if (SchedulingEvent.willCauseSchedulingEvent(eventBefore, eventAfter)) {
      const schedulingEvent = SchedulingEvent.init({
        scheduledByStaffer: staffer.ref,
        scheduledByPractice: data.schedulingEventData.scheduledByPractice,
        reason: data.schedulingEventData.reason,
        reasonSetManually: data.schedulingEventData.reasonSetManually,
        schedulingConditions: data.schedulingEventData.schedulingConditions,
        eventBefore,
        eventAfter,
      });

      await Appointment.addSchedulingEvent(
        appointment,
        schedulingEvent,
        owner,
        data.schedulingEventData.comments
      );
    }

    if (data.treatmentStep) {
      appointment = {
        ...appointment,
        treatmentPlan: {
          ...appointment.treatmentPlan,
          treatmentStep: {
            ...appointment.treatmentPlan.treatmentStep,
            name: data.treatmentStep.name,
            display: data.treatmentStep.display,
            duration: data.treatmentStep.schedulingRules.duration,
          },
        },
      };
    }

    const appointmentRef = await Firestore.saveDoc(appointment);
    await Appointment.cancelFollowUp(appointment, owner);

    if (data.treatmentStep) {
      await Firestore.patchDoc(appointment.treatmentPlan.treatmentStep.ref, {
        treatments: data.treatmentStep?.treatments,
        display: data.treatmentStep?.display,
        name: data.treatmentStep?.name,
      });
    }

    return appointmentRef;
  }

  static getCheckInRequirementCount$(
    patient: IReffable<IPatient>,
    appointment: WithRef<IAppointment> | AppointmentSummary
  ): Observable<number> {
    if (!appointment.ref) {
      return of(0);
    }

    return combineLatest([
      Patient.form$(patient, PatientForm.PatientDetailsForm),
      Appointment.checklistItems$({ ref: appointment.ref }, ChecklistType.Pre),
    ]).pipe(
      map(([patientDetailsForm, preChecklistItems]) => {
        const patientDetailsFormCount =
          patientDetailsForm?.status === FormStatus.Submitted ? 1 : 0;

        return preChecklistItems.length + patientDetailsFormCount;
      })
    );
  }

  static async checkOut(
    staffer: WithRef<IStaffer>,
    appointment: WithRef<IAppointment>
  ): Promise<void> {
    await AppointmentManager.issueInvoice(appointment, staffer);
    await Appointment.updateStatus(
      appointment,
      AppointmentStatus.Complete,
      InteractionType.AppointmentComplete,
      stafferToNamedDoc(staffer)
    );
    await Firestore.saveDoc(appointment);
  }

  static async issueInvoice(
    appointment: WithRef<IAppointment>,
    staffer: WithRef<IStaffer>
  ): Promise<void> {
    if (!appointment.invoiceRef) {
      throw new Error('No Invoice to Issue');
    }
    const invoice = await Firestore.getDoc(appointment.invoiceRef);
    if (invoice.status !== InvoiceStatus.Draft) {
      return;
    }
    // TODO: Upon issuing Invoice, tie treatments to invoice.
    await Invoice.issueInvoice(
      invoice,
      await snapshot(Invoice.transactions$(invoice)),
      staffer
    );
  }

  static async markScheduled(
    staffer: WithRef<IStaffer>,
    appointment: WithRef<IAppointment>
  ): Promise<void> {
    await Appointment.updateStatus(
      appointment,
      AppointmentStatus.Scheduled,
      InteractionType.AppointmentBook,
      stafferToNamedDoc(staffer)
    );
    await Firestore.saveDoc(appointment);
  }

  static async markConfirmed(
    staffer: WithRef<IStaffer>,
    appointment: WithRef<IAppointment>
  ): Promise<void> {
    await Appointment.updateStatus(
      appointment,
      AppointmentStatus.Confirmed,
      InteractionType.AppointmentConfirm,
      stafferToNamedDoc(staffer)
    );
    await Firestore.saveDoc(appointment);
  }

  static async markArrived(
    staffer: WithRef<IStaffer>,
    appointment: WithRef<IAppointment>
  ): Promise<void> {
    await Appointment.updateStatus(
      appointment,
      AppointmentStatus.Arrived,
      InteractionType.AppointmentArrived,
      stafferToNamedDoc(staffer)
    );
    await Firestore.saveDoc(appointment);
  }

  static async markCheckedIn(
    staffer: WithRef<IStaffer>,
    appointment: WithRef<IAppointment>
  ): Promise<void> {
    await Appointment.updateStatus(
      appointment,
      AppointmentStatus.CheckedIn,
      InteractionType.AppointmentCheckIn,
      stafferToNamedDoc(staffer)
    );
    await Firestore.saveDoc(appointment);
  }

  static async markCheckingOut(
    staffer: WithRef<IStaffer>,
    appointment: WithRef<IAppointment>
  ): Promise<void> {
    await Appointment.updateStatus(
      appointment,
      AppointmentStatus.CheckingOut,
      InteractionType.AppointmentCheckOut,
      stafferToNamedDoc(staffer)
    );
    await Firestore.patchDoc(appointment.treatmentPlan.treatmentStep.ref, {
      status: TreatmentStepStatus.Incomplete,
    });
    await Firestore.saveDoc(appointment);
  }

  static async markInProgress(
    staffer: WithRef<IStaffer>,
    appointment: WithRef<IAppointment>
  ): Promise<void> {
    await Appointment.updateStatus(
      appointment,
      AppointmentStatus.InProgress,
      InteractionType.AppointmentStart,
      stafferToNamedDoc(staffer)
    );
    await Firestore.patchDoc(appointment.treatmentPlan.treatmentStep.ref, {
      status: TreatmentStepStatus.Incomplete,
    });
    await Firestore.saveDoc(appointment);
  }
}
