import {
  IPractice,
  IPrescription,
  IPrintPrescription,
} from '@principle-theorem/principle-core/interfaces';
import { Region, WithRef } from '@principle-theorem/shared';
import { AustraliaPrescriptionPrintStrategy } from './print/australian-prescription-print-strategy';
import { NewZealandPrescriptionPrintStrategy } from './print/new-zealand-prescription-print-strategy';

export class PrescriptionPrintContext {
  private strategy: IPrintPrescription;

  constructor(region: Region | undefined) {
    switch (region) {
      case Region.Australia:
        this.strategy = new AustraliaPrescriptionPrintStrategy();
        break;
      case Region.NewZealand:
        this.strategy = new NewZealandPrescriptionPrintStrategy();
        break;
      default:
        throw new Error(`Unsupported region: ${region}`);
    }
  }

  async printPrescription(
    prescription: WithRef<IPrescription>
  ): Promise<WithRef<IPrescription> | string> {
    return this.strategy.printPrescription(prescription);
  }

  printTestPrescription(practice: WithRef<IPractice>): void {
    this.strategy.printTestPrescription(practice);
  }
}
