const A4_PAGE_WIDTH = 210;
const A4_PAGE_HEIGHT = 297;

type BarcodeTemplateOrientation = 'portrait' | 'landscape';

export interface IBarcodeTemplate {
  id: string;
  name: string;
  quantity: number;
  labelWidth: number;
  labelHeight: number;
  columns: number;
  rows: number;
  marginTop: number;
  marginLeft: number;
  columnGap: number;
  rowGap: number;
  paperWidth: number;
  paperHeight: number;
  orientation: BarcodeTemplateOrientation;
}

export const BARCODE_PRINT_TEMPLATES: IBarcodeTemplate[] = [
  {
    id: 'avery-j8651',
    name: 'Avery J8651 - 65 per sheet',
    labelWidth: 38.1,
    labelHeight: 21.2,
    quantity: 65,
    columns: 5,
    rows: 13,
    marginTop: 11,
    marginLeft: 7,
    columnGap: 3,
    rowGap: 0,
    paperWidth: A4_PAGE_WIDTH,
    paperHeight: A4_PAGE_HEIGHT,
    orientation: 'portrait',
  },
  {
    id: 'avery-l7159',
    name: 'Avery L7159 - 24 per sheet',
    labelWidth: 64,
    labelHeight: 33.8,
    quantity: 24,
    columns: 3,
    rows: 8,
    marginTop: 13.1,
    marginLeft: 8.5,
    columnGap: 3,
    rowGap: 0,
    paperWidth: A4_PAGE_WIDTH,
    paperHeight: A4_PAGE_HEIGHT,
    orientation: 'portrait',
  },
  {
    id: 'avery-l7163',
    name: 'Avery L7163 - 14 per sheet',
    labelWidth: 99.1,
    labelHeight: 38.1,
    quantity: 14,
    columns: 2,
    rows: 7,
    marginTop: 15,
    marginLeft: 6,
    columnGap: 3,
    rowGap: 0,
    paperWidth: A4_PAGE_WIDTH,
    paperHeight: A4_PAGE_HEIGHT,
    orientation: 'portrait',
  },
  {
    id: 'dymo-30252',
    name: 'DYMO 30252 Address Label',
    labelWidth: 89,
    labelHeight: 28.7,
    quantity: 1,
    columns: 1,
    rows: 1,
    marginTop: 0,
    marginLeft: 0,
    columnGap: 0,
    rowGap: 0,
    paperWidth: 89,
    paperHeight: 28.7,
    orientation: 'landscape',
  },
];
