import {
  DEFAULT_SCHEDULE_ID,
  FeeSchedule,
  hasMergeConflicts,
  serviceCodeToFee,
  ServiceProviderHandler,
} from '@principle-theorem/principle-core';
import {
  DestinationEntityRecordStatus,
  IMigratedDataSummary,
  ServiceCodeType,
  SourceEntityRecordStatus,
  type FailedDestinationEntityRecord,
  type IBrand,
  type IDestinationEntity,
  type IDestinationEntityRecord,
  type IFeeSchedule,
  type IGetRecordResponse,
  type IPractice,
  type IPracticeMigration,
  type ISourceEntityRecord,
  type MergeConflictDestinationEntityRecord,
  ITranslationMap,
} from '@principle-theorem/principle-core/interfaces';
import {
  asDocRef,
  doc,
  doc$,
  getDoc,
  getError,
  toNamedDocument,
  toTimestamp,
  type DocumentReference,
  type INamedDocument,
  type WithRef,
} from '@principle-theorem/shared';
import { compact, groupBy, last, sortBy } from 'lodash';
import { of, type Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import { BaseDestinationEntity } from '../../../destination/base-destination-entity';
import { FirestoreMigrate } from '../../../destination/destination';
import { DestinationEntity } from '../../../destination/destination-entity';
import { PracticeMigration } from '../../../practice-migrations';
import { buildSkipMigratedQuery } from '../../../source/source-entity-record';
import { type TranslationMapHandler } from '../../../translation-map';
import {
  FEE_SCHEDULE_RESOURCE_TYPE,
  FeeScheduleSourceEntity,
  type ID4WFeeSchedule,
} from '../../source/entities/fee-schedule';
import { D4WFeeScheduleMappingHandler } from '../mappings/fee-schedules';
import { D4WPracticeMappingHandler } from '../mappings/practices';

export const FEE_SCHEDULE_DESTINATION_ENTITY = DestinationEntity.init({
  metadata: {
    key: 'feeSchedules',
    label: 'Fee Schedules',
    description:
      'Fee Schedule custom mapping must be set after this migration is run.',
  },
});

export interface IFeeScheduleDestinationRecord {
  sourceRef: DocumentReference<ISourceEntityRecord>;
  feeScheduleRef: DocumentReference<IFeeSchedule>;
}

export interface IFeeScheduleErrorData {
  sourceRef: DocumentReference<ISourceEntityRecord>;
}

export interface IFeeScheduleJobData {
  feeScheduleRecord: IGetRecordResponse<ID4WFeeSchedule>;
  practices: WithRef<ITranslationMap<IPractice>>[];
  brand: WithRef<IBrand>;
  feeScheduleMappings: WithRef<ITranslationMap<IFeeSchedule>>[];
}

interface IFeeScheduleMigrationData {
  isDefault: boolean;
  sourceFeeScheduleId: string;
  feeSchedule: IFeeSchedule;
  practiceRef: DocumentReference<IPractice>;
}

export class FeeScheduleDestinationEntity extends BaseDestinationEntity<
  IFeeScheduleDestinationRecord,
  IFeeScheduleJobData,
  IFeeScheduleMigrationData
> {
  sourceCountComparison = new FeeScheduleSourceEntity();

  override sourceEntities = {
    feeSchedules: new FeeScheduleSourceEntity(),
  };

  customMappings = {
    practices: new D4WPracticeMappingHandler(),
    feeSchedules: new D4WFeeScheduleMappingHandler(),
  };

  destinationEntity = FEE_SCHEDULE_DESTINATION_ENTITY;

  sourceCountDataAccessor(
    data: IFeeScheduleJobData
  ): DocumentReference<ISourceEntityRecord> {
    return data.feeScheduleRecord.record.ref;
  }

  getMigratedData$(
    record: IDestinationEntityRecord<IFeeScheduleDestinationRecord>
  ): Observable<IMigratedDataSummary[]> {
    if (record.status !== DestinationEntityRecordStatus.Migrated) {
      return of([]);
    }

    return doc$(record.data.feeScheduleRef).pipe(
      map((feeSchedule) => [
        {
          label: 'Fee Schedule',
          data: feeSchedule,
        },
      ])
    );
  }

  buildJobData$(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMapHandler: TranslationMapHandler,
    skipMigrated: boolean
  ): Observable<IFeeScheduleJobData[]> {
    const practices$ = this.customMappings.practices.getRecords$(
      translationMapHandler
    );
    const feeScheduleMappings$ = this.customMappings.feeSchedules.getRecords$(
      translationMapHandler
    );
    const brand$ = PracticeMigration.brand$(migration);
    return this.sourceEntities.feeSchedules
      .getRecords$(
        migration,
        1000,
        buildSkipMigratedQuery(skipMigrated, this.destinationEntity)
      )
      .pipe(
        withLatestFrom(brand$, practices$, feeScheduleMappings$),
        map(([feeScheduleRecords, brand, practices, feeScheduleMappings]) =>
          feeScheduleRecords.map((feeScheduleRecord) => ({
            feeScheduleRecord,
            brand,
            practices,
            feeScheduleMappings,
          }))
        )
      );
  }

  getDestinationEntityRecordUid(data: IFeeScheduleJobData): string {
    return data.feeScheduleRecord.record.uid;
  }

  buildMigrationData(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    _translationMap: TranslationMapHandler,
    data: IFeeScheduleJobData
  ):
    | IFeeScheduleMigrationData
    | (IDestinationEntityRecord & FailedDestinationEntityRecord) {
    const errorResponseData = {
      label: data.feeScheduleRecord.record.label,
      uid: data.feeScheduleRecord.record.uid,
      ref: data.feeScheduleRecord.record.ref,
    };

    if (
      data.feeScheduleRecord.record.status === SourceEntityRecordStatus.Invalid
    ) {
      return this._buildErrorResponse(
        errorResponseData,
        'Source fee schedule is invalid'
      );
    }

    const practiceId = data.feeScheduleRecord.data.data.practice_id;

    const practice = data.practices.find(
      (searchPractice) =>
        searchPractice.sourceIdentifier === practiceId.toString()
    );

    if (!practice || !practice.destinationIdentifier) {
      return this._buildErrorResponse(
        errorResponseData,
        `Can't find practice with id ${practiceId}`
      );
    }

    const items = groupBy(
      data.feeScheduleRecord.data.data.items,
      (item) => `${item.item_id}`
    );

    const filteredItems = compact(
      Object.values(items).map((groupItems) =>
        last(sortBy(groupItems, (item) => item.end_date))
      )
    );

    return {
      isDefault: data.feeScheduleRecord.data.data.is_default,
      practiceRef: practice.destinationIdentifier,
      sourceFeeScheduleId: this.sourceEntities.feeSchedules
        .getSourceRecordId(data.feeScheduleRecord.data.data)
        .toString(),
      feeSchedule: FeeSchedule.init({
        name: data.feeScheduleRecord.data.data.description,
        serviceCodeType: ServiceCodeType.ADA,
        serviceCodes: compact(
          filteredItems.map((item) => {
            const foundServiceCode = ServiceProviderHandler.findServiceCode(
              item.item_id
            );

            if (!foundServiceCode) {
              // eslint-disable-next-line no-console
              console.error(
                `Can't find service code info for code ${item.item_id}`
              );
              return;
            }

            return serviceCodeToFee(foundServiceCode, item.price);
          })
        ),
      }),
    };
  }

  async hasMergeConflict(
    translationMap: TranslationMapHandler,
    data: IFeeScheduleMigrationData
  ): Promise<IFeeScheduleMigrationData | undefined> {
    const existingFeeScheduleRef = data.isDefault
      ? doc(
          FeeSchedule.col({
            ref: data.practiceRef,
          }),
          DEFAULT_SCHEDULE_ID
        )
      : await translationMap.getDestination(
          data.sourceFeeScheduleId,
          FEE_SCHEDULE_RESOURCE_TYPE
        );

    if (!existingFeeScheduleRef) {
      return;
    }

    const existingFeeSchedule = await getDoc(
      existingFeeScheduleRef as DocumentReference<IFeeSchedule>
    );

    const hasMergeConflict = hasMergeConflicts(
      data.feeSchedule,
      existingFeeSchedule
    );

    if (hasMergeConflict) {
      return {
        ...data,
        feeSchedule: existingFeeSchedule,
      };
    }
  }

  buildMergeConflictRecord(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    _translationMap: TranslationMapHandler,
    jobData: IFeeScheduleJobData,
    _migrationData: IFeeScheduleMigrationData
  ): IDestinationEntityRecord & MergeConflictDestinationEntityRecord {
    return {
      uid: jobData.feeScheduleRecord.record.uid,
      label: jobData.feeScheduleRecord.record.label,
      status: DestinationEntityRecordStatus.MergeConflict,
    };
  }

  async runJob(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    jobData: IFeeScheduleJobData,
    migrationData: IFeeScheduleMigrationData
  ): Promise<IDestinationEntityRecord> {
    try {
      const mapping = jobData.feeScheduleMappings.find(
        (feeScheduleMapping) =>
          feeScheduleMapping.sourceIdentifier ===
          migrationData.sourceFeeScheduleId
      );

      if (mapping?.destinationIdentifier) {
        await translationMap.upsert({
          sourceIdentifier: migrationData.sourceFeeScheduleId,
          destinationIdentifier: mapping?.destinationIdentifier,
          resourceType: FEE_SCHEDULE_RESOURCE_TYPE,
        });
        return this._buildSkippedResponse(jobData.feeScheduleRecord);
      }

      const existingFeeScheduleRef = migrationData.isDefault
        ? doc(
            FeeSchedule.col({
              ref: migration.configuration.organisation.ref,
            }),
            DEFAULT_SCHEDULE_ID
          )
        : await translationMap.getDestination(
            migrationData.sourceFeeScheduleId,
            FEE_SCHEDULE_RESOURCE_TYPE
          );

      const feeScheduleRef = await FirestoreMigrate.upsertDoc(
        FeeSchedule.col({
          ref: migration.configuration.organisation.ref,
        }),
        migrationData.feeSchedule,
        existingFeeScheduleRef?.id
      );

      if (!existingFeeScheduleRef) {
        await translationMap.upsert({
          sourceIdentifier: migrationData.sourceFeeScheduleId,
          destinationIdentifier: feeScheduleRef,
          resourceType: FEE_SCHEDULE_RESOURCE_TYPE,
        });
      }

      return this._buildSuccessResponse(
        jobData.feeScheduleRecord,
        feeScheduleRef
      );
    } catch (error) {
      const errorResponseData = {
        label: jobData.feeScheduleRecord.record.label,
        uid: jobData.feeScheduleRecord.record.uid,
        ref: jobData.feeScheduleRecord.record.ref,
      };

      return this._buildErrorResponse(errorResponseData, getError(error));
    }
  }

  private _buildErrorResponse(
    feeSchedule: Pick<IGetRecordResponse['record'], 'label' | 'uid' | 'ref'>,
    errorMessage?: string
  ): IDestinationEntityRecord & FailedDestinationEntityRecord {
    return {
      uid: feeSchedule.uid,
      label: feeSchedule.label,
      status: DestinationEntityRecordStatus.Failed,
      errorMessage: errorMessage ?? `Can't resolve fee schedule data`,
      failData: {
        feeScheduleRef: feeSchedule.ref,
      },
    };
  }

  private _buildSuccessResponse(
    record: IGetRecordResponse<ID4WFeeSchedule>,
    feeScheduleRef: DocumentReference<IFeeSchedule>
  ): IDestinationEntityRecord<IFeeScheduleDestinationRecord> {
    return {
      uid: record.record.uid,
      label: record.data.data.description,
      data: {
        sourceRef: record.record.ref,
        feeScheduleRef,
      },
      status: DestinationEntityRecordStatus.Migrated,
      migratedAt: toTimestamp(),
    };
  }

  private _buildSkippedResponse(
    record: IGetRecordResponse<ID4WFeeSchedule>
  ): IDestinationEntityRecord {
    return {
      uid: record.record.uid,
      label: record.data.data.description,
      status: DestinationEntityRecordStatus.Skipped,
    };
  }
}

export type FeeScheduleResolverFn = (
  id?: string
) => Promise<INamedDocument<IFeeSchedule>>;

export function feeScheduleResolverFn(
  translationMap: TranslationMapHandler,
  defaultFeeSchedule: INamedDocument<IFeeSchedule>
): FeeScheduleResolverFn {
  return async (id?: string) => {
    if (!id) {
      return defaultFeeSchedule;
    }
    const resolved = await translationMap.getBySource(
      id,
      FEE_SCHEDULE_RESOURCE_TYPE
    );
    if (!resolved || !resolved.destinationIdentifier) {
      return defaultFeeSchedule;
    }
    return toNamedDocument(
      await getDoc(asDocRef<IFeeSchedule>(resolved.destinationIdentifier))
    );
  };
}
