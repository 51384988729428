<form
  [formGroup]="form"
  class="flex flex-col gap-4"
  *ngrxLet="patient$ as patient"
>
  <pr-patient-details-compare-field
    *ngrxLet="'name' | map: getBasePatientFieldState$ : this | async as state"
    [state]="state"
    (revertChange)="service.revertChange('name', state)"
  >
    <mat-form-field class="flex-1">
      <mat-label>Full Name</mat-label>
      <input matInput type="text" formControlName="name" required />
    </mat-form-field>
  </pr-patient-details-compare-field>

  <pr-patient-details-compare-field
    *ngrxLet="'gender' | map: getBasePatientFieldState$ : this | async as state"
    [state]="state"
    (revertChange)="service.revertChange('gender', state)"
  >
    <mat-form-field class="flex-1">
      <mat-label>Gender</mat-label>
      <mat-select formControlName="gender" required>
        <mat-option
          *ngFor="let gender of genders; trackBy: trackByGender"
          [value]="gender"
        >
          {{ gender | splitCamel | titlecase }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </pr-patient-details-compare-field>

  <pr-patient-details-compare-field
    *ngrxLet="
      'dateOfBirth' | map: getBasePatientFieldState$ : this | async as state
    "
    [state]="state"
    (revertChange)="service.revertChange('dateOfBirth', state)"
  >
    <pt-iso-date-selector
      class="flex-1"
      placeholder="Date of birth"
      formControlName="dateOfBirth"
      [required]="'dateOfBirth' | map: isRequired$ : this | async"
      [max]="today"
    />
  </pr-patient-details-compare-field>

  <pr-patient-details-compare-field
    *ngrxLet="
      ['address', 'address']
        | map: getBasePatientNestedFieldState$ : this
        | async as state
    "
    [state]="state"
    (revertChange)="service.revertAddressChange(state)"
  >
    <pr-address-input class="flex-1" formControlName="address" />
  </pr-patient-details-compare-field>

  <pr-patient-details-compare-field
    *ngrxLet="'email' | map: getBasePatientFieldState$ : this | async as state"
    [state]="state"
    (revertChange)="service.revertChange('email', state)"
  >
    <mat-form-field class="flex-1">
      <mat-label>Email</mat-label>
      <input
        matInput
        type="email"
        formControlName="email"
        [required]="'email' | map: isRequired$ : this | async"
      />
    </mat-form-field>
  </pr-patient-details-compare-field>

  <div formArrayName="contactNumbers" class="flex flex-col gap-3">
    @for (control of contactNumberControls.controls; track $index) {
      <pr-patient-details-compare-field
        *ngrxLet="
          control | map: getContactNumberFieldState$ : this | async as state
        "
        [state]="state"
        (revertChange)="service.revertContactNumberChange(control, state)"
      >
        <pr-contact-number-input
          class="flex-1"
          [form]="control"
          [isFirst]="$first"
          [required]="'contactNumbers' | map: isRequired$ : this | async"
        >
          <div class="flex flex-row content-center">
            <button
              mat-icon-button
              (click)="contactNumberControls.addControl($index)"
            >
              <mat-icon>add</mat-icon>
            </button>
            <button
              mat-icon-button
              (click)="contactNumberControls.removeControl($index)"
            >
              <mat-icon>remove</mat-icon>
            </button>
          </div>
        </pr-contact-number-input>
      </pr-patient-details-compare-field>
    }
  </div>

  <ng-container *prIsRegion="[Region.Australia]">
    <span>Health Fund Card</span>
    <pr-patient-details-compare-field
      *ngrxLet="
        healthFundCard
          | pairMap$: 'healthFundCard' : getHealthCardFieldState$ : this
          | async as state
      "
      [state]="state"
      (revertChange)="
        service.revertHealthCardChange(healthFundCard, state, 'healthFundCard')
      "
    >
      <pr-patient-details-health-fund-form
        class="flex-1"
        [form]="form.controls.healthFundCard"
      />
    </pr-patient-details-compare-field>

    <span>Medicare Card</span>
    <pr-patient-details-compare-field
      *ngrxLet="
        medicareCard
          | pairMap$: 'medicareCard' : getHealthCardFieldState$ : this
          | async as state
      "
      [state]="state"
      (revertChange)="
        service.revertHealthCardChange(medicareCard, state, 'medicareCard')
      "
    >
      <pr-patient-details-medicare-form
        class="flex-1"
        [form]="form.controls.medicareCard"
      />
    </pr-patient-details-compare-field>

    <span>DVA Card</span>
    <pr-patient-details-compare-field
      *ngrxLet="
        dvaCard
          | pairMap$: 'dvaCard' : getHealthCardFieldState$ : this
          | async as state
      "
      [state]="state"
      (revertChange)="service.revertHealthCardChange(dvaCard, state, 'dvaCard')"
    >
      <pr-patient-details-dva-form
        class="flex-1"
        [form]="form.controls.dvaCard"
      />
    </pr-patient-details-compare-field>
  </ng-container>
  <ng-container *prIsRegion="[Region.NewZealand]">
    <span>NHI Number</span>
    <pr-patient-details-compare-field
      *ngrxLet="
        'nationalHealthIndexNumber'
          | map: getBasePatientFieldState$ : this
          | async as state
      "
      [state]="state"
      (revertChange)="service.revertChange('nationalHealthIndexNumber', state)"
    >
      <pr-national-health-index-number-form
        class="flex-1"
        [nationalHealthIndexNumber]="
          form.controls.nationalHealthIndexNumber.value
        "
        (nationalHealthIndexNumberChange)="
          form.controls.nationalHealthIndexNumber.setValue($event)
        "
      />
    </pr-patient-details-compare-field>
  </ng-container>
</form>
