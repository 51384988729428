import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  inject,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { DialogPresets } from '@principle-theorem/ng-shared';
import {
  IDVACard,
  IHealthFundCard,
  IMedicareCard,
  IPatient,
} from '@principle-theorem/principle-core/interfaces';
import {
  Firestore,
  Region,
  WithRef,
  snapshot,
} from '@principle-theorem/shared';
import { ReplaySubject, Subject } from 'rxjs';
import { DVACardDisplayComponent } from '../dva-card-display/dva-card-display.component';
import { EditDVACardComponent } from '../edit-dva-card/edit-dva-card.component';
import { EditHealthFundCardComponent } from '../edit-healthfund-card/edit-healthfund-card.component';
import { EditMedicareCardComponent } from '../edit-medicare-card/edit-medicare-card.component';
import { HealthFundCardDisplayComponent } from '../healthfund-card-display/healthfund-card-display.component';
import { MedicareCardDisplayComponent } from '../medicare-card-display/medicare-card-display.component';
import { NationalHealthIndexNumberFormComponent } from '../national-health-index-number-form/national-health-index-number-form.component';
import { HealthInsuranceDetailsFormComponent } from '../health-insurance-details-form/health-insurance-details-form.component';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';

@Component({
  selector: 'pr-patient-healthcare-information',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatTabsModule,
    MatFormFieldModule,
    MedicareCardDisplayComponent,
    DVACardDisplayComponent,
    HealthFundCardDisplayComponent,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    NationalHealthIndexNumberFormComponent,
    HealthInsuranceDetailsFormComponent,
    NgPrincipleSharedModule,
  ],
  templateUrl: './patient-healthcare-information.component.html',
  styleUrl: './patient-healthcare-information.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientHealthcareInformationComponent implements OnDestroy {
  private _dialog = inject(MatDialog);
  private _onDestroy$ = new Subject<void>();
  readonly Region = Region;

  patient$ = new ReplaySubject<WithRef<IPatient>>(1);
  region$ = new ReplaySubject<Region>(1);

  @Input()
  set region(region: Region) {
    if (region) {
      this.region$.next(region);
    }
  }

  @Input()
  set patient(patient: WithRef<IPatient>) {
    if (patient) {
      this.patient$.next(patient);
    }
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async editMedicareCard(): Promise<void> {
    const patient = await snapshot(this.patient$);
    const medicareCard = await this._dialog
      .open<
        EditMedicareCardComponent,
        IMedicareCard | undefined,
        IMedicareCard | undefined
      >(
        EditMedicareCardComponent,
        DialogPresets.small({ data: patient.medicareCard })
      )
      .afterClosed()
      .toPromise();
    if (medicareCard) {
      await Firestore.patchDoc(patient.ref, { medicareCard });
    }
  }

  async editDVACard(): Promise<void> {
    const patient = await snapshot(this.patient$);
    const dvaCard = await this._dialog
      .open<EditDVACardComponent, IDVACard | undefined, IDVACard | undefined>(
        EditDVACardComponent,
        DialogPresets.small({ data: patient.dvaCard })
      )
      .afterClosed()
      .toPromise();
    if (dvaCard) {
      await Firestore.patchDoc(patient.ref, { dvaCard });
    }
  }

  async editHealthFundCard(): Promise<void> {
    const patient = await snapshot(this.patient$);
    const healthFundCard = await this._dialog
      .open<
        EditHealthFundCardComponent,
        IHealthFundCard | undefined,
        IHealthFundCard | undefined
      >(
        EditHealthFundCardComponent,
        DialogPresets.small({ data: patient.healthFundCard })
      )
      .afterClosed()
      .toPromise();
    if (healthFundCard) {
      await Firestore.patchDoc(patient.ref, { healthFundCard });
    }
  }

  async updatePatient(data: Partial<IPatient>): Promise<void> {
    const patient = await snapshot(this.patient$);
    const updatedPatient = { ...patient, ...data };
    await Firestore.saveDoc(updatedPatient);
  }
}
