import {
  IPrintPrescription,
  IPractice,
  IPrescription,
  IPrintPrescriptionSettings,
  IPatientPrescriptionDetails,
  IPracticePrescriptionDetails,
  IPrescriberPrescriptionDetails,
  IStaffer,
  IPatient,
  IPrescriptionItem,
  ICoordinate,
} from '@principle-theorem/principle-core/interfaces';
import {
  DAY_MONTH_YEAR_FORMAT,
  Firestore,
  MockGenerator,
  Timestamp,
  Timezone,
  WithRef,
  toMoment,
  toMomentTz,
  toTimestamp,
} from '@principle-theorem/shared';
import { jsPDF } from 'jspdf';
import { NameHelpers } from '../../patient/name-helpers';
import { MockWithRef } from '@principle-theorem/testing';
import { PatientMock } from '../../patient/patient.mock';
import { PractitionerMock } from '../../staffer/practitioner.mock';
import { PrescriptionMock } from '../prescription.mock';
import {
  addWatermark,
  createDocument,
  printText,
  splitTextToLines,
} from './print-prescription-helpers';

interface IHealthcareCard {
  nhi?: string;
}

type BasePatientDetails = Required<Omit<IPatientPrescriptionDetails, 'ref'>>;
type PatientDetails = Required<BasePatientDetails & IHealthcareCard>;
type PrescriberDetails = Required<Omit<IPrescriberPrescriptionDetails, 'ref'>>;
type PracticeDetails = Required<Omit<IPracticePrescriptionDetails, 'ref'>>;

export class NewZealandPrescriptionPrintStrategy implements IPrintPrescription {
  private _coordinates: { [key: string]: ICoordinate } = {
    practice: { x: 22.5, y: 30.5 },
    prescriberNameOne: { x: 22.5, y: 15 },
    prescriberNameTwo: { x: 67, y: 173 },
    qualifications: { x: 22.5, y: 21 },
    prescriberNumber: { x: 22.5, y: 25.5 },
    patient: { x: 6.5, y: 47 },
    nhi: { x: 56, y: 70 },
    prescribedDate: { x: 6.5, y: 173 },
    medication: { x: 15.5, y: 81.5 },
  };

  readonly linespacing = 3.4;
  readonly regularFontSize = 8;
  readonly largeFontSize = 10;

  async printPrescription(
    prescription: WithRef<IPrescription>
  ): Promise<WithRef<IPrescription> | string> {
    const practice = await Firestore.getDoc(prescription.practice.ref);
    const staffer = await Firestore.getDoc(prescription.prescriber.ref);
    const patient = await Firestore.getDoc(prescription.patient.ref);
    const timezone = practice.settings.timezone;
    const offset = practice.settings.print?.prescriptionMarginOffset;

    if (!patient.address || !staffer.prescriberNumber) {
      return this._checkPrescriptionRequirements(
        patient.address,
        staffer.prescriberNumber
      );
    }

    if (offset) {
      this._updateCoords(offset);
    }

    const prescribedAt = prescription.prescribedAt ?? toTimestamp();
    const prescriberData = this._getPrescriberData(staffer);
    const patientData = this._getPatientData(patient);
    const practiceData = this._getPracticeData(practice);
    const doc = createDocument({ format: 'a5' });
    this._printPrescriberDetails(doc, prescriberData);
    this._printPatientDetails(doc, patientData);
    this._printPracticeDetails(doc, practiceData);
    this._printPrescriptionDetails(doc, timezone, prescribedAt);
    this._printMedicationDetails(doc, prescription.items);

    doc.autoPrint();
    window.open(doc.output('bloburl'), '_blank');

    return {
      ...prescription,
      prescribedAt,
      patient: { ...prescription.patient, ...patientData },
      prescriber: { ...prescription.prescriber, ...prescriberData },
      practice: { ...prescription.practice, ...practiceData },
    };
  }

  printTestPrescription(practice: WithRef<IPractice>): void {
    const timezone = practice.settings.timezone;
    const offset = practice.settings.print?.prescriptionMarginOffset;

    if (offset) {
      this._updateCoords(offset);
    }

    const patient = MockWithRef(MockGenerator.generate(PatientMock));
    const staffer = MockWithRef(MockGenerator.generate(PractitionerMock));
    const prescription = MockWithRef(MockGenerator.generate(PrescriptionMock));
    const doc = createDocument({ format: 'a5' });
    addWatermark(doc, 'TEST PRESCRIPTION', 45, 150);
    this._printPrescriberDetails(doc, this._getPrescriberData(staffer));
    this._printPracticeDetails(doc, this._getPracticeData(practice));
    this._printPatientDetails(doc, this._getPatientData(patient));
    this._printMedicationDetails(doc, prescription.items);
    this._printPrescriptionDetails(doc, timezone, toTimestamp());
    doc.autoPrint();
    window.open(doc.output('bloburl'), '_blank');
  }

  private _checkPrescriptionRequirements(
    address?: string,
    prescriberNumber?: string
  ): string {
    const missingFields = [];
    if (!address) {
      missingFields.push('patient address');
    }
    if (!prescriberNumber) {
      missingFields.push('prescriber number');
    }
    if (missingFields.length > 0) {
      const missingString = missingFields.join(' and ');
      return `Cannot print prescription: missing ${missingString}. Please update and try again.`;
    }
    return '';
  }

  private _updateCoords(offset: IPrintPrescriptionSettings): void {
    Object.keys(this._coordinates).forEach((key) => {
      this._coordinates[key].x += offset.horizontalOffset;
      this._coordinates[key].y += offset.verticalOffset;
    });
  }

  private _getPrescriberData(staffer: WithRef<IStaffer>): PrescriberDetails {
    return {
      name: staffer.user.name,
      number: staffer.prescriberNumber as string,
      qualifications: staffer.qualifications ?? '',
    };
  }

  private _getPatientData(patient: WithRef<IPatient>): PatientDetails {
    const DOB = patient.dateOfBirth;

    return {
      name: NameHelpers.fullName(patient.name),
      address: patient.address as string,
      dateOfBirth: DOB ? toMoment(DOB).format(DAY_MONTH_YEAR_FORMAT) : '',
      nhi: patient.nationalHealthIndexNumber ?? '',
    };
  }

  private _getPracticeData(practice: WithRef<IPractice>): PracticeDetails {
    return {
      name: practice.name,
      address: practice.address,
      phone: practice.phone,
    };
  }

  private _printPrescriberDetails(doc: jsPDF, data: PrescriberDetails): void {
    const {
      prescriberNameOne,
      prescriberNameTwo,
      prescriberNumber,
      qualifications,
    } = this._coordinates;

    doc.setFontSize(this.largeFontSize);
    printText(doc, data.name, prescriberNameOne, { fontStyle: 'bold' });
    doc.setFontSize(this.regularFontSize);
    printText(doc, data.qualifications, qualifications);
    printText(doc, `NZMC: ${data.number}`, prescriberNumber);
    printText(doc, data.name, prescriberNameTwo);
  }

  private _printPatientDetails(doc: jsPDF, data: PatientDetails): void {
    const maxWidth = 87.5;
    const { name, address, dateOfBirth } = data;
    const x = this._coordinates.patient.x;
    let currentYPos = this._coordinates.patient.y;

    printText(
      doc,
      name,
      { x, y: currentYPos },
      { maxWidth, fontStyle: 'bold' }
    );

    currentYPos += this.linespacing;

    splitTextToLines(doc, address, maxWidth).forEach((line) => {
      printText(doc, line, { x, y: currentYPos });
      currentYPos += this.linespacing;
    });

    printText(doc, dateOfBirth ? `Date of Birth: ${data.dateOfBirth}` : '', {
      x,
      y: currentYPos,
    });

    printText(doc, `NHI: ${data.nhi}`, {
      x: this._coordinates.nhi.x,
      y: currentYPos,
    });
  }

  private _printPracticeDetails(doc: jsPDF, data: PracticeDetails): void {
    const maxWidth = 71.5;
    const x = this._coordinates.practice.x;
    const { name, address, phone } = data;
    let currentYPos = this._coordinates.practice.y;

    printText(
      doc,
      name,
      { x, y: currentYPos },
      { maxWidth, fontStyle: 'bold' }
    );

    currentYPos += this.linespacing;

    splitTextToLines(doc, address, maxWidth).forEach((line) => {
      printText(doc, line, { x, y: currentYPos });
      currentYPos += this.linespacing;
    });

    printText(doc, `Phone: ${phone}`, { x, y: currentYPos });
  }

  private _printPrescriptionDetails(
    doc: jsPDF,
    timezone: Timezone,
    prescribedAt: Timestamp
  ): void {
    const prescribedDate = toMomentTz(prescribedAt, timezone).format(
      DAY_MONTH_YEAR_FORMAT
    );

    printText(doc, prescribedDate, this._coordinates.prescribedDate);
  }

  private _printMedicationDetails(
    doc: jsPDF,
    items: IPrescriptionItem[]
  ): void {
    const maxWidth = 122;
    const x = this._coordinates.medication.x;
    let currentYPos = this._coordinates.medication.y;

    printText(doc, `Rx:`, { x: 6.5, y: 81.5 });

    items.forEach((item) => {
      const { display, instructions, info } = this._getItemPrintData(item);

      splitTextToLines(doc, item.medicationName, maxWidth, true).forEach(
        (line) => {
          printText(
            doc,
            line,
            { x, y: currentYPos },
            { maxWidth, fontStyle: 'bold' }
          );
          currentYPos += this.linespacing;
        }
      );

      printText(doc, display, { x, y: currentYPos });

      if (item.instructions || item.safetyInformation) {
        currentYPos += this.linespacing;
      }

      if (item.instructions) {
        splitTextToLines(doc, instructions, maxWidth).forEach((line) => {
          printText(doc, line, { x, y: currentYPos });
          currentYPos += this.linespacing;
        });
      }

      if (item.safetyInformation) {
        splitTextToLines(doc, info, maxWidth).forEach((line) => {
          printText(doc, line, { x, y: currentYPos });
          currentYPos += this.linespacing;
        });
      }

      currentYPos += this.linespacing;
    });
  }

  private _getItemPrintData(item: IPrescriptionItem): {
    display: string;
    instructions: string;
    info: string;
  } {
    const quantity = item.quantity ? `Quantity: ${item.quantity}` : '0';
    const repeatXTimes = item.repeat ? item.repeat : 0;
    const repeat = repeatXTimes > 1 ? `Repeats` : `Repeat`;
    const display = `${quantity} ${repeat}: ${repeatXTimes}`;
    const instructions = item.instructions ?? '';
    const info = item.safetyInformation
      ? `Comment: ${item.safetyInformation}`
      : '';
    return { display, instructions, info };
  }
}
