import {
  rand,
  randBetweenDate,
  randCompanyName,
  randEmail,
  randFullName,
  randImg,
  randNumber,
  randPhoneNumber,
  randSentence,
  randStreetAddress,
} from '@ngneat/falso';
import {
  initVersionedSchema,
  toLinkContent,
  toMentionContent,
  toTextContent,
} from '@principle-theorem/editor';
import {
  ChartView,
  GENDERS,
  IBasePatient,
  IFeeSchedule,
  IInteractionV2,
  INote,
  IPatient,
  IPatientContactDetails,
  IPractice,
  IReferralSource,
  IStaffer,
  ITag,
  InteractionType,
  MentionResourceType,
  PATIENT_RELATIONSHIP_TYPES,
  PatientStatus,
  initAccountSummary,
} from '@principle-theorem/principle-core/interfaces';
import {
  BaseFirestoreMock,
  DATE_FORMAT,
  MockBaseFirestore,
  MockGenerator,
  MockISODate,
  MockTimestamp,
  Properties,
  WithRef,
  toISODate,
  toTimestamp,
} from '@principle-theorem/shared';
import { MockNamedDocument, MockWithRef } from '@principle-theorem/testing';
import * as moment from 'moment-timezone';
import { v4 as uuid } from 'uuid';
import { Interaction } from '../interaction/interaction';
import { toMention } from '../mention/mention';
import { Note } from '../note/note';
import { NoteMock } from '../note/note.mock';
import { DEFAULT_REFERRAL_SOURCES, ReferralSource } from './referral-source';

export class PatientMock
  extends BaseFirestoreMock
  implements Properties<IPatient>
{
  name = `${randFullName()}`;
  address = randStreetAddress();
  contactNumbers = [
    { label: '', number: randPhoneNumber() },
    { label: 'mobile', number: '0478123456' },
  ];
  email = randEmail();
  referrer: IReferralSource = {
    ...MockNamedDocument<IPatient>(),
    type: MentionResourceType.Patient,
  };
  referenceId = uuid();
  relationships = [
    {
      patient: MockNamedDocument<IPatient>(),
      type: rand(PATIENT_RELATIONSHIP_TYPES),
    },
  ];
  dateOfBirth = MockISODate();
  gender = rand(GENDERS);
  status = PatientStatus.Active;
  statusHistory = [
    {
      status: PatientStatus.Inactive,
      updatedAt: MockTimestamp(),
    },
    {
      status: PatientStatus.Active,
      updatedAt: MockTimestamp(),
    },
  ];
  settings = {
    charting: {
      view: ChartView.Adult,
    },
  };
  preferredDentist = MockNamedDocument<IStaffer>();
  preferredHygienist = MockNamedDocument<IStaffer>();
  preferredPractice = MockNamedDocument<IPractice>();
  notes = [MockGenerator.generate(NoteMock)];
  tags = [MockNamedDocument<ITag>()];
  lastVisit = MockTimestamp();
  preferredFeeSchedule = MockNamedDocument<IFeeSchedule>();
  profileImageURL = randImg();
  medicareCard = {
    number: `${randNumber()}`,
    subNumerate: `${randNumber()}`,
    expiryDate: MockTimestamp(),
  };
  dvaCard = {
    number: `${randNumber()}`,
    expiryDate: MockTimestamp(),
  };
  healthFundCard = {
    membershipNumber: `${randNumber()}`,
    memberNumber: `${randNumber()}`,
  };
  healthInsuranceDetails = {
    provider: randCompanyName(),
    membershipNumber: `${randNumber()}`,
  };
  nationalHealthIndexNumber = `${randNumber()}`; // - NHI - National Health Index Number (New Zealand)
  accountSummary = initAccountSummary();
  metadata = {};
}

export function MockPatient(
  name?: string
): IBasePatient & IPatientContactDetails {
  return {
    ...MockBaseFirestore(),
    name: name ? name : rand(PATIENTS),
    address: randStreetAddress(),
    relationships: [],
    status: PatientStatus.Active,
    statusHistory: [],
    email: randEmail(),
    gender: rand(GENDERS),
    contactNumbers: [{ label: 'Mobile', number: randPhoneNumber() }],
    dateOfBirth: toISODate(randBetweenDate({ from: '1960', to: '2017' })),
    referrer: rand(
      DEFAULT_REFERRAL_SOURCES.map((referrer) =>
        ReferralSource.toReferrer(MockWithRef(referrer))
      )
    ),
    notes: PATIENT_NOTES,
    tags: [],
    profileImageURL: rand(PROFILE_PICS),
    accountSummary: initAccountSummary(),
  };
}

export const PROFILE_PICS: string[] = [
  `https://firebasestorage.googleapis.com/v0/b/principle-dev.appspot.com/o/users%2FprofilePics%2F` +
    `1044023_10152975393655457_1907006679_n.jpg?alt=media&token=28ee7127-4e38-4aec-8910-d2d166116ad7`,
  `https://firebasestorage.googleapis.com/v0/b/principle-dev.appspot.com/o/users%2FprofilePics%2F` +
    `10592955_10154407951415693_579205735785527126_n.jpg?alt=media&token=b27df2f7-f506-4d2a-9fb0-5c05ecd8eb6c`,
  `https://firebasestorage.googleapis.com/v0/b/principle-dev.appspot.com/o/users%2FprofilePics%2F` +
    `10960032_10155202262270487_9173500961963737537_o.jpg?alt=media&token=36b8ff73-7bb9-41fc-9f68-1c16e68d30cc`,
  `https://firebasestorage.googleapis.com/v0/b/principle-dev.appspot.com/o/users%2FprofilePics%2F` +
    `13043742_10156907490590457_7948568696113833965_n.jpg?alt=media&token=5cf7ec56-c622-4146-81ae-c11d92f60e01`,
  `https://firebasestorage.googleapis.com/v0/b/principle-dev.appspot.com/o/users%2FprofilePics%2F` +
    `14448980_10157456428810693_1168626316083576050_n.jpg?alt=media&token=c496fa51-3eaf-4d26-a137-e95ad5be6a43`,
  `https://firebasestorage.googleapis.com/v0/b/principle-dev.appspot.com/o/users%2FprofilePics%2F` +
    `17504580_10158545995305457_2595949758415143578_o.jpg?alt=media&token=9e9347b5-706c-40dd-b65a-6d8540e8e6b5`,
  `https://firebasestorage.googleapis.com/v0/b/principle-dev.appspot.com/o/users%2FprofilePics%2F` +
    `20287044_10212580735687426_552832733444367451_o.jpg?alt=media&token=abb577c7-729e-441a-b0db-e03017bff0c5`,
  `https://firebasestorage.googleapis.com/v0/b/principle-dev.appspot.com/o/users%2FprofilePics%2F` +
    `22050946_10213076205193854_8364909345614161373_o.jpg?alt=media&token=8f87a4ca-4ea2-4407-a46f-232f479434d8`,
  `https://firebasestorage.googleapis.com/v0/b/principle-dev.appspot.com/o/users%2FprofilePics%2F` +
    `23415650_10155985296198958_1187136561788304394_o.jpg?alt=media&token=27fcb0c3-9cf1-4dd2-b926-50a21a6ae2b3`,
  `https://firebasestorage.googleapis.com/v0/b/principle-dev.appspot.com/o/users%2FprofilePics%2F` +
    `26114085_10155968630129395_4761594343080206927_n.jpg?alt=media&token=53797e86-8334-465d-b0fd-24403e44035d`,
  `https://firebasestorage.googleapis.com/v0/b/principle-dev.appspot.com/o/users%2FprofilePics%2F` +
    `26685951_10160004566540457_907177571362615301_o.jpg?alt=media&token=29e1afc7-f604-4cc4-b824-baa03b829906`,
  `https://firebasestorage.googleapis.com/v0/b/principle-dev.appspot.com/o/users%2FprofilePics%2F` +
    `26730794_10156200656618958_6380998990042008023_n.jpg?alt=media&token=8a297c7d-f15a-4c82-9291-6a328675ca49`,
  `https://firebasestorage.googleapis.com/v0/b/principle-dev.appspot.com/o/users%2FprofilePics%2F` +
    `27971821_10156303730678958_1776490776860879706_n.jpg?alt=media&token=cd2ca6f7-ec0c-4c8e-9fbd-b01e67b5d3a5`,
  `https://firebasestorage.googleapis.com/v0/b/principle-dev.appspot.com/o/users%2FprofilePics%2F` +
    `304376_10150774833005693_3928778_n.jpg?alt=media&token=8383c9ab-fccf-452c-89f8-a19c624b3d08`,
  `https://firebasestorage.googleapis.com/v0/b/principle-dev.appspot.com/o/users%2FprofilePics%2F` +
    `30442489_10160453291645457_1689782946203959296_o.jpg?alt=media&token=a5556c35-3437-4bb0-b6ab-e45e75280dc2`,
  `https://firebasestorage.googleapis.com/v0/b/principle-dev.appspot.com/o/users%2FprofilePics%2F` +
    `38151862_1982155608471873_2926906533029609472_o.jpg?alt=media&token=f65a5145-3901-483f-a315-d1cb9b9fe183`,
  `https://firebasestorage.googleapis.com/v0/b/principle-dev.appspot.com/o/users%2FprofilePics%2F` +
    `64398_10150288733720693_7118061_n.jpg?alt=media&token=d3ace27f-0d7b-470b-8d5a-eab941b89717`,
  `https://firebasestorage.googleapis.com/v0/b/principle-dev.appspot.com/o/users%2FprofilePics%2F` +
    `Screen%20Shot%202018-06-05%20at%204.31.16%20pm.png?alt=media&token=414bfcf1-4c50-4814-8212-976feb0ca664`,
  `https://firebasestorage.googleapis.com/v0/b/principle-dev.appspot.com/o/users%2FprofilePics%2F` +
    `young%20model%20toby.jpg?alt=media&token=592977ed-002a-4de9-b44d-1d158d6fcb28`,
];

/**
 * PATIENTS contains a list ofMocked patients
 */
export const PATIENTS: string[] = [
  // Politicians
  'Eric Abetz',
  'Fraser Anning',
  'Chris Back',
  'Andrew Bartlett',
  'Cory Bernardi',
  'Catryna Bilyk',
  'Simon Birmingham',
  'George Brandis',
  'Slade Brockman',
  'Carol Brown',
  'Brian Burston',
  'David Bushby',
  'Doug Cameron',
  'Matthew Canavan',
  'Kim Carr',
  'Michaelia Cash',
  'Anthony Chisholm',
  'Richard Colbeck',
  'Jacinta Collins',
  'Stephen Conroy',
  'Mathias Cormann',
  'Rod Culleton',
  'Sam Dastyari',
  'Bob Day',
  'Richard Di Natale',
  'Pat Dodson',
  'Jonathon Duniam',
  'Don Farrell',
  'David Fawcett',
  'Concetta Fierravanti-Wells',
  'Mitch Fifield',
  'Alex Gallacher',
  'Katy Gallagher',
  'Peter Georgiou',
  'Lucy Gichuhi',
  'Stirling Griff',
  'Pauline Hanson',
  'Sarah Hanson-Young',
  'Derryn Hinch',
  'Jane Hume',
  'Skye Kakoschke-Moore',
  'Kristina Keneally',
  'Chris Ketter',
  'Kimberley Kitching',
  'Jacqui Lambie',
  'David Leyonhjelm',
  'Sue Lines',
  'Ian Macdonald',
  'Gavin Marshall',
  'Steve Martin',
  'Jenny McAllister',
  'Malarndirri McCarthy',
  'James McGrath',
  'Bridget McKenzie',
  'Nick McKim',
  'Jim Molan',
  'Claire Moore',
  `Deborah O'Neill`,
  `Barry O'Sullivan`,
  'James Paterson',
  'Rex Patrick',
  'Marise Payne',
  'Helen Polley',
  'Louise Pratt',
  'Linda Reynolds',
  'Lee Rhiannon',
  'Janet Rice',
  'Anne Ruston',
  'Scott Ryan',
  'Nigel Scullion',
  'Zed Seselja',
  'Rachel Siewert',
  'Lisa Singh',
  'Arthur Sinodinos',
  'Dean Smith',
  'Jordon Steele-John',
  'Glenn Sterle',
  'Tim Storer',
  'Anne Urquhart',
  'Murray Watt',
  'Peter Whish-Wilson',
  'John Williams',
  'Penny Wong',
  'Nick Xenophon',

  // Rappers
  'Eminem',
  'Kendrick Lamar',
  'Snoop Dogg',
  'Dr. Dre',
  '50 Cent',
  'Nicki Minaj',
  'Lil Wayne',
  'Drake',
  'Big Sean',
  'Joyner Lucas',
  'Post Malone',
  'Kanye West',
  'Travis Scott',
  'J. Cole',
  'Wiz Khalifa',
  'A Boogie Wit Da Hoodie',
  'Jay-Z',
  'Logic',
  'Childish Gambino',
  'Lil Uzi Vert',
  'ASAP Rocky',
  'Ice Cube',
  '21 Savage',
  'DMX',
  'Cardi B',
  'Future',
  'Tupac Shakur',
  'Nas',
  'The Notorious B.I.G.',
  'André 3000',
  'Travis Scott',
  'Rakim',
  'Eazy-E',
  'Gucci Mane',
  'Busta Rhymes',
  'Ludacris',
  'Rick Ross',
  'Chance The Rapper',
  '2 Chainz',
  'Young Thug',
  'Sean Combs',
  'Big Boi',
  'LL Cool J',
  'Method Man',
  'Common',
  'Meek Mill',
  'Big Daddy Kane',
  'Ghostface Killah',
  'Mos Def',
  'Kodak Black',
  'Swae Lee',
  'Mac Miller',

  // Scientists
  'Albert Einstein',
  'Isaac Newton',
  'Marie Curie',
  'Galileo Galilei',
  'Charles Darwin',
  'Stephen Hawking',
  'Thomas Edison',
  'Louis Pasteur',
  'Nicolaus Copernicus',
  'Nikola Tesla',
  'Michael Faraday',
  'Aristotle',
  'Leonardo da Vinci',
  'Alexander Graham Bell',
  'Neils Bohr',
  'Francis Crick',
  'James Watson',
  'Rosalind Franklin',
  'Archimedes',
  'Max Planck',
  'Alfred Nobel',
  'Antione Lavoisier',
  'James Clerk Maxwell',
  'Alexander Fleming',
  'Johannes Kepler',
  'Gregor Mendel',
  'Enrico Fermi',
  'Ernest Rutherford',
  'Rachel Carson',
  'Dmitri Mendeleev',
  'Richard Feynman',
  'William Thomson',
  'Carl Sagan',
  'Robert Boyle',
  'Tim Berners-Lee',
  'George Washington Carver',
  'Linus Pauling',
  'Ada Lovelace',
  'Alessandro Volta',
  'John Dalton',
  'Carl Linnaeus',
  'Wilhelm Röntgen',
  'Edwin Hubble',
  'J. J. Thomson',
  'J. Rober Oppenheimer',
  'Paul Dirac',
  'Erwin Schrödinger',
  'James Watt',
  'Blaise Pascal',
  'Robert Hooke',
  'André-Marie Ampère',

  // Philosophers
  'Plato',
  'Aristotle',
  'Socrates',
  'Immanuel Kant',
  'René Descartes',
  'Fredrich Neitzsche',
  'Ludwig Wittgenstein',
  'David Hume',
  'Thomas Aquinas',
  'Confucius',
  'Karl Marx',
  'Jean-Jacques Rosseau',
  'John Locke',
  'Jean-Paul Sartre',
  'Bertrand Russell',
  'Baurch Spinoza',
  'Jogn Stuart Mill',
  'Epicurus',
  'John Rawls',
  'Michael Foucault',
  'Martin Heidegger',
  'Georg Wilhelm Friedrich Hegel',
  'Goltfriend Wilhelm Leibniz',
  'Hannah Arendt',
  'Søren Kierkegaard',
  'Thomas Hobbes',
  'Simon de Beauvoir',
  'Voltaire',
  'Francis Bacon',
  'John Dewey',
  'Albert Camus',
  'Jacques Derrida',
  'Henry David Thoreau',
  'Noam Chomsky',
  'William James',
  'Mary Wollstonecraft',

  // Female Singers
  'Diana Ross',
  'Aretha Franklin',
  'Donna SUmmer',
  'Tina Turner',
  'Kate Bush',
  'Billie Holiday',
  'Donnie Warwick',
  'Chaka Khan',
  'Whitney Houston',
  'Cyndi Lauper',
  'Janet Jackson',
  'Nina Simone',
  'Céline Dion',
  'Bonnie Tyler',
  'Ella Fitzgerald',
  'Debbie Harry',
  'Minnie Riperton',
  'Grace Slick',
  'Karen Carpenter',
];

export function MockPatientInteractions(
  patient: WithRef<IBasePatient & IPatientContactDetails>
): IInteractionV2[] {
  if (!patient.preferredDentist || !patient.preferredHygienist) {
    return [];
  }

  return [
    {
      type: InteractionType.Patient,
      createdAt: toTimestamp(
        moment().subtract({
          days: 89,
          hours: 4,
          minutes: 32,
        })
      ),
      title: [toTextContent(`Added ${patient.name} to the System`)],
      content: initVersionedSchema(),
    },
    {
      type: InteractionType.Appointment,
      createdAt: toTimestamp(
        moment().subtract({
          days: 89,
          hours: 4,
          minutes: 32,
        })
      ),
      title: [toTextContent('Booked Appointment')],
      content: initVersionedSchema([
        toLinkContent('Initial Patient Evaluation', '#'),
        toTextContent(' booked with '),
        toLinkContent(patient.preferredDentist.ref.id, '#'),
        toTextContent(' on '),
        toLinkContent(
          moment()
            .subtract({ days: 18, hours: 3, minutes: 0 })
            .format(DATE_FORMAT),
          '#'
        ),
      ]),
    },
    {
      type: InteractionType.Email,
      createdAt: toTimestamp(
        moment().subtract({
          days: 89,
          hours: 4,
          minutes: 31,
        })
      ),
      title: [toTextContent('Welcome Package Sent')],
      content: initVersionedSchema(),
    },
    {
      type: InteractionType.Email,
      createdAt: toTimestamp(
        moment().subtract({
          days: 89,
          hours: 4,
          minutes: 31,
        })
      ),
      title: [toTextContent('Appointment Confirmation Sent')],
      content: initVersionedSchema(),
    },
    {
      type: InteractionType.Patient,
      createdAt: toTimestamp(
        moment().subtract({
          days: 88,
          hours: 15,
          minutes: 0,
        })
      ),
      title: [toTextContent('Medical History Submitted')],
      content: initVersionedSchema(),
    },
    {
      type: InteractionType.Sms,
      createdAt: toTimestamp(
        moment().subtract({
          days: 82,
          hours: 0,
          minutes: 5,
        })
      ),
      title: [toTextContent('Appointment Reminder Sent')],
      content: initVersionedSchema(),
    },
    {
      type: InteractionType.Sms,
      createdAt: toTimestamp(
        moment().subtract({
          days: 81,
          hours: 17,
          minutes: 2,
        })
      ),
      title: [toTextContent('Appointment Reminder Confirmed')],
      content: initVersionedSchema(),
    },
    {
      type: InteractionType.Appointment,
      createdAt: toTimestamp(
        moment().subtract({
          days: 18,
          hours: 3,
          minutes: 0,
        })
      ),
      title: [toTextContent('Arrived for Appointment')],
      content: initVersionedSchema(),
    },
    {
      type: InteractionType.Appointment,
      createdAt: toTimestamp(
        moment().subtract({
          days: 18,
          hours: 2,
          minutes: 15,
        })
      ),
      title: [toTextContent('Completed Appointment')],
      content: initVersionedSchema(),
    },
    {
      type: InteractionType.Appointment,
      createdAt: toTimestamp(
        moment().subtract({
          days: 18,
          hours: 2,
          minutes: 14,
        })
      ),
      title: [toTextContent('Booked Appointment')],
      content: initVersionedSchema([
        toTextContent('Hygiene appointment booked with '),
        toLinkContent(patient.preferredHygienist.name, '#'),
        toTextContent(' on '),
        toLinkContent(
          moment().add({ days: 3, hours: 0, minutes: 5 }).format(DATE_FORMAT),
          '#'
        ),
      ]),
    },
    {
      type: InteractionType.Email,
      createdAt: toTimestamp(
        moment().subtract({
          days: 18,
          hours: 2,
          minutes: 13,
        })
      ),
      title: [toTextContent('Appointment Confirmation Sent')],
      content: initVersionedSchema(),
    },
    {
      type: InteractionType.Payment,
      createdAt: toTimestamp(
        moment().subtract({
          days: 18,
          hours: 2,
          minutes: 12,
        })
      ),
      title: [toTextContent('Invoice Paid')],
      content: initVersionedSchema('Invoice paid for Appointment.'),
    },
    {
      type: InteractionType.Gap,
      createdAt: toTimestamp(
        moment().subtract({
          days: 15,
          hours: 1,
          minutes: 45,
        })
      ),
      title: [toTextContent('Added to Short List')],
      content: initVersionedSchema([
        toTextContent('Added to short list for gap on the '),
        toLinkContent(
          moment()
            .subtract({ days: 3, hours: 0, minutes: 5 })
            .format(DATE_FORMAT),
          '#'
        ),
      ]),
    },
    {
      type: InteractionType.Gap,
      createdAt: toTimestamp(
        moment().subtract({
          days: 15,
          hours: 1,
          minutes: 14,
        })
      ),
      title: [toTextContent('Accepted Offer to Move Appointment')],
      content: initVersionedSchema(
        `Offer accepted to move appointment to the ` +
          moment()
            .subtract({ days: 3, hours: 0, minutes: 5 })
            .format(DATE_FORMAT)
      ),
    },
    {
      type: InteractionType.Appointment,
      createdAt: toTimestamp(
        moment().subtract({
          days: 15,
          hours: 1,
          minutes: 13,
        })
      ),
      title: [toTextContent(`Appointment Rescheduled`)],
      content: initVersionedSchema([
        toTextContent('Appointment moved to the '),
        toLinkContent(
          moment()
            .subtract({ days: 3, hours: 0, minutes: 5 })
            .format(DATE_FORMAT),
          '#'
        ),
      ]),
    },
    {
      type: InteractionType.Sms,
      createdAt: toTimestamp(
        moment().subtract({
          days: 5,
          hours: 0,
          minutes: 5,
        })
      ),
      title: [toTextContent('Appointment Reminder Sent')],
      content: initVersionedSchema(),
    },
    {
      type: InteractionType.Sms,
      createdAt: toTimestamp(
        moment().subtract({
          days: 4,
          hours: 16,
          minutes: 0,
        })
      ),
      title: [toTextContent('Appointment Reminder Confirmed')],
      content: initVersionedSchema(),
    },
    {
      type: InteractionType.Appointment,
      createdAt: toTimestamp(
        moment().subtract({
          days: 3,
          hours: 1,
          minutes: 10,
        })
      ),
      title: [toTextContent('Arrived for Appointment')],
      content: initVersionedSchema(),
    },
    {
      type: InteractionType.Appointment,
      createdAt: toTimestamp(
        moment().subtract({
          days: 3,
          hours: 0,
          minutes: 5,
        })
      ),
      title: [toTextContent('Completed Appointment')],
      content: initVersionedSchema(),
    },
    {
      type: InteractionType.Appointment,
      createdAt: toTimestamp(
        moment().subtract({
          days: 3,
          hours: 0,
          minutes: 5,
        })
      ),
      title: [toTextContent('Follow Up for Next Appointment')],
      notes: initVersionedSchema([
        toTextContent('Will follow up in 2 days to book '),
        toLinkContent('3 Unit Bridge', '#'),
        toTextContent(' appointment.'),
      ]),
    },
    {
      type: InteractionType.Payment,
      createdAt: toTimestamp(
        moment().subtract({
          days: 3,
          hours: 0,
          minutes: 4,
        })
      ),
      title: [toTextContent('Invoice Paid')],
      content: initVersionedSchema('Invoice paid for Appointment.'),
    },
    {
      type: InteractionType.Call,
      createdAt: toTimestamp(
        moment().subtract({
          days: 1,
          hours: 0,
          minutes: 5,
        })
      ),
      title: [toTextContent('Called Patient')],
      content: initVersionedSchema([
        toTextContent('Called '),
        toMentionContent(toMention(patient, MentionResourceType.Patient)),
        toTextContent(
          ` on ${
            patient.contactNumbers
              ? patient.contactNumbers[0].number
              : randPhoneNumber()
          } to book `
        ),
        toLinkContent('3 Unit Bridge', '#'),
        toTextContent(' appointment.'),
      ]),
    },
    {
      type: InteractionType.Appointment,
      createdAt: toTimestamp(
        moment().subtract({
          days: 1,
          hours: 0,
          minutes: 4,
        })
      ),
      title: [toTextContent('Booked Appointment')],
      notes: initVersionedSchema([
        toLinkContent('3 Unit Bridge', '#'),
        toTextContent(' booked with '),
        toMentionContent(
          toMention(patient.preferredDentist, MentionResourceType.Staffer)
        ),
        toTextContent(' on '),
        toLinkContent(
          moment().add({ days: 18, hours: 3, minutes: 0 }).format(DATE_FORMAT),
          '#'
        ),
      ]),
    },
    {
      type: InteractionType.Email,
      createdAt: toTimestamp(
        moment().subtract({
          days: 1,
          hours: 0,
          minutes: 3,
        })
      ),
      title: [toTextContent('Appointment Confirmation Sent')],
      content: initVersionedSchema(),
    },
    {
      type: InteractionType.Task,
      createdAt: toTimestamp(
        moment().subtract({
          days: 1,
          hours: 0,
          minutes: 2,
        })
      ),
      title: [toTextContent('Finished Follow Up Task')],
      content: initVersionedSchema(
        'Finished task to followed up to book appointment.'
      ),
    },
  ].map((data: Partial<IInteractionV2>) => Interaction.init(data));
}

const notes: Partial<INote>[] = [
  {
    content: initVersionedSchema('Prefers soda water on arrival'),
    pinned: true,
    createdAt: toTimestamp(
      moment().subtract({
        days: 90,
      })
    ),
  },
  {
    content: initVersionedSchema('Had a new baby boy Aerial'),
    pinned: false,
    createdAt: toTimestamp(
      moment().subtract({
        days: 18,
        hours: 3,
      })
    ),
  },
  {
    content: initVersionedSchema(
      'Likes to have history channel on during visit'
    ),
    pinned: true,
    createdAt: toTimestamp(
      moment().subtract({
        days: 76,
        hours: 0,
        minutes: 0,
      })
    ),
  },
  {
    content: initVersionedSchema(randSentence()),
    pinned: false,
    createdAt: toTimestamp(
      moment().subtract({
        days: 60,
        hours: 0,
        minutes: 0,
      })
    ),
  },
  {
    content: initVersionedSchema(randSentence()),
    pinned: false,
    createdAt: toTimestamp(
      moment().subtract({
        days: 48,
        hours: 0,
        minutes: 0,
      })
    ),
  },
];

export const PATIENT_NOTES: INote[] = notes.map((data: Partial<INote>) =>
  Note.init({
    ...data,
    updatedAt: toTimestamp(),
  })
);
