import jsPDF from 'jspdf';
import { IBarcodeTemplate } from './barcode-template';
import JsBarcode from 'jsbarcode';
import { chunk, range } from 'lodash';
import { uid } from '@principle-theorem/shared';

export class BarcodeSheetGenerator {
  private _pdf: jsPDF;

  constructor(
    private _template: IBarcodeTemplate,
    private _sheetQuantity = 1
  ) {
    this._pdf = new jsPDF({
      orientation: _template.orientation,
      unit: 'mm',
      format: [_template.paperWidth, _template.paperHeight],
    });
  }

  generateLabelUids(): string[] {
    return Array.from(
      { length: Number(this._template.quantity * this._sheetQuantity) },
      () => uid()
    );
  }

  generateLabels(barcodeIds: string[]): BarcodeSheetGenerator {
    const barcodes = this._generateBarcodes(barcodeIds, {
      height: this._template.labelHeight,
      fontSize: 16,
      marginLeft: 50,
      marginRight: 50,
    });

    const barcodePages = chunk(barcodes, this._template.quantity);
    barcodePages.map((pageBarcodes, pageIndex) => {
      if (pageIndex > 0) {
        this._pdf.addPage();
      }

      chunk(range(this._template.quantity), this._template.columns).map(
        (rows, rowIndex) =>
          rows.map((_, columnIndex) =>
            this._positionBarcodeOnPage(columnIndex, rowIndex, pageBarcodes)
          )
      );
    });

    return this;
  }

  save(filename = 'pr-sterilisation-barcodes.pdf'): void {
    this._pdf.save(filename);
  }

  getDataUrl(): string {
    return this._pdf.output('dataurlstring');
  }

  private _generateBarcodes(
    barcodeIds: string[],
    options?: JsBarcode.Options
  ): HTMLCanvasElement[] {
    return barcodeIds.map((id) => {
      const canvas = document.createElement('canvas');
      JsBarcode(canvas, id, { ...options, ean128: true });
      return canvas;
    });
  }

  private _positionBarcodeOnPage(
    columnIndex: number,
    rowIndex: number,
    barcodes: HTMLCanvasElement[]
  ): void {
    const xPosition = this._calculateLabelXPosition(columnIndex);
    const yPosition = this._calculateLabelYPosition(rowIndex);
    const canvas = barcodes[rowIndex * this._template.columns + columnIndex];

    if (canvas) {
      this._pdf.addImage(
        canvas,
        xPosition,
        yPosition,
        this._template.labelWidth,
        this._template.labelHeight
      );
    }
  }

  private _calculateCenteredMargin(
    totalItems: number,
    itemSize: number,
    itemGap: number,
    pageSize: number
  ): number {
    const totalContentWidth =
      itemSize * totalItems + itemGap * (totalItems - 1);
    return (pageSize - totalContentWidth) / 2;
  }

  private _calculateLabelXPosition(columnIndex: number): number {
    const leftMargin = this._calculateCenteredMargin(
      this._template.columns,
      this._template.labelWidth,
      this._template.columnGap,
      this._template.paperWidth
    );

    return (
      leftMargin +
      columnIndex * (this._template.labelWidth + this._template.columnGap)
    );
  }

  private _calculateLabelYPosition(rowIndex: number): number {
    const topMargin = this._calculateCenteredMargin(
      this._template.rows,
      this._template.labelHeight,
      this._template.rowGap,
      this._template.paperHeight
    );
    return (
      topMargin +
      rowIndex * (this._template.labelHeight + this._template.rowGap)
    );
  }
}
