import { initVersionedSchema, toTextContent } from '@principle-theorem/editor';
import {
  Appointment,
  Brand,
  Event,
  Interaction,
  TreatmentPlan,
  TreatmentStep,
  hasMergeConflicts,
  stafferToNamedDoc,
} from '@principle-theorem/principle-core';
import {
  AppointmentStatus,
  DestinationEntityRecordStatus,
  EventType,
  IDestinationEntity,
  IMigratedDataSummary,
  ITranslationMap,
  InteractionType,
  ParticipantType,
  SkippedDestinationEntityRecord,
  TreatmentPlanStatus,
  TreatmentStepStatus,
  type FailedDestinationEntityRecord,
  type IAppointment,
  type IBrand,
  type IDestinationEntityRecord,
  type IEvent,
  type IGetRecordResponse,
  type IInteractionV2,
  type IPatient,
  type IPractice,
  type IPracticeMigration,
  type ISourceEntityRecord,
  type IStaffer,
  type ITag,
  type ITreatmentPlan,
  type ITreatmentStep,
  type MergeConflictDestinationEntityRecord,
  type MigratedDestinationEntityRecord,
} from '@principle-theorem/principle-core/interfaces';
import {
  DEFAULT_TIMEZONE,
  Firestore,
  HISTORY_DATE_FORMAT,
  SystemActors,
  asDocRef,
  asyncForEach,
  find$,
  getDoc,
  getDocs,
  getError,
  initFirestoreModel,
  isINamedDocument,
  isObject,
  multiFilter,
  multiMap,
  snapshot,
  toInt,
  toMoment,
  toNamedDocument,
  toTimestamp,
  upsertBulk,
  where,
  type DocumentReference,
  type INamedDocument,
  type Timestamp,
  type WithRef,
} from '@principle-theorem/shared';
import { isString, omit, sortBy } from 'lodash';
import * as moment from 'moment-timezone';
import { combineLatest, of, type Observable } from 'rxjs';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import { BaseDestinationEntity } from '../../../destination/base-destination-entity';
import { FirestoreMigrate } from '../../../destination/destination';
import { DestinationEntity } from '../../../destination/destination-entity';
import { PATIENT_APPOINTMENT_RESOURCE_TYPE } from '../../../destination/entities/patient-appointments';
import { STAFFER_RESOURCE_TYPE } from '../../../destination/entities/staff';
import { PatientIdFilter } from '../../../destination/filters/patient-id-filter';
import { PracticeIdFilter } from '../../../destination/filters/practice-id-filter';
import { buildSkipMigratedQuery } from '../../../source/source-entity-record';
import { type TranslationMapHandler } from '../../../translation-map';
import {
  PATIENT_RESOURCE_TYPE,
  PatientSourceEntity,
} from '../../source/entities/patient';
import {
  PatientAppointmentSourceEntity,
  type ID4WAppointment,
  type ID4WAppointmentFilters,
  type ID4WAppointmentTranslations,
} from '../../source/entities/patient-appointment';
import { D4WAppointmentBookToPractitionerMappingHandler } from '../mappings/appointment-book-to-practitioner';
import {
  AppointmentStatusMapType,
  D4WAppointmentStatusMappingHandler,
} from '../mappings/appointment-statuses';
import { D4WExcludedAppointmentBooksMappingHandler } from '../mappings/exclude-appointment-books';
import { D4WPracticeMappingHandler } from '../mappings/practices';
import { D4WStafferMappingHandler } from '../mappings/staff';
import { PatientDestinationEntity } from './patients';
import { StafferDestinationEntity } from './staff';
import {
  PATIENT_TREATMENT_PLAN_CUSTOM_MAPPING_TYPE,
  PATIENT_TREATMENT_STEP_CUSTOM_MAPPING_TYPE,
} from '../../../destination/entities/patient-treatment-plans';

export const PATIENT_APPOINTMENT_DESTINATION_ENTITY = DestinationEntity.init({
  metadata: {
    key: PATIENT_APPOINTMENT_RESOURCE_TYPE,
    label: 'Patient Appointments',
    description: `Unfortunately D4W doesn't have a relationship tying treatments to the appointment that they were performed in for future appointments. For this reason we can't tie exactly what was done in an appointment without having to referr to the clinical notes.

    Principle has an individual treatment step for each appointment. As such, we have created an empty treatment step for each appointment.

    The following rules are used to determine the status of the appointment. They're in order of precedence so if the first condition is met the rest are ignored:
    - Checked Out: If the patient is marked as checked out
    - Checked In: If the patient is marked as checked in
    - Arrived: If the patient is marked as arrived
    - Confirmed: If a "confirm type" appointment status is set
    - Cancelled: If a "cancel type" appointment status is set
    - Completed: If the appointment is in the past
    - Scheduled: If the appointment is in the future
    - Unscheduled: If no other conditions can be met
    `,
  },
});

export const D4W_MIGRATED_APPOINTMENTS_PLAN_NAME =
  'D4W - Migrated Appointments';

interface IAppointmentSuccessData {
  sourceRef: DocumentReference<ISourceEntityRecord>;
  appointmentRef: DocumentReference<IAppointment>;
}

export interface IPatientAppointmentJobData {
  sourceAppointment: IGetRecordResponse<
    ID4WAppointment,
    ID4WAppointmentTranslations,
    ID4WAppointmentFilters
  >;
  staff: WithRef<ITranslationMap<IStaffer>>[];
  practitioners: WithRef<IStaffer>[];
  brand: WithRef<IBrand>;
  practices: WithRef<ITranslationMap<IPractice>>[];
  excludedAppointmentBookIds: string[];
  appointmentBookToPractitioner: WithRef<ITranslationMap<IStaffer>>[];
}

export interface IPatientAppointmentMigrationData {
  patientRef: DocumentReference<IPatient>;
  sourcePatientId: string;
  appointmentUid: string;
  appointment: Omit<IAppointment, 'treatmentPlan'>;
  createdAt?: Timestamp;
  planUid: string;
  stepUid: string;
  plan: ITreatmentPlan;
  step: ITreatmentStep;
  interactions: IInteractionV2[];
}

export class PatientAppointmentDestinationEntity extends BaseDestinationEntity<
  IAppointmentSuccessData,
  IPatientAppointmentJobData,
  IPatientAppointmentMigrationData
> {
  destinationEntity = PATIENT_APPOINTMENT_DESTINATION_ENTITY;

  override filters = [
    new PracticeIdFilter<IPatientAppointmentJobData>((jobData) =>
      jobData.sourceAppointment.data.data.practice_id.toString()
    ),
    new PatientIdFilter<IPatientAppointmentJobData>((jobData) =>
      jobData.sourceAppointment.data.data.patient_id.toString()
    ),
  ];

  override canMigrateByDateRange = true;
  override canMigrateByIdRange = true;

  sourceCountComparison = new PatientAppointmentSourceEntity();

  override sourceEntities = {
    patients: new PatientSourceEntity(),
    appointments: new PatientAppointmentSourceEntity(),
  };

  override destinationEntities = {
    patients: new PatientDestinationEntity(),
    staff: new StafferDestinationEntity(),
  };

  customMappings = {
    staff: new D4WStafferMappingHandler(),
    practices: new D4WPracticeMappingHandler(),
    appointmentStatuses: new D4WAppointmentStatusMappingHandler(),
    excludedAppointmentBooks: new D4WExcludedAppointmentBooksMappingHandler(),
    appointmentBookToPractitioner:
      new D4WAppointmentBookToPractitionerMappingHandler(),
  };

  sourceCountDataAccessor(
    data: IPatientAppointmentJobData
  ): DocumentReference<ISourceEntityRecord> {
    return data.sourceAppointment.record.ref;
  }

  getMigratedData$(
    record: IDestinationEntityRecord<IAppointmentSuccessData>
  ): Observable<IMigratedDataSummary[]> {
    if (record.status !== DestinationEntityRecordStatus.Migrated) {
      return of([]);
    }

    return combineLatest([
      Firestore.doc$(record.data.sourceRef),
      Firestore.doc$(record.data.appointmentRef),
    ]).pipe(
      map(([sourceAppointment, appointment]) => [
        {
          label: 'Source Appointment',
          data: sourceAppointment,
        },
        {
          label: 'Appointment',
          data: appointment,
        },
      ])
    );
  }

  buildJobData$(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    skipMigrated: boolean,
    fromDate?: Timestamp,
    toDate?: Timestamp,
    fromId?: string,
    toId?: string
  ): Observable<IPatientAppointmentJobData[]> {
    const brand$ = Firestore.doc$(migration.configuration.brand.ref);
    const practices$ =
      this.customMappings.practices.getRecords$(translationMap);
    const staff$ = combineLatest([
      this.customMappings.staff.getRecords$(translationMap),
      translationMap.getByType$<IStaffer>(STAFFER_RESOURCE_TYPE),
    ]).pipe(map(([staff, mappedStaff]) => [...staff, ...mappedStaff]));
    const appointmentBookToPractitioner$ =
      this.customMappings.appointmentBookToPractitioner.getRecords$(
        translationMap
      );
    const practitioners$ = brand$.pipe(
      switchMap((brand) => Firestore.getDocs(Brand.stafferCol(brand)))
    );
    const excludedAppointmentBooks$ =
      this.customMappings.excludedAppointmentBooks.getRecords$(translationMap);

    return this.sourceEntities.appointments
      .getRecords$(
        migration,
        1000,
        buildSkipMigratedQuery(skipMigrated, this.destinationEntity),
        undefined,
        fromDate,
        toDate
      )
      .pipe(
        multiFilter((appointment) => {
          if (!fromId || !toId) {
            return true;
          }

          return (
            appointment.data.data.id >= toInt(fromId) &&
            appointment.data.data.id <= toInt(toId)
          );
        }),
        withLatestFrom(
          staff$,
          practitioners$,
          brand$,
          practices$,
          excludedAppointmentBooks$.pipe(
            multiMap((book) => book.sourceIdentifier.toString())
          ),
          appointmentBookToPractitioner$
        ),
        map(
          ([
            sourceAppointments,
            staff,
            practitioners,
            brand,
            practices,
            excludedAppointmentBookIds,
            appointmentBookToPractitioner,
          ]) =>
            sourceAppointments.map((sourceAppointment) => ({
              sourceAppointment,
              staff,
              practitioners,
              brand,
              practices,
              excludedAppointmentBookIds,
              appointmentBookToPractitioner,
            }))
        )
      );
  }

  getDestinationEntityRecordUid(data: IPatientAppointmentJobData): string {
    return data.sourceAppointment.record.uid;
  }

  async buildMigrationData(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    data: IPatientAppointmentJobData
  ): Promise<
    | IPatientAppointmentMigrationData
    | (IDestinationEntityRecord & FailedDestinationEntityRecord)
    | (IDestinationEntityRecord & SkippedDestinationEntityRecord)
  > {
    const appointmentUid = this.sourceEntities.appointments
      .getSourceRecordId(data.sourceAppointment.data.data)
      .toString();
    const sourcePatientId =
      data.sourceAppointment.data.data.patient_id.toString();

    const patientRef = await translationMap.getDestination<IPatient>(
      sourcePatientId,
      PATIENT_RESOURCE_TYPE
    );

    if (!patientRef) {
      return this._buildErrorResponse(
        data.sourceAppointment,
        `No patient with id ${sourcePatientId}`
      );
    }

    const appointmentBookId =
      data.sourceAppointment.data.data.appointment_book_id.toString();

    const shouldExcludeAppointment =
      data.excludedAppointmentBookIds.includes(appointmentBookId);

    if (shouldExcludeAppointment) {
      return this._buildSkippedResponse(data.sourceAppointment);
    }

    const providerId =
      data.sourceAppointment.data.data.practitioner_id?.toString();

    const practitionerMap = this.resolvePractitioner(
      data,
      appointmentBookId,
      providerId
    );

    if (!practitionerMap) {
      return this._buildErrorResponse(
        data.sourceAppointment,
        `No practitioner with id: ${
          providerId ?? ''
        }, appointmentBookId: ${appointmentBookId}`
      );
    }

    const practiceSourceId =
      data.sourceAppointment.data.data.practice_id.toString();
    const practiceMap = data.practices.find(
      (practice) => practice.sourceIdentifier === practiceSourceId
    );

    if (!practiceMap?.destinationIdentifier) {
      return this._buildErrorResponse(
        data.sourceAppointment,
        `No practice with id ${practiceSourceId}`
      );
    }

    const practice = await getDoc(practiceMap.destinationIdentifier);

    const createdAt = this._determineCreatedAt(data);

    try {
      const [appointment, interactions] = await this._buildAppointmentData(
        patientRef,
        practitionerMap,
        data.sourceAppointment,
        practice,
        data.brand,
        createdAt,
        translationMap
      );

      const planUid = `${sourcePatientId}-appointments`;
      const stepUid = `${sourcePatientId}-${appointmentUid}-appointments`;
      const plan = TreatmentPlan.init({
        name: D4W_MIGRATED_APPOINTMENTS_PLAN_NAME,
        status: TreatmentPlanStatus.InProgress,
      });

      const timezone = migration.configuration.timezone;
      const appointmentDate = moment(
        data.sourceAppointment.data.translations.from.toDate()
      )
        .tz(timezone)
        .format(HISTORY_DATE_FORMAT);

      const step = TreatmentStep.init({
        name: `D4W Appointment - ${appointmentDate}`,
        status:
          appointment.status === AppointmentStatus.Complete
            ? TreatmentStepStatus.Complete
            : TreatmentStepStatus.Incomplete,
        schedulingRules: {
          duration: appointment.event ? Event.duration(appointment.event) : 0,
        },
      });

      return {
        sourcePatientId,
        patientRef,
        appointmentUid,
        createdAt,
        appointment,
        planUid,
        plan,
        stepUid,
        step,
        interactions,
      };
    } catch (error) {
      return this._buildErrorResponse(data.sourceAppointment, getError(error));
    }
  }

  async hasMergeConflict(
    translationMap: TranslationMapHandler,
    data: IPatientAppointmentMigrationData
  ): Promise<IPatientAppointmentMigrationData | undefined> {
    const existingAppointmentRef = await translationMap.getDestination(
      data.appointmentUid,
      PATIENT_APPOINTMENT_RESOURCE_TYPE
    );

    if (!existingAppointmentRef) {
      return;
    }

    try {
      const existingAppointment = await getDoc(
        asDocRef<IAppointment>(existingAppointmentRef)
      );

      const hasMergeConflict = hasMergeConflicts(
        data.appointment,
        omit(existingAppointment, 'treatmentPlan'),
        ['dateFrom', 'dateTo'],
        [
          {
            key: 'tags',
            typeGuardFn: (item): item is unknown =>
              isObject(item) && isString(item.name),
            sortByPath: 'name',
          },
        ]
      );

      if (hasMergeConflict) {
        return {
          ...data,
          appointment: existingAppointment,
        };
      }
    } catch (error) {
      return;
    }
  }

  buildMergeConflictRecord(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    _translationMap: TranslationMapHandler,
    jobData: IPatientAppointmentJobData,
    _migrationData: IPatientAppointmentMigrationData
  ): IDestinationEntityRecord & MergeConflictDestinationEntityRecord {
    return {
      uid: jobData.sourceAppointment.record.uid,
      label: jobData.sourceAppointment.record.label,
      status: DestinationEntityRecordStatus.MergeConflict,
    };
  }

  async runJob(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    jobData: IPatientAppointmentJobData,
    migrationData: IPatientAppointmentMigrationData
  ): Promise<IDestinationEntityRecord> {
    try {
      const appointmentRef = await this._upsertAppointment(
        migrationData,
        translationMap
      );

      await asyncForEach(
        await Firestore.getDocs(
          // Nothing is being returned by this
          Appointment.interactionCol({ ref: appointmentRef }),
          where('createdBy', '==', SystemActors.Migration)
        ),
        (interaction) => FirestoreMigrate.deleteDoc(interaction.ref)
      );

      await upsertBulk(
        Appointment.interactionCol({ ref: appointmentRef }),
        migrationData.interactions,
        'uid',
        SystemActors.Migration
      );

      return this._buildSuccessResponse(
        jobData.sourceAppointment,
        appointmentRef
      );
    } catch (error) {
      return this._buildErrorResponse(
        jobData.sourceAppointment,
        getError(error)
      );
    }
  }

  async rollbackRecord(
    record: IDestinationEntityRecord<IAppointmentSuccessData> &
      MigratedDestinationEntityRecord<IAppointmentSuccessData>
  ): Promise<void> {
    const interactions = await getDocs(
      Appointment.interactionCol({ ref: record.data.appointmentRef })
    );
    await asyncForEach(interactions, (interaction) =>
      FirestoreMigrate.deleteDoc(interaction.ref)
    );
    await FirestoreMigrate.deleteDoc(record.data.appointmentRef);
  }

  resolvePractitioner(
    data: IPatientAppointmentJobData,
    appointmentBookId: string,
    providerId?: string
  ): DocumentReference<IStaffer> | undefined {
    const appointmentBookPractitionerMap =
      data.appointmentBookToPractitioner.find(
        (practitionerMap) =>
          practitionerMap.sourceIdentifier === appointmentBookId
      );

    if (appointmentBookPractitionerMap?.destinationIdentifier) {
      return appointmentBookPractitionerMap.destinationIdentifier;
    }

    if (providerId) {
      const mappedStaffer = data.staff.find(
        (staffer) => staffer.sourceIdentifier === providerId
      )?.destinationIdentifier;

      if (mappedStaffer) {
        return mappedStaffer;
      }
    }

    // Default provider is used from custom mapping with id: 0
    return data.staff.find((staffer) => staffer.sourceIdentifier === '0')
      ?.destinationIdentifier;
  }

  private _determineCreatedAt(data: IPatientAppointmentJobData): Timestamp {
    if (data.sourceAppointment.data.translations.createdAt) {
      return data.sourceAppointment.data.translations.createdAt;
    }

    if (
      toMoment(data.sourceAppointment.data.translations.from).isBefore(moment())
    ) {
      return data.sourceAppointment.data.translations.from;
    }

    return toTimestamp();
  }

  private _buildSuccessResponse(
    appointment: IGetRecordResponse<
      ID4WAppointment,
      ID4WAppointmentTranslations
    >,
    appointmentRef: DocumentReference<IAppointment>
  ): IDestinationEntityRecord<IAppointmentSuccessData> {
    return {
      uid: appointment.record.uid,
      label: appointment.record.label,
      data: {
        sourceRef: appointment.record.ref,
        appointmentRef,
      },
      status: DestinationEntityRecordStatus.Migrated,
      migratedAt: toTimestamp(),
    };
  }

  private async _upsertAppointment(
    data: IPatientAppointmentMigrationData,
    translationMap: TranslationMapHandler
  ): Promise<DocumentReference<IAppointment>> {
    const planDestinationRef = await translationMap.getDestination(
      data.planUid,
      PATIENT_TREATMENT_PLAN_CUSTOM_MAPPING_TYPE
    );

    const planRef = await FirestoreMigrate.upsertDoc(
      TreatmentPlan.col({
        ref: data.patientRef,
      }),
      data.plan,
      planDestinationRef?.id
    );

    if (!planDestinationRef) {
      await translationMap.upsert({
        sourceIdentifier: data.planUid,
        destinationIdentifier: planRef,
        resourceType: PATIENT_TREATMENT_PLAN_CUSTOM_MAPPING_TYPE,
      });
    }

    const stepDestinationRef = await translationMap.getDestination(
      data.stepUid,
      PATIENT_TREATMENT_STEP_CUSTOM_MAPPING_TYPE
    );

    const stepRef = await FirestoreMigrate.upsertDoc(
      TreatmentPlan.treatmentStepCol({
        ref: planRef,
      }),
      data.step,
      stepDestinationRef?.id
    );

    if (!stepDestinationRef) {
      await translationMap.upsert({
        sourceIdentifier: data.stepUid,
        destinationIdentifier: stepRef,
        resourceType: PATIENT_TREATMENT_STEP_CUSTOM_MAPPING_TYPE,
      });
    }

    const treatmentPlan = await getDoc(planRef);
    const treatmentStep = await getDoc(stepRef);

    const patientAppointmentDestinationRef =
      await translationMap.getDestination(
        data.appointmentUid,
        PATIENT_APPOINTMENT_RESOURCE_TYPE
      );

    const appointmentRef = await FirestoreMigrate.upsertDoc(
      Appointment.col({
        ref: data.patientRef,
      }),
      {
        ...data.appointment,
        treatmentPlan: TreatmentPlan.treatmentStepToAssociatedTreatment(
          treatmentPlan,
          treatmentStep
        ),
        createdAt: data.createdAt ?? toTimestamp(),
      },
      patientAppointmentDestinationRef?.id
    );

    if (!patientAppointmentDestinationRef) {
      await translationMap.upsert({
        sourceIdentifier: data.appointmentUid,
        destinationIdentifier: appointmentRef,
        resourceType: PATIENT_APPOINTMENT_RESOURCE_TYPE,
      });
    }

    await FirestoreMigrate.patchDoc(stepRef, {
      appointment: appointmentRef,
    });
    return appointmentRef;
  }

  private async _buildAppointmentData(
    patientRef: DocumentReference<IPatient>,
    practitionerRef: DocumentReference<IStaffer>,
    sourceAppointment: IGetRecordResponse<
      ID4WAppointment,
      ID4WAppointmentTranslations
    >,
    practice: WithRef<IPractice>,
    brand: WithRef<IBrand>,
    createdAt: Timestamp,
    translationMap: TranslationMapHandler
  ): Promise<[Omit<IAppointment, 'treatmentPlan'>, IInteractionV2[]]> {
    const patient = await Firestore.getDoc(patientRef);
    const practitioner = await Firestore.getDoc(practitionerRef);
    const appointment = sourceAppointment.data.data;
    const translations = sourceAppointment.data.translations;
    const { status, interactions, tags } =
      await this._buildStatusAndInteractions(
        appointment,
        translations,
        translationMap,
        brand
      );

    if (appointment.notes.length) {
      interactions.unshift(
        Interaction.init({
          type: InteractionType.Note,
          title: [toTextContent('Appointment note')],
          content: initVersionedSchema(appointment.notes),
          pinned: true,
          createdAt,
        })
      );
    }

    const event =
      status !== AppointmentStatus.Unscheduled
        ? getAppointmentEvent(
            sourceAppointment.data.translations,
            patient,
            practice,
            practitioner
          )
        : undefined;

    return [
      {
        event,
        eventHistory: [],
        status,
        statusHistory: [],
        practice: toNamedDocument(practice),
        practitioner: stafferToNamedDoc(practitioner),
        cancellationHistory: [],
        dependencies: [],
        tags,
        ...initFirestoreModel(),
      },
      interactions,
    ];
  }

  private async _buildStatusAndInteractions(
    appointment: ID4WAppointment,
    translations: ID4WAppointmentTranslations,
    translationMap: TranslationMapHandler,
    brand: WithRef<IBrand>
  ): Promise<
    Pick<IAppointment, 'status' | 'tags'> & { interactions: IInteractionV2[] }
  > {
    const tags: INamedDocument<ITag>[] = [];
    const interactions: IInteractionV2[] = [];
    let status: AppointmentStatus | undefined;

    if (translations.deletedAt) {
      status = AppointmentStatus.Unscheduled;
    }

    // TODO: CU-219c6hc - These date for Beyond Dental are well off. Keep this and decide whether we allow the checks to be turned on for specific practices
    // if (appointment.checked_out_at && translations.checkedOutAt) {
    //   if (!status) {
    //     status = AppointmentStatus.Complete;
    //   }
    //   interactions.push(
    //     Interaction.init({
    //       title: [toTextContent(`Checked out`)],
    //       type: InteractionType.AppointmentCheckOut,
    //       createdAt: translations.checkedOutAt,
    //     })
    //   );
    // }

    // if (appointment.checked_in_at && translations.checkedInAt) {
    //   if (!status) {
    //     status = AppointmentStatus.Complete;
    //   }
    //   interactions.push(
    //     Interaction.init({
    //       title: [toTextContent(`Checked in`)],
    //       type: InteractionType.AppointmentCheckIn,
    //       createdAt: translations.checkedInAt,
    //     })
    //   );
    // }

    // if (appointment.arrived_at && translations.arrivedAt) {
    //   if (!status) {
    //     status = AppointmentStatus.Arrived;
    //   }
    //   interactions.push(
    //     Interaction.init({
    //       title: [toTextContent(`Arrived`)],
    //       type: InteractionType.AppointmentArrived,
    //       createdAt: translations.arrivedAt,
    //     })
    //   );
    // }

    const customStatusMappings = await snapshot(
      this.customMappings.appointmentStatuses.getRecords$(translationMap)
    );

    await asyncForEach(
      appointment.statuses.reverse(),
      async (appointmentStatus) => {
        const statusMapping = customStatusMappings.find(
          (customStatusMapping) =>
            customStatusMapping.sourceIdentifier === appointmentStatus
        );

        if (status || !statusMapping) {
          return;
        }

        if (
          statusMapping.destinationValue ===
          AppointmentStatusMapType.CancelAppointment
        ) {
          status = AppointmentStatus.Cancelled;
          interactions.push(
            Interaction.init({
              title: [
                toTextContent(
                  `Appointment cancelled by D4W status: ${
                    statusMapping.sourceLabel ?? ''
                  }`
                ),
              ],
              type: InteractionType.AppointmentArrived,
              createdAt: translations.from,
            })
          );
          return;
        }

        if (
          statusMapping.destinationValue ===
          AppointmentStatusMapType.ConfirmAppointment
        ) {
          status = AppointmentStatus.Confirmed;
          interactions.push(
            Interaction.init({
              title: [
                toTextContent(
                  `Appointment confirmed by D4W status: ${
                    statusMapping.sourceLabel ?? ''
                  }`
                ),
              ],
              type: InteractionType.AppointmentArrived,
              createdAt: translations.from,
            })
          );
          return;
        }

        if (
          statusMapping.destinationValue ===
          AppointmentStatusMapType.AddAppointmentTag
        ) {
          const tagName = isINamedDocument(statusMapping.associatedValue)
            ? statusMapping.associatedValue.name
            : '';
          const tag = await snapshot(
            find$(Brand.appointmentTagCol(brand), where('name', '==', tagName))
          );
          if (!tag) {
            throw new Error(
              `No tag found for ${statusMapping.destinationValue} looking for ${tagName}`
            );
          }
          tags.push(toNamedDocument(tag));
        }

        const pinnedInteraction = appointment.description.trim();
        if (pinnedInteraction) {
          interactions.push(
            Interaction.init({
              content: initVersionedSchema(pinnedInteraction),
              type: InteractionType.Note,
              createdAt: translations.from,
              pinned: true,
            })
          );
        }
      }
    );

    const isBeforeNow = toMoment(translations.from).isBefore(
      moment.tz(DEFAULT_TIMEZONE)
    );

    const isStalledStatus = [
      AppointmentStatus.Scheduled,
      AppointmentStatus.Confirmed,
      AppointmentStatus.Arrived,
      AppointmentStatus.CheckedIn,
      AppointmentStatus.InProgress,
    ];

    if (isBeforeNow && (!status || isStalledStatus.includes(status))) {
      status = AppointmentStatus.Complete;
    }

    if (!isBeforeNow && !status) {
      status = AppointmentStatus.Scheduled;
    }

    return {
      status: status || AppointmentStatus.Unscheduled,
      interactions: sortBy(interactions, (interaction) =>
        interaction.createdAt.toDate()
      ),
      tags,
    };
  }

  private _buildErrorResponse(
    appointment: IGetRecordResponse<
      ID4WAppointment,
      ID4WAppointmentTranslations
    >,
    errorMessage?: string
  ): IDestinationEntityRecord & FailedDestinationEntityRecord {
    return {
      uid: appointment.record.uid,
      label: appointment.record.label,
      status: DestinationEntityRecordStatus.Failed,
      errorMessage:
        errorMessage ?? 'Missing required properties for appointment',
      failData: {
        appointmentRef: appointment.record.ref,
      },
    };
  }

  private _buildSkippedResponse(
    appointment: IGetRecordResponse<
      ID4WAppointment,
      ID4WAppointmentTranslations
    >
  ): IDestinationEntityRecord & SkippedDestinationEntityRecord {
    return {
      uid: appointment.record.uid,
      label: appointment.record.label,
      status: DestinationEntityRecordStatus.Skipped,
    };
  }
}

export function getAppointmentEvent(
  translations: ID4WAppointmentTranslations,
  patient: WithRef<IPatient>,
  practice: WithRef<IPractice>,
  practitioner: WithRef<IStaffer>
): IEvent {
  return Event.init({
    from: translations.from,
    to: translations.to,
    practice: toNamedDocument(practice),
    type: EventType.Appointment,
    participants: [
      {
        ...toNamedDocument(patient),
        type: ParticipantType.Patient,
      },
      {
        ...stafferToNamedDoc(practitioner),
        type: ParticipantType.Staffer,
      },
    ],
  });
}
