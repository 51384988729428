import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  ContactNumberFormGroup,
  OrganisationService,
} from '@principle-theorem/ng-principle-shared';
import { TypedFormGroup } from '@principle-theorem/ng-shared';
import {
  IDVACard,
  IHealthFundCard,
  IMedicareCard,
  IPatient,
} from '@principle-theorem/principle-core/interfaces';
import { WithRef } from '@principle-theorem/shared';
import { Observable, ReplaySubject } from 'rxjs';
import { PatientDetailsFormComponent } from '../patient-details-form/patient-details-form.component';
import {
  BasePatientField,
  ICompareFormFieldState,
  PatientDetailsFormComparisonService,
  PatientHealthCardField,
} from './patient-details-form-comparison.service';

@Component({
  selector: 'pr-patient-details-form-compare',
  templateUrl: './patient-details-form-compare.component.html',
  styleUrl: './patient-details-form-compare.component.scss',
  providers: [PatientDetailsFormComparisonService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientDetailsFormCompareComponent extends PatientDetailsFormComponent {
  patient$ = new ReplaySubject<WithRef<IPatient>>(1);

  @Input()
  set patient(patient: WithRef<IPatient>) {
    if (patient) {
      this.patient$.next(patient);
    }
  }

  constructor(
    public service: PatientDetailsFormComparisonService,
    public organisation: OrganisationService
  ) {
    super();
    service.init(this.patient$, this.patientDetails$, this.form);
  }

  getBasePatientFieldState$(
    fieldName: keyof BasePatientField,
    subFieldName?: string
  ): Observable<ICompareFormFieldState | undefined> {
    return this.service.getBasePatientFieldState$(fieldName, subFieldName);
  }

  getBasePatientNestedFieldState$(
    args: [keyof BasePatientField, string?]
  ): Observable<ICompareFormFieldState | undefined> {
    return this.getBasePatientFieldState$(...args);
  }

  getContactNumberFieldState$(
    formGroup: ContactNumberFormGroup
  ): Observable<ICompareFormFieldState | undefined> {
    return this.service.getContactNumberFieldState$(formGroup);
  }

  getHealthCardFieldState$(
    formGroup: TypedFormGroup<IHealthFundCard | IMedicareCard | IDVACard>,
    fieldName: keyof PatientHealthCardField
  ): Observable<ICompareFormFieldState | undefined> {
    return this.service.getHealthCardFieldState$(formGroup, fieldName);
  }
}
