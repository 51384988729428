import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { ButtonsContainerComponent } from '@principle-theorem/ng-shared';
import { type IHealthFundCard } from '@principle-theorem/principle-core/interfaces';

@Component({
  selector: 'pr-healthfund-card-display',
  standalone: true,
  imports: [
    CommonModule,
    MatListModule,
    ButtonsContainerComponent,
    MatButtonModule,
  ],
  templateUrl: './healthfund-card-display.component.html',
  styleUrls: ['./healthfund-card-display.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HealthFundCardDisplayComponent {
  @Input() healthFundCard?: IHealthFundCard;
  @Output() remove = new EventEmitter<void>();
  @Output() edit = new EventEmitter<void>();
}
