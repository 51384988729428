import { roundTo2Decimals, TaxRate } from '@principle-theorem/accounting';
import {
  Brand,
  feeToLineItem,
  hasMergeConflicts,
  Invoice,
  Patient,
  stafferToNamedDoc,
  toAccountDetails,
  Transaction,
  TransactionOperators,
  TreatmentPlan,
  treatmentToLineItem,
} from '@principle-theorem/principle-core';
import {
  DestinationEntityRecordStatus,
  IMigratedDataSummary,
  InvoiceStatus,
  ITranslationMap,
  TransactionProvider,
  TransactionStatus,
  TransactionType,
  type FailedDestinationEntityRecord,
  type IBasePatient,
  type IBrand,
  type ICustomLineItem,
  type IDestinationEntity,
  type IDestinationEntityRecord,
  type IGetRecordResponse,
  type IInvoice,
  type IInvoiceReference,
  type IPatient,
  type IPatientContactDetails,
  type IPractice,
  type IPracticeMigration,
  type ISourceEntityRecord,
  type IStaffer,
  type ITransaction,
  type ITreatmentLineItem,
  type ITreatmentPlan,
  type MergeConflictDestinationEntityRecord,
} from '@principle-theorem/principle-core/interfaces';
import {
  all$,
  asDocRef,
  asyncForEach,
  doc,
  doc$,
  Firestore,
  getDoc,
  getError,
  ISO_DATE_TIME_FORMAT,
  isSameRef,
  multiFilter,
  safeCombineLatest,
  snapshot,
  sortByCreatedAt,
  toInt,
  toNamedDocument,
  toTimestamp,
  type AtLeast,
  type DocumentReference,
  type IIdentifiable,
  type Timestamp,
  type Timezone,
  type WithRef,
} from '@principle-theorem/shared';
import { compact, first, omit, uniq } from 'lodash';
import * as moment from 'moment-timezone';
import { combineLatest, of, type Observable } from 'rxjs';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';
import { BaseDestinationEntity } from '../../../destination/base-destination-entity';
import { FirestoreMigrate } from '../../../destination/destination';
import { DestinationEntity } from '../../../destination/destination-entity';
import { InvoiceIdFilter } from '../../../destination/filters/invoice-id-filter';
import { PracticeIdFilter } from '../../../destination/filters/practice-id-filter';
import { ItemCodeResourceMapType } from '../../../mappings/item-codes-to-xlsx';
import { PracticeMigration } from '../../../practice-migrations';
import { buildSkipMigratedQuery } from '../../../source/source-entity-record';
import { type TranslationMapHandler } from '../../../translation-map';
import { PATIENT_RESOURCE_TYPE } from '../../source/entities/patient';
import {
  D4WMethodOfPayment,
  D4WPaymentType,
  PATIENT_INVOICE_RESOURCE_TYPE,
  PatientInvoiceSourceEntity,
  type ID4WPatientInvoice,
  type ID4WPatientInvoiceFilters,
  type ID4WPatientInvoicePayment,
  type ID4WPatientInvoiceTranslations,
} from '../../source/entities/patient-invoice';
import {
  PatientTreatmentSourceEntity,
  type ID4WPatientTreatment,
  type ID4WPatientTreatmentFilters,
  type ID4WPatientTreatmentTranslations,
} from '../../source/entities/patient-treatment';
import { D4WItemCodeMappingHandler } from '../mappings/item-codes';
import { D4WPracticeMappingHandler } from '../mappings/practices';
import { D4WStafferMappingHandler } from '../mappings/staff';
import { PatientTreatmentPlanDestinationEntity } from './patient-treatment-plan';
import { PatientDestinationEntity } from './patients';
import { resolveMappedCode } from '../../../mappings/item-codes';
import { STAFFER_RESOURCE_TYPE } from '../../../destination/entities/staff';

interface IInvoiceBuildData {
  invoice: IInvoice;
  // accountCreditAmount: number;
  transactions: (IIdentifiable & ITransaction)[];
  planTreatmentPairs: IPlanWithTreatmentPair[];
}

interface IPatientRelationshipData {
  patientId: string;
  patientRef: DocumentReference<IPatient>;
  primaryPatient: WithRef<IPatient>;
}

interface IInvoiceUpdateData {
  invoiceRef: DocumentReference<IInvoice>;
  transactionRefs: DocumentReference<ITransaction>[];
}

interface IInvoiceSuccessData extends IInvoiceUpdateData {
  sourceRef: DocumentReference<ISourceEntityRecord>;
  invoiceRef: DocumentReference<IInvoice>;
  transactionRefs: DocumentReference<ITransaction>[];
}

export const PATIENT_INVOICE_DESTINATION_ENTITY = DestinationEntity.init({
  metadata: {
    key: 'patientInvoices',
    label: 'Patient Invoices',
    description: `Each service code will be nested under a "Custom Service Code" treatment on the invoice. Products will be shown as normal.

    If a refund to card needs to be done for a D4W payment, it will need to be performed in D4W, and record a manual refund in Principle.
    Transactions, invoice adjustments, refunds etc... will be done as expected in Principle.

    Some things to note:
    - Non service codes will be added as line items eg: "stock - $60"
    `,
  },
});

export interface IPatientInvoiceJobData {
  sourceInvoice: IGetRecordResponse<
    ID4WPatientInvoice,
    ID4WPatientInvoiceTranslations,
    ID4WPatientInvoiceFilters
  >;
  practices: WithRef<ITranslationMap<IPractice>>[];
  brand: WithRef<IBrand>;
  staff: WithRef<ITranslationMap<IStaffer>>[];
  practitioners: WithRef<IStaffer>[];
  sourceItemCodes: WithRef<ITranslationMap<object, ItemCodeResourceMapType>>[];
}

interface IPatientInvoiceMigrationData extends IInvoiceBuildData {
  patientRef: DocumentReference<IPatient>;
  invoiceId: string;
  createdAt: Timestamp;
}

interface IPlanWithTreatmentPair
  extends Pick<IInvoiceReference, 'lineItemUid' | 'amount'> {
  planRef: DocumentReference<ITreatmentPlan>;
}

export class PatientInvoiceDestinationEntity extends BaseDestinationEntity<
  IInvoiceSuccessData,
  IPatientInvoiceJobData,
  IPatientInvoiceMigrationData
> {
  destinationEntity = PATIENT_INVOICE_DESTINATION_ENTITY;

  override filters = [
    new PracticeIdFilter<IPatientInvoiceJobData>((jobData) =>
      jobData.sourceInvoice.data.data.practice_id.toString()
    ),
    new InvoiceIdFilter<IPatientInvoiceJobData>((jobData) =>
      this.sourceEntities.invoices
        .getSourceRecordId(jobData.sourceInvoice.data.data)
        .toString()
    ),
  ];

  override canMigrateByDateRange = true;
  override canMigrateByIdRange = true;

  sourceCountComparison = new PatientInvoiceSourceEntity();

  override sourceEntities = {
    treatments: new PatientTreatmentSourceEntity(),
    invoices: new PatientInvoiceSourceEntity(),
  };

  override destinationEntities = {
    patients: new PatientDestinationEntity(),
    treatmentPlans: new PatientTreatmentPlanDestinationEntity(),
  };

  customMappings = {
    staff: new D4WStafferMappingHandler(),
    practices: new D4WPracticeMappingHandler(),
    itemCodes: new D4WItemCodeMappingHandler(),
  };

  sourceCountDataAccessor(
    data: IPatientInvoiceJobData
  ): DocumentReference<ISourceEntityRecord> {
    return data.sourceInvoice.record.ref;
  }

  getMigratedData$(
    record: IDestinationEntityRecord<IInvoiceSuccessData>
  ): Observable<IMigratedDataSummary[]> {
    if (record.status !== DestinationEntityRecordStatus.Migrated) {
      return of([]);
    }

    return combineLatest([
      doc$(record.data.invoiceRef),
      safeCombineLatest(
        record.data.transactionRefs.map((transactionRef) =>
          doc$(transactionRef)
        )
      ),
    ]).pipe(
      map(([invoiceRef, transactions]) => {
        const data: IMigratedDataSummary[] = [
          {
            label: 'Invoice',
            data: invoiceRef,
          },
        ];

        data.push(
          ...transactions.map((transaction) => ({
            label: 'Transactions',
            data: transaction,
          }))
        );

        return data;
      })
    );
  }

  buildJobData$(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    skipMigrated: boolean,
    fromDate?: Timestamp,
    toDate?: Timestamp,
    fromId?: string,
    toId?: string
  ): Observable<IPatientInvoiceJobData[]> {
    const staff$ = combineLatest([
      this.customMappings.staff.getRecords$(translationMap),
      translationMap.getByType$<IStaffer>(STAFFER_RESOURCE_TYPE),
    ]).pipe(map(([staff, mappedStaff]) => [...staff, ...mappedStaff]));
    const practices$ =
      this.customMappings.practices.getRecords$(translationMap);
    const brand$ = PracticeMigration.brand$(migration);
    const practitioners$ = brand$.pipe(
      switchMap((brand) => all$(Brand.stafferCol(brand)))
    );
    const sourceItemCodes$ =
      this.customMappings.itemCodes.getRecords$(translationMap);

    return this.sourceEntities.invoices
      .getRecords$(
        migration,
        200,
        buildSkipMigratedQuery(skipMigrated, this.destinationEntity),
        undefined,
        fromDate,
        toDate
      )
      .pipe(
        multiFilter((invoice) => {
          if (!fromId || !toId) {
            return true;
          }

          return (
            invoice.data.data.account_id >= toInt(fromId) &&
            invoice.data.data.account_id <= toInt(toId)
          );
        }),
        withLatestFrom(
          brand$,
          practices$,
          staff$,
          practitioners$,
          sourceItemCodes$
        ),
        map(
          ([
            sourceInvoices,
            brand,
            practices,
            staff,
            practitioners,
            sourceItemCodes,
          ]) =>
            sourceInvoices.map((sourceInvoice) => ({
              sourceInvoice,
              brand,
              practices,
              staff,
              practitioners,
              sourceItemCodes,
            }))
        )
      );
  }

  getDestinationEntityRecordUid(data: IPatientInvoiceJobData): string {
    return data.sourceInvoice.record.uid;
  }

  async buildMigrationData(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    data: IPatientInvoiceJobData
  ): Promise<
    | IPatientInvoiceMigrationData
    | (IDestinationEntityRecord & FailedDestinationEntityRecord)
  > {
    const invoiceId = data.sourceInvoice.data.data.account_id.toString();
    const practiceId = data.sourceInvoice.data.data.practice_id.toString();
    const practiceMap = data.practices.find(
      (searchPractice) =>
        searchPractice.sourceIdentifier === practiceId.toString()
    );

    if (!practiceMap?.destinationIdentifier) {
      throw new Error(`Can't find practice with id ${practiceId}`);
    }

    const practice = await getDoc(practiceMap.destinationIdentifier);

    const treatments = await this.sourceEntities.treatments.filterRecords(
      migration,
      'accountId',
      invoiceId
    );

    const patientIds = uniq(
      treatments.map((treatment) => treatment.record.filters.patientId)
    );

    // Try and resolve the invoice to a single patient
    let invoicePrimaryPatient =
      data.sourceInvoice.data.data.send_to_patient_id?.toString();

    if (!patientIds.length && invoicePrimaryPatient) {
      patientIds.push(invoicePrimaryPatient);
    }

    if (!patientIds.length) {
      return this._buildErrorResponse(
        data.sourceInvoice,
        `No patients from ids ${patientIds.join(', ')}`
      );
    }

    try {
      if (patientIds.length > 1) {
        const invoicePatientRefs = await asyncForEach(
          patientIds,
          async (patientId) => {
            const patientRef = await translationMap.getDestination<IPatient>(
              patientId,
              PATIENT_RESOURCE_TYPE
            );
            if (!patientRef) {
              throw new Error(`No patient for id ${patientId}`);
            }
            const primaryPatient =
              await Patient.resolvePrimaryContact(patientRef);
            const resolvedPatient = await getDoc(patientRef);

            return {
              patientId,
              patientRef,
              primaryPatient: primaryPatient ?? resolvedPatient,
            };
          }
        );

        const allHaveSamePrimaryPatient =
          uniq(
            invoicePatientRefs.map((patient) => patient.primaryPatient.ref.path)
          ).length === 1;

        if (!invoicePrimaryPatient && !allHaveSamePrimaryPatient) {
          const paidByPatientIds = uniq(
            data.sourceInvoice.record.filters.paidByPatientIds
          );
          const paidBySinglePatient = paidByPatientIds.length === 1;
          if (!paidBySinglePatient) {
            return this._buildErrorResponse(
              data.sourceInvoice,
              `No primary patient for multiple patient Ids: ${patientIds.join(
                ', '
              )}`
            );
          }

          invoicePrimaryPatient = paidByPatientIds[0];
        }

        const primaryPatient = invoicePatientRefs[0]?.primaryPatient;
        const patientRef = invoicePrimaryPatient
          ? await translationMap.getDestination<IPatient>(
              invoicePrimaryPatient,
              PATIENT_RESOURCE_TYPE
            )
          : allHaveSamePrimaryPatient && primaryPatient
            ? primaryPatient.ref
            : undefined;
        if (!patientRef) {
          throw new Error(`No primary patient found`);
        }

        const { invoice, transactions, planTreatmentPairs } =
          await this._buildInvoiceData(
            data,
            patientRef,
            invoicePatientRefs,
            practice,
            treatments,
            translationMap,
            migration.configuration.timezone
          );

        return {
          patientRef,
          createdAt: data.sourceInvoice.data.translations.createdAt,
          invoiceId,
          invoice,
          transactions,
          planTreatmentPairs,
        };
      }

      const patientRef = await translationMap.getDestination<IPatient>(
        patientIds[0],
        PATIENT_RESOURCE_TYPE
      );
      if (!patientRef) {
        return this._buildErrorResponse(
          data.sourceInvoice,
          `No patient for id ${patientIds[0]}`
        );
      }

      const { invoice, transactions, planTreatmentPairs } =
        await this._buildInvoiceData(
          data,
          patientRef,
          [
            {
              patientId: patientIds[0],
              patientRef,
              primaryPatient: await Firestore.getDoc(patientRef),
            },
          ],
          practice,
          treatments,
          translationMap,
          migration.configuration.timezone
        );

      return {
        patientRef,
        createdAt: data.sourceInvoice.data.translations.createdAt,
        invoiceId,
        invoice,
        transactions,
        planTreatmentPairs,
      };
    } catch (error) {
      return this._buildErrorResponse(
        data.sourceInvoice,
        `Building of migration data failed: ${getError(error)}`
      );
    }
  }

  async hasMergeConflict(
    translationMap: TranslationMapHandler,
    data: IPatientInvoiceMigrationData
  ): Promise<IPatientInvoiceMigrationData | undefined> {
    const invoiceRef = await translationMap.getDestination(
      data.invoiceId,
      PATIENT_INVOICE_RESOURCE_TYPE
    );

    if (!invoiceRef) {
      return;
    }

    try {
      const existingInvoice = await getDoc(asDocRef<IInvoice>(invoiceRef));

      const invoiceMergeConflict = hasMergeConflicts(
        omit(data.invoice, ['from', 'to']),
        omit(existingInvoice, ['from', 'to']),
        [],
        [
          {
            key: 'transactions',
            sortByPath: 'reference',
          },
        ]
      );

      const existingTransactions: (IIdentifiable & ITransaction)[] = [];

      const transactionMergeConflicts = await asyncForEach(
        data.transactions,
        async (transaction) => {
          try {
            const existingTransaction = await getDoc(
              doc(Invoice.transactionCol(existingInvoice), transaction.uid)
            );

            existingTransactions.push({
              ...existingTransaction,
            });

            return hasMergeConflicts(transaction, existingTransaction);
          } catch (error) {
            return false;
          }
        }
      );

      const hasMergeConflict = [
        invoiceMergeConflict,
        ...transactionMergeConflicts,
      ].some((mergeConflict) => mergeConflict);

      if (hasMergeConflict) {
        return {
          ...data,
          invoice: existingInvoice,
          transactions: existingTransactions,
        };
      }
    } catch (error) {
      return;
    }
  }

  buildMergeConflictRecord(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    _translationMap: TranslationMapHandler,
    jobData: IPatientInvoiceJobData,
    _migrationData: IPatientInvoiceMigrationData
  ): IDestinationEntityRecord & MergeConflictDestinationEntityRecord {
    return {
      uid: jobData.sourceInvoice.record.uid,
      label: jobData.sourceInvoice.record.label,
      status: DestinationEntityRecordStatus.MergeConflict,
    };
  }

  async runJob(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    jobData: IPatientInvoiceJobData,
    migrationData: IPatientInvoiceMigrationData
  ): Promise<IDestinationEntityRecord> {
    try {
      const invoiceRef = await this._upsertInvoice(
        migrationData,
        translationMap,
        migrationData.patientRef
      );

      return this._buildSuccessResponse(jobData.sourceInvoice, invoiceRef);
    } catch (error) {
      return this._buildErrorResponse(
        jobData.sourceInvoice,
        `Run job failed: ${getError(error)}`
      );
    }
  }

  private _buildSuccessResponse(
    invoice: IGetRecordResponse<
      ID4WPatientInvoice,
      ID4WPatientInvoiceTranslations
    >,
    updateData?: IInvoiceUpdateData
  ): IDestinationEntityRecord {
    return {
      uid: invoice.record.uid,
      label: invoice.record.label,
      data: {
        sourceRef: invoice.record.ref,
        ...updateData,
      },
      status: DestinationEntityRecordStatus.Migrated,
      migratedAt: toTimestamp(),
    };
  }

  private async _upsertInvoice(
    migrationData: IPatientInvoiceMigrationData,
    translationMap: TranslationMapHandler,
    patientRef: DocumentReference<IPatient>
  ): Promise<IInvoiceUpdateData> {
    const invoiceDestinationRef = await translationMap.getDestination(
      migrationData.invoiceId,
      PATIENT_INVOICE_RESOURCE_TYPE
    );

    const invoiceRef = await FirestoreMigrate.upsertDoc(
      Patient.invoiceCol({
        ref: patientRef,
      }),
      { ...migrationData.invoice, createdAt: migrationData.createdAt },
      invoiceDestinationRef?.id
    );

    if (!invoiceDestinationRef) {
      await translationMap.upsert({
        sourceIdentifier: migrationData.invoiceId,
        destinationIdentifier: invoiceRef,
        resourceType: PATIENT_INVOICE_RESOURCE_TYPE,
      });
    }

    const transactionRefs = await asyncForEach(
      migrationData.transactions,
      (transaction) =>
        FirestoreMigrate.upsertDoc(
          Invoice.transactionCol({
            ref: invoiceRef,
          }),
          transaction,
          transaction.uid
        )
    );

    // TODO: CU-219c758 - Do we want to set invoice id on the treatment?
    // await resolveSequentially(
    //   migrationData.planTreatmentPairs,
    //   async (planTreatmentPair) => {
    //     const step = await snapshot(
    //       TreatmentPlan.stepForTreatmentById$(
    //         { ref: planTreatmentPair.planRef },
    //         planTreatmentPair.lineItemUid
    //       )
    //     );

    //     if (!step) {
    //       throw new Error(
    //         `No step found for plan ${planTreatmentPair.planRef.id} and treatment ${planTreatmentPair.lineItemUid}`
    //       );
    //     }

    //     await FirestoreMigrate.patchDoc(step.ref, {
    //       treatments: step.treatments.map((treatment) => {
    //         if (treatment.uuid !== planTreatmentPair.lineItemUid) {
    //           return treatment;
    //         }
    //         return {
    //           ...treatment,
    //           invoices: uniqBy(
    //             [
    //               ...treatment.invoices,
    //               {
    //                 invoiceRef,
    //                 lineItemUid: planTreatmentPair.lineItemUid,
    //                 amount: planTreatmentPair.amount,
    //               },
    //             ],
    //             (item) => item.invoiceRef.path
    //           ),
    //         };
    //       }),
    //     });
    //   }
    // );

    return {
      invoiceRef,
      transactionRefs,
    };
  }

  private async _buildInvoiceData(
    data: IPatientInvoiceJobData,
    accountPatientRef: DocumentReference<IPatient>,
    invoicePatientRefs: IPatientRelationshipData[],
    practice: WithRef<IPractice>,
    treatments: IGetRecordResponse<
      ID4WPatientTreatment,
      ID4WPatientTreatmentTranslations,
      ID4WPatientTreatmentFilters
    >[],
    translationMap: TranslationMapHandler,
    timezone: Timezone
  ): Promise<IInvoiceBuildData> {
    const primaryContact =
      await Patient.resolvePrimaryContact(accountPatientRef);
    const resolvedPatient = await getDoc(accountPatientRef);
    const onBehalfOf = toAccountDetails(
      resolvedPatient as IBasePatient & IPatientContactDetails
    );

    const invoiceTreatments = treatments
      .filter((procedure) =>
        procedure.data.data.account_id
          ? // TODO: Had two treatments that didn't have a date. Do we need the date filter?
            // procedure.data.data.date && procedure.data.data.account_id
            true
          : false
      )
      .map((procedure) => procedure.data.data);

    const [treatmentLineItems, planTreatmentPairs] = await getTreatments(
      data,
      invoicePatientRefs,
      invoiceTreatments,
      translationMap
    );

    const miscLineItems = getMiscLineItems(data, invoiceTreatments);

    const invoice = Invoice.init({
      from: toAccountDetails(practice),
      to: primaryContact
        ? {
            ...toAccountDetails(primaryContact),
            onBehalfOf,
          }
        : onBehalfOf,
      practice: toNamedDocument(practice),
      due: data.sourceInvoice.data.translations.dueAt,
      items: [...treatmentLineItems, ...miscLineItems],
      claims: [],
    });
    invoice.reference = data.sourceInvoice.data.data.account_id.toString();

    const transactions = [
      ...getTransactions(
        data.sourceInvoice.data.data.payments,
        data.sourceInvoice.data.translations.createdAt,
        timezone,
        invoice.practice.ref
      ),
    ];

    const status = getInvoiceStatus(Invoice.balance(invoice, transactions));
    const lastTransaction = new TransactionOperators(transactions)
      .completed()
      .sort(sortByCreatedAt)
      .reverse()
      .last();

    if (status === InvoiceStatus.Paid && lastTransaction) {
      invoice.paidAt = lastTransaction.createdAt;
    }

    const issuedAt =
      lastTransaction?.createdAt ??
      data.sourceInvoice.data.translations.createdAt;

    if (status !== InvoiceStatus.Draft) {
      invoice.issuedAt = issuedAt;
    }

    Invoice.updateStatus(invoice, status, issuedAt);

    return {
      invoice,
      transactions,
      planTreatmentPairs,
    };
  }

  private _buildErrorResponse(
    invoice: IGetRecordResponse<
      ID4WPatientInvoice,
      ID4WPatientInvoiceTranslations
    >,
    errorMessage?: string
  ): IDestinationEntityRecord & FailedDestinationEntityRecord {
    return {
      uid: invoice.record.uid,
      label: invoice.record.label,
      status: DestinationEntityRecordStatus.Failed,
      errorMessage: errorMessage ?? 'Missing required properties for invoice',
      failData: {
        invoiceRef: invoice.record.ref,
      },
    };
  }
}

async function getTreatments(
  data: IPatientInvoiceJobData,
  invoicePatientRefs: IPatientRelationshipData[],
  treatments: ID4WPatientTreatment[],
  translationMap: TranslationMapHandler
): Promise<[ITreatmentLineItem[], IPlanWithTreatmentPair[]]> {
  const planTreatmentPairs: IPlanWithTreatmentPair[] = [];

  const lineItems = compact(
    await asyncForEach(treatments, async (treatment) => {
      const procedureCode = treatment.item_code;
      const code = resolveMappedCode(
        data.sourceItemCodes,
        treatment.item_id.toString(),
        procedureCode
      );

      if (!code) {
        return;
      }

      const patientId = treatment.patient_id.toString();
      const patientRef = await translationMap.getDestination<IPatient>(
        patientId,
        PATIENT_RESOURCE_TYPE
      );
      if (!patientRef) {
        throw new Error(`No patient for id ${patientId}`);
      }

      const invoicePatientRef = invoicePatientRefs.find(
        (patient) => patient.patientId === patientId
      )?.patientRef;
      if (!invoicePatientRef) {
        throw new Error(`No patient for id ${patientId}`);
      }

      const treatmentPlans = await snapshot(
        TreatmentPlan.all$({ ref: patientRef })
      );

      const treatmentUid = treatment.id.toString();
      const existingTreatment = await snapshot(
        combineLatest(
          treatmentPlans.map((treatmentPlan) =>
            TreatmentPlan.treatmentById$(treatmentPlan, treatmentUid).pipe(
              map((planTreatment) => ({
                treatment: planTreatment,
                treatmentPlan,
              }))
            )
          )
        ).pipe(
          multiFilter((planTreatment) => !!planTreatment.treatment),
          map(first)
        )
      );

      if (!existingTreatment?.treatment) {
        throw new Error(`No treatment found for id ${treatment.id}`);
      }

      const providerId = treatment.provider_id.toString();
      const practitionerMap = data.staff.find(
        (staffer) => staffer.sourceIdentifier === providerId
      )?.destinationIdentifier;

      if (!practitionerMap) {
        throw new Error(`Couldn't resolve practitioner ${providerId ?? ''}`);
      }
      const practitioner = data.practitioners.find((searchPractitioner) =>
        isSameRef(searchPractitioner.ref, practitionerMap)
      );

      if (!practitioner) {
        throw new Error(`Couldn't resolve practitioner ${providerId ?? ''}`);
      }

      const lineItem = {
        patientRef: invoicePatientRef,
        ...treatmentToLineItem(
          existingTreatment.treatment,
          existingTreatment.treatmentPlan,
          stafferToNamedDoc(practitioner),
          TaxRate.GST
        ),
      };

      planTreatmentPairs.push({
        planRef: existingTreatment.treatmentPlan.ref,
        lineItemUid: treatmentUid,
        amount: roundTo2Decimals(lineItem.amount),
      });

      return lineItem;
    })
  );

  return [lineItems, planTreatmentPairs];
}

function getMiscLineItems(
  data: IPatientInvoiceJobData,
  treatments: ID4WPatientTreatment[]
): ICustomLineItem[] {
  return treatments
    .filter((treatment) => !!treatment.account_id)
    .filter((treatment) => {
      const procedureCode = treatment.item_code;
      const code = resolveMappedCode(
        data.sourceItemCodes,
        treatment.item_id.toString(),
        procedureCode
      );

      if (code) {
        return false;
      }

      return true;
    })
    .map((treatment) => {
      const description = treatment.item_code_description
        ? `${treatment.item_code} - ${treatment.item_code_description}`
        : treatment.item_code;
      const cost = roundTo2Decimals(parseFloat(treatment.fee));
      return {
        ...feeToLineItem(description, cost),
        quantity: treatment.times,
      };
    });
}

function getInvoiceStatus(amountRemaining: number): InvoiceStatus {
  if (amountRemaining === 0) {
    return InvoiceStatus.Paid;
  }

  if (amountRemaining > 0) {
    return InvoiceStatus.Issued;
  }

  if (amountRemaining < 0) {
    return InvoiceStatus.Paid;
  }

  return InvoiceStatus.Draft;
}

function getTransactions(
  payments: ID4WPatientInvoicePayment[],
  invoiceCreatedAt: Timestamp,
  timezone: Timezone,
  practiceRef: DocumentReference<IPractice>
): (IIdentifiable & ITransaction)[] {
  return payments.map((payment) => {
    const amount = roundTo2Decimals(
      parseFloat(payment.true_payment_amount ?? '0')
    );

    const isOutgoingPaymentType = [
      D4WPaymentType.RefundFromDeposit,
      D4WPaymentType.RefundOfTreat,
    ].includes(payment.payment_type ?? D4WPaymentType.Usual);

    const type =
      amount < 0 || isOutgoingPaymentType
        ? TransactionType.Outgoing
        : TransactionType.Incoming;

    const uid = compact([payment.payment_plan_id, payment.payment_id]).join(
      '-'
    );

    const reference = `${
      payment.payment_method ?? TransactionProvider.Manual
    }:${uid || uuid()}`;

    const createdAt = payment.tot_payment_created_at
      ? toTimestamp(
          moment.tz(
            payment.tot_payment_created_at,
            ISO_DATE_TIME_FORMAT,
            timezone
          )
        )
      : invoiceCreatedAt;

    const isDiscount =
      payment.payment_method_id === D4WMethodOfPayment.Discount;
    const isDeposit = payment.payment_method_id === D4WMethodOfPayment.Deposit;

    const provider = isDiscount
      ? TransactionProvider.Discount
      : isDeposit
        ? TransactionProvider.AccountCredit
        : TransactionProvider.Manual;

    const transaction: AtLeast<
      ITransaction,
      'reference' | 'from' | 'to' | 'practiceRef'
    > = {
      reference,
      type,
      status: TransactionStatus.Complete,
      from: '',
      to: '',
      amount: Math.abs(amount),
      extendedData: payment,
      createdAt,
      practiceRef,
    };

    return {
      ...Transaction.init({
        ...Transaction.internalReference(provider, uid),
        ...transaction,
      }),
    };
  });
}
