import {
  roundTo2Decimals,
  TaxRate,
  TaxStrategy,
} from '@principle-theorem/accounting';
import {
  ADA_CODE_TREATMENT_CONFIG_ID,
  Brand,
  CalculateTreatmentWeight,
  ChartedItemScopeResolver,
  ChartedSurface,
  ChartedTreatment,
  determineTaxFromStrategy,
  FeeSchedule,
  getQuadrant,
  getQuadrantIndex,
  hasMergeConflicts,
  PricedServiceCodeEntry,
  stafferToNamedDoc,
  toothNumberToDeciduous,
  TreatmentConfiguration,
  TreatmentPlan,
  TreatmentStep,
} from '@principle-theorem/principle-core';
import {
  ChartableSurface,
  DestinationEntityRecordStatus,
  IAppointment,
  IChartedRef,
  IChartedTreatment,
  IFeeSchedule,
  IHasSourceIdentifier,
  IMigratedDataSummary,
  IPatient,
  IPractice,
  IStaffer,
  isToothNumber,
  IToothRef,
  ITranslationMap,
  ITreatmentCategory,
  ITreatmentConfiguration,
  ITreatmentPlan,
  ITreatmentStep,
  ToothNumber,
  TreatmentPlanStatus,
  TreatmentStepStatus,
  type FailedDestinationEntityRecord,
  type IDestinationEntity,
  type IDestinationEntityRecord,
  type IGetRecordResponse,
  type IPracticeMigration,
  type ISourceEntityRecord,
  type MergeConflictDestinationEntityRecord,
  IArraySorter,
} from '@principle-theorem/principle-core/interfaces';
import {
  all$,
  asyncForEach,
  doc$,
  DocumentReference,
  find$,
  Firestore,
  getDoc,
  getDoc$,
  getDocs,
  getError,
  HISTORY_DATE_FORMAT,
  ISO_DATE_FORMAT,
  multiFilter,
  reduceToSingleArray,
  resolveSequentially,
  safeCombineLatest,
  snapshot,
  sortByCreatedAt,
  toInt,
  toISODate,
  toMoment,
  toMomentTz,
  toNamedDocument,
  toTimestamp,
  undeletedQuery,
  where,
  WithRef,
  type INamedDocument,
  type ISODateType,
  type Timestamp,
  type Timezone,
} from '@principle-theorem/shared';
import { compact, first, groupBy, omit, sortBy } from 'lodash';
import * as moment from 'moment-timezone';
import { combineLatest, of, type Observable } from 'rxjs';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import { BaseDestinationEntity } from '../../../destination/base-destination-entity';
import { FirestoreMigrate } from '../../../destination/destination';
import { DestinationEntity } from '../../../destination/destination-entity';
import {
  ITreatmentPlanSuccessData,
  PATIENT_TREATMENT_PLAN_CUSTOM_MAPPING_TYPE,
  PATIENT_TREATMENT_STEP_CUSTOM_MAPPING_TYPE,
} from '../../../destination/entities/patient-treatment-plans';
import { STAFFER_RESOURCE_TYPE } from '../../../destination/entities/staff';
import { PatientIdFilter } from '../../../destination/filters/patient-id-filter';
import { PracticeIdFilter } from '../../../destination/filters/practice-id-filter';
import { resolveMappedCode } from '../../../mappings/item-codes';
import { ItemCodeResourceMapType } from '../../../mappings/item-codes-to-xlsx';
import { getPractitionerOrDefault } from '../../../mappings/staff';
import { PracticeMigration } from '../../../practice-migrations';
import { buildSkipMigratedQuery } from '../../../source/source-entity-record';
import { type TranslationMapHandler } from '../../../translation-map';
import {
  PATIENT_RESOURCE_TYPE,
  PatientSourceEntity,
  type ID4WPatient,
  type ID4WPatientFilters,
  type ID4WPatientTranslations,
} from '../../source/entities/patient';
import {
  ID4WAppointment,
  ID4WAppointmentFilters,
  ID4WAppointmentTranslations,
  PatientAppointmentSourceEntity,
} from '../../source/entities/patient-appointment';
import {
  D4WToothSurface,
  ID4WPatientTreatment,
  ID4WPatientTreatmentFilters,
  ID4WPatientTreatmentTranslations,
  PatientTreatmentSourceEntity,
  TOOTH_SURFACES_MAP,
} from '../../source/entities/patient-treatment';
import { D4WItemCodeToTreatmentMappingHandler } from '../mappings/item-code-to-treatment';
import { D4WItemCodeMappingHandler } from '../mappings/item-codes';
import { D4WPracticeMappingHandler } from '../mappings/practices';
import { D4WStafferMappingHandler } from '../mappings/staff';
import {
  FeeScheduleDestinationEntity,
  feeScheduleResolverFn,
} from './fee-schedules';
import {
  D4W_MIGRATED_APPOINTMENTS_PLAN_NAME,
  PatientAppointmentDestinationEntity,
} from './patient-appointments';
import { PatientDestinationEntity } from './patients';

export const PATIENT_TREATMENT_PLAN_DESTINATION_ENTITY = DestinationEntity.init(
  {
    metadata: {
      key: 'patientTreatmentPlans',
      label: 'Patient Treatment Plans',
      description: `This migrates the treatments for each patient and creates:
      - A single step for performed treatments
      - A single step for all pending treatments

      Some points:
      - Notes for each treatment are consolidated on the clinical chart notes
      - If a quadrant has been used for a tooth number, it will be omitted. It's very likely that a clinical note has accompanied the treatment so this is a negligible omission.
      `,
    },
  }
);

export interface IPatientTreatmentPlanJobData<
  Patient = ID4WPatient,
  Translations = ID4WPatientTranslations,
  Filters extends object = ID4WPatientFilters,
> {
  sourcePatient: IGetRecordResponse<Patient, Translations, Filters>;
  defaultTreatmentConfiguration: WithRef<ITreatmentConfiguration>;
  treatmentConfigurationMappings: WithRef<
    ITranslationMap<ITreatmentConfiguration>
  >[];
  sourceItemCodes: WithRef<ITranslationMap<object, ItemCodeResourceMapType>>[];
  staff: WithRef<ITranslationMap<IStaffer>>[];
  practices: WithRef<ITranslationMap<IPractice>>[];
  practitioners: WithRef<IStaffer>[];
  treatmentCategories: WithRef<ITreatmentCategory>[];
}

export interface IPatientTreatmentPlanMigrationData {
  patientRef: DocumentReference<IPatient>;
  sourcePatientId: string;
  planPairs: ITreatmentPlanStepPair[];
}

export class PatientTreatmentPlanDestinationEntity extends BaseDestinationEntity<
  ITreatmentPlanSuccessData,
  IPatientTreatmentPlanJobData,
  IPatientTreatmentPlanMigrationData
> {
  destinationEntity = PATIENT_TREATMENT_PLAN_DESTINATION_ENTITY;

  override canMigrateByIdRange = true;

  override filters = [
    new PracticeIdFilter<IPatientTreatmentPlanJobData>((jobData) =>
      jobData.sourcePatient.data.data.practice_id.toString()
    ),
    new PatientIdFilter<IPatientTreatmentPlanJobData>((jobData) =>
      this.sourceEntities.patients
        .getSourceRecordId(jobData.sourcePatient.data.data)
        .toString()
    ),
  ];

  override sorters: IArraySorter[] = [
    {
      key: 'treatments',
      sortByPath: 'uuid',
    },
    {
      key: 'adaCodes',
      sortByPath: 'code.name',
    },
  ];

  sourceCountComparison = new PatientSourceEntity();

  override sourceEntities = {
    patients: new PatientSourceEntity(),
    treatments: new PatientTreatmentSourceEntity(),
    appointments: new PatientAppointmentSourceEntity(),
  };

  override destinationEntities = {
    patients: new PatientDestinationEntity(),
    feeSchedules: new FeeScheduleDestinationEntity(),
    patientAppointments: new PatientAppointmentDestinationEntity(),
  };

  customMappings = {
    staff: new D4WStafferMappingHandler(),
    practice: new D4WPracticeMappingHandler(),
    itemCodes: new D4WItemCodeMappingHandler(),
    itemCodeToTreatment: new D4WItemCodeToTreatmentMappingHandler(),
  };

  sourceCountDataAccessor(
    data: IPatientTreatmentPlanJobData
  ): DocumentReference<ISourceEntityRecord> {
    return data.sourcePatient.record.ref;
  }

  getMigratedData$(
    record: IDestinationEntityRecord<ITreatmentPlanSuccessData>
  ): Observable<IMigratedDataSummary[]> {
    if (record.status !== DestinationEntityRecordStatus.Migrated) {
      return of([]);
    }

    return combineLatest([
      doc$(record.data.sourceRef),
      safeCombineLatest(
        record.data.treatmentPlanRefs.map((treatmentPlanRef) =>
          doc$(treatmentPlanRef)
        )
      ),
      safeCombineLatest(
        record.data.treatmentStepRefs.map((treatmentStepRef) =>
          doc$(treatmentStepRef)
        )
      ),
    ]).pipe(
      map(([sourceRecord, treatmentPlans, treatmentSteps]) => [
        {
          label: 'Source Record',
          data: sourceRecord,
        },
        ...treatmentPlans.map((treatmentPlan) => ({
          label: `Treatment Plan - ${treatmentPlan.name}`,
          data: treatmentPlan,
        })),
        ...treatmentSteps.map((treatmentStep) => ({
          label: `Treatment Step - ${treatmentStep.name}`,
          data: treatmentStep,
        })),
      ])
    );
  }

  buildJobData$(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    skipMigrated: boolean,
    _fromDate?: Timestamp,
    _toDate?: Timestamp,
    fromId?: string,
    toId?: string
  ): Observable<IPatientTreatmentPlanJobData[]> {
    const staff$ = combineLatest([
      this.customMappings.staff.getRecords$(translationMap),
      translationMap.getByType$<IStaffer>(STAFFER_RESOURCE_TYPE),
    ]).pipe(map(([staff, mappedStaff]) => [...staff, ...mappedStaff]));
    const sourceItemCodes$ =
      this.customMappings.itemCodes.getRecords$(translationMap);
    const practices$ = this.customMappings.practice.getRecords$(translationMap);
    const brand$ = PracticeMigration.brand$(migration);
    const practitioners$ = brand$.pipe(
      switchMap((brand) => all$(Brand.stafferCol(brand)))
    );
    const treatmentConfigurationMappings$ =
      this.customMappings.itemCodeToTreatment.getRecords$(translationMap);
    const defaultTreatmentConfiguration$ = brand$.pipe(
      switchMap((brand) =>
        getDoc$(TreatmentConfiguration.col(brand), ADA_CODE_TREATMENT_CONFIG_ID)
      )
    );
    const treatmentCategories$ = brand$.pipe(
      switchMap((brand) => Brand.treatmentCategories$(brand))
    );

    return this.sourceEntities.patients
      .getRecords$(
        migration,
        1000,
        buildSkipMigratedQuery(skipMigrated, this.destinationEntity)
      )
      .pipe(
        multiFilter((patient) => {
          if (!fromId || !toId) {
            return true;
          }

          return (
            patient.data.data.patient_id >= toInt(fromId) &&
            patient.data.data.patient_id <= toInt(toId)
          );
        }),
        withLatestFrom(
          staff$,
          practitioners$,
          defaultTreatmentConfiguration$,
          treatmentConfigurationMappings$,
          sourceItemCodes$,
          practices$,
          treatmentCategories$
        ),
        map(
          ([
            sourcePatients,
            staff,
            practitioners,
            defaultTreatmentConfiguration,
            treatmentConfigurationMappings,
            sourceItemCodes,
            practices,
            treatmentCategories,
          ]) =>
            sourcePatients.map((sourcePatient) => ({
              sourcePatient,
              staff,
              practitioners,
              defaultTreatmentConfiguration,
              treatmentConfigurationMappings,
              sourceItemCodes,
              practices,
              treatmentCategories,
            }))
        )
      );
  }

  getDestinationEntityRecordUid(data: IPatientTreatmentPlanJobData): string {
    return data.sourcePatient.record.uid;
  }

  async buildMigrationData(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    data: IPatientTreatmentPlanJobData
  ): Promise<
    | IPatientTreatmentPlanMigrationData
    | (IDestinationEntityRecord & FailedDestinationEntityRecord)
  > {
    const sourcePatientId = this.sourceEntities.patients
      .getSourceRecordId(data.sourcePatient.data.data)
      .toString();

    const patientRef = await translationMap.getDestination<IPatient>(
      sourcePatientId,
      PATIENT_RESOURCE_TYPE
    );

    if (!patientRef) {
      return this._buildErrorResponse(data.sourcePatient, 'No patient');
    }

    const providerId = data.sourcePatient.data.data.provider_id?.toString();
    const practitioner = getPractitionerOrDefault(
      providerId,
      data.staff,
      data.practitioners
    );

    if (!practitioner) {
      return this._buildErrorResponse(
        data.sourcePatient,
        `Couldn't resolve practitioner ${providerId ?? ''}`
      );
    }

    try {
      const treatments = await this.sourceEntities.treatments.filterRecords(
        migration,
        'patientId',
        sourcePatientId
      );

      const appointments = await this.sourceEntities.appointments.filterRecords(
        migration,
        'patientId',
        sourcePatientId
      );

      const planPairs = await this._buildTreatmentPlanData(
        data,
        translationMap,
        practitioner,
        treatments,
        appointments,
        migration,
        sourcePatientId
      );

      return {
        sourcePatientId,
        patientRef,
        planPairs,
      };
    } catch (error) {
      return this._buildErrorResponse(data.sourcePatient, getError(error));
    }
  }

  async hasMergeConflict(
    translationMap: TranslationMapHandler,
    data: IPatientTreatmentPlanMigrationData
  ): Promise<IPatientTreatmentPlanMigrationData | undefined> {
    try {
      const mergeConflictPlanPairs = await asyncForEach(
        data.planPairs,
        async ({ plan, steps }) => {
          const planRef = await translationMap.getDestination(
            plan.sourceIdentifier,
            PATIENT_TREATMENT_PLAN_CUSTOM_MAPPING_TYPE
          );

          if (!planRef) {
            return;
          }

          const existingPlan = await getDoc(
            planRef as DocumentReference<ITreatmentPlan>
          );

          const planMergeConflict = hasMergeConflicts(plan, {
            ...existingPlan,
            steps: [],
          });

          const existingSteps: IStepWithIdentifiers[] = [];

          const stepMergeConflicts = await asyncForEach(steps, async (step) => {
            const stepRef = await translationMap.getDestination(
              step.sourceIdentifier,
              PATIENT_TREATMENT_STEP_CUSTOM_MAPPING_TYPE
            );

            if (!stepRef) {
              return false;
            }

            const existingStep = await getDoc(
              stepRef as DocumentReference<ITreatmentStep>
            );
            existingSteps.push({
              ...omit(existingStep, 'ref'),
              sourceIdentifier: step.sourceIdentifier,
              createdAt: step.createdAt,
            });

            const stepMergeConflict = hasMergeConflicts(
              omit(step, ['sourceIdentifier']),
              omit(existingStep, ['sourceIdentifier']),
              ['chartedAt'],
              [
                {
                  key: 'chartedSurfaces',
                  sortByPath: [
                    'resolvedAt.seconds',
                    'chartedRef.wholeMouth',
                    'chartedRef.tooth.quadrant',
                    'chartedRef.tooth.quadrantIndex',
                    'chartedRef.tooth.surface',
                  ],
                },
                {
                  key: 'treatments',
                  sortByPath: [
                    'type',
                    'config.ref.id',
                    'scopeRef',
                    'price',
                    'attributedTo.ref.id',
                    'adaCodes[0].code.name',
                    'chartedSurfaces[0].resolvedAt.seconds',
                    'chartedSurfaces[0].chartedRef.wholeMouth',
                    'chartedSurfaces[0].chartedRef.tooth.quadrant',
                    'chartedSurfaces[0].chartedRef.tooth.quadrantIndex',
                    'chartedSurfaces[0].chartedRef.tooth.surface',
                  ],
                },
              ]
            );

            return stepMergeConflict;
          });

          const hasIncorrectStepCount =
            existingPlan.steps.length !== existingSteps.length;

          const hasMergeConflict = [
            hasIncorrectStepCount,
            planMergeConflict,
            ...stepMergeConflicts,
          ].some((stepMergeConflict) => stepMergeConflict);

          if (hasMergeConflict) {
            return {
              plan: {
                ...omit(existingPlan, 'ref'),
                steps: [],
                sourceIdentifier: plan.sourceIdentifier,
              },
              steps: sortBy(existingSteps, 'name'),
            };
          }
        }
      );

      if (compact(mergeConflictPlanPairs).length) {
        return {
          ...data,
          planPairs: compact(mergeConflictPlanPairs),
        };
      }
    } catch (error) {
      return;
    }
  }

  buildMergeConflictRecord(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    _translationMap: TranslationMapHandler,
    jobData: IPatientTreatmentPlanJobData,
    _migrationData: IPatientTreatmentPlanMigrationData
  ): IDestinationEntityRecord & MergeConflictDestinationEntityRecord {
    return {
      uid: jobData.sourcePatient.record.uid,
      label: jobData.sourcePatient.record.label,
      status: DestinationEntityRecordStatus.MergeConflict,
    };
  }

  async runJob(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    jobData: IPatientTreatmentPlanJobData,
    migrationData: IPatientTreatmentPlanMigrationData
  ): Promise<IDestinationEntityRecord> {
    try {
      const { planRefs, stepRefs } = await this._upsertTreatmentPlans(
        migrationData,
        translationMap
      );

      return this._buildSuccessResponse(
        jobData.sourcePatient,
        planRefs,
        stepRefs
      );
    } catch (error) {
      return this._buildErrorResponse(jobData.sourcePatient, String(error));
    }
  }

  private _buildSuccessResponse(
    patient: IGetRecordResponse<ID4WPatient, ID4WPatientTranslations>,
    treatmentPlanRefs: DocumentReference<ITreatmentPlan>[],
    treatmentStepRefs: DocumentReference<ITreatmentStep>[]
  ): IDestinationEntityRecord<ITreatmentPlanSuccessData> {
    return {
      uid: patient.record.uid,
      label: patient.record.label,
      data: {
        sourceRef: patient.record.ref,
        treatmentPlanRefs,
        treatmentStepRefs,
      },
      status: DestinationEntityRecordStatus.Migrated,
      migratedAt: toTimestamp(),
    };
  }

  private async _upsertTreatmentPlans(
    migrationData: IPatientTreatmentPlanMigrationData,
    translationMap: TranslationMapHandler
  ): Promise<{
    planRefs: DocumentReference<ITreatmentPlan>[];
    stepRefs: DocumentReference<ITreatmentStep>[];
  }> {
    const results = await asyncForEach(
      migrationData.planPairs,
      async ({ plan, steps }) => {
        const planDestinationRef = await translationMap.getDestination(
          plan.sourceIdentifier,
          PATIENT_TREATMENT_PLAN_CUSTOM_MAPPING_TYPE
        );

        const planRef = await FirestoreMigrate.upsertDoc(
          TreatmentPlan.col({
            ref: migrationData.patientRef,
          }),
          {
            ...plan,
            deleted: false,
          },
          planDestinationRef?.id
        );

        if (!planDestinationRef) {
          await translationMap.upsert({
            sourceIdentifier: plan.sourceIdentifier,
            destinationIdentifier: planRef,
            resourceType: PATIENT_TREATMENT_PLAN_CUSTOM_MAPPING_TYPE,
          });
        }

        const resolvedPlan = await getDoc(planRef);

        const stepRefs = await asyncForEach(steps, async (step) => {
          const stepDestinationRef = await translationMap.getDestination(
            step.sourceIdentifier,
            PATIENT_TREATMENT_STEP_CUSTOM_MAPPING_TYPE
          );

          const stepRef = await FirestoreMigrate.upsertDoc(
            TreatmentPlan.treatmentStepCol({
              ref: planRef,
            }),
            {
              ...step,
              deleted: false,
            },
            stepDestinationRef?.id
          );

          if (!stepDestinationRef) {
            await translationMap.upsert({
              sourceIdentifier: step.sourceIdentifier,
              destinationIdentifier: stepRef,
              resourceType: PATIENT_TREATMENT_STEP_CUSTOM_MAPPING_TYPE,
            });
          }

          if (step.appointment) {
            await this._associateAppointmentWithStep(
              step.appointment,
              resolvedPlan,
              stepRef
            );
          }

          return stepRef;
        });

        await FirestoreMigrate.patchDoc(planRef, {
          steps: stepRefs,
        });

        return { planRefs: [planRef], stepRefs };
      }
    );

    const appointmentPlan = await snapshot(
      find$(
        TreatmentPlan.col({
          ref: migrationData.patientRef,
        }),
        where('name', '==', D4W_MIGRATED_APPOINTMENTS_PLAN_NAME)
      )
    );

    const planSteps = appointmentPlan
      ? await getDocs(
          undeletedQuery(TreatmentPlan.treatmentStepCol(appointmentPlan))
        )
      : [];

    if (appointmentPlan && !planSteps.length) {
      await FirestoreMigrate.deleteDoc(appointmentPlan.ref);
    }

    return {
      planRefs: reduceToSingleArray(results.map((result) => result.planRefs)),
      stepRefs: reduceToSingleArray(results.map((result) => result.stepRefs)),
    };
  }

  private async _associateAppointmentWithStep(
    appointmentRef: DocumentReference<IAppointment>,
    resolvedPlan: WithRef<ITreatmentPlan>,
    stepRef: DocumentReference<ITreatmentStep>
  ): Promise<void> {
    const appointment = await getDoc(appointmentRef);
    const oldPlanSummary = appointment.treatmentPlan;
    const oldStep = await getDoc(oldPlanSummary.treatmentStep.ref);

    await FirestoreMigrate.saveDoc(omit(oldStep, 'appointment'));
    if (oldPlanSummary.name === D4W_MIGRATED_APPOINTMENTS_PLAN_NAME) {
      await FirestoreMigrate.deleteDoc(oldStep.ref);
    } else {
      await FirestoreMigrate.patchDoc(stepRef, {
        deleted: false,
      });
    }
    await FirestoreMigrate.updateDoc(appointment.ref, {
      treatmentPlan: TreatmentPlan.treatmentStepToAssociatedTreatment(
        resolvedPlan,
        await Firestore.getDoc(stepRef)
      ),
    });

    await FirestoreMigrate.patchDoc(stepRef, {
      appointment: appointmentRef,
    });
  }

  private async _buildTreatmentPlanData(
    data: IPatientTreatmentPlanJobData,
    translationMap: TranslationMapHandler,
    practitioner: WithRef<IStaffer>,
    sourceTreatments: IGetRecordResponse<
      ID4WPatientTreatment,
      ID4WPatientTreatmentTranslations,
      ID4WPatientTreatmentFilters
    >[],
    sourceAppointments: IGetRecordResponse<
      ID4WAppointment,
      ID4WAppointmentTranslations,
      ID4WAppointmentFilters
    >[],
    migration: WithRef<IPracticeMigration>,
    sourcePatientId: string
  ): Promise<ITreatmentPlanStepPair[]> {
    const defaultFeeSchedule = await FeeSchedule.getPreferredOrDefault(
      migration.configuration.organisation
    );

    const treatmentPlansGroups = groupBy(
      sourceTreatments,
      (treatment) => treatment.data.data.chart_id ?? 0
    );

    const plans = await asyncForEach(
      Object.values(treatmentPlansGroups),
      async (treatments) => {
        const planId = treatments[0].data.data.chart_id?.toString() ?? '';

        const planName = planId
          ? treatments[0].data.data.plan_name ?? ''
          : 'Misc Treatment Plan';

        const planCreatedAt =
          treatments[0].data.translations.planCreatedAt ?? toTimestamp();
        const planPractitioner =
          getPractitionerOrDefault(
            treatments[0].data.data.plan_provider_id ?? undefined,
            data.staff,
            data.practitioners
          ) ?? practitioner;

        const plan = {
          ...TreatmentPlan.init({
            name:
              planName.trim() ||
              `Treatment Plan - ${
                planCreatedAt
                  ? toMoment(planCreatedAt).format(HISTORY_DATE_FORMAT)
                  : planId
              }`,
            practitioner: planPractitioner
              ? stafferToNamedDoc(planPractitioner)
              : undefined,
            createdAt: planCreatedAt,
          }),
          sourceIdentifier: `${sourcePatientId}-plan-${planId}`,
        };

        const filteredTreatments = treatments.filter((treatment) =>
          data.practices.some(
            (practice) =>
              practice.sourceIdentifier === treatment.record.filters.practiceId
          )
        );

        const pendingSteps: IStepWithIdentifiers[] = [];
        const completedSteps: IStepWithIdentifiers[] = [];

        await resolveSequentially(filteredTreatments, async (treatment) => {
          const chartedTreatments = await getChartedTreatments(
            [treatment.data.data],
            feeScheduleResolverFn(translationMap, defaultFeeSchedule),
            data,
            migration.configuration.timezone
          );

          if (!chartedTreatments.length) {
            return;
          }

          if (treatment.data.translations.date) {
            const date = toISODate(
              toMomentTz(
                treatment.data.translations.date,
                migration.configuration.timezone
              )
            );
            const sourceIdentifier = `${sourcePatientId}-${planId}-completed-${date}`;

            const existingStep = completedSteps.find(
              (step) => step.sourceIdentifier === sourceIdentifier
            );

            if (existingStep) {
              existingStep.treatments.push(...chartedTreatments);
              return;
            }

            const step = TreatmentStep.init({
              name: `Completed ${toMomentTz(
                date,
                migration.configuration.timezone
              ).format(HISTORY_DATE_FORMAT)}`,
              treatments: chartedTreatments,
              status: TreatmentStepStatus.Complete,
            });
            const treatmentCategory =
              await CalculateTreatmentWeight.getPrimaryCategory(
                step,
                data.treatmentCategories
              );

            const createdAt = treatment.data.translations.date
              ? toTimestamp(
                  toMomentTz(
                    treatment.data.translations.date,
                    migration.configuration.timezone
                  )
                )
              : toTimestamp();

            completedSteps.push({
              ...step,
              display: {
                ...step.display,
                primaryTreatmentCategory: treatmentCategory?.ref,
              },
              createdAt,
              date,
              sourceIdentifier,
            });
            return;
          }

          if (!treatment.data.translations.planDate) {
            return;
          }

          const date = toISODate(
            toMomentTz(
              treatment.data.translations.planDate,
              migration.configuration.timezone
            )
          );
          const stepNumber = treatment.data.data.plan_visit_no || 0;
          const sourceIdentifier = `${sourcePatientId}-${planId}-pending-${date}-${stepNumber}`;
          const existingStep = pendingSteps.find(
            (step) => step.sourceIdentifier === sourceIdentifier
          );

          if (existingStep) {
            existingStep.treatments.push(...chartedTreatments);
            return;
          }

          const step = TreatmentStep.init({
            name: `Pending Step ${
              stepNumber ||
              toMomentTz(
                treatment.data.translations.planDate,
                migration.configuration.timezone
              ).format(HISTORY_DATE_FORMAT)
            }`,
            treatments: chartedTreatments,
            status: TreatmentStepStatus.Incomplete,
          });

          const treatmentCategory =
            await CalculateTreatmentWeight.getPrimaryCategory(
              step,
              data.treatmentCategories
            );

          const createdAt = treatment.data.translations.date
            ? toTimestamp(
                toMomentTz(
                  treatment.data.translations.date,
                  migration.configuration.timezone
                )
              )
            : toTimestamp();

          pendingSteps.push({
            ...step,
            display: {
              ...step.display,
              primaryTreatmentCategory: treatmentCategory?.ref,
            },
            createdAt,
            date,
            sourceIdentifier,
          });
        });

        const steps = [
          ...sortBy(completedSteps, (step) => step.date),
          ...sortBy(pendingSteps, (step) => step.name),
        ];

        if (!planCreatedAt) {
          const earliestStepDate = steps.sort(sortByCreatedAt).pop()?.createdAt;
          if (earliestStepDate) {
            plan.createdAt = earliestStepDate;
          }
        }

        const status = determinePlanStatus(steps, plan.createdAt);

        return {
          plan: {
            ...plan,
            status,
          },
          steps,
        };
      }
    );

    const allPlans = compact(
      sortBy(plans, 'sourceIdentifier').reduce((mergedPlans, plan) => {
        const planMatchFn = (mergedPlan: ITreatmentPlanStepPair): boolean =>
          mergedPlan.plan.name === plan.plan.name;

        const existingPlan = mergedPlans.find(planMatchFn);
        if (!existingPlan) {
          return [...mergedPlans, plan];
        }

        const mergedSteps = sortBy(
          [...existingPlan.steps, ...plan.steps],
          'sourceIdentifier'
        ).reduce((steps, step) => {
          const stepMatchFn = (mergedStep: IStepWithIdentifiers): boolean =>
            mergedStep.name === step.name &&
            mergedStep.status === TreatmentStepStatus.Complete &&
            step.status === TreatmentStepStatus.Complete;

          const existingStep = steps.find(stepMatchFn);

          if (!existingStep) {
            return [...steps, step];
          }

          return steps.map((filterStep) => {
            if (!stepMatchFn(filterStep)) {
              return filterStep;
            }

            return {
              ...filterStep,
              treatments: [...filterStep.treatments, ...step.treatments],
            };
          });
        }, [] as IStepWithIdentifiers[]);

        const mergedPlanStatus = determinePlanStatus(
          mergedSteps,
          existingPlan.plan.createdAt
        );

        const mergedPlan = {
          ...existingPlan,
          steps: mergedSteps,
          plan: {
            ...existingPlan.plan,
            status: mergedPlanStatus,
          },
        };

        return [
          ...mergedPlans.filter((filterPlan) => !planMatchFn(filterPlan)),
          mergedPlan,
        ];
      }, [] as ITreatmentPlanStepPair[])
    );

    const availableAppointments = compact(
      await asyncForEach(sourceAppointments, async (sourceAppointment) => {
        const appointmentRef = await translationMap.getDestination<
          WithRef<IAppointment>
        >(
          String(
            this.sourceEntities.appointments.getSourceRecordId(
              sourceAppointment.data.data
            )
          ),
          this.destinationEntities.patientAppointments.destinationEntity
            .metadata.key
        );

        if (!appointmentRef) {
          return;
        }

        return getDoc(appointmentRef);
      })
    );

    return allPlans.map((plan) => {
      return {
        ...plan,
        steps: plan.steps.map((step) => {
          const matchingAppointmentIndex = availableAppointments.findIndex(
            (appointment) => {
              if (!appointment.event) {
                return;
              }

              const date = toISODate(
                toMomentTz(
                  appointment.event.from,
                  migration.configuration.timezone
                )
              );

              return (
                step.status === TreatmentStepStatus.Complete &&
                step.date === date
              );
            }
          );

          const matchingAppointment =
            availableAppointments[matchingAppointmentIndex];

          if (matchingAppointment) {
            availableAppointments.splice(matchingAppointmentIndex, 1);
          }

          return {
            ...step,
            appointment: matchingAppointment?.ref,
          };
        }),
      };
    });
  }

  private _buildErrorResponse(
    patient: IGetRecordResponse<ID4WPatient, ID4WPatientTranslations>,
    errorMessage?: string
  ): IDestinationEntityRecord & FailedDestinationEntityRecord {
    return {
      uid: patient.record.uid,
      label: patient.record.label,
      status: DestinationEntityRecordStatus.Failed,
      errorMessage:
        errorMessage ?? 'Missing required properties for treatment plan',
      failData: {
        patientRef: patient.record.ref,
      },
    };
  }
}

export function determinePlanStatus(
  steps: IStepWithIdentifiers[],
  planCreatedAt: Timestamp
): TreatmentPlanStatus {
  let preliminaryStatus: TreatmentPlanStatus | undefined;

  if (steps.length === 0) {
    preliminaryStatus = TreatmentPlanStatus.Draft;
  }

  const someComplete = steps.some(
    (step) => step.status === TreatmentStepStatus.Complete
  );

  if (someComplete) {
    preliminaryStatus = TreatmentPlanStatus.InProgress;
  }

  const allComplete = steps.every(
    (step) => step.status === TreatmentStepStatus.Complete
  );

  if (allComplete) {
    preliminaryStatus = TreatmentPlanStatus.Completed;
  }

  const allIncomplete = steps.every(
    (step) => step.status === TreatmentStepStatus.Incomplete
  );

  if (allIncomplete) {
    preliminaryStatus = TreatmentPlanStatus.Draft;
  }

  if (!preliminaryStatus) {
    preliminaryStatus = TreatmentPlanStatus.Draft;
  }

  const planAgeInMonths = moment().diff(toMoment(planCreatedAt), 'months');

  if (
    preliminaryStatus === TreatmentPlanStatus.Draft &&
    planAgeInMonths > 12 &&
    allIncomplete
  ) {
    return TreatmentPlanStatus.Declined;
  }

  return preliminaryStatus;
}

export interface IStepWithIdentifiers
  extends ITreatmentStep,
    IHasSourceIdentifier {
  createdAt: Timestamp;
  date?: ISODateType;
}

export interface ITreatmentPlanStepPair {
  plan: ITreatmentPlan & IHasSourceIdentifier;
  steps: IStepWithIdentifiers[];
}

export interface ITreatmentPlanStepDocPair {
  planRef: DocumentReference<ITreatmentPlan>;
  stepRefs: DocumentReference<ITreatmentStep>[];
}

export async function getChartedTreatments(
  sourceTreatments: ID4WPatientTreatment[],
  getPreferredOrDefaultRef: (
    feeSheduleId?: string
  ) => Promise<INamedDocument<IFeeSchedule>>,
  data: IPatientTreatmentPlanJobData,
  timezone: Timezone
): Promise<IChartedTreatment[]> {
  const treatments: IChartedTreatment[] = [];

  await asyncForEach(sourceTreatments, async (sourceTreatment) => {
    const treatmentProviderId = sourceTreatment.provider_id.toString();
    const practitioner = getPractitionerOrDefault(
      treatmentProviderId,
      data.staff,
      data.practitioners
    );

    if (!practitioner) {
      throw new Error(
        `Couldn't resolve practitioner for treatment ${treatmentProviderId}`
      );
    }

    if (!sourceTreatment.item_code) {
      return;
    }
    const procedureCode = sourceTreatment.item_code;
    const code = resolveMappedCode(
      data.sourceItemCodes,
      sourceTreatment.item_id.toString(),
      procedureCode
    );
    if (!code) {
      return;
    }

    const itemCodeId = sourceTreatment.item_id.toString();
    const mappedTreatment = data.treatmentConfigurationMappings.find(
      (mapping) => mapping.sourceIdentifier === itemCodeId
    );
    const treatmentConfiguration = mappedTreatment?.destinationIdentifier
      ? await getDoc(mappedTreatment?.destinationIdentifier)
      : data.defaultTreatmentConfiguration;

    const sourceFeeScheduleId =
      sourceTreatment.payment_plans[0]?.fee_level_id?.toString();
    const feeSchedule = await getPreferredOrDefaultRef(sourceFeeScheduleId);
    const taxStatus = TaxStrategy.GSTFree;
    const price = roundTo2Decimals(parseFloat(sourceTreatment.fee));

    const toothNumber = sourceTreatment.tooth_ref
      ? String(sourceTreatment.tooth_ref).trim()
      : undefined;
    if (!isToothNumber(toothNumber) && toothNumber) {
      // eslint-disable-next-line no-console
      console.info(`${toothNumber} isn't a valid tooth number`);
    }

    const serviceCode = PricedServiceCodeEntry.init({
      code: code.code,
      type: code.type,
      taxStatus,
      price,
      quantity: sourceTreatment.times,
      tax: determineTaxFromStrategy(TaxRate.GST, {
        taxStatus: taxStatus,
        amount: price,
      }),
      chartedSurfaces: isToothNumber(toothNumber)
        ? getChartedRefs(
            toothNumber,
            sourceTreatment.tooth_surface,
            sourceTreatment.is_baby_tooth
          ).map((chartedRef) =>
            ChartedSurface.init({
              chartedBy: stafferToNamedDoc(practitioner),
              resolvedBy: getResolvedBy(sourceTreatment.date, practitioner),
              resolvedAt: getResolvedAt(sourceTreatment.date, timezone),
              chartedRef,
            })
          )
        : [],
    });

    const scopeResolver = new ChartedItemScopeResolver();
    const scopeRefs = scopeResolver.reduceChartedSurfacesToScope(
      treatmentConfiguration,
      serviceCode.chartedSurfaces
    );
    const scopeRef = first(scopeRefs)?.scopeRef ?? {
      scope: ChartableSurface.WholeMouth,
    };

    treatments.push(
      ChartedTreatment.init({
        uuid: sourceTreatment.id.toString(),
        config: toNamedDocument(treatmentConfiguration),
        feeSchedule,
        serviceCodes: [serviceCode],
        chartedSurfaces: serviceCode.chartedSurfaces,
        attributedTo: stafferToNamedDoc(practitioner),
        scopeRef,
        resolvedBy: getResolvedBy(sourceTreatment.date, practitioner),
        resolvedAt: getResolvedAt(sourceTreatment.date, timezone),
      })
    );
  });

  return treatments;
}

export function getChartedRefs(
  toothNumber: ToothNumber | null | undefined,
  surfaces: D4WToothSurface[] = [],
  isDeciduous: boolean = false
): Partial<IChartedRef>[] {
  const adjustedToothNumber =
    isDeciduous && toothNumber
      ? toothNumberToDeciduous(toothNumber)
      : toothNumber;
  const quadrant = adjustedToothNumber
    ? getQuadrant(adjustedToothNumber)
    : undefined;
  const quadrantIndex = adjustedToothNumber
    ? getQuadrantIndex(adjustedToothNumber)
    : undefined;
  if (!adjustedToothNumber || !quadrant || !quadrantIndex) {
    return [
      {
        unscoped: true,
      },
    ];
  }

  const toothRef: IToothRef = {
    quadrant,
    quadrantIndex,
  };

  if (!surfaces.length) {
    return [
      {
        tooth: toothRef,
      },
    ];
  }

  return surfaces.map((individualSurface) => {
    const surface = TOOTH_SURFACES_MAP[individualSurface];

    return {
      tooth: {
        quadrant,
        quadrantIndex,
        surface,
      },
    };
  });
}

export function getResolvedAt(
  completedDate: string | undefined | null,
  timezone: Timezone
): Timestamp | undefined {
  return completedDate
    ? toTimestamp(
        moment.tz(completedDate, ISO_DATE_FORMAT, timezone).startOf('day')
      )
    : undefined;
}

export function getResolvedBy(
  completedDate: string | undefined | null,
  practitioner: WithRef<IStaffer>
): INamedDocument<IStaffer> | undefined {
  return completedDate ? stafferToNamedDoc(practitioner) : undefined;
}
