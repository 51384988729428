import { initVersionedSchema, toTextContent } from '@principle-theorem/editor';
import {
  Appointment,
  Brand,
  Event,
  Interaction,
  TreatmentPlan,
  TreatmentStep,
  hasMergeConflicts,
  stafferToNamedDoc,
} from '@principle-theorem/principle-core';
import {
  AppointmentStatus,
  DestinationEntityRecordStatus,
  EventType,
  IBrand,
  IMigratedDataSummary,
  ITranslationMap,
  ParticipantType,
  SkippedDestinationEntityRecord,
  TreatmentPlanStatus,
  TreatmentStepStatus,
  type FailedDestinationEntityRecord,
  type IAppointment,
  type IDestinationEntity,
  type IDestinationEntityRecord,
  type IEvent,
  type IGetRecordResponse,
  type IInteractionV2,
  type IPatient,
  type IPractice,
  type IPracticeMigration,
  type ISourceEntityRecord,
  type IStaffer,
  type ITreatmentPlan,
  type ITreatmentStep,
  type MergeConflictDestinationEntityRecord,
  ITreatmentCategory,
  ITag,
  isTag,
} from '@principle-theorem/principle-core/interfaces';
import {
  Firestore,
  HISTORY_DATE_FORMAT,
  ITimePeriod,
  SystemActors,
  asDocRef,
  getError,
  initFirestoreModel,
  multiFilter,
  multiMap,
  toInt,
  toMoment,
  toNamedDocument,
  toTimestamp,
  upsertBulk,
  type DocumentReference,
  type ISODateType,
  type Timestamp,
  type WithRef,
  toMomentTz,
  INamedDocument,
} from '@principle-theorem/shared';
import { compact, omit } from 'lodash';
import { combineLatest, of, type Observable, from } from 'rxjs';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import { BaseDestinationEntity } from '../../../destination/base-destination-entity';
import { FirestoreMigrate } from '../../../destination/destination';
import { DestinationEntity } from '../../../destination/destination-entity';
import { PATIENT_APPOINTMENT_RESOURCE_TYPE } from '../../../destination/entities/patient-appointments';
import {
  PATIENT_TREATMENT_PLAN_CUSTOM_MAPPING_TYPE,
  PATIENT_TREATMENT_STEP_CUSTOM_MAPPING_TYPE,
} from '../../../destination/entities/patient-treatment-plans';
import { STAFFER_RESOURCE_TYPE } from '../../../destination/entities/staff';
import { buildSkipMigratedQuery } from '../../../source/source-entity-record';
import { type TranslationMapHandler } from '../../../translation-map';
import {
  ICorePracticePatientAppointment,
  ICorePracticePatientAppointmentFilters,
  ICorePracticePatientAppointmentTranslations,
  PatientAppointmentSourceEntity,
} from '../../source/entities/patient-appointments';
import {
  PATIENT_RESOURCE_TYPE,
  PatientSourceEntity,
} from '../../source/entities/patients';
import { CorePracticeAppointmentBookToPractitionerMappingHandler } from '../mappings/appointment-book-to-practitioner';
import { CorePracticeExcludedAppointmentBooksMappingHandler } from '../mappings/exclude-appointment-books';
import { CorePracticePracticeMappingHandler } from '../mappings/practices';
import { CorePracticeStafferMappingHandler } from '../mappings/staff';
import { PatientDestinationEntity } from './patients';
import { StafferDestinationEntity } from './staff';
import { CorePracticeEventClassToTreatmentCategoryMappingHandler } from '../mappings/event-class-to-treatment-category';
import { EventClassDestination } from '../mappings/event-class-to-treatment-category-to-xslx';
import * as moment from 'moment-timezone';

export const CORE_PRACTICE_MIGRATED_APPOINTMENTS_PLAN_NAME =
  'Core Practice - Migrated Appointments';

export const PATIENT_APPOINTMENT_DESTINATION_ENTITY = DestinationEntity.init({
  metadata: {
    key: PATIENT_APPOINTMENT_RESOURCE_TYPE,
    label: 'Patient Appointments',
    description: '',
  },
});

interface IAppointmentSuccessData {
  sourceRef: DocumentReference<ISourceEntityRecord>;
  appointmentRef: DocumentReference<IAppointment>;
}

interface IPatientAppointmentJobData {
  sourceAppointment: IGetRecordResponse<
    ICorePracticePatientAppointment,
    ICorePracticePatientAppointmentTranslations,
    ICorePracticePatientAppointmentFilters
  >;
  staff: WithRef<ITranslationMap<IStaffer>>[];
  practitioners: WithRef<IStaffer>[];
  brand: WithRef<IBrand>;
  practices: WithRef<ITranslationMap<IPractice>>[];
  excludedAppointmentBookIds: string[];
  appointmentBookToPractitioner: WithRef<ITranslationMap<IStaffer>>[];
  eventClassToTreatmentCategory: WithRef<
    ITranslationMap<EventClassDestination>
  >[];
}

export interface IPatientAppointmentMigrationData {
  patientRef: DocumentReference<IPatient>;
  sourcePatientId: string;
  appointmentUid: string;
  appointment: Omit<IAppointment, 'treatmentPlan'>;
  createdAt: Timestamp;
  planUid: string;
  stepUid: string;
  plan: ITreatmentPlan;
  step: ITreatmentStep;
  interactions: IInteractionV2[];
}

export class PatientAppointmentDestinationEntity extends BaseDestinationEntity<
  IAppointmentSuccessData,
  IPatientAppointmentJobData,
  IPatientAppointmentMigrationData
> {
  destinationEntity = PATIENT_APPOINTMENT_DESTINATION_ENTITY;
  override canMigrateByDateRange = true;
  override canMigrateByIdRange = true;

  sourceCountComparison = new PatientAppointmentSourceEntity();

  override sourceEntities = {
    patients: new PatientSourceEntity(),
    appointments: new PatientAppointmentSourceEntity(),
  };

  override destinationEntities = {
    patients: new PatientDestinationEntity(),
    staff: new StafferDestinationEntity(),
  };

  customMappings = {
    staff: new CorePracticeStafferMappingHandler(),
    practices: new CorePracticePracticeMappingHandler(),
    excludedAppointmentBooks:
      new CorePracticeExcludedAppointmentBooksMappingHandler(),
    appointmentBookToPractitioner:
      new CorePracticeAppointmentBookToPractitionerMappingHandler(),
    eventClassToTreatmentCategory:
      new CorePracticeEventClassToTreatmentCategoryMappingHandler(),
  };

  sourceCountDataAccessor(
    data: IPatientAppointmentJobData
  ): DocumentReference<ISourceEntityRecord> {
    return data.sourceAppointment.record.ref;
  }

  getMigratedData$(
    record: IDestinationEntityRecord<IAppointmentSuccessData>
  ): Observable<IMigratedDataSummary[]> {
    if (record.status !== DestinationEntityRecordStatus.Migrated) {
      return of([]);
    }

    return combineLatest([
      Firestore.getDoc(record.data.sourceRef),
      Firestore.getDoc(record.data.appointmentRef),
    ]).pipe(
      map(([sourceAppointment, appointment]) => [
        {
          label: 'Source Appointment',
          data: sourceAppointment,
        },
        {
          label: 'Appointment',
          data: appointment,
        },
      ])
    );
  }

  buildJobData$(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    skipMigrated: boolean,
    fromDate?: Timestamp,
    toDate?: Timestamp,
    fromId?: string,
    toId?: string
  ): Observable<IPatientAppointmentJobData[]> {
    const brand$ = Firestore.getDoc(migration.configuration.brand.ref);
    const practices$ =
      this.customMappings.practices.getRecords$(translationMap);
    const staff$ = combineLatest([
      this.customMappings.staff.getRecords$(translationMap),
      translationMap.getByType$<IStaffer>(STAFFER_RESOURCE_TYPE),
    ]).pipe(map(([staff, mappedStaff]) => [...staff, ...mappedStaff]));
    const appointmentBookToPractitioner$ =
      this.customMappings.appointmentBookToPractitioner.getRecords$(
        translationMap
      );
    const practitioners$ = from(brand$).pipe(
      switchMap((brand) => Firestore.getDocs(Brand.stafferCol(brand)))
    );
    const excludedAppointmentBooks$ =
      this.customMappings.excludedAppointmentBooks.getRecords$(translationMap);
    const eventClassToTreatmentCategory$ =
      this.customMappings.eventClassToTreatmentCategory.getRecords$(
        translationMap
      );

    return this.sourceEntities.appointments
      .getRecords$(
        migration,
        1000,
        buildSkipMigratedQuery(skipMigrated, this.destinationEntity),
        undefined,
        fromDate,
        toDate
      )
      .pipe(
        multiFilter((appointment) => {
          if (!fromId || !toId) {
            return true;
          }

          return (
            appointment.data.data.id >= toInt(fromId) &&
            appointment.data.data.id <= toInt(toId)
          );
        }),
        withLatestFrom(
          staff$,
          practitioners$,
          brand$,
          practices$,
          excludedAppointmentBooks$.pipe(
            multiMap((book) => book.sourceIdentifier.toString())
          ),
          appointmentBookToPractitioner$,
          eventClassToTreatmentCategory$
        ),
        map(
          ([
            sourceAppointments,
            staff,
            practitioners,
            brand,
            practices,
            excludedAppointmentBookIds,
            appointmentBookToPractitioner,
            eventClassToTreatmentCategory,
          ]) =>
            sourceAppointments.map((sourceAppointment) => ({
              sourceAppointment,
              staff,
              practitioners,
              brand,
              practices,
              excludedAppointmentBookIds,
              appointmentBookToPractitioner,
              eventClassToTreatmentCategory,
            }))
        )
      );
  }

  getDestinationEntityRecordUid(data: IPatientAppointmentJobData): string {
    return data.sourceAppointment.record.uid;
  }

  async buildMigrationData(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    data: IPatientAppointmentJobData
  ): Promise<
    | IPatientAppointmentMigrationData
    | (IDestinationEntityRecord & FailedDestinationEntityRecord)
    | (IDestinationEntityRecord & SkippedDestinationEntityRecord)
  > {
    const appointmentUid = this.sourceEntities.appointments
      .getSourceRecordId(data.sourceAppointment.data.data)
      .toString();
    const sourcePatientId =
      data.sourceAppointment.data.data.patientId.toString();

    const patientRef = await translationMap.getDestination<IPatient>(
      sourcePatientId,
      PATIENT_RESOURCE_TYPE
    );

    if (!patientRef) {
      return this._buildErrorResponse(
        data.sourceAppointment,
        `No patient with id ${sourcePatientId}`
      );
    }

    const appointmentBookId =
      data.sourceAppointment.data.data.calendarId.toString();

    const shouldExcludeAppointment =
      data.excludedAppointmentBookIds.includes(appointmentBookId);

    if (shouldExcludeAppointment) {
      return this._buildSkippedResponse(data.sourceAppointment);
    }

    const providerId = data.sourceAppointment.data.data.providerId?.toString();

    const practitionerMap = this.resolvePractitioner(
      data,
      appointmentBookId,
      providerId
    );

    if (!practitionerMap) {
      return this._buildErrorResponse(
        data.sourceAppointment,
        `No practitioner with id: ${
          providerId ?? ''
        }, appointmentBookId: ${appointmentBookId}`
      );
    }

    const practiceSourceId =
      data.sourceAppointment.data.data.locationId.toString();
    const practiceMap = data.practices.find(
      (practice) => practice.sourceIdentifier === practiceSourceId
    );

    if (!practiceMap?.destinationIdentifier) {
      return this._buildErrorResponse(
        data.sourceAppointment,
        `No practice with id ${practiceSourceId}`
      );
    }

    const practice = await Firestore.getDoc(practiceMap.destinationIdentifier);

    const createdAt = data.sourceAppointment.data.translations.from;

    try {
      const [appointment, interactions] = await this._buildAppointmentData(
        patientRef,
        practitionerMap,
        data.sourceAppointment,
        practice,
        migration.configuration.backupDate
      );

      const planUid = `${sourcePatientId}-appointments`;
      const stepUid = `${sourcePatientId}-${appointmentUid}-appointments`;
      const plan = TreatmentPlan.init({
        name: CORE_PRACTICE_MIGRATED_APPOINTMENTS_PLAN_NAME,
        status: TreatmentPlanStatus.InProgress,
      });

      const timezone = migration.configuration.timezone;
      const appointmentDate = toMomentTz(
        data.sourceAppointment.data.translations.from,
        timezone
      ).format(HISTORY_DATE_FORMAT);

      const categoryDestinationIdentifier =
        data.eventClassToTreatmentCategory.find(
          (categoryMap) =>
            categoryMap.sourceIdentifier ===
            data.sourceAppointment.data.data.eventClassId?.toString()
        )?.destinationIdentifier;

      const categoryDestination = categoryDestinationIdentifier
        ? await Firestore.getDoc(categoryDestinationIdentifier)
        : undefined;

      const overrideTreatmentCategory = isTag(categoryDestination)
        ? undefined
        : (categoryDestination?.ref as DocumentReference<ITreatmentCategory>);

      const appointmentTag = isTag(categoryDestination)
        ? (categoryDestination as INamedDocument<ITag>)
        : undefined;
      if (appointmentTag) {
        appointment.tags.push(appointmentTag);
      }

      const step = TreatmentStep.init({
        name: appointmentDate,
        status:
          appointment.status === AppointmentStatus.Complete
            ? TreatmentStepStatus.Complete
            : TreatmentStepStatus.Incomplete,
        schedulingRules: {
          duration: appointment.event ? Event.duration(appointment.event) : 0,
        },
        display: {
          overrideTreatmentCategory,
        },
      });

      return {
        sourcePatientId,
        patientRef,
        appointmentUid,
        createdAt,
        appointment,
        planUid,
        plan,
        stepUid,
        step,
        interactions,
      };
    } catch (error) {
      return this._buildErrorResponse(data.sourceAppointment, getError(error));
    }
  }

  async hasMergeConflict(
    translationMap: TranslationMapHandler,
    data: IPatientAppointmentMigrationData
  ): Promise<IPatientAppointmentMigrationData | undefined> {
    const existingAppointmentRef = await translationMap.getDestination(
      data.appointmentUid,
      PATIENT_APPOINTMENT_RESOURCE_TYPE
    );

    if (!existingAppointmentRef) {
      return;
    }

    try {
      const existingAppointment = await Firestore.getDoc(
        asDocRef<IAppointment>(existingAppointmentRef)
      );

      const hasMergeConflict = hasMergeConflicts(
        data.appointment,
        omit(existingAppointment, 'treatmentPlan'),
        ['dateFrom', 'dateTo']
      );

      if (hasMergeConflict) {
        return {
          ...data,
          appointment: existingAppointment,
        };
      }
    } catch (error) {
      return;
    }
  }

  buildMergeConflictRecord(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    _translationMap: TranslationMapHandler,
    jobData: IPatientAppointmentJobData,
    _migrationData: IPatientAppointmentMigrationData
  ): IDestinationEntityRecord & MergeConflictDestinationEntityRecord {
    return {
      uid: jobData.sourceAppointment.record.uid,
      label: jobData.sourceAppointment.record.label,
      status: DestinationEntityRecordStatus.MergeConflict,
    };
  }

  async runJob(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    jobData: IPatientAppointmentJobData,
    migrationData: IPatientAppointmentMigrationData
  ): Promise<IDestinationEntityRecord> {
    try {
      const appointmentRef = await this._upsertAppointment(
        migrationData,
        translationMap
      );

      await upsertBulk(
        Appointment.interactionCol({ ref: appointmentRef }),
        migrationData.interactions,
        'uid',
        SystemActors.Migration
      );

      return this._buildSuccessResponse(
        jobData.sourceAppointment,
        appointmentRef
      );
    } catch (error) {
      return this._buildErrorResponse(
        jobData.sourceAppointment,
        getError(error)
      );
    }
  }

  resolvePractitioner(
    data: IPatientAppointmentJobData,
    appointmentBookId: string,
    providerId?: string
  ): DocumentReference<IStaffer> | undefined {
    const appointmentBookPractitionerMap =
      data.appointmentBookToPractitioner.find(
        (practitionerMap) =>
          practitionerMap.sourceIdentifier === appointmentBookId
      );

    if (appointmentBookPractitionerMap?.destinationIdentifier) {
      return appointmentBookPractitionerMap.destinationIdentifier;
    }

    if (providerId) {
      const mappedStaffer = data.staff.find(
        (staffer) => staffer.sourceIdentifier === providerId
      )?.destinationIdentifier;

      if (mappedStaffer) {
        return mappedStaffer;
      }
    }

    // Default provider is used from custom mapping with id: 0
    return data.staff.find((staffer) => staffer.sourceIdentifier === '0')
      ?.destinationIdentifier;
  }

  private _buildSuccessResponse(
    appointment: IGetRecordResponse<
      ICorePracticePatientAppointment,
      ICorePracticePatientAppointmentTranslations
    >,
    appointmentRef: DocumentReference<IAppointment>
  ): IDestinationEntityRecord<IAppointmentSuccessData> {
    return {
      uid: appointment.record.uid,
      label: appointment.record.label,
      data: {
        sourceRef: appointment.record.ref,
        appointmentRef,
      },
      status: DestinationEntityRecordStatus.Migrated,
      migratedAt: toTimestamp(),
    };
  }

  private async _upsertAppointment(
    data: IPatientAppointmentMigrationData,
    translationMap: TranslationMapHandler
  ): Promise<DocumentReference<IAppointment>> {
    const planDestinationRef = await translationMap.getDestination(
      data.planUid,
      PATIENT_TREATMENT_PLAN_CUSTOM_MAPPING_TYPE
    );

    const planRef = await FirestoreMigrate.upsertDoc(
      TreatmentPlan.col({
        ref: data.patientRef,
      }),
      data.plan,
      planDestinationRef?.id
    );

    if (!planDestinationRef) {
      await translationMap.upsert({
        sourceIdentifier: data.planUid,
        destinationIdentifier: planRef,
        resourceType: PATIENT_TREATMENT_PLAN_CUSTOM_MAPPING_TYPE,
      });
    }

    const stepDestinationRef = await translationMap.getDestination(
      data.stepUid,
      PATIENT_TREATMENT_STEP_CUSTOM_MAPPING_TYPE
    );

    const stepRef = await FirestoreMigrate.upsertDoc(
      TreatmentPlan.treatmentStepCol({
        ref: planRef,
      }),
      data.step,
      stepDestinationRef?.id
    );

    if (!stepDestinationRef) {
      await translationMap.upsert({
        sourceIdentifier: data.stepUid,
        destinationIdentifier: stepRef,
        resourceType: PATIENT_TREATMENT_STEP_CUSTOM_MAPPING_TYPE,
      });
    }

    const treatmentPlan = await Firestore.getDoc(planRef);
    const treatmentStep = await Firestore.getDoc(stepRef);

    const patientAppointmentDestinationRef =
      await translationMap.getDestination(
        data.appointmentUid,
        PATIENT_APPOINTMENT_RESOURCE_TYPE
      );

    const appointmentRef = await FirestoreMigrate.upsertDoc(
      Appointment.col({
        ref: data.patientRef,
      }),
      {
        ...data.appointment,
        treatmentPlan: TreatmentPlan.treatmentStepToAssociatedTreatment(
          treatmentPlan,
          treatmentStep
        ),
        createdAt: data.createdAt ?? toTimestamp(),
      },
      patientAppointmentDestinationRef?.id
    );

    if (!patientAppointmentDestinationRef) {
      await translationMap.upsert({
        sourceIdentifier: data.appointmentUid,
        destinationIdentifier: appointmentRef,
        resourceType: PATIENT_APPOINTMENT_RESOURCE_TYPE,
      });
    }

    await FirestoreMigrate.patchDoc(stepRef, {
      appointment: appointmentRef,
    });
    return appointmentRef;
  }

  private async _buildAppointmentData(
    patientRef: DocumentReference<IPatient>,
    practitionerRef: DocumentReference<IStaffer>,
    appointment: IGetRecordResponse<
      ICorePracticePatientAppointment,
      ICorePracticePatientAppointmentTranslations
    >,
    practice: WithRef<IPractice>,
    backupDate: ISODateType
  ): Promise<[Omit<IAppointment, 'treatmentPlan'>, IInteractionV2[]]> {
    const patient = await Firestore.getDoc(patientRef);
    const practitioner = await Firestore.getDoc(practitionerRef);

    const interactions = compact([appointment.data.data.note]).map((note) => {
      const createdAt = toMoment(appointment.data.translations.from).isAfter(
        moment()
      )
        ? toTimestamp()
        : appointment.data.translations.from;

      return Interaction.init({
        uid: appointment.record.uid,
        title: [toTextContent(`Added a note`)],
        content: initVersionedSchema(note),
        createdAt,
        pinned: true,
      });
    });

    const appointmentStartTime = toMomentTz(
      appointment.data.translations.from,
      practice.settings.timezone
    );
    const appointmentEndTime = toMomentTz(
      appointment.data.translations.to,
      practice.settings.timezone
    );

    return [
      {
        event: getAppointmentEvent(
          {
            from: appointmentStartTime,
            to: appointmentEndTime,
          },
          patient,
          practice,
          practitioner
        ),
        eventHistory: [],
        status: appointment.data.data.cancelDate
          ? AppointmentStatus.Cancelled
          : appointmentStartTime.isAfter(toMoment(backupDate))
            ? AppointmentStatus.Scheduled
            : AppointmentStatus.Complete,
        statusHistory: [],
        practice: toNamedDocument(practice),
        practitioner: stafferToNamedDoc(practitioner),
        cancellationHistory: [],
        dependencies: [],
        tags: [],
        ...initFirestoreModel(),
        deleted: appointment.data.data.isDeleted,
      },
      interactions,
    ];
  }

  private _buildErrorResponse(
    appointment: IGetRecordResponse<
      ICorePracticePatientAppointment,
      ICorePracticePatientAppointmentTranslations
    >,
    errorMessage?: string
  ): IDestinationEntityRecord & FailedDestinationEntityRecord {
    return {
      uid: appointment.record.uid,
      label: appointment.record.label,
      status: DestinationEntityRecordStatus.Failed,
      errorMessage:
        errorMessage ?? 'Missing required properties for appointment',
      failData: {
        appointmentRef: appointment.record.ref,
      },
    };
  }

  private _buildSkippedResponse(
    appointment: IGetRecordResponse<
      ICorePracticePatientAppointment,
      ICorePracticePatientAppointmentTranslations
    >
  ): IDestinationEntityRecord & SkippedDestinationEntityRecord {
    return {
      uid: appointment.record.uid,
      label: appointment.record.label,
      status: DestinationEntityRecordStatus.Skipped,
    };
  }
}

export function getAppointmentEvent(
  period: ITimePeriod,
  patient?: WithRef<IPatient>,
  practice?: WithRef<IPractice>,
  practitioner?: WithRef<IStaffer>
): IEvent | undefined {
  if (!practice || !patient || !practitioner) {
    return;
  }
  return Event.init({
    from: toTimestamp(period.from),
    to: toTimestamp(period.to),
    practice: toNamedDocument(practice),
    type: EventType.Appointment,
    participants: [
      {
        ...toNamedDocument(patient),
        type: ParticipantType.Patient,
      },
      {
        ...stafferToNamedDoc(practitioner),
        type: ParticipantType.Staffer,
      },
    ],
  });
}
