import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { OrganisationService } from '@principle-theorem/ng-principle-shared';
import { DialogPresets } from '@principle-theorem/ng-shared';
import { type IPractice } from '@principle-theorem/principle-core/interfaces';
import { type WithRef } from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SidebarPracticeSelectorDialogComponent } from '../sidebar-practice-selector-dialog/sidebar-practice-selector-dialog.component';

@Component({
  selector: 'pr-sidebar-practice-selector',
  templateUrl: './sidebar-practice-selector.component.html',
  styleUrls: ['./sidebar-practice-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarPracticeSelectorComponent {
  @Input() expanded = false;
  practiceName$: Observable<string>;
  canChangePractice$: Observable<boolean>;
  @Output() practiceSelected = new EventEmitter<WithRef<IPractice>>();

  constructor(
    public organisation: OrganisationService,
    private _dialog: MatDialog
  ) {
    this.practiceName$ = this.organisation.practice$.pipe(
      map((practice) => practice?.name ?? '')
    );

    this.canChangePractice$ = this.organisation.allUserPractices$.pipe(
      map((practices) => practices.length > 1)
    );
  }

  async openChangePractice(): Promise<void> {
    const practice = await this._dialog
      .open<SidebarPracticeSelectorDialogComponent, void, WithRef<IPractice>>(
        SidebarPracticeSelectorDialogComponent,
        DialogPresets.medium({})
      )
      .afterClosed()
      .toPromise();

    if (!practice) {
      return;
    }
    this.practiceSelected.next(practice);
  }
}
