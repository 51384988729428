@if (patient$ | async; as patient) {
  <mat-card class="no-padding">
    <mat-tab-group mat-stretch-tabs>
      <ng-container *prIsRegion="[Region.Australia]">
        <mat-tab label="Medicare">
          <pr-medicare-card-display
            [medicareCard]="patient.medicareCard"
            (edit)="editMedicareCard()"
            (remove)="updatePatient({ medicareCard: undefined })"
          />
        </mat-tab>
        <mat-tab label="DVA">
          <pr-dva-card-display
            [dvaCard]="patient.dvaCard"
            (edit)="editDVACard()"
            (remove)="updatePatient({ dvaCard: undefined })"
          />
        </mat-tab>
        <mat-tab label="Health Fund">
          <pr-healthfund-card-display
            [healthFundCard]="patient.healthFundCard"
            (edit)="editHealthFundCard()"
            (remove)="updatePatient({ healthFundCard: undefined })"
          />
        </mat-tab>
      </ng-container>

      <ng-container *prIsRegion="[Region.NewZealand]">
        <mat-tab label="NHI">
          <div class="p-4">
            <pr-national-health-index-number-form
              [nationalHealthIndexNumber]="patient.nationalHealthIndexNumber"
              (nationalHealthIndexNumberChange)="
                updatePatient({ nationalHealthIndexNumber: $event })
              "
            />
          </div>
        </mat-tab>

        <mat-tab label="Health Insurance">
          <div class="p-4">
            <pr-health-insurance-details-form
              [healthInsuranceDetails]="patient.healthInsuranceDetails"
              (healthInsuranceDetailsChange)="
                updatePatient({ healthInsuranceDetails: $event })
              "
            />
          </div>
        </mat-tab>
      </ng-container>
    </mat-tab-group>
  </mat-card>
}
