export enum ChartItemDisplayType {
  CurrentCondition = 'Current',
  ResolvedCondition = 'Resolved',
  ExistingTreatment = 'Prior/Existing',
  CompletedTreatment = 'Completed',
  ProposedTreatment = 'Proposed',
  MigratedCompletedTreatment = 'Migrated',
}

export const CHART_ITEM_DISPLAY_TYPES: ChartItemDisplayType[] = [
  ChartItemDisplayType.CurrentCondition,
  ChartItemDisplayType.ResolvedCondition,
  ChartItemDisplayType.ExistingTreatment,
  ChartItemDisplayType.CompletedTreatment,
  ChartItemDisplayType.ProposedTreatment,
  ChartItemDisplayType.MigratedCompletedTreatment,
];
