import { Brand, Practice, Task } from '@principle-theorem/principle-core';
import {
  ConversationStatus,
  type IAppointmentRequest,
  type IStaffer,
  TaskStatus,
  ChatStatus,
} from '@principle-theorem/principle-core/interfaces';
import {
  count,
  doc$,
  isSameRef,
  multiFilter,
  multiSwitchMap,
  query$,
  toMomentTz,
  toTimestamp,
  undeletedQuery,
  type WithRef,
} from '@principle-theorem/shared';
import { compact, uniqWith } from 'lodash';
import { combineLatest, type Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { type ISideBarItemScope } from './sidebar-items';
import { limit, orderBy, where } from '@principle-theorem/shared';

export class SidebarBadges {
  scope?: Badgeworthy;

  constructor(private _scope: ISideBarItemScope) {
    this.scope = this._scope.practice
      ? {
          appointmentRequests$: Practice.getAppointmentRequests$(
            this._scope.practice
          ),
        }
      : this._scope.brand
        ? {
            appointmentRequests$: Brand.getAppointmentRequests$(
              this._scope.brand
            ),
          }
        : undefined;
  }

  getAppointmentRequestsBadge(): Observable<number> {
    if (!this.scope) {
      return of(0);
    }
    return this.scope.appointmentRequests$.pipe(count());
  }

  getFollowUpsBadge(): Observable<number> {
    const practice = this._scope.practice;
    if (!practice) {
      return of(0);
    }
    return Practice.getAppointmentsWithExistingFollowUps$(practice, 10).pipe(
      map((appointments) => appointments.length)
    );
  }

  getConversationsBadge(): Observable<number> {
    const practice = this._scope.practice;
    const brand = this._scope.brand;
    const staffer = this._scope.staffer;
    if (!brand || !practice || !staffer) {
      return of(0);
    }

    const userPractices$ = this._scope.userPractices$ || of([]);

    return userPractices$.pipe(
      switchMap((userPractices) => {
        if (
          !staffer.settings?.conversations?.showAllPractices ||
          !userPractices.length
        ) {
          return query$(
            Brand.smsConversationCol(brand),
            where('practiceRef', '==', practice.ref),
            where('status', '==', ConversationStatus.Open),
            orderBy('lastMessageAt', 'desc'),
            limit(10)
          );
        }
        return query$(
          Brand.smsConversationCol(brand),
          where(
            'practiceRef',
            'in',
            userPractices.map((userPractice) => userPractice.ref)
          ),
          where('status', '==', ConversationStatus.Open),
          orderBy('lastMessageAt', 'desc'),
          limit(10)
        );
      }),
      multiSwitchMap((conversation) =>
        conversation.lastMessage
          ? doc$(conversation.lastMessage)
          : of(undefined)
      ),
      map(compact),
      multiFilter(
        (message) =>
          !message.readBy.find((readBy) =>
            isSameRef(readBy, this._scope.staffer)
          )
      ),
      count()
    );
  }

  getChatsBadge(): Observable<number> {
    const practice = this._scope.practice;
    const brand = this._scope.brand;
    const staffer = this._scope.staffer;
    if (!brand || !practice || !staffer) {
      return of(0);
    }

    const userPractices$ = this._scope.userPractices$ || of([]);

    return userPractices$.pipe(
      switchMap((userPractices) => {
        if (
          !staffer.settings?.conversations?.showAllPractices ||
          !userPractices.length
        ) {
          return query$(
            Brand.chatCol(brand),
            where('practiceRef', '==', practice.ref),
            where('participants', 'array-contains', staffer.ref),
            where('status', '==', ChatStatus.Open),
            orderBy('lastMessageAt', 'desc'),
            limit(10)
          );
        }
        return query$(
          Brand.chatCol(brand),
          where(
            'practiceRef',
            'in',
            userPractices.map((userPractice) => userPractice.ref)
          ),
          where('participants', 'array-contains', staffer.ref),
          where('status', '==', ChatStatus.Open),
          orderBy('lastMessageAt', 'desc'),
          limit(10)
        );
      }),
      multiSwitchMap((conversation) =>
        conversation.lastMessage
          ? doc$(conversation.lastMessage)
          : of(undefined)
      ),
      map(compact),
      multiFilter(
        (message) =>
          !message.readBy.find((readBy) =>
            isSameRef(readBy, this._scope.staffer)
          )
      ),
      count()
    );
  }

  getTasksBadge(): Observable<number> {
    if (!this._scope.staffer || !this.scope) {
      return of(0);
    }
    const staffer: WithRef<IStaffer> = this._scope.staffer;

    if (this._scope.practice) {
      const today = toMomentTz(
        toTimestamp(),
        this._scope.practice.settings.timezone
      ).startOf('day');

      return combineLatest([
        query$(
          undeletedQuery(Task.col(this._scope.practice)),
          where('assignedUser.ref', '==', staffer.ref),
          where('status', '==', TaskStatus.Open),
          where('visibleFrom', '<=', toTimestamp(today)),
          limit(10)
        ),
        staffer.teams.length
          ? query$(
              undeletedQuery(Task.col(this._scope.practice)),
              where(
                'assignedTeam.ref',
                'in',
                staffer.teams.map((team) => team.ref)
              ),
              where('status', '==', TaskStatus.Open),
              where('visibleFrom', '<=', toTimestamp(today)),
              limit(10)
            )
          : of([]),
      ]).pipe(
        map(([tasks, teamTasks]) =>
          uniqWith([...tasks, ...teamTasks], isSameRef)
        ),
        count()
      );
    }

    return of(0);
  }
}

export type Badgeworthy = IHasAppointmentRequests;

interface IHasAppointmentRequests {
  appointmentRequests$: Observable<WithRef<IAppointmentRequest>[]>;
}
