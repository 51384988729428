<form [formGroup]="healthInsuranceForm">
  <div class="flex gap-2">
    <mat-form-field class="w-full">
      <mat-label>Insurance Company Name</mat-label>
      <input matInput formControlName="provider" />
    </mat-form-field>

    <mat-form-field class="w-full">
      <mat-label>Membership/ Policy Number</mat-label>
      <input matInput formControlName="membershipNumber" />
    </mat-form-field>

    <button
      mat-icon-button
      matSuffix
      matTooltip="Remove Health Insurance Details"
      (click)="healthInsuranceDetailsChange.emit(undefined)"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
</form>
