import {
  DestinationEntityRecordStatus,
  FailedDestinationEntityRecord,
  IDestinationEntity,
  IDestinationEntityRecord,
  IGetRecordResponse,
  IHasSourceIdentifier,
  IMigratedDataSummary,
  IPracticeMigration,
  ISourceEntityHandler,
  ISourceEntityRecord,
  ISterilisationRecord,
  MergeConflictDestinationEntityRecord,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  Firestore,
  Timestamp,
  WithRef,
  asDocRef,
  asyncForEach,
  getError,
  safeCombineLatest,
  toTimestamp,
} from '@principle-theorem/shared';
import { DestinationEntity } from '../destination-entity';
import { Observable, combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslationMapHandler } from '../../translation-map';
import { BaseDestinationEntity } from '../base-destination-entity';
import { PatientIdFilter } from '../filters/patient-id-filter';
import { Practice, hasMergeConflicts } from '@principle-theorem/principle-core';
import { FirestoreMigrate } from '../destination';

export const STERILISATION_RECORD_RESOURCE_TYPE = 'sterilisationRecord';

export const STERILISATION_RECORD_DESTINATION_ENTITY = DestinationEntity.init({
  metadata: {
    key: STERILISATION_RECORD_RESOURCE_TYPE,
    label: 'Sterilisation Records',
    description: ``,
  },
});

export interface ISterilisationRecordBaseJobData<PatientRecord extends object> {
  sourcePatient: IGetRecordResponse<PatientRecord>;
}

export interface ISterilisationRecordMigrationData {
  sterilisationRecords: (ISterilisationRecord &
    IHasSourceIdentifier & {
      createdAt: Timestamp;
    })[];
}

export interface ISterilisationRecordSuccessData {
  sourceRef: DocumentReference<ISourceEntityRecord>;
  sterilisationRefs: DocumentReference<ISterilisationRecord>[];
}

export abstract class BasePatientSterilisationDestinationEntity<
  PatientRecord extends object,
  JobData extends ISterilisationRecordBaseJobData<PatientRecord>,
> extends BaseDestinationEntity<
  ISterilisationRecordSuccessData,
  JobData,
  ISterilisationRecordMigrationData
> {
  destinationEntity = STERILISATION_RECORD_DESTINATION_ENTITY;
  abstract patientSourceEntity: ISourceEntityHandler<PatientRecord[]>;

  override filters = [
    new PatientIdFilter<JobData>((jobData) =>
      this.patientSourceEntity
        .getSourceRecordId(jobData.sourcePatient.data.data)
        .toString()
    ),
  ];

  get sourceCountComparison(): ISourceEntityHandler<PatientRecord[]> {
    return this.patientSourceEntity;
  }

  sourceCountDataAccessor(
    data: JobData
  ): DocumentReference<ISourceEntityRecord> {
    return data.sourcePatient.record.ref;
  }

  getMigratedData$(
    record: IDestinationEntityRecord<ISterilisationRecordSuccessData>
  ): Observable<IMigratedDataSummary[]> {
    if (record.status !== DestinationEntityRecordStatus.Migrated) {
      return of([]);
    }

    return combineLatest([
      Firestore.getDoc(record.data.sourceRef),
      safeCombineLatest(
        record.data.sterilisationRefs.map((sterilisationRef) =>
          Firestore.getDoc(sterilisationRef)
        )
      ),
    ]).pipe(
      map(([source, sterilisationRecords]) => [
        {
          label: 'Source Patient',
          data: source,
        },
        ...sterilisationRecords.map((sterilisationRecord) => ({
          label: `Sterilisation Record`,
          data: sterilisationRecord,
        })),
      ])
    );
  }

  getDestinationEntityRecordUid(data: JobData): string {
    return data.sourcePatient.record.uid;
  }

  async hasMergeConflict(
    translationMap: TranslationMapHandler,
    data: ISterilisationRecordMigrationData
  ): Promise<ISterilisationRecordMigrationData | undefined> {
    const existingSterilisationRecords: (ISterilisationRecord &
      IHasSourceIdentifier & { createdAt: Timestamp })[] = [];

    const sterilisationMergeConflicts = await asyncForEach(
      data.sterilisationRecords,
      async (sterilisationRecord) => {
        const sterilisationRecordRef = await translationMap.getDestination(
          sterilisationRecord.sourceIdentifier,
          STERILISATION_RECORD_RESOURCE_TYPE
        );

        if (!sterilisationRecordRef) {
          return false;
        }

        try {
          const existingSterilisationRecord = await Firestore.getDoc(
            asDocRef<ISterilisationRecord>(sterilisationRecordRef)
          );
          existingSterilisationRecords.push({
            ...existingSterilisationRecord,
            sourceIdentifier: sterilisationRecord.sourceIdentifier,
          });

          return hasMergeConflicts(
            sterilisationRecord,
            existingSterilisationRecord
          );
        } catch (error) {
          return;
        }
      }
    );

    if (sterilisationMergeConflicts.some((mergeConflict) => mergeConflict)) {
      return {
        ...data,
        sterilisationRecords: existingSterilisationRecords,
      };
    }
  }

  buildMergeConflictRecord(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    _translationMap: TranslationMapHandler,
    jobData: JobData,
    _migrationData: ISterilisationRecordMigrationData
  ): IDestinationEntityRecord & MergeConflictDestinationEntityRecord {
    return {
      uid: jobData.sourcePatient.record.uid,
      label: jobData.sourcePatient.record.label,
      status: DestinationEntityRecordStatus.MergeConflict,
    };
  }

  async runJob(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    data: JobData,
    migrationData: ISterilisationRecordMigrationData
  ): Promise<IDestinationEntityRecord> {
    try {
      const sterilisationRefs = await asyncForEach(
        migrationData.sterilisationRecords,
        async (sterilisationRecord) => {
          const sterilisationDestinationRef =
            await translationMap.getDestination(
              sterilisationRecord.sourceIdentifier,
              STERILISATION_RECORD_RESOURCE_TYPE
            );

          const sterilisationRef = await FirestoreMigrate.upsertDoc(
            Practice.sterilisationRecordCol({
              ref: sterilisationRecord.practice.ref,
            }),
            sterilisationRecord,
            sterilisationDestinationRef?.id
          );

          if (!sterilisationDestinationRef) {
            await translationMap.upsert({
              sourceIdentifier: sterilisationRecord.sourceIdentifier,
              destinationIdentifier: sterilisationRef,
              resourceType: STERILISATION_RECORD_RESOURCE_TYPE,
            });
          }

          return sterilisationRef;
        }
      );

      return this._buildSuccessResponse(data.sourcePatient, sterilisationRefs);
    } catch (error) {
      return this._buildErrorResponse(data.sourcePatient, getError(error));
    }
  }

  protected _buildSuccessResponse(
    patientRecord: IGetRecordResponse<PatientRecord>,
    sterilisationRefs: DocumentReference<ISterilisationRecord>[]
  ): IDestinationEntityRecord<ISterilisationRecordSuccessData> {
    return {
      uid: patientRecord.record.uid,
      label: patientRecord.record.label,
      data: {
        sourceRef: patientRecord.record.ref,
        sterilisationRefs,
      },
      status: DestinationEntityRecordStatus.Migrated,
      migratedAt: toTimestamp(),
    };
  }

  protected _buildErrorResponse(
    patient: IGetRecordResponse<PatientRecord>,
    errorMessage?: string
  ): IDestinationEntityRecord<ISterilisationRecordSuccessData> &
    FailedDestinationEntityRecord {
    return {
      uid: patient.record.uid,
      label: patient.record.label,
      status: DestinationEntityRecordStatus.Failed,
      errorMessage:
        errorMessage ?? 'Missing required properties for sterilisation',
      failData: {},
    };
  }
}
