import {
  ChangeDetectionStrategy,
  Component,
  Input,
  type OnDestroy,
  ViewChild,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import {
  extendSortingDataAccessor,
  type IFilterOption,
  ObservableDataSource,
  TableFilters,
  timestampSortingAccessor,
  TrackByFunctions,
} from '@principle-theorem/ng-shared';
import { getTransactionProviderName } from '@principle-theorem/principle-core';
import { type ITransaction } from '@principle-theorem/principle-core/interfaces';
import {
  type IAccountCreditPaymentTransactionRecord,
  type IPractitionerTransactionGrouping,
  type IPractitionerTransactionReportRecord,
  type IPractitionerTransactionSummary,
} from '@principle-theorem/reporting';
import {
  DAY_MONTH_YEAR_FORMAT,
  type FieldsOfType,
  type ITimePeriod,
  multiConcatMap,
  multiFilter,
  multiMap,
  reduce2DArray,
  type UnwrapArray,
} from '@principle-theorem/shared';
import { compact, sum } from 'lodash';
import { BehaviorSubject, type Observable, ReplaySubject, Subject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
  getManualTransactionType,
  getPaymentCardType,
} from '../practitioner-transactions-report-drilldown/practitioner-transactions-report-drilldown.component';
import { PractitionerTransactionsReportStore } from '../practitioner-transactions-report.store';
import { PractitionerTransactionComplexDrilldownToCSV } from './practitioner-transactions-complex-drilldown-to-csv';

@Component({
  selector: 'pr-practitioner-transactions-report-complex-drilldown',
  templateUrl:
    './practitioner-transactions-report-complex-drilldown.component.html',
  styleUrls: [
    './practitioner-transactions-report-complex-drilldown.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PractitionerTransactionsReportComplexDrilldownComponent
  implements OnDestroy
{
  private _onDestroy$ = new Subject<void>();
  description = `All transactions for invoices that are either shared between multiple practitioners, products, or a general deposit in addition to the practitioner's treatment.`;
  trackByPractitionerRef =
    TrackByFunctions.ref<
      UnwrapArray<IPractitionerTransactionSummary['treatmentAmounts']>
    >('practitioner.ref');
  trackByAccountCreditPayment =
    TrackByFunctions.ref<IAccountCreditPaymentTransactionRecord>(
      'payment.transaction.ref'
    );
  result$ = new ReplaySubject<IPractitionerTransactionGrouping>(1);
  filtered$: Observable<IPractitionerTransactionReportRecord[]>;
  dataSource: ObservableDataSource<IPractitionerTransactionReportRecord>;
  dateFormat = DAY_MONTH_YEAR_FORMAT;
  displayedColumns = [
    'transactionDate',
    'invoiceIssuedAt',
    'patient',
    'invoice',
    'transactionProvider',
    'transactionProviderType',
    'accountCreditPaymentType',
    'invoiceAmount',
    'lineItemAmounts',
    'treatmentAmounts',
    'accountCreditAmount',
    'discountAmount',
    'paymentAmount',
    'practitionerProportionAmount',
  ];
  dateRange$ = new ReplaySubject<ITimePeriod>(1);
  providerFilters$: Observable<IFilterOption<string>[]>;
  selectedProviders$ = new BehaviorSubject<string[]>([]);
  providerTypeFilters$: Observable<IFilterOption<string>[]>;
  selectedProviderTypes$ = new BehaviorSubject<string[]>([]);
  accountCreditPaymentTypeFilters$: Observable<IFilterOption<string>[]>;
  selectedAccountCreditPaymentTypes$ = new BehaviorSubject<string[]>([]);
  accountCreditPaymentTypeTotal$: Observable<number>;
  csvData$: Observable<IPractitionerTransactionReportRecord[]>;
  csvTranslator = new PractitionerTransactionComplexDrilldownToCSV();

  @Input()
  set dateRange(dateRange: ITimePeriod) {
    if (dateRange) {
      this.dateRange$.next(dateRange);
    }
  }

  @ViewChild(MatSort)
  set tableSort(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  @Input()
  set result(result: IPractitionerTransactionGrouping) {
    if (result) {
      this.result$.next(result);
    }
  }

  constructor(public store: PractitionerTransactionsReportStore) {
    const data$ = this.result$.pipe(
      map((result) => result.records),
      multiFilter((record) => record.summary.isComplexInvoice)
    );

    this.providerFilters$ = data$.pipe(
      TableFilters.create((record) => [record.transaction.provider])
    );
    this.providerTypeFilters$ = data$.pipe(
      TableFilters.create((record) =>
        compact([getManualTransactionType(record), getPaymentCardType(record)])
      )
    );
    this.accountCreditPaymentTypeFilters$ = data$.pipe(
      TableFilters.create((record) =>
        this._getAccountCreditPaymentTypes(record)
      )
    );

    const providerFilters = TableFilters.multiSelect(
      this.selectedProviders$,
      (record: IPractitionerTransactionReportRecord, selected) =>
        TableFilters.toFilterId(record.transaction.provider) === selected
    );
    const providerTypeFilters = TableFilters.multiSelect(
      this.selectedProviderTypes$,
      (record: IPractitionerTransactionReportRecord, selected) => {
        const type = getManualTransactionType(record) ?? '';
        const cardType = getPaymentCardType(record) ?? '';
        return TableFilters.toFilterId(`${type} ${cardType}`) === selected;
      }
    );
    const accountCreditPaymentTypeFilters = TableFilters.multiSelect(
      this.selectedAccountCreditPaymentTypes$,
      (record: IPractitionerTransactionReportRecord, selected) =>
        record.accountCreditPaymentTransactions
          .map((payment) =>
            getTransactionProviderName(payment.payment.transaction)
          )
          .map((payment) => TableFilters.toFilterId(payment))
          .includes(selected)
    );

    this.filtered$ = data$.pipe(
      providerFilters,
      providerTypeFilters,
      accountCreditPaymentTypeFilters
    );

    this.dataSource = new ObservableDataSource(this.filtered$);
    this.dataSource.sortingDataAccessor = extendSortingDataAccessor(
      (data, sortHeaderId) => this._sortingDataAccessor(data, sortHeaderId)
    );

    this.accountCreditPaymentTypeTotal$ = this.dataSource.filteredData$.pipe(
      multiMap((record) => record.accountCreditPaymentTransactions),
      reduce2DArray(),
      switchMap((records) => this.getFilteredAccountCreditPayments$(records)),
      multiMap((payment) => payment.practitionerAmount),
      map((amounts) => sum(amounts))
    );

    this.csvData$ = this.dataSource.filteredData$.pipe(
      multiConcatMap((record) =>
        this.getFilteredAccountCreditPayments$(
          record.accountCreditPaymentTransactions
        ).pipe(
          map((accountCreditPaymentTransactions) => ({
            ...record,
            accountCreditPaymentTransactions,
          }))
        )
      )
    );
  }

  ngOnDestroy(): void {
    this.dataSource.disconnect();
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  sumAmount$(
    key: FieldsOfType<IPractitionerTransactionSummary, number>
  ): Observable<number> {
    return this.filtered$.pipe(
      multiMap(
        (item) => item.summary[key as keyof IPractitionerTransactionSummary]
      ),
      map(sum)
    );
  }

  getFilteredAccountCreditPayments$(
    records: IAccountCreditPaymentTransactionRecord[]
  ): Observable<IAccountCreditPaymentTransactionRecord[]> {
    return this.selectedAccountCreditPaymentTypes$.pipe(
      map((selectedPaymentTypes) => {
        if (!selectedPaymentTypes.length) {
          return records;
        }
        return records.filter((record) => {
          const provider = getTransactionProviderName(
            record.payment.transaction
          );
          return selectedPaymentTypes.includes(
            TableFilters.toFilterId(provider)
          );
        });
      })
    );
  }

  getProviderName(transaction: ITransaction): string {
    return getTransactionProviderName(transaction);
  }

  private _sortingDataAccessor(
    data: IPractitionerTransactionReportRecord,
    sortHeaderId: string
  ): string | number | undefined {
    switch (sortHeaderId) {
      case 'discountAmount':
        return data.summary.discountAmount;
      case 'accountCreditAmount':
        return data.summary.accountCreditAmount;
      case 'paymentAmount':
        return data.summary.paymentAmount;
      case 'transactionDate':
        return timestampSortingAccessor(data.transaction.createdAt);
      case 'invoiceIssuedAt':
        return data.invoice.issuedAt
          ? timestampSortingAccessor(data.invoice.issuedAt)
          : undefined;
      default:
        return;
    }
  }

  private _getAccountCreditPaymentTypes(
    item: IPractitionerTransactionReportRecord
  ): string[] {
    return item.accountCreditPaymentTransactions.map((payment) =>
      getTransactionProviderName(payment.payment.transaction)
    );
  }
}
