<div
  class="chart-toolbar-container flex flex-wrap items-center justify-between gap-4"
>
  @if (showHistorySelector) {
    <pr-charting-history-selector
      [selected]="chart$ | async"
      (selectedChange)="selectChart($event)"
    />
  } @else {
    <mat-form-field floatLabel="always" subscriptSizing="dynamic">
      <mat-select
        placeholder="Select Treatment Plan"
        [formControl]="treatmentPlanCtrl"
        [compareWith]="compareWithFn"
      >
        @for (plan of treatmentPlans$ | async; track plan.ref.path) {
          <mat-option [value]="plan">{{ plan.name }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
  }

  <div class="flex items-center gap-4">
    <pr-chart-view-selector
      [view]="view$ | async"
      (selectionChange)="selectView($event)"
    />
    <pr-chart-section-selector
      [section]="section$ | async"
      (selectionChange)="selectSection($event)"
    />
    <ng-content />
  </div>
</div>
