import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
  type OnDestroy,
  EventEmitter,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  ManagementService,
  OrganisationService,
  StateBasedNavigationService,
} from '@principle-theorem/ng-principle-shared';
import {
  SidebarManagerService,
  TrackByFunctions,
} from '@principle-theorem/ng-shared';
import { type INotification } from '@principle-theorem/notifications';
import { Brand, Staffer, User } from '@principle-theorem/principle-core';
import {
  BrandPermissions,
  BRANDS_FEATURE,
  OrganisationPermissions,
  PracticePermissions,
  STAFF_CONFIGURATIONS_TREATMENTS_FEATURE,
} from '@principle-theorem/principle-core/features';
import {
  type IBrand,
  type IPractice,
} from '@principle-theorem/principle-core/interfaces';
import {
  filterUndefined,
  type INamedDocument,
  isPathChanged$,
  isSameRef,
  multiFind,
  query$,
  type WithRef,
} from '@principle-theorem/shared';
import { combineLatest, type Observable, of, Subject } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import {
  ResourceType,
  RESOURCE_DISPLAY_MAP,
} from '../../models/resource-display';
import { type ISidebarItem } from '../sidebar/sidebar-items/sidebar-item';
import { limit, orderBy, where } from '@principle-theorem/shared';

@Component({
  selector: 'pr-settings-sidebar',
  templateUrl: './settings-sidebar.component.html',
  styleUrls: ['./settings-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsSidebarComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  @Input() expanded = false;
  notifications$: Observable<WithRef<INotification>[]>;
  notificationsOpen = false;
  trackByBrand = TrackByFunctions.ref<INamedDocument<IBrand>>();
  trackByPractice = TrackByFunctions.ref<INamedDocument<IPractice>>();
  trackByRoute = TrackByFunctions.label<ISidebarItem>();
  brand$: Observable<INamedDocument<IBrand> | undefined>;
  practice$: Observable<INamedDocument<IPractice> | undefined>;
  hasStaffer$: Observable<boolean>;
  hasPracticeAccess$: Observable<boolean>;
  brandRoutePrefix$: Observable<string>;
  stafferRoutePrefix$: Observable<string>;
  practiceRoutePrefix$: Observable<string>;

  workspaceRoutes: ISidebarItem[] = WORKSPACE_ROUTES;
  brandSettings: ISidebarItem[] = BRAND_ROUTES;
  stafferSettings: ISidebarItem[] = STAFFER_ROUTES;
  practiceSettings: ISidebarItem[] = PRACTICE_ROUTES;

  @Output() widthChange = new EventEmitter<void>();

  constructor(
    private _organisation: OrganisationService,
    private _management: ManagementService,
    private _router: Router,
    private _stateNav: StateBasedNavigationService,
    private _managementUser: ManagementService,
    public sidebar: SidebarManagerService
  ) {
    this.notifications$ = this._organisation.staffer$.pipe(
      isPathChanged$('ref.path'),
      filterUndefined(),
      switchMap((staffer) =>
        query$(
          Staffer.notificationCol(staffer),
          where('viewed', '==', false),
          orderBy('createdAt', 'desc'),
          limit(30)
        )
      )
    );

    this.brand$ = this._organisation.brand$;
    this.practice$ = this._organisation.practice$;
    this.hasPracticeAccess$ = combineLatest([
      this._organisation.user$.pipe(filterUndefined()),
      this.practice$.pipe(filterUndefined()),
      this._managementUser.user$,
    ]).pipe(
      switchMap(([user, practice, managementUser]) =>
        managementUser
          ? of(true)
          : User.practices$(user).pipe(
              multiFind((userPractice) => isSameRef(userPractice, practice)),
              map(Boolean)
            )
      )
    );

    this.brandRoutePrefix$ = this.brand$.pipe(
      filterUndefined(),
      map((brand) => `/settings/brand/${brand.ref.id}`)
    );

    this.stafferRoutePrefix$ = this.brandRoutePrefix$.pipe(
      map((brandRoute) => `${brandRoute}/account`)
    );

    this.practiceRoutePrefix$ = this.practice$.pipe(
      filterUndefined(),
      map((practice) => `/settings/practice/${practice.ref.id}`)
    );

    this.hasStaffer$ = combineLatest([
      this._organisation.user$.pipe(filterUndefined()),
      this._management.user$,
      this.brand$.pipe(filterUndefined()),
    ]).pipe(
      switchMap(([user, managementUser, brand]) =>
        managementUser
          ? this._organisation.staffer$
          : Brand.userStaffer$(brand, user)
      ),
      map((hasStaffer) => !!hasStaffer)
    );

    this.sidebar.expanded$.pipe(takeUntil(this._onDestroy$)).subscribe(() => {
      setTimeout(() => this.widthChange.emit());
    });
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async loadPractice(): Promise<void> {
    await this._router.navigate(['/settings']);
  }

  async returnToDashboard(): Promise<void> {
    await this._stateNav.practice([]);
  }
}

const WORKSPACE_ROUTES: ISidebarItem[] = [
  {
    label: 'Workspace',
    tooltip: '',
    path: '',
    children: [
      {
        label: 'Fee Schedules',
        path: `fee-schedules`,
        display: { icon: 'attach_money' },
        permissions: [
          OrganisationPermissions.OrganisationConfigureFeeSchedules,
        ],
      },
      {
        label: 'Users',
        path: `users`,
        display: { icon: 'group' },
        permissions: [OrganisationPermissions.OrganisationConfigureAuth],
      },
      {
        label: 'Roles',
        path: `roles`,
        display: { icon: 'security' },
        permissions: [OrganisationPermissions.OrganisationConfigureAuth],
      },
      {
        label: 'Integrations',
        path: `integrations`,
        display: { icon: 'dynamic_feed' },
        permissions: [
          OrganisationPermissions.OrganisationConfigureIntegrations,
        ],
      },
      {
        label: 'Security',
        path: `security`,
        display: { icon: 'lock' },
        permissions: [OrganisationPermissions.OrganisationConfigureAuth],
      },
    ],
  },
];

const BRAND_ROUTES: ISidebarItem[] = [
  {
    label: 'Brand',
    path: '',
    tooltip:
      'These settings are shared between all of your physical practice locations',
    children: [
      {
        label: 'General',
        path: `general`,
        display: { icon: 'settings' },
        permissions: [
          BrandPermissions.BrandConfigure,
          BrandPermissions.BrandConfigureGeneral,
        ],
      },
      {
        label: 'Accounting',
        path: `accounting`,
        display: { icon: 'account_balance' },
        permissions: [BrandPermissions.BrandConfigure],
      },
      {
        label: 'Medical History',
        path: `medical-history`,
        display: RESOURCE_DISPLAY_MAP[ResourceType.MedicalHistory],
        permissions: [
          BrandPermissions.BrandConfigure,
          BrandPermissions.BrandConfigureGeneral,
        ],
      },
      {
        label: 'Tags',
        path: `tags`,
        display: RESOURCE_DISPLAY_MAP[ResourceType.Tag],
        permissions: [
          BrandPermissions.BrandConfigure,
          BrandPermissions.TagsManage,
        ],
      },
      {
        label: 'Referral Sources',
        path: `referral-sources`,
        display: { icon: 'connect_without_contact' },
        permissions: [BrandPermissions.BrandConfigure],
      },
      {
        label: 'Rescheduling & Cancellation Reasons',
        path: `cancellation-reasons`,
        display: { icon: 'cancel_presentation' },
        permissions: [BrandPermissions.BrandConfigure],
      },
      {
        label: 'Automation Configurations',
        path: `automations`,
        display: RESOURCE_DISPLAY_MAP[ResourceType.Automation],
        permissions: [BrandPermissions.BrandConfigureAutomations],
      },
      {
        label: 'Document Templates',
        path: `document-templates`,
        display: { icon: 'document_scanner' },
        permissions: [BrandPermissions.BrandConfigure],
      },
      {
        label: 'Treatment Templates',
        path: `treatment-templates`,
        display: { icon: 'local_hospital' },
        permissions: [BrandPermissions.BrandConfigure],
      },
      {
        label: 'Treatment Categories',
        path: `treatment-categories`,
        display: { icon: 'category' },
        permissions: [BrandPermissions.BrandConfigure],
      },
      {
        label: 'Shared Snippets',
        path: `snippets`,
        display: RESOURCE_DISPLAY_MAP[ResourceType.Snippet],
        permissions: [BrandPermissions.BrandConfigure],
      },
      {
        label: 'Prescriptions',
        path: `prescriptions`,
        display: { icon: 'medication' },
        permissions: [BrandPermissions.BrandConfigurePrescriptions],
      },
      {
        label: 'Products',
        path: `products`,
        display: { icon: 'inventory_2' },
        permissions: [BrandPermissions.BrandConfigureProducts],
      },
      {
        label: 'Staff',
        path: `staff`,
        display: RESOURCE_DISPLAY_MAP[ResourceType.Staffer],
        permissions: [
          BrandPermissions.BrandConfigure,
          BrandPermissions.BrandConfigureStaff,
        ],
      },
      {
        label: 'Teams',
        path: `teams`,
        display: RESOURCE_DISPLAY_MAP[ResourceType.Team],
        permissions: [
          BrandPermissions.BrandConfigure,
          BrandPermissions.BrandConfigureTeams,
        ],
      },
      {
        label: 'Practices',
        path: `practices`,
        display: { icon: 'holiday_village' },
        permissions: [PracticePermissions.PracticeManage],
      },
      {
        label: 'Conditions',
        path: `charting/conditions`,
        display: RESOURCE_DISPLAY_MAP[ResourceType.ConditionConfiguration],
        feature: BRANDS_FEATURE,
        permissions: [
          BrandPermissions.BrandConfigure,
          BrandPermissions.BrandConfigureClinical,
        ],
      },
      {
        label: 'Treatments',
        path: `charting/treatments`,
        display: RESOURCE_DISPLAY_MAP[ResourceType.TreatmentConfiguration],
        feature: BRANDS_FEATURE,
        permissions: [
          BrandPermissions.BrandConfigure,
          BrandPermissions.BrandConfigureClinical,
        ],
      },
      {
        label: 'Multi Treatments',
        path: `charting/multi-treatments`,
        display: RESOURCE_DISPLAY_MAP[ResourceType.MultiTreatmentConfiguration],
        feature: BRANDS_FEATURE,
        permissions: [
          BrandPermissions.BrandConfigure,
          BrandPermissions.BrandConfigureClinical,
        ],
      },
      {
        label: 'Sterilisation',
        path: `sterilisation`,
        display: { icon: 'clean_hands' },
        feature: BRANDS_FEATURE,
        permissions: [
          BrandPermissions.BrandConfigure,
          BrandPermissions.BrandConfigureClinical,
        ],
      },
    ],
  },
];

const STAFFER_ROUTES: ISidebarItem[] = [
  {
    label: 'My Account',
    path: '',
    tooltip: '',
    children: [
      {
        label: 'General',
        path: `general`,
        display: { icon: 'settings' },
        permissions: [],
      },
      {
        label: 'Personal Snippets',
        path: `snippets`,
        display: RESOURCE_DISPLAY_MAP[ResourceType.Snippet],
        permissions: [],
      },
      {
        label: 'Conditions',
        path: `charting/conditions`,
        display: RESOURCE_DISPLAY_MAP[ResourceType.ConditionConfiguration],
        feature: STAFF_CONFIGURATIONS_TREATMENTS_FEATURE,
        permissions: [],
      },
      {
        label: 'Treatments',
        path: `charting/treatments`,
        display: RESOURCE_DISPLAY_MAP[ResourceType.TreatmentConfiguration],
        feature: STAFF_CONFIGURATIONS_TREATMENTS_FEATURE,
        permissions: [],
      },
      {
        label: 'Multi Treatments',
        path: `charting/multi-treatments`,
        display: RESOURCE_DISPLAY_MAP[ResourceType.MultiTreatmentConfiguration],
        feature: STAFF_CONFIGURATIONS_TREATMENTS_FEATURE,
        permissions: [],
      },
      {
        label: 'Fee Schedules',
        path: `fee-schedules`,
        display: { icon: 'attach_money' },
        permissions: [],
      },
    ],
  },
];

const PRACTICE_ROUTES: ISidebarItem[] = [
  {
    label: 'Practice',
    path: '',
    tooltip: 'These settings are specific to each physical practice location',
    children: [
      {
        label: 'General',
        path: `general`,
        display: { icon: 'settings' },
        permissions: [PracticePermissions.PracticeConfigure],
      },
      {
        label: 'Fee Schedules',
        path: `fee-schedules`,
        display: { icon: 'attach_money' },
        permissions: [PracticePermissions.PracticeConfigure],
      },
      {
        label: 'Opening Hours',
        path: `opening-hours`,
        display: RESOURCE_DISPLAY_MAP[ResourceType.Break],
        permissions: [PracticePermissions.PracticeConfigure],
      },
      {
        label: 'Media Tags',
        path: `tags`,
        display: RESOURCE_DISPLAY_MAP[ResourceType.Tag],
        permissions: [PracticePermissions.PracticeConfigure],
      },
      {
        label: 'Integrations',
        path: `integrations`,
        display: { icon: 'dynamic_feed' },
        permissions: [PracticePermissions.PracticeConfigure],
      },
      {
        label: 'Patient Portal',
        path: `patient-portal`,
        display: { icon: 'face' },
        permissions: [PracticePermissions.PracticeConfigure],
      },
    ],
  },
];
