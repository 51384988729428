import { MixedSchema } from '@principle-theorem/editor';
import {
  DocumentReference,
  IFirestoreModel,
  Timestamp,
  WithRef,
} from '@principle-theorem/shared';
import { IPatient } from '../patient/patient';
import { IPractice } from '../practice/practice';
import { IStaffer } from '../staffer/staffer';
import { IStatusHistory } from '../common';

export interface IPrintOptions {
  fontStyle: 'normal' | 'bold';
  maxWidth?: number;
}

export interface ICoordinate {
  x: number;
  y: number;
}

export interface IMultiColumnCoordinate {
  x: number[];
  y: number;
}

export interface IPatientPrescriptionDetails {
  ref: DocumentReference<IPatient>;
  name?: string;
  address?: string;
  dateOfBirth?: string;
}

export interface IPrescriberPrescriptionDetails {
  ref: DocumentReference<IStaffer>;
  number?: string;
  name?: string;
  qualifications?: string;
}

export interface IPracticePrescriptionDetails {
  ref: DocumentReference<IPractice>;
  name?: string;
  address?: string;
  phone?: string;
}

export interface IPrescription extends IFirestoreModel {
  patient: IPatientPrescriptionDetails;
  prescriber: IPrescriberPrescriptionDetails;
  practice: IPracticePrescriptionDetails;
  items: IPrescriptionItem[];
  notes: MixedSchema;
  status: PrescriptionStatus;
  statusHistory: IPrescirptionStatusHistory[];
  prescribedAt?: Timestamp;
  pbsSafetyNet?: boolean; // Pharmaceutical Benefits Scheme Safety Net
  concessionalOrDependentRPBS?: boolean;
  pbs?: boolean; // Pharmaceutical Benefits Scheme
  rpbs?: boolean; // Repatriation Schedule of Pharmaceutical Benefits Item
  brandSubstitutionNotPermitted?: boolean;
}

export interface IPrescirptionStatusHistory
  extends IStatusHistory<PrescriptionStatus> {
  actionedByRef: DocumentReference<IStaffer>; // The staff member who created/sent the prescription
}

export type PrescriptionFormData = Pick<
  IPrescription,
  | 'notes'
  | 'pbsSafetyNet'
  | 'concessionalOrDependentRPBS'
  | 'pbs'
  | 'rpbs'
  | 'brandSubstitutionNotPermitted'
> & {
  items: PrescriptionItemFormData[];
};

export enum PrescriptionStatus {
  Draft = 'draft',
  Printed = 'printed',
  Sent = 'sent',
  Issued = 'issued',
  Cancelled = 'cancelled',
  Delete = 'delete',
}

export interface IPrescriptionItem extends IFirestoreModel {
  medicationName: string; // name of the medication
  quantity?: string; // number of tablets, capsules etc. in the prescription
  repeat?: number; // number of repeats allowed
  instructions?: string; // take 1 tablet every 4 hours etc.
  safetyInformation?: string; // any warnings about the medication
}

export type PrescriptionItemFormData = Pick<
  IPrescriptionItem,
  | 'medicationName'
  | 'quantity'
  | 'repeat'
  | 'instructions'
  | 'safetyInformation'
>;

export interface IPrintPrescriptionSettings {
  horizontalOffset: number;
  verticalOffset: number;
}

export interface IPrintPrescription {
  printPrescription(
    prescription: WithRef<IPrescription>
  ): Promise<WithRef<IPrescription> | string>;
  printTestPrescription(practice: WithRef<IPractice>): void;
}
