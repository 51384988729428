import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  OrganisationService,
  StateBasedNavigationService,
} from '@principle-theorem/ng-principle-shared';
import { TrackByFunctions } from '@principle-theorem/ng-shared';
import {
  type IBrand,
  type IPractice,
} from '@principle-theorem/principle-core/interfaces';
import { Brand } from '@principle-theorem/principle-core';
import { isSameRef, snapshot, type WithRef } from '@principle-theorem/shared';
import { type Observable, ReplaySubject, combineLatest } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { intersectionWith } from 'lodash';

@Component({
  selector: 'pr-practice-selector-card',
  templateUrl: './practice-selector-card.component.html',
  styleUrls: ['./practice-selector-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PracticeSelectorCardComponent {
  private _brand$: ReplaySubject<WithRef<IBrand>> = new ReplaySubject(1);
  trackByPractice = TrackByFunctions.ref<WithRef<IPractice>>();
  practices$: Observable<WithRef<IPractice>[]>;

  constructor(
    private _organisation: OrganisationService,
    private _stateNav: StateBasedNavigationService
  ) {
    this.practices$ = combineLatest([
      this._brand$,
      this._organisation.allUserPractices$,
    ]).pipe(
      switchMap(([brand, userPractices]) =>
        Brand.practices$(brand).pipe(
          map((brandPractices) =>
            intersectionWith(brandPractices, userPractices, isSameRef)
          )
        )
      )
    );
  }

  @Input()
  set brand(brand: WithRef<IBrand>) {
    if (brand) {
      this._brand$.next(brand);
    }
  }

  async loadPractice(practice: WithRef<IPractice>): Promise<void> {
    const brand: WithRef<IBrand> = await snapshot(this._brand$);
    await this._organisation.selectBrand(brand);
    await this._organisation.selectPractice(practice);
    await this._stateNav.practice(
      ['schedule', 'timeline'],
      undefined,
      practice
    );
  }
}
