import {
  AccountCredit,
  Invoice,
  Patient,
  hasMergeConflicts,
} from '@principle-theorem/principle-core';
import {
  DestinationEntityRecordStatus,
  IInvoice,
  IMigratedDataSummary,
  ISourceEntityHandler,
  ISourceEntityRecord,
  ITransaction,
  type FailedDestinationEntityRecord,
  type IAccountCredit,
  type IDestinationEntity,
  type IDestinationEntityRecord,
  type IGetRecordResponse,
  type IPatient,
  type IPracticeMigration,
  type MergeConflictDestinationEntityRecord,
  ITranslationMap,
  IPractice,
} from '@principle-theorem/principle-core/interfaces';
import {
  Firestore,
  IIdentifiable,
  asDocRef,
  asyncForEach,
  getError,
  safeCombineLatest,
  toTimestamp,
  type DocumentReference,
  type Timestamp,
  type WithRef,
} from '@principle-theorem/shared';
import { compact, omit, sortBy } from 'lodash';
import { combineLatest, of, type Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslationMapHandler } from '../../translation-map';
import { BaseDestinationEntity } from '../base-destination-entity';
import { FirestoreMigrate } from '../destination';
import { DestinationEntity } from '../destination-entity';
import { PatientIdFilter } from '../filters/patient-id-filter';

export const PATIENT_DEPOSIT_RESOURCE_TYPE = 'patientDeposit';
export const PATIENT_DEPOSIT_INVOICE_RESOURCE_TYPE = 'patientDepositInvoice';
export const PATIENT_DEPOSIT_TRANSACTION_RESOURCE_TYPE =
  'patientDepositTransaction';

export const PATIENT_DEPOSIT_DESTINATION_ENTITY = DestinationEntity.init({
  metadata: {
    key: PATIENT_DEPOSIT_RESOURCE_TYPE,
    label: 'Patient Deposits',
    description: '',
  },
});

export interface IDepositSuccessData {
  patientRef: DocumentReference<IPatient>;
  accountCreditRefs: DocumentReference<IAccountCredit>[];
  invoiceRefs: DocumentReference<IInvoice>[];
  transactionRefs: DocumentReference<ITransaction>[];
}

export interface IDepositSaveData {
  invoiceRef?: DocumentReference<IInvoice>;
  transactionRefs: DocumentReference<ITransaction>[];
  accountCreditRef: DocumentReference<IAccountCredit>;
}

export interface IDepositJobData<PatientRecord extends object> {
  sourcePatient: IGetRecordResponse<PatientRecord>;
  practices: WithRef<ITranslationMap<IPractice, unknown>>[];
}

export interface IDepositBuildData extends IIdentifiable {
  invoice?: IInvoice & { createdAt: Timestamp };
  transactions: (IIdentifiable & ITransaction)[];
  accountCredit: IAccountCredit & { createdAt: Timestamp };
}

export interface IDepositMigrationData {
  patientRef: DocumentReference<IPatient>;
  deposits: IDepositBuildData[];
}

export abstract class BasePatientDepositDestinationEntity<
  PatientRecord extends object,
  JobData extends IDepositJobData<PatientRecord>,
> extends BaseDestinationEntity<
  IDepositSuccessData,
  JobData,
  IDepositMigrationData
> {
  destinationEntity = PATIENT_DEPOSIT_DESTINATION_ENTITY;
  abstract patientSourceEntity: ISourceEntityHandler<PatientRecord[]>;

  override filters = [
    new PatientIdFilter<JobData>((jobData) =>
      this.patientSourceEntity
        .getSourceRecordId(jobData.sourcePatient.data.data)
        .toString()
    ),
  ];

  get sourceCountComparison(): ISourceEntityHandler<PatientRecord[]> {
    return this.patientSourceEntity;
  }

  sourceCountDataAccessor(
    data: JobData
  ): DocumentReference<ISourceEntityRecord> {
    return data.sourcePatient.record.ref;
  }

  getMigratedData$(
    record: IDestinationEntityRecord<IDepositSuccessData>
  ): Observable<IMigratedDataSummary[]> {
    if (record.status !== DestinationEntityRecordStatus.Migrated) {
      return of([]);
    }

    return combineLatest([
      safeCombineLatest(
        record.data.accountCreditRefs.map((accountCreditRef) =>
          Firestore.getDoc(accountCreditRef)
        )
      ),
      safeCombineLatest(
        record.data.invoiceRefs.map((invoiceRef) =>
          Firestore.getDoc(invoiceRef)
        )
      ),
      safeCombineLatest(
        record.data.transactionRefs.map((transactionRef) =>
          Firestore.getDoc(transactionRef)
        )
      ),
    ]).pipe(
      map(([accountCredits, invoices, transactions]) => {
        const data: IMigratedDataSummary[] = [];

        data.push(
          ...accountCredits.map((accountCredit) => ({
            label: 'Account Credit',
            data: accountCredit,
          }))
        );
        data.push(
          ...invoices.map((invoice) => ({
            label: 'Invoices',
            data: invoice,
          }))
        );
        data.push(
          ...transactions.map((transaction) => ({
            label: 'Transaction',
            data: transaction,
          }))
        );

        return data;
      })
    );
  }

  getDestinationEntityRecordUid(data: JobData): string {
    return data.sourcePatient.record.uid;
  }

  async hasMergeConflict(
    translationMap: TranslationMapHandler,
    data: IDepositMigrationData
  ): Promise<IDepositMigrationData | undefined> {
    const existingDeposits: IDepositBuildData[] = [];

    const depositMergeConflicts = await asyncForEach(
      data.deposits,
      async (deposit) => {
        const depositRef = await translationMap.getDestination(
          deposit.uid,
          PATIENT_DEPOSIT_RESOURCE_TYPE
        );

        if (!depositRef) {
          return false;
        }

        const invoiceRef = await translationMap.getDestination(
          deposit.uid,
          PATIENT_DEPOSIT_INVOICE_RESOURCE_TYPE
        );

        const transactionRefs = await asyncForEach(
          deposit.transactions,
          async (transaction) =>
            translationMap.getDestination(
              transaction.uid,
              PATIENT_DEPOSIT_TRANSACTION_RESOURCE_TYPE
            )
        );

        try {
          const existingAccountCredit = await Firestore.getDoc(
            asDocRef<IAccountCredit>(depositRef)
          );

          const existingInvoice = invoiceRef
            ? await Firestore.getDoc(asDocRef<IInvoice>(invoiceRef))
            : undefined;

          const existingTransactions = await asyncForEach(
            compact(transactionRefs),
            (transactionRef) =>
              Firestore.getDoc(asDocRef<ITransaction>(transactionRef))
          );

          const builtData: IDepositBuildData = {
            uid: deposit.uid,
            accountCredit: existingAccountCredit,
            invoice: existingInvoice,
            transactions: sortBy(existingTransactions, 'uid'),
          };

          existingDeposits.push(builtData);

          return hasMergeConflicts(
            omit({
              ...deposit,
              transactions: sortBy(deposit.transactions, 'uid'),
            }),
            builtData
          );
        } catch (error) {
          return false;
        }
      }
    );

    if (depositMergeConflicts.some((mergeConflict) => mergeConflict)) {
      return {
        ...data,
        deposits: existingDeposits,
      };
    }
  }

  buildMergeConflictRecord(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    _translationMap: TranslationMapHandler,
    jobData: JobData,
    _migrationData: IDepositMigrationData
  ): IDestinationEntityRecord & MergeConflictDestinationEntityRecord {
    return {
      uid: jobData.sourcePatient.record.uid,
      label: jobData.sourcePatient.record.label,
      status: DestinationEntityRecordStatus.MergeConflict,
    };
  }

  async runJob(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMapHandler: TranslationMapHandler,
    jobData: JobData,
    migrationData: IDepositMigrationData
  ): Promise<IDestinationEntityRecord> {
    try {
      const depositRefs = await asyncForEach(
        migrationData.deposits,
        async (deposit) => {
          return this._upsertDeposit(
            deposit,
            deposit.uid,
            translationMapHandler,
            migrationData.patientRef
          );
        }
      );

      const successData = depositRefs.reduce(
        (deposits, deposit) => ({
          ...deposits,
          accountCreditRefs: [
            ...deposits.accountCreditRefs,
            deposit.accountCreditRef,
          ],
          invoiceRefs: compact([...deposits.invoiceRefs, deposit.invoiceRef]),
          transactionRefs: [
            ...deposits.transactionRefs,
            ...deposit.transactionRefs,
          ],
        }),
        {
          patientRef: migrationData.patientRef,
          accountCreditRefs: [],
          invoiceRefs: [],
          transactionRefs: [],
        } as IDepositSuccessData
      );

      return this._buildSuccessResponse(jobData.sourcePatient, successData);
    } catch (error) {
      return this._buildErrorResponse(jobData.sourcePatient, getError(error));
    }
  }

  protected _buildErrorResponse(
    patient: IGetRecordResponse<PatientRecord>,
    errorMessage?: string
  ): IDestinationEntityRecord<IDepositSuccessData> &
    FailedDestinationEntityRecord {
    return {
      uid: patient.record.uid,
      label: patient.record.label,
      status: DestinationEntityRecordStatus.Failed,
      errorMessage: errorMessage ?? 'Missing required properties for deposit',
      failData: {},
    };
  }

  protected _buildSuccessResponse(
    patient: IGetRecordResponse<PatientRecord>,
    updateData: IDepositSuccessData
  ): IDestinationEntityRecord<IDepositSuccessData> {
    return {
      uid: patient.record.uid,
      label: patient.record.label,
      data: {
        ...updateData,
      },
      status: DestinationEntityRecordStatus.Migrated,
      migratedAt: toTimestamp(),
    };
  }

  private async _upsertDeposit(
    depositData: IDepositBuildData,
    depositUid: string,
    translationMap: TranslationMapHandler,
    patientRef: DocumentReference<IPatient>
  ): Promise<IDepositSaveData> {
    let invoiceRef: DocumentReference<IInvoice> | undefined;
    let transactionRefs: DocumentReference<ITransaction>[] = [];

    if (depositData.invoice && depositData.transactions) {
      const depositInvoiceDestinationRef = await translationMap.getDestination(
        depositUid,
        PATIENT_DEPOSIT_INVOICE_RESOURCE_TYPE
      );

      const invoice = await FirestoreMigrate.upsertDoc(
        Patient.invoiceCol({
          ref: patientRef,
        }),
        { ...depositData.invoice },
        depositInvoiceDestinationRef?.id
      );

      if (!depositInvoiceDestinationRef) {
        await translationMap.upsert({
          sourceIdentifier: depositUid,
          destinationIdentifier: invoice,
          resourceType: PATIENT_DEPOSIT_INVOICE_RESOURCE_TYPE,
        });
      }

      invoiceRef = invoice;

      transactionRefs = await asyncForEach(
        depositData.transactions,
        async (transaction) => {
          const depositTransactionDestinationRef =
            await translationMap.getDestination(
              transaction.uid,
              PATIENT_DEPOSIT_TRANSACTION_RESOURCE_TYPE
            );

          const transactionRef = await FirestoreMigrate.upsertDoc(
            Invoice.transactionCol({
              ref: invoice,
            }),
            transaction,
            depositTransactionDestinationRef?.id
          );

          if (!depositTransactionDestinationRef) {
            await translationMap.upsert({
              sourceIdentifier: transaction.uid,
              destinationIdentifier: transactionRef,
              resourceType: PATIENT_DEPOSIT_TRANSACTION_RESOURCE_TYPE,
            });
          }

          return transactionRef;
        }
      );
    }

    const depositDestinationRef = await translationMap.getDestination(
      depositUid,
      PATIENT_DEPOSIT_RESOURCE_TYPE
    );

    const accountCreditRef = await FirestoreMigrate.upsertDoc(
      AccountCredit.col({
        ref: patientRef,
      }),
      depositData.accountCredit,
      depositDestinationRef?.id
    );

    if (!depositDestinationRef) {
      await translationMap.upsert({
        sourceIdentifier: depositUid,
        destinationIdentifier: accountCreditRef,
        resourceType: PATIENT_DEPOSIT_RESOURCE_TYPE,
      });
    }

    return {
      invoiceRef,
      transactionRefs,
      accountCreditRef,
    };
  }
}
