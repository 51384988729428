import {
  roundTo2Decimals,
  TaxRate,
  TaxStrategy,
} from '@principle-theorem/accounting';
import {
  ADA_CODE_TREATMENT_CONFIG_ID,
  Brand,
  CalculateTreatmentWeight,
  ChartedItemScopeResolver,
  ChartedSurface,
  ChartedTreatment,
  determineTaxFromStrategy,
  FeeSchedule,
  hasMergeConflicts,
  PricedServiceCodeEntry,
  stafferToNamedDoc,
  TreatmentConfiguration,
  TreatmentPlan,
  TreatmentStep,
} from '@principle-theorem/principle-core';
import {
  ChartableSurface,
  DestinationEntityRecordStatus,
  IAppointment,
  IChartedTreatment,
  IFeeSchedule,
  IMigratedDataSummary,
  isToothNumber,
  TreatmentPlanStatus,
  TreatmentStepStatus,
  type FailedDestinationEntityRecord,
  type IDestinationEntity,
  type IDestinationEntityRecord,
  type IGetRecordResponse,
  type IPatient,
  type IPracticeMigration,
  type ISourceEntityRecord,
  type IStaffer,
  type ITreatmentPlan,
  type ITreatmentStep,
  type MergeConflictDestinationEntityRecord,
  IPractice,
  IArraySorter,
} from '@principle-theorem/principle-core/interfaces';
import {
  asyncForEach,
  find$,
  Firestore,
  getDoc$,
  getError,
  HISTORY_DATE_FORMAT,
  multiFilter,
  reduceToSingleArray,
  resolveSequentially,
  safeCombineLatest,
  snapshot,
  sortByCreatedAt,
  sortTimestamp,
  Timezone,
  toInt,
  toISODate,
  toMomentTz,
  toNamedDocument,
  toTimestamp,
  undeletedQuery,
  where,
  type DocumentReference,
  type INamedDocument,
  type Timestamp,
  type WithRef,
} from '@principle-theorem/shared';
import { compact, first, groupBy, omit, sortBy } from 'lodash';
import { combineLatest, of, type Observable } from 'rxjs';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import { feeScheduleResolverFn } from '../../../d4w/destination/entities/fee-schedules';
import {
  determinePlanStatus,
  getChartedRefs,
  getResolvedAt,
  getResolvedBy,
  IPatientTreatmentPlanJobData,
  IPatientTreatmentPlanMigrationData,
  IStepWithIdentifiers,
  ITreatmentPlanStepPair,
} from '../../../d4w/destination/entities/patient-treatment-plan';
import { BaseDestinationEntity } from '../../../destination/base-destination-entity';
import { FirestoreMigrate } from '../../../destination/destination';
import { DestinationEntity } from '../../../destination/destination-entity';
import {
  ITreatmentPlanSuccessData,
  PATIENT_TREATMENT_PLAN_CUSTOM_MAPPING_TYPE,
  PATIENT_TREATMENT_STEP_CUSTOM_MAPPING_TYPE,
} from '../../../destination/entities/patient-treatment-plans';
import { STAFFER_RESOURCE_TYPE } from '../../../destination/entities/staff';
import { PatientIdFilter } from '../../../destination/filters/patient-id-filter';
import { getPractitionerOrDefault } from '../../../mappings/staff';
import { PracticeMigration } from '../../../practice-migrations';
import { buildSkipMigratedQuery } from '../../../source/source-entity-record';
import { TranslationMapHandler } from '../../../translation-map';
import {
  ICorePracticePatientAppointment,
  ICorePracticePatientAppointmentFilters,
  ICorePracticePatientAppointmentTranslations,
  PatientAppointmentSourceEntity,
} from '../../source/entities/patient-appointments';
import { PatientChartedItemSourceEntity } from '../../source/entities/patient-charted-items';
import {
  ICorePracticePatientTreatment,
  ICorePracticePatientTreatmentFilters,
  ICorePracticePatientTreatmentTranslations,
  PatientTreatmentSourceEntity,
} from '../../source/entities/patient-treatments';
import {
  ICorePracticePatient,
  ICorePracticePatientFilters,
  ICorePracticePatientTranslations,
  PATIENT_RESOURCE_TYPE,
  PatientSourceEntity,
} from '../../source/entities/patients';
import { CorePracticeItemCodeToTreatmentMappingHandler } from '../mappings/item-code-to-treatment';
import { CorePracticeItemCodeMappingHandler } from '../mappings/item-codes';
import {
  CorePracticePracticeMappingHandler,
  PRACTICE_MAPPING,
} from '../mappings/practices';
import { CorePracticeStafferMappingHandler } from '../mappings/staff';
import {
  CORE_PRACTICE_MIGRATED_APPOINTMENTS_PLAN_NAME,
  PatientAppointmentDestinationEntity,
} from './patient-appointments';
import { PatientDestinationEntity } from './patients';
import { StafferDestinationEntity } from './staff';
import { resolveMappedCode } from '../../../mappings/item-codes';

export const PATIENT_TREATMENT_PLAN_DESTINATION_ENTITY = DestinationEntity.init(
  {
    metadata: {
      key: 'patientTreatmentPlans',
      label: 'Patient Treatment Plans',
      description: `This migrates the appointment procedures for each patient's appointment and creates a single step on a single plan for each appointment.`,
    },
  }
);

type PatientTreatmentPlanJobData = IPatientTreatmentPlanJobData<
  ICorePracticePatient,
  ICorePracticePatientTranslations,
  ICorePracticePatientFilters
>;

export class PatientTreatmentPlanDestinationEntity extends BaseDestinationEntity<
  ITreatmentPlanSuccessData,
  PatientTreatmentPlanJobData,
  IPatientTreatmentPlanMigrationData
> {
  destinationEntity = PATIENT_TREATMENT_PLAN_DESTINATION_ENTITY;

  override canMigrateByIdRange = true;

  override filters = [
    new PatientIdFilter<PatientTreatmentPlanJobData>((jobData) =>
      this.sourceEntities.patients
        .getSourceRecordId(jobData.sourcePatient.data.data)
        .toString()
    ),
  ];

  override sorters: IArraySorter[] = [
    {
      key: 'treatments',
      sortByPath: 'uuid',
    },
    {
      key: 'adaCodes',
      sortByPath: 'code.name',
    },
  ];

  sourceCountComparison = new PatientSourceEntity();

  override sourceEntities = {
    patients: new PatientSourceEntity(),
    appointments: new PatientAppointmentSourceEntity(),
    treatments: new PatientTreatmentSourceEntity(),
    chartedItems: new PatientChartedItemSourceEntity(),
  };

  override destinationEntities = {
    patients: new PatientDestinationEntity(),
    staff: new StafferDestinationEntity(),
    patientAppointments: new PatientAppointmentDestinationEntity(),
  };

  customMappings = {
    staff: new CorePracticeStafferMappingHandler(),
    practice: new CorePracticePracticeMappingHandler(),
    itemCodes: new CorePracticeItemCodeMappingHandler(),
    itemCodeToTreatment: new CorePracticeItemCodeToTreatmentMappingHandler(),
  };

  sourceCountDataAccessor(
    data: PatientTreatmentPlanJobData
  ): DocumentReference<ISourceEntityRecord> {
    return data.sourcePatient.record.ref;
  }

  getMigratedData$(
    record: IDestinationEntityRecord<ITreatmentPlanSuccessData>
  ): Observable<IMigratedDataSummary[]> {
    if (record.status !== DestinationEntityRecordStatus.Migrated) {
      return of([]);
    }

    return combineLatest([
      Firestore.getDoc(record.data.sourceRef),
      safeCombineLatest(
        record.data.treatmentPlanRefs.map((treatmentPlanRef) =>
          Firestore.getDoc(treatmentPlanRef)
        )
      ),
      safeCombineLatest(
        record.data.treatmentStepRefs.map((treatmentStepRef) =>
          Firestore.getDoc(treatmentStepRef)
        )
      ),
    ]).pipe(
      map(([sourceRecord, treatmentPlans, treatmentSteps]) => [
        {
          label: 'Source Record',
          data: sourceRecord,
        },
        ...treatmentPlans.map((treatmentPlan) => ({
          label: `Treatment Plan - ${treatmentPlan.name}`,
          data: treatmentPlan,
        })),
        ...treatmentSteps.map((treatmentStep) => ({
          label: `Treatment Step - ${treatmentStep.name}`,
          data: treatmentStep,
        })),
      ])
    );
  }

  buildJobData$(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    skipMigrated: boolean,
    _fromDate?: Timestamp,
    _toDate?: Timestamp,
    fromId?: string,
    toId?: string
  ): Observable<PatientTreatmentPlanJobData[]> {
    const staff$ = combineLatest([
      this.customMappings.staff.getRecords$(translationMap),
      translationMap.getByType$<IStaffer>(STAFFER_RESOURCE_TYPE),
    ]).pipe(map(([staff, mappedStaff]) => [...staff, ...mappedStaff]));
    const sourceItemCodes$ =
      this.customMappings.itemCodes.getRecords$(translationMap);
    const practices$ = this.customMappings.practice.getRecords$(translationMap);
    const brand$ = PracticeMigration.brand$(migration);
    const practitioners$ = brand$.pipe(
      switchMap((brand) => Firestore.getDocs(Brand.stafferCol(brand)))
    );
    const treatmentConfigurationMappings$ =
      this.customMappings.itemCodeToTreatment.getRecords$(translationMap);
    const defaultTreatmentConfiguration$ = brand$.pipe(
      switchMap((brand) =>
        getDoc$(TreatmentConfiguration.col(brand), ADA_CODE_TREATMENT_CONFIG_ID)
      )
    );
    const treatmentCategories$ = brand$.pipe(
      switchMap((brand) => Brand.treatmentCategories$(brand))
    );

    return this.sourceEntities.patients
      .getRecords$(
        migration,
        1000,
        buildSkipMigratedQuery(skipMigrated, this.destinationEntity)
      )
      .pipe(
        multiFilter((patient) => {
          if (!fromId || !toId) {
            return true;
          }

          return (
            patient.data.data.id >= toInt(fromId) &&
            patient.data.data.id <= toInt(toId)
          );
        }),
        withLatestFrom(
          staff$,
          practitioners$,
          defaultTreatmentConfiguration$,
          treatmentConfigurationMappings$,
          sourceItemCodes$,
          practices$,
          treatmentCategories$
        ),
        map(
          ([
            sourcePatients,
            staff,
            practitioners,
            defaultTreatmentConfiguration,
            treatmentConfigurationMappings,
            sourceItemCodes,
            practices,
            treatmentCategories,
          ]) =>
            sourcePatients.map((sourcePatient) => ({
              sourcePatient,
              staff,
              practitioners,
              defaultTreatmentConfiguration,
              treatmentConfigurationMappings,
              sourceItemCodes,
              practices,
              treatmentCategories,
            }))
        )
      );
  }

  getDestinationEntityRecordUid(data: PatientTreatmentPlanJobData): string {
    return data.sourcePatient.record.uid;
  }

  // The chartId seems to be the treatment plan itself where the planVisit is the order of the planned treatments. It's not necessarily the case that treatment is performed in this order, but it would dicate how it's displayed in Core Practice.

  // Plan date is purely the correlating `chartedAt` value and `resolvedAt` is dictated by the `completeDate`.

  // We also want to filter out all treatments that are `isVoided` or `isDeleted`.

  // `isPaid` has no correlation to `completeDate` as this is the accounting aspect.

  // `fee` is the true value that will be charted on the invoice, and `planFee` seems to be the quote amount that was given to the patient originally. This is the same for the quantity.

  async buildMigrationData(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    data: PatientTreatmentPlanJobData
  ): Promise<
    | IPatientTreatmentPlanMigrationData
    | (IDestinationEntityRecord & FailedDestinationEntityRecord)
  > {
    const sourcePatientId = this.sourceEntities.patients.getSourceRecordId(
      data.sourcePatient.data.data
    );

    const patientRef = await translationMap.getDestination<IPatient>(
      sourcePatientId.toString(),
      PATIENT_RESOURCE_TYPE
    );

    if (!patientRef) {
      return this._buildErrorResponse(data.sourcePatient, 'No patient');
    }

    try {
      const treatments = (
        await this.sourceEntities.treatments.filterRecords(
          migration,
          'patientId',
          sourcePatientId
        )
      ).filter(
        (treatment) =>
          !treatment.data.data.isVoided && !treatment.data.data.isDeleted
      );

      const appointments = (
        await this.sourceEntities.appointments.filterRecords(
          migration,
          'patientId',
          sourcePatientId
        )
      ).filter((appointment) => !appointment.data.data.isDeleted);

      const planPairs = await this._buildTreatmentPlanData(
        data,
        translationMap,
        treatments,
        appointments,
        migration,
        sourcePatientId
      );

      return {
        sourcePatientId: sourcePatientId.toString(),
        patientRef,
        planPairs,
      };
    } catch (error) {
      return this._buildErrorResponse(data.sourcePatient, getError(error));
    }
  }

  async hasMergeConflict(
    translationMap: TranslationMapHandler,
    data: IPatientTreatmentPlanMigrationData
  ): Promise<IPatientTreatmentPlanMigrationData | undefined> {
    try {
      const mergeConflictPlanPairs = await asyncForEach(
        data.planPairs,
        async ({ plan, steps }) => {
          const planRef = await translationMap.getDestination(
            plan.sourceIdentifier,
            PATIENT_TREATMENT_PLAN_CUSTOM_MAPPING_TYPE
          );

          if (!planRef) {
            return;
          }

          const existingPlan = await Firestore.getDoc(
            planRef as DocumentReference<ITreatmentPlan>
          );

          const planMergeConflict = hasMergeConflicts(plan, {
            ...existingPlan,
            steps: [],
          });

          const existingSteps: IStepWithIdentifiers[] = [];

          const stepMergeConflicts = await asyncForEach(steps, async (step) => {
            const stepRef = await translationMap.getDestination(
              step.sourceIdentifier,
              PATIENT_TREATMENT_STEP_CUSTOM_MAPPING_TYPE
            );

            if (!stepRef) {
              return false;
            }

            const existingStep = await Firestore.getDoc(
              stepRef as DocumentReference<ITreatmentStep>
            );
            existingSteps.push({
              ...omit(existingStep, 'ref'),
              sourceIdentifier: step.sourceIdentifier,
              createdAt: step.createdAt,
            });

            const stepMergeConflict = hasMergeConflicts(
              omit(step, ['sourceIdentifier']),
              omit(existingStep, ['sourceIdentifier']),
              ['chartedAt'],
              [
                {
                  key: 'chartedSurfaces',
                  sortByPath: [
                    'resolvedAt.seconds',
                    'chartedRef.wholeMouth',
                    'chartedRef.tooth.quadrant',
                    'chartedRef.tooth.quadrantIndex',
                    'chartedRef.tooth.surface',
                  ],
                },
                {
                  key: 'treatments',
                  sortByPath: [
                    'type',
                    'config.ref.id',
                    'scopeRef',
                    'price',
                    'attributedTo.ref.id',
                    'adaCodes[0].code.name',
                    'chartedSurfaces[0].resolvedAt.seconds',
                    'chartedSurfaces[0].chartedRef.wholeMouth',
                    'chartedSurfaces[0].chartedRef.tooth.quadrant',
                    'chartedSurfaces[0].chartedRef.tooth.quadrantIndex',
                    'chartedSurfaces[0].chartedRef.tooth.surface',
                  ],
                },
              ]
            );

            return stepMergeConflict;
          });

          const hasIncorrectStepCount =
            existingPlan.steps.length !== existingSteps.length;

          const hasMergeConflict = [
            hasIncorrectStepCount,
            planMergeConflict,
            ...stepMergeConflicts,
          ].some((stepMergeConflict) => stepMergeConflict);

          if (hasMergeConflict) {
            return {
              plan: {
                ...omit(existingPlan, 'ref'),
                steps: [],
                sourceIdentifier: plan.sourceIdentifier,
              },
              steps: sortBy(existingSteps, 'name'),
            };
          }
        }
      );

      if (compact(mergeConflictPlanPairs).length) {
        return {
          ...data,
          planPairs: compact(mergeConflictPlanPairs),
        };
      }
    } catch (error) {
      return;
    }
  }

  buildMergeConflictRecord(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    _translationMap: TranslationMapHandler,
    jobData: PatientTreatmentPlanJobData,
    _migrationData: IPatientTreatmentPlanMigrationData
  ): IDestinationEntityRecord & MergeConflictDestinationEntityRecord {
    return {
      uid: jobData.sourcePatient.record.uid,
      label: jobData.sourcePatient.record.label,
      status: DestinationEntityRecordStatus.MergeConflict,
    };
  }

  async runJob(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    jobData: PatientTreatmentPlanJobData,
    migrationData: IPatientTreatmentPlanMigrationData
  ): Promise<IDestinationEntityRecord> {
    try {
      const { planRefs, stepRefs } = await this._upsertTreatmentPlans(
        migrationData,
        translationMap
      );

      return this._buildSuccessResponse(
        jobData.sourcePatient,
        planRefs,
        stepRefs
      );
    } catch (error) {
      return this._buildErrorResponse(jobData.sourcePatient, String(error));
    }
  }

  private _buildSuccessResponse(
    patient: IGetRecordResponse<
      ICorePracticePatient,
      ICorePracticePatientTranslations
    >,
    treatmentPlanRefs: DocumentReference<ITreatmentPlan>[],
    treatmentStepRefs: DocumentReference<ITreatmentStep>[]
  ): IDestinationEntityRecord<ITreatmentPlanSuccessData> {
    return {
      uid: patient.record.uid,
      label: patient.record.label,
      data: {
        sourceRef: patient.record.ref,
        treatmentPlanRefs,
        treatmentStepRefs,
      },
      status: DestinationEntityRecordStatus.Migrated,
      migratedAt: toTimestamp(),
    };
  }

  private async _upsertTreatmentPlans(
    migrationData: IPatientTreatmentPlanMigrationData,
    translationMap: TranslationMapHandler
  ): Promise<{
    planRefs: DocumentReference<ITreatmentPlan>[];
    stepRefs: DocumentReference<ITreatmentStep>[];
  }> {
    const results = await asyncForEach(
      migrationData.planPairs,
      async ({ plan, steps }) => {
        const planDestinationRef = await translationMap.getDestination(
          plan.sourceIdentifier,
          PATIENT_TREATMENT_PLAN_CUSTOM_MAPPING_TYPE
        );

        const planRef = await FirestoreMigrate.upsertDoc(
          TreatmentPlan.col({
            ref: migrationData.patientRef,
          }),
          {
            ...plan,
            deleted: false,
          },
          planDestinationRef?.id
        );

        if (!planDestinationRef) {
          await translationMap.upsert({
            sourceIdentifier: plan.sourceIdentifier,
            destinationIdentifier: planRef,
            resourceType: PATIENT_TREATMENT_PLAN_CUSTOM_MAPPING_TYPE,
          });
        }

        const resolvedPlan = await Firestore.getDoc(planRef);

        const stepRefs = await asyncForEach(steps, async (step) => {
          const stepDestinationRef = await translationMap.getDestination(
            step.sourceIdentifier,
            PATIENT_TREATMENT_STEP_CUSTOM_MAPPING_TYPE
          );

          const stepRef = await FirestoreMigrate.upsertDoc(
            TreatmentPlan.treatmentStepCol({
              ref: planRef,
            }),
            {
              ...step,
              deleted: false,
            },
            stepDestinationRef?.id
          );

          if (!stepDestinationRef) {
            await translationMap.upsert({
              sourceIdentifier: step.sourceIdentifier,
              destinationIdentifier: stepRef,
              resourceType: PATIENT_TREATMENT_STEP_CUSTOM_MAPPING_TYPE,
            });
          }

          if (step.appointment) {
            await this._associateAppointmentWithStep(
              step.appointment,
              resolvedPlan,
              stepRef
            );
          }

          return stepRef;
        });

        await FirestoreMigrate.patchDoc(planRef, {
          steps: stepRefs,
        });

        return { planRefs: [planRef], stepRefs };
      }
    );

    const appointmentPlan = await snapshot(
      find$(
        TreatmentPlan.col({
          ref: migrationData.patientRef,
        }),
        where('name', '==', CORE_PRACTICE_MIGRATED_APPOINTMENTS_PLAN_NAME)
      )
    );

    const planSteps = appointmentPlan
      ? await Firestore.getDocs(
          undeletedQuery(TreatmentPlan.treatmentStepCol(appointmentPlan))
        )
      : [];

    if (appointmentPlan && !planSteps.length) {
      await FirestoreMigrate.deleteDoc(appointmentPlan.ref);
    }

    return {
      planRefs: reduceToSingleArray(results.map((result) => result.planRefs)),
      stepRefs: reduceToSingleArray(results.map((result) => result.stepRefs)),
    };
  }

  private async _associateAppointmentWithStep(
    appointmentRef: DocumentReference<IAppointment>,
    resolvedPlan: WithRef<ITreatmentPlan>,
    stepRef: DocumentReference<ITreatmentStep>
  ): Promise<void> {
    const appointment = await Firestore.getDoc(appointmentRef);
    const oldPlanSummary = appointment.treatmentPlan;
    const oldStep = await Firestore.getDoc(oldPlanSummary.treatmentStep.ref);

    await FirestoreMigrate.saveDoc(omit(oldStep, 'appointment'));
    if (oldPlanSummary.name === CORE_PRACTICE_MIGRATED_APPOINTMENTS_PLAN_NAME) {
      await FirestoreMigrate.deleteDoc(oldStep.ref);
    } else {
      await FirestoreMigrate.patchDoc(stepRef, {
        deleted: false,
      });
    }
    await FirestoreMigrate.updateDoc(appointment.ref, {
      treatmentPlan: TreatmentPlan.treatmentStepToAssociatedTreatment(
        resolvedPlan,
        await Firestore.getDoc(stepRef)
      ),
    });
  }

  private async _buildTreatmentPlanData(
    data: PatientTreatmentPlanJobData,
    translationMap: TranslationMapHandler,
    sourceTreatments: IGetRecordResponse<
      ICorePracticePatientTreatment,
      ICorePracticePatientTreatmentTranslations,
      ICorePracticePatientTreatmentFilters
    >[],
    sourceAppointments: IGetRecordResponse<
      ICorePracticePatientAppointment,
      ICorePracticePatientAppointmentTranslations,
      ICorePracticePatientAppointmentFilters
    >[],
    migration: WithRef<IPracticeMigration>,
    sourcePatientId: number
  ): Promise<ITreatmentPlanStepPair[]> {
    const defaultFeeSchedule = await FeeSchedule.getPreferredOrDefault(
      migration.configuration.organisation
    );

    const treatmentPlansGroups = groupBy(
      sourceTreatments,
      (treatment) => treatment.data.data.chartId ?? 0
    );

    const plans = await asyncForEach(
      Object.values(treatmentPlansGroups),
      async (treatments) => {
        const planId = treatments[0].data.data.chartId ?? '';
        const planCreatedAt = first(
          compact(
            treatments.map(
              (treatment) => treatment.data.translations.completeDate
            )
          )
            .sort(sortTimestamp)
            .reverse()
        );

        const sourcePractice = data.practices.find(
          (practice) =>
            practice.sourceIdentifier ===
            treatments[0].data.data.locationId.toString()
        );

        if (!sourcePractice) {
          throw new Error(
            `No practice found for id: ${treatments[0].data.data.locationId}`
          );
        }

        const practiceRef = await translationMap.getDestination<IPractice>(
          sourcePractice.sourceIdentifier,
          PRACTICE_MAPPING.metadata.type
        );

        if (!practiceRef) {
          throw new Error(
            `No practice found for id: ${sourcePractice.sourceIdentifier}`
          );
        }

        const practice = await Firestore.getDoc(practiceRef);

        const planDateFormatted = planCreatedAt
          ? toMomentTz(planCreatedAt, practice.settings.timezone).format(
              HISTORY_DATE_FORMAT
            )
          : undefined;

        const planName = planDateFormatted
          ? `Treatment Plan - ${planDateFormatted}`
          : planId
            ? `Treatment Plan - ${planId}`
            : `Misc Treatment Plan`;

        const planPractitioner = getPractitionerOrDefault(
          treatments[0].data.data.providerId ?? undefined,
          data.staff,
          data.practitioners
        );

        const plan = {
          ...TreatmentPlan.init({
            name: planName.trim(),
            practitioner: planPractitioner
              ? stafferToNamedDoc(planPractitioner)
              : undefined,
            createdAt: planCreatedAt ?? toTimestamp(),
          }),
          sourceIdentifier: `${sourcePatientId}-plan-${planId}`,
        };

        const filteredTreatments = treatments.filter((treatment) =>
          data.practices.some(
            (practiceOption) =>
              practiceOption.sourceIdentifier ===
              treatment.record.filters.locationId.toString()
          )
        );

        const pendingSteps: IStepWithIdentifiers[] = [];
        const completedSteps: IStepWithIdentifiers[] = [];

        await resolveSequentially(filteredTreatments, async (treatment) => {
          const chartedTreatments = await getChartedTreatments(
            [treatment.data.data],
            feeScheduleResolverFn(translationMap, defaultFeeSchedule),
            data,
            migration.configuration.timezone
          );

          if (!chartedTreatments.length) {
            return;
          }

          if (treatment.data.translations.completeDate) {
            const date = toISODate(
              toMomentTz(
                treatment.data.translations.completeDate,
                migration.configuration.timezone
              )
            );
            const sourceIdentifier = `${sourcePatientId}-${planId}-completed-${date}`;

            const existingStep = completedSteps.find(
              (step) => step.sourceIdentifier === sourceIdentifier
            );

            if (existingStep) {
              existingStep.treatments.push(...chartedTreatments);
              return;
            }

            const step = TreatmentStep.init({
              name: `Completed ${toMomentTz(
                date,
                migration.configuration.timezone
              ).format(HISTORY_DATE_FORMAT)}`,
              treatments: chartedTreatments,
              status: TreatmentStepStatus.Complete,
            });
            const treatmentCategory =
              await CalculateTreatmentWeight.getPrimaryCategory(
                step,
                data.treatmentCategories
              );

            const createdAt =
              treatment.data.translations.completeDate ?? toTimestamp();

            completedSteps.push({
              ...step,
              display: {
                ...step.display,
                primaryTreatmentCategory: treatmentCategory?.ref,
              },
              createdAt,
              date,
              sourceIdentifier,
            });
            return;
          }

          if (!treatment.data.translations.completeDate) {
            return;
          }

          const date = toISODate(
            toMomentTz(
              treatment.data.translations.completeDate,
              migration.configuration.timezone
            )
          );
          const stepNumber = treatment.data.data.planVisit ?? 0;
          const sourceIdentifier = `${sourcePatientId}-${planId}-pending-${date}-${stepNumber}`;
          const existingStep = pendingSteps.find(
            (step) => step.sourceIdentifier === sourceIdentifier
          );

          if (existingStep) {
            existingStep.treatments.push(...chartedTreatments);
            return;
          }

          const step = TreatmentStep.init({
            name: `Pending Step ${stepNumber}`,
            treatments: chartedTreatments,
            status: TreatmentStepStatus.Incomplete,
          });

          const treatmentCategory =
            await CalculateTreatmentWeight.getPrimaryCategory(
              step,
              data.treatmentCategories
            );

          const createdAt =
            treatment.data.translations.planDate ?? toTimestamp();

          pendingSteps.push({
            ...step,
            display: {
              ...step.display,
              primaryTreatmentCategory: treatmentCategory?.ref,
            },
            createdAt,
            date,
            sourceIdentifier,
          });
        });

        const steps = [
          ...sortBy(completedSteps, (step) => step.date),
          ...sortBy(pendingSteps, (step) => step.name),
        ];

        if (!planCreatedAt) {
          const earliestStepDate = steps.sort(sortByCreatedAt).pop()?.createdAt;
          if (earliestStepDate) {
            plan.createdAt = earliestStepDate;
          }
        }

        const status = determinePlanStatus(steps, plan.createdAt);

        return {
          plan: {
            ...plan,
            status,
          },
          steps,
        };
      }
    );

    const allPlans = compact(
      sortBy(plans, 'sourceIdentifier').reduce((mergedPlans, plan) => {
        const planMatchFn = (mergedPlan: ITreatmentPlanStepPair): boolean =>
          mergedPlan.plan.name === plan.plan.name;

        const existingPlan = mergedPlans.find(planMatchFn);
        if (!existingPlan) {
          return [...mergedPlans, plan];
        }

        const mergedSteps = sortBy(
          [...existingPlan.steps, ...plan.steps],
          'sourceIdentifier'
        ).reduce((steps, step) => {
          const stepMatchFn = (mergedStep: IStepWithIdentifiers): boolean =>
            mergedStep.name === step.name &&
            mergedStep.status === TreatmentStepStatus.Complete &&
            step.status === TreatmentStepStatus.Complete;

          const existingStep = steps.find(stepMatchFn);

          if (!existingStep) {
            return [...steps, step];
          }

          return steps.map((filterStep) => {
            if (!stepMatchFn(filterStep)) {
              return filterStep;
            }

            return {
              ...filterStep,
              treatments: [...filterStep.treatments, ...step.treatments],
            };
          });
        }, [] as IStepWithIdentifiers[]);

        const mergedPlanStatus = determinePlanStatus(
          mergedSteps,
          existingPlan.plan.createdAt
        );

        const mergedPlan = {
          ...existingPlan,
          steps: mergedSteps,
          plan: {
            ...existingPlan.plan,
            status: mergedPlanStatus,
          },
        };

        return [
          ...mergedPlans.filter((filterPlan) => !planMatchFn(filterPlan)),
          mergedPlan,
        ];
      }, [] as ITreatmentPlanStepPair[])
    );

    const availableAppointments = compact(
      await asyncForEach(sourceAppointments, async (sourceAppointment) => {
        const appointmentRef = await translationMap.getDestination<
          WithRef<IAppointment>
        >(
          String(
            this.sourceEntities.appointments.getSourceRecordId(
              sourceAppointment.data.data
            )
          ),
          this.destinationEntities.patientAppointments.destinationEntity
            .metadata.key
        );

        if (!appointmentRef) {
          return;
        }

        return Firestore.getDoc(appointmentRef);
      })
    );

    return allPlans.map((plan) => {
      return {
        ...plan,
        steps: plan.steps.map((step) => {
          const matchingAppointmentIndex = availableAppointments.findIndex(
            (appointment) => {
              if (!appointment.event) {
                return;
              }

              const date = toISODate(
                toMomentTz(
                  appointment.event.from,
                  migration.configuration.timezone
                )
              );

              return (
                step.status === TreatmentStepStatus.Complete &&
                step.date === date
              );
            }
          );

          const matchingAppointment =
            availableAppointments[matchingAppointmentIndex];

          if (matchingAppointment) {
            availableAppointments.splice(matchingAppointmentIndex, 1);
          }

          return {
            ...step,
            appointment: matchingAppointment?.ref,
          };
        }),
      };
    });
  }

  private _buildErrorResponse(
    patient: IGetRecordResponse<
      ICorePracticePatient,
      ICorePracticePatientTranslations,
      ICorePracticePatientFilters
    >,
    errorMessage?: string
  ): IDestinationEntityRecord & FailedDestinationEntityRecord {
    return {
      uid: patient.record.uid,
      label: patient.record.label,
      status: DestinationEntityRecordStatus.Failed,
      errorMessage:
        errorMessage ?? 'Missing required properties for treatment plan',
      failData: {
        patientRef: patient.record.ref,
      },
    };
  }
}

export interface ITreatmentPlanStepDocPair {
  planRef: DocumentReference<ITreatmentPlan>;
  stepRefs: DocumentReference<ITreatmentStep>[];
}

export function getPlanStatus(
  stepStatuses: TreatmentStepStatus[]
): TreatmentPlanStatus {
  if (!stepStatuses.length) {
    return TreatmentPlanStatus.Draft;
  }

  return stepStatuses.filter(
    (stepStatus) => stepStatus === TreatmentStepStatus.Complete
  ).length
    ? TreatmentPlanStatus.InProgress
    : TreatmentPlanStatus.Completed;
}

export async function getChartedTreatments(
  sourceTreatments: ICorePracticePatientTreatment[],
  getPreferredOrDefaultRef: (
    feeSheduleId?: string
  ) => Promise<INamedDocument<IFeeSchedule>>,
  data: PatientTreatmentPlanJobData,
  timezone: Timezone
): Promise<IChartedTreatment[]> {
  const treatments: IChartedTreatment[] = [];

  await asyncForEach(sourceTreatments, async (sourceTreatment) => {
    const treatmentProviderId = sourceTreatment.providerId.toString();
    const practitioner = getPractitionerOrDefault(
      treatmentProviderId,
      data.staff,
      data.practitioners
    );

    if (!practitioner) {
      throw new Error(
        `Couldn't resolve practitioner for treatment ${treatmentProviderId}`
      );
    }

    if (!sourceTreatment.itemCode) {
      return;
    }
    const procedureCode = sourceTreatment.itemCode;
    const code = resolveMappedCode(
      data.sourceItemCodes,
      sourceTreatment.itemId.toString(),
      procedureCode
    );
    if (!code) {
      return;
    }

    const itemCodeId = sourceTreatment.itemId.toString();
    const mappedTreatment = data.treatmentConfigurationMappings.find(
      (mapping) => mapping.sourceIdentifier === itemCodeId
    );
    const treatmentConfiguration = mappedTreatment?.destinationIdentifier
      ? await Firestore.getDoc(mappedTreatment?.destinationIdentifier)
      : data.defaultTreatmentConfiguration;

    const feeSchedule = await getPreferredOrDefaultRef();
    const taxStatus = TaxStrategy.GSTFree;
    const price = roundTo2Decimals(sourceTreatment.fee);

    const toothNumber = sourceTreatment.tooth
      ? String(sourceTreatment.tooth).trim()
      : undefined;
    if (!isToothNumber(toothNumber) && toothNumber) {
      // eslint-disable-next-line no-console
      console.info(`${toothNumber} isn't a valid tooth number`);
    }

    const serviceCode = PricedServiceCodeEntry.init({
      code: code.code,
      type: code.type,
      taxStatus,
      price,
      quantity: sourceTreatment.quantity,
      tax: determineTaxFromStrategy(TaxRate.GST, {
        taxStatus: taxStatus,
        amount: price,
      }),
      chartedSurfaces: isToothNumber(toothNumber)
        ? getChartedRefs(toothNumber, sourceTreatment.surface).map(
            (chartedRef) =>
              ChartedSurface.init({
                chartedBy: stafferToNamedDoc(practitioner),
                resolvedBy: getResolvedBy(
                  sourceTreatment.completeDate,
                  practitioner
                ),
                resolvedAt: getResolvedAt(
                  sourceTreatment.completeDate,
                  timezone
                ),
                chartedRef,
              })
          )
        : [],
    });

    const scopeResolver = new ChartedItemScopeResolver();
    const scopeRefs = scopeResolver.reduceChartedSurfacesToScope(
      treatmentConfiguration,
      serviceCode.chartedSurfaces
    );
    const scopeRef = first(scopeRefs)?.scopeRef ?? {
      scope: ChartableSurface.WholeMouth,
    };

    treatments.push(
      ChartedTreatment.init({
        uuid: sourceTreatment.id.toString(),
        config: toNamedDocument(treatmentConfiguration),
        feeSchedule,
        serviceCodes: [serviceCode],
        chartedSurfaces: serviceCode.chartedSurfaces,
        attributedTo: stafferToNamedDoc(practitioner),
        scopeRef,
        resolvedBy: getResolvedBy(sourceTreatment.completeDate, practitioner),
        resolvedAt: getResolvedAt(sourceTreatment.completeDate, timezone),
      })
    );
  });

  return treatments;
}
