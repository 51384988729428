import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  ViewChildren,
  type AfterViewInit,
  type OnDestroy,
  type QueryList,
} from '@angular/core';
import { Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  TrackByFunctions,
  TypedFormArray,
  TypedFormControl,
  TypedFormGroup,
  isDisabled$,
} from '@principle-theorem/ng-shared';
import {
  Practice,
  SterilisationRecord,
} from '@principle-theorem/principle-core';
import {
  type IPatient,
  type IPractice,
  type IStaffer,
  type ISterilisationRecord,
} from '@principle-theorem/principle-core/interfaces';
import {
  Firestore,
  snapshot,
  toTimestamp,
  type WithRef,
} from '@principle-theorem/shared';
import { compact, isEqual, isString, last } from 'lodash';
import * as moment from 'moment-timezone';
import { Subject, of, Observable, BehaviorSubject, EMPTY } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  pairwise,
  startWith,
  switchMap,
  takeUntil,
} from 'rxjs/operators';

export interface ISterilisationDialogData {
  patient?: WithRef<IPatient>;
  practice: WithRef<IPractice>;
  staffer: WithRef<IStaffer>;
  linkExistingRecords?: boolean;
}

export interface ISterilisationRecordFormData {
  data: (string | WithRef<ISterilisationRecord>)[];
  patient: WithRef<IPatient>;
  staffer: WithRef<IStaffer>;
}

@Component({
  selector: 'pr-sterilisation-record-dialog',
  templateUrl: './sterilisation-record-dialog.component.html',
  styleUrls: ['./sterilisation-record-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SterilisationRecordDialogComponent
  implements OnDestroy, AfterViewInit
{
  private _onDestroy$ = new Subject<void>();
  searchCtrl: TypedFormControl<string | IPatient> = new TypedFormControl();
  trackByIndex = TrackByFunctions.index<TypedFormControl<string>>();
  form = new TypedFormGroup<ISterilisationRecordFormData>({
    data: new TypedFormArray([new TypedFormControl('', Validators.required)]),
    patient: new TypedFormControl<WithRef<IPatient>>(
      undefined,
      Validators.required
    ),
    staffer: new TypedFormControl<WithRef<IStaffer>>(undefined),
  });
  disabled$: Observable<boolean>;
  barcodeMode$ = new BehaviorSubject<boolean>(true);
  availableRecords$: Observable<WithRef<ISterilisationRecord>[]>;
  filteredRecords$: Observable<WithRef<ISterilisationRecord>[]>;

  @ViewChildren('dataInput') dataInputs: QueryList<
    ElementRef<HTMLInputElement>
  >;

  constructor(
    private _dialogRef: MatDialogRef<
      SterilisationRecordDialogComponent,
      ISterilisationRecordFormData
    >,
    @Inject(MAT_DIALOG_DATA) public data: ISterilisationDialogData
  ) {
    if (data.patient) {
      this.searchCtrl.setValue(data.patient);
      this.searchCtrl.disable();
      this.form.patchValue(data);
      this.form.controls.patient.disable();
    }

    this.barcodeMode$.next(
      !!this.data.practice.settings.sterilisation?.barcodeModeEnabled
    );

    this.form.controls.staffer.setValue(data.staffer);
    this.disabled$ = isDisabled$(this.form);
    this.availableRecords$ = this._getAvailableRecords();

    this.barcodeMode$
      .pipe(distinctUntilChanged(), takeUntil(this._onDestroy$))
      .subscribe(
        (barcodeMode) =>
          void Firestore.patchDoc(this.data.practice.ref, {
            settings: {
              ...this.data.practice.settings,
              sterilisation: {
                ...this.data.practice.settings.sterilisation,
                barcodeModeEnabled: barcodeMode,
              },
            },
          })
      );

    this.searchCtrl.valueChanges
      .pipe(
        filter((value): value is WithRef<IPatient> => !isString(value)),
        takeUntil(this._onDestroy$)
      )
      .subscribe((patient) => this.form.controls.patient.setValue(patient));

    const recordChanges$ = this.barcodeMode$.pipe(
      switchMap((barcodeMode) =>
        !barcodeMode ? EMPTY : this.records.valueChanges
      ),
      debounceTime(350),
      startWith([])
    );

    recordChanges$
      .pipe(
        pairwise(),
        filter(([previous, current]) => {
          const decreaseInArraySize = previous.length > current.length;
          const noChangeToPrevious =
            previous.length === current.length &&
            isEqual(last(previous), last(current));
          const lastValue = last(current);
          const emptyLastIndex = lastValue ? lastValue.trim() === '' : true;
          if (decreaseInArraySize || noChangeToPrevious || emptyLastIndex) {
            return false;
          }
          return true;
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe(() => this.addRecord());
  }

  get records(): TypedFormArray<string> {
    return this.form.controls.data as TypedFormArray<string>;
  }

  ngAfterViewInit(): void {
    this.dataInputs.changes
      .pipe(
        map((inputs: QueryList<ElementRef<HTMLInputElement>>) =>
          inputs.toArray()
        ),
        takeUntil(this._onDestroy$)
      )
      .subscribe((records) =>
        setTimeout(() => last(records)?.nativeElement.focus())
      );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  async save(): Promise<void> {
    if (!this.form.valid) {
      return;
    }
    const availableRecords = await snapshot(this.availableRecords$);
    const formData = this.form.getRawValue();
    const data = compact(
      formData.data.map((record) => (isString(record) ? record.trim() : record))
    ).map((record) => {
      const existingRecord = availableRecords.find(
        (available) => available.data === record
      );
      return existingRecord || record;
    });

    this._dialogRef.close({
      data,
      patient: formData.patient,
      staffer: formData.staffer,
    });
  }

  onEnter($event: Event): void {
    $event.stopPropagation();
    $event.preventDefault();
    this.addRecord();
  }

  addRecord(): void {
    this.records.push(new TypedFormControl(''));
  }

  async getExistingRecord(
    value?: string | null
  ): Promise<WithRef<ISterilisationRecord> | undefined> {
    if (!value) {
      return;
    }
    const data = value.trim();
    const availableRecords = await snapshot(this.availableRecords$);
    return availableRecords.find((record) => record.data === data);
  }

  private _getAvailableRecords(): Observable<WithRef<ISterilisationRecord>[]> {
    if (!this.data.practice || !this.data.linkExistingRecords) {
      return of([]);
    }
    return Practice.sterilisationRecords$(this.data.practice, {
      from: toTimestamp(moment().startOf('day').subtract(7, 'day')),
      to: toTimestamp(moment().endOf('day')),
    }).pipe(
      map((records) =>
        records.filter((record) =>
          SterilisationRecord.canBeLinked(record, this.data.patient?.ref)
        )
      )
    );
  }
}
