import { TypeGuardFn, type Timestamp } from '@principle-theorem/shared';

export interface IDataChange<Data> {
  before: Data;
  after: Data;
}

export interface IStatusHistory<Status> {
  status: Status;
  updatedAt: Timestamp;
}

export type SortReturnValue = -1 | 0 | 1;

export interface IArraySorter {
  key: string;
  typeGuardFn?: TypeGuardFn<unknown>;
  sortByPath: string | string[] | ((data: unknown) => string | number);
}
