import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  BasicDialogService,
  DialogPresets,
  ISelectListDialogOption,
} from '@principle-theorem/ng-shared';
import { Invoice, MedicareClaimType } from '@principle-theorem/principle-core';
import {
  IPatientClaimDialogResponse,
  type IBulkBillDialogResponse,
  type IHealthcareClaim,
  type IInvoice,
} from '@principle-theorem/principle-core/interfaces';
import { Firestore, snapshot, type WithRef } from '@principle-theorem/shared';
import { isEqual } from 'lodash';
import {
  BulkBillDialogComponent,
  IBulkBillDialogData,
} from './bulk-bill-dialog/bulk-bill-dialog.component';
import { MedicareClaimTypeHelpers } from './medicare-claim-type-helpers';
import { PatientClaimDialogComponent } from './patient-claim-dialog/patient-claim-dialog.component';

@Injectable()
export class MedicareDialogService {
  constructor(
    private _dialog: MatDialog,
    private _basicDialog: BasicDialogService
  ) {}

  async selectClaimType(
    claimTypes: MedicareClaimType[],
    invoice: WithRef<IInvoice>,
    claim?: IHealthcareClaim
  ): Promise<MedicareClaimType | undefined> {
    const options = claimTypes.map((claimType) =>
      this._toClaimTypeOption(claimType, invoice, claim)
    );
    return this._basicDialog.selectList<MedicareClaimType>({
      title: 'Select Medicare Claim Type',
      prompt: 'Medicare Claim Type',
      options: await Promise.all(options),
      lines: 3,
    });
  }

  async bulkBill(
    invoice: WithRef<IInvoice>,
    claim: IHealthcareClaim,
    isPartPaid: boolean
  ): Promise<IBulkBillDialogResponse | undefined> {
    const patient = await snapshot(Invoice.patient$(invoice));
    const data: IBulkBillDialogData = {
      title: isPartPaid ? 'Part Paid' : 'Bulk Bill',
      isPartPaid,
      invoice,
      claim,
      patient,
    };
    const response = await this._dialog
      .open<
        BulkBillDialogComponent,
        IBulkBillDialogData,
        IBulkBillDialogResponse
      >(BulkBillDialogComponent, DialogPresets.medium({ height: '80%', data }))
      .afterClosed()
      .toPromise();

    if (response && !isEqual(patient.medicareCard, response.medicareCard)) {
      const medicareCard = response.medicareCard;
      await Firestore.patchDoc(patient.ref, { medicareCard });
    }

    return response;
  }

  async patientClaim(
    invoice: WithRef<IInvoice>,
    claim: IHealthcareClaim
  ): Promise<IPatientClaimDialogResponse | undefined> {
    const patient = await snapshot(Invoice.patient$(invoice));
    const data: IBulkBillDialogData = {
      title: 'Patient Claim',
      isPartPaid: false,
      invoice,
      claim,
      patient,
    };
    const response = await this._dialog
      .open<
        PatientClaimDialogComponent,
        IBulkBillDialogData,
        IPatientClaimDialogResponse
      >(PatientClaimDialogComponent, DialogPresets.medium({ data }))
      .afterClosed()
      .toPromise();

    if (response && !isEqual(patient.medicareCard, response.medicareCard)) {
      const medicareCard = response.medicareCard;
      await Firestore.patchDoc(patient.ref, { medicareCard });
    }

    return response;
  }

  private async _toClaimTypeOption(
    claimType: MedicareClaimType,
    invoice: WithRef<IInvoice>,
    claim?: IHealthcareClaim
  ): Promise<ISelectListDialogOption<MedicareClaimType>> {
    const definition = MedicareClaimTypeHelpers.getDefinition(claimType);
    const canClaim = await snapshot(definition.canClaim$(invoice, claim));
    return {
      label: definition.label,
      value: claimType,
      info: definition.info,
      isDisabled: !canClaim,
      disabledReason: definition.disabledReason,
    };
  }
}
