import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import {
  TypedFormControl,
  validFormControlChanges$,
} from '@principle-theorem/ng-shared';
import { debounceUserInput, isChanged$ } from '@principle-theorem/shared';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'pr-national-health-index-number-form',
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatButtonModule,
  ],
  templateUrl: './national-health-index-number-form.component.html',
  styleUrl: './national-health-index-number-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NationalHealthIndexNumberFormComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();

  nationalHealthIndexNumberCtrl = new TypedFormControl<string | undefined>(
    undefined,
    [Validators.minLength(7)]
  );

  @Input()
  set nationalHealthIndexNumber(nationalHealthIndexNumber: string | undefined) {
    this.nationalHealthIndexNumberCtrl.setValue(nationalHealthIndexNumber, {
      emitEvent: false,
    });
  }

  @Output() nationalHealthIndexNumberChange = new EventEmitter<string>();

  constructor() {
    validFormControlChanges$(this.nationalHealthIndexNumberCtrl)
      .pipe(isChanged$(), debounceUserInput(), takeUntil(this._onDestroy$))
      .subscribe((number) =>
        this.nationalHealthIndexNumberChange.emit(number || undefined)
      );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
