import { Region, type DocumentReference } from '@principle-theorem/shared';
import { isDocRef, isObject, type WithRef } from '@principle-theorem/shared';
import { isString } from 'lodash';
import { PatientOptionalField, type IBrand } from '../brand';
import { type ISubmittedForm } from '../custom-forms/submitted-form-history';
import { type IPatient } from './patient';
import { type IReferralSourceConfiguration } from './referral-source';

export interface IPatientTokenData {
  patient: DocumentReference<IPatient>;
}

export function isPatientTokenData(data: unknown): data is IPatientTokenData {
  return isObject(data) && isDocRef(data.patient);
}
export interface ISubmitMedicalFormRequest {
  tokenUid: string;
  data: ISubmittedForm<object>;
}

export interface ISubmitPatientDetailsFormRequest {
  tokenUid: string;
  data: ISubmittedForm<Partial<IPatient>>;
}

export function isSubmitMedicalFormRequest(
  data: unknown
): data is ISubmitMedicalFormRequest {
  return isObject(data) && isString(data.tokenUid) && isObject(data.data);
}

export function isSubmitPatientDetailsFormRequest(
  data: unknown
): data is ISubmitPatientDetailsFormRequest {
  return isObject(data) && isString(data.tokenUid) && isObject(data.data);
}

export interface IPatientMedicalHistoryFormTokenData {
  region: Region;
  brand: WithRef<IBrand>;
  patientName: string;
  alreadySubmitted: boolean;
  referralSources: WithRef<IReferralSourceConfiguration>[];
  requiredFields: PatientOptionalField[];
}
