import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  type ISterilisationPack,
  type ISterilisationRecord,
} from '@principle-theorem/principle-core/interfaces';
import { type INamedDocument, type WithRef } from '@principle-theorem/shared';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { SterilisationRecordItemComponent } from '../sterilisation-record-item/sterilisation-record-item.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { EmptyStateComponent } from '@principle-theorem/ng-principle-shared';

export type SterilisationItemDisplayField = keyof Pick<
  ISterilisationRecord,
  'patient' | 'cycle'
>;

@Component({
  selector: 'pr-sterilisation-record-list',
  standalone: true,
  imports: [
    CommonModule,
    SterilisationRecordItemComponent,
    NgxSkeletonLoaderModule,
    EmptyStateComponent,
  ],
  templateUrl: './sterilisation-record-list.component.html',
  styleUrl: './sterilisation-record-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SterilisationRecordListComponent {
  records$ = new ReplaySubject<WithRef<ISterilisationRecord>[]>(1);
  packOptions$ = new ReplaySubject<INamedDocument<ISterilisationPack>[]>(1);
  omitFields$ = new BehaviorSubject<SterilisationItemDisplayField[]>([]);

  @Input()
  set records(records: WithRef<ISterilisationRecord>[]) {
    if (records) {
      this.records$.next(records);
    }
  }

  @Input()
  set packOptions(options: INamedDocument<ISterilisationPack>[]) {
    if (options) {
      this.packOptions$.next(options);
    }
  }

  @Input()
  set omitFields(fields: SterilisationItemDisplayField[]) {
    if (fields) {
      this.omitFields$.next(fields);
    }
  }
}
