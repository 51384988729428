import {
  ChartedItemType,
  IChartedMultiStepTreatment,
  IChartedMultiStepTreatmentStep,
  IChartedSurface,
  IChartedTreatment,
  IFeeSchedule,
  IMultiTreatmentConfiguration,
  IMultiTreatmentPackage,
  IScopeRef,
  IStaffer,
  ITreatmentStep,
} from '@principle-theorem/principle-core/interfaces';
import {
  asyncForEach,
  AtLeast,
  getDoc,
  INamedDocument,
  reduceToSingleArrayFn,
  toNamedDocument,
  WithRef,
} from '@principle-theorem/shared';
import { findIndex, matchesProperty, reject } from 'lodash';
import { v4 as uuid } from 'uuid';
import { ChartedItem } from '../core/charted-item';
import { ChartedItemTotalCalculator } from './charted-item-total-calculator';
import { ChartedTreatment } from './charted-treatment';
import { TreatmentStep } from './treatment-step';

export class ChartedMultiStepTreatment extends ChartedItem {
  static override init(
    overrides: AtLeast<IChartedMultiStepTreatment, 'config' | 'scopeRef'>
  ): IChartedMultiStepTreatment {
    return {
      uuid: uuid(),
      steps: [],
      type: ChartedItemType.ChartedMultiStepTreatment,
      chartedSurfaces: [],
      notes: [],
      ...overrides,
    };
  }

  static override allChartedSurfaces(
    multiStepTreatment: IChartedMultiStepTreatment
  ): IChartedSurface[] {
    const treatmentSurfaces: IChartedSurface[] = multiStepTreatment.steps
      .map((step) => step.treatments)
      .reduce(reduceToSingleArrayFn, [])
      .reduce((surfaces: IChartedSurface[], treatment: IChartedTreatment) => {
        return [...surfaces, ...ChartedTreatment.allChartedSurfaces(treatment)];
      }, []);
    return [...multiStepTreatment.chartedSurfaces, ...treatmentSurfaces];
  }

  static deleteTreatment(
    multiTreatment: IChartedMultiStepTreatment,
    treatmentUid: string
  ): IChartedMultiStepTreatment {
    return {
      ...multiTreatment,
      steps: multiTreatment.steps.map((step) =>
        TreatmentStep.deleteTreatment(step, treatmentUid)
      ),
    };
  }

  static findTreatmentStepById(
    multiTreatment: IChartedMultiStepTreatment,
    treatmentStepUid: string
  ): IChartedMultiStepTreatmentStep | undefined {
    return multiTreatment.steps.find((step) => step.uid === treatmentStepUid);
  }

  static toChartedMultiStepTreatmentStep(
    step: ITreatmentStep
  ): IChartedMultiStepTreatmentStep {
    return {
      uid: uuid(),
      ...step,
    };
  }

  static async fromConfig(
    config: WithRef<IMultiTreatmentConfiguration>,
    feeSchedule: WithRef<IFeeSchedule>,
    scopeRef: IScopeRef,
    attributedTo?: INamedDocument<IStaffer>
  ): Promise<IChartedMultiStepTreatment> {
    const stepPromises: Promise<ITreatmentStep>[] = config.steps.map(
      (stepConfig) =>
        TreatmentStep.fromConfig(
          stepConfig,
          feeSchedule,
          scopeRef,
          attributedTo
        )
    );

    const steps: ITreatmentStep[] = await Promise.all(stepPromises);

    return ChartedMultiStepTreatment.init({
      steps: steps.map((step) =>
        ChartedMultiStepTreatment.toChartedMultiStepTreatmentStep(step)
      ),
      config: toNamedDocument(config),
      feeSchedule: toNamedDocument(feeSchedule),
      scopeRef,
    });
  }

  static updateTreatmentStep(
    multiTreatment: IChartedMultiStepTreatment,
    step: IChartedMultiStepTreatmentStep
  ): IChartedMultiStepTreatment {
    const index = findIndex(
      multiTreatment.steps,
      matchesProperty('uid', step.uid)
    );
    if (index !== -1) {
      multiTreatment.steps[index] = step;
    }
    return multiTreatment;
  }

  static removeTreatmentStep(
    multiTreatment: IChartedMultiStepTreatment,
    step: IChartedMultiStepTreatmentStep
  ): IChartedMultiStepTreatment {
    multiTreatment.steps = reject(
      multiTreatment.steps,
      matchesProperty('uid', step.uid)
    );
    return multiTreatment;
  }

  static addStep(
    multiTreatment: IChartedMultiStepTreatment
  ): IChartedMultiStepTreatment {
    multiTreatment.steps.push({
      ...TreatmentStep.init(),
      uid: uuid(),
    });
    return multiTreatment;
  }

  static async resolveStepDuration(
    step: IChartedMultiStepTreatmentStep
  ): Promise<number> {
    if (step.schedulingRules.duration) {
      return step.schedulingRules.duration;
    }
    const treatmentConfigs = await asyncForEach(
      step.treatments,
      (chartedTreatment) => getDoc(chartedTreatment.config.ref)
    );
    return treatmentConfigs.reduce(
      (total, config) => total + config.duration,
      0
    );
  }

  static applyPackagePricing(
    multiTreatment: IChartedMultiStepTreatment,
    treatmentPackage: IMultiTreatmentPackage
  ): IChartedMultiStepTreatment {
    const calc = new ChartedItemTotalCalculator();

    const steps = multiTreatment.steps.map((step, index) => {
      const treatmentPackageStep = treatmentPackage.steps[index];
      const treatments = step.treatments.map((treatment, treatmentIndex) => {
        const packageTreatment =
          treatmentPackageStep.treatments[treatmentIndex];
        if (!packageTreatment) {
          return treatment;
        }
        const serviceCodes = treatment.serviceCodes.map((serviceCode) => {
          const packageCode = packageTreatment.priceOverrides.find(
            (code) =>
              code.code === serviceCode.code && code.type === serviceCode.type
          );
          if (!packageCode) {
            return serviceCode;
          }
          return {
            ...serviceCode,
            priceOverride: packageCode.price,
          };
        });

        const updated = {
          ...treatment,
          serviceCodes,
        };
        const price = calc.treatment(updated);
        return {
          ...updated,
          price,
        };
      });

      return {
        ...step,
        treatments,
      };
    });

    return {
      ...multiTreatment,
      steps,
      treatmentPackageId: treatmentPackage.uid,
    };
  }

  static clearPackagePricing(
    multiTreatment: IChartedMultiStepTreatment
  ): IChartedMultiStepTreatment {
    const calc = new ChartedItemTotalCalculator();
    const steps = multiTreatment.steps.map((step) => {
      const treatments = step.treatments.map((treatment) => {
        const serviceCodes = treatment.serviceCodes.map((serviceCode) => ({
          ...serviceCode,
          priceOverride: undefined,
        }));
        const updated = {
          ...treatment,
          serviceCodes,
        };
        const price = calc.treatment(updated);
        return {
          ...updated,
          price,
        };
      });
      return {
        ...step,
        treatments,
      };
    });

    return {
      ...multiTreatment,
      steps,
      treatmentPackageId: undefined,
    };
  }
}
