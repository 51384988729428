<mat-tab-group
  class="mat-tab-icon-labels"
  [disablePagination]="true"
  *ngrxLet="patient$ as patient"
>
  <mat-tab>
    <ng-template matTabLabel>
      <mat-icon matTooltip="Patient Details">subject</mat-icon>
      <mat-icon
        *ngIf="(validPatientDetails$ | async) === false"
        class="has-notification"
        matTooltip="Missing Patient Details"
        color="warn"
      >
        warning
      </mat-icon>
    </ng-template>
    <ng-template matTabContent>
      <pr-edit-patient-form
        #patientDetailsform
        class="tab-padding"
        [patient]="patient"
        [newPatientName]="newPatientName"
        [emitPatientDataOnUpdate]="true"
        [tabGroup]="tabGroup"
        (submitted)="patientDetailsSubmitted($event)"
      />
    </ng-template>
  </mat-tab>
  <mat-tab [disabled]="!patient">
    <ng-template matTabLabel>
      <mat-icon matTooltip="Healthcare Cards">local_hospital</mat-icon>
    </ng-template>
    <ng-template matTabContent>
      <div fxLayout="column" fxLayoutGap="32px" class="tab-padding">
        <ng-container *prIsRegion="[Region.Australia]">
          <div>
            <p class="font-bold">Medicare Card</p>
            <pr-medicare-card-form
              [medicareCard]="patient?.medicareCard"
              (medicareCardChange)="updatePatient({ medicareCard: $event })"
            />
          </div>
          <div>
            <p class="font-bold">DVA Card</p>
            <pr-dva-card-form
              [dvaCard]="patient?.dvaCard"
              (dvaCardChange)="updatePatient({ dvaCard: $event })"
            />
          </div>
        </ng-container>
        <ng-container *prIsRegion="[Region.NewZealand]">
          <div>
            <p class="font-bold">NHI</p>
            <pr-national-health-index-number-form
              [nationalHealthIndexNumber]="patient?.nationalHealthIndexNumber"
              (nationalHealthIndexNumberChange)="
                updatePatient({ nationalHealthIndexNumber: $event })
              "
            />
          </div>
          <div>
            <p class="font-bold">Health Insurance</p>
            <pr-health-insurance-details-form
              [healthInsuranceDetails]="patient?.healthInsuranceDetails"
              (healthInsuranceDetailsChange)="
                updatePatient({ healthInsuranceDetails: $event })
              "
            />
          </div>
        </ng-container>
      </div>
    </ng-template>
  </mat-tab>
  <mat-tab [disabled]="!patient">
    <ng-template matTabLabel>
      <mat-icon matTooltip="Relationships">group</mat-icon>
    </ng-template>
    <ng-template matTabContent>
      <div class="tab-padding">
        <pr-patient-primary-contact-form
          [formData]="primaryContactData$ | async"
          [autoSave]="true"
          (primaryContactChange)="primaryContactChange($event)"
        />
        <pr-patient-relationship-form
          [formData]="relationshipData$ | async"
          [autoSave]="true"
          (relationshipChange)="relationshipChange($event)"
        />
      </div>
    </ng-template>
  </mat-tab>
</mat-tab-group>
