import { MaterialDesignFrameworkModule } from '@ajsf/material';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import { NationalHealthIndexNumberFormComponent } from '@principle-theorem/ng-patient';
import { NgPrincipleSharedModule } from '@principle-theorem/ng-principle-shared';
import { NgSharedModule } from '@principle-theorem/ng-shared';
import { CustomFormComponent } from './components/custom-form/custom-form.component';
import { FormDataProviderService } from './components/form-dialog/form-data-provider.service';
import { FormDialogComponent } from './components/form-dialog/form-dialog.component';
import { FormsDashboardComponent } from './components/forms-dashboard/forms-dashboard.component';
import { MedicalAlertsPreviewComponent } from './components/medical-alerts-preview/medical-alerts-preview.component';
import { MedicalHistoryAlertsConfigureComponent } from './components/medical-history-alerts-configure/medical-history-alerts-configure.component';
import { MedicalHistoryDialogComponent } from './components/medical-history-dialog/medical-history-dialog.component';
import { MedicalHistoryFormPrintComponent } from './components/medical-history-form-print/medical-history-form-print.component';
import { MedicalHistoryFormComponent } from './components/medical-history-form/medical-history-form.component';
import { MedicalHistoryListComponent } from './components/medical-history-list/medical-history-list.component';
import { MedicalHistoryListStore } from './components/medical-history-list/medical-history-list.store';
import { PatientDetailsCompareFieldComponent } from './components/patient-details-form-compare/patient-details-compare-field/patient-details-compare-field.component';
import { PatientDetailsFormCompareComponent } from './components/patient-details-form-compare/patient-details-form-compare.component';
import { PatientDetailsDvaFormComponent } from './components/patient-details-form/patient-details-dva-form/patient-details-dva-form.component';
import { PatientDetailsFormComponent } from './components/patient-details-form/patient-details-form.component';
import { PatientDetailsHealthFundFormComponent } from './components/patient-details-form/patient-details-health-fund-form/patient-details-health-fund-form.component';
import { PatientDetailsMedicareFormComponent } from './components/patient-details-form/patient-details-medicare-form/patient-details-medicare-form.component';
import { PatientDetailsHistoryListComponent } from './components/patient-details-history-list/patient-details-history-list.component';
import { PatientDetailsHistoryListStore } from './components/patient-details-history-list/patient-details-history-list.store';
import { PatientFormsTabComponent } from './components/patient-forms-tab/patient-forms-tab.component';
import { PatientMedicalAlertDetailsComponent } from './components/patient-medical-alert-details/patient-medical-alert-details.component';
import { PatientSubmittedFormsListComponent } from './components/patient-submitted-forms-list/patient-submitted-forms-list.component';
import { PatientSubmittedFormsListStore } from './components/patient-submitted-forms-list/patient-submitted-forms-list.store';

@NgModule({
  imports: [
    CommonModule,
    NgSharedModule,
    NgPrincipleSharedModule,
    MaterialDesignFrameworkModule,
    NgMaterialModule,
    NationalHealthIndexNumberFormComponent,
  ],
  declarations: [
    MedicalHistoryFormComponent,
    MedicalHistoryDialogComponent,
    CustomFormComponent,
    PatientFormsTabComponent,
    MedicalHistoryFormPrintComponent,
    MedicalHistoryListComponent,
    PatientDetailsHistoryListComponent,
    FormsDashboardComponent,
    PatientSubmittedFormsListComponent,
    PatientDetailsFormComponent,
    PatientDetailsFormCompareComponent,
    FormDialogComponent,
    PatientDetailsCompareFieldComponent,
    PatientDetailsMedicareFormComponent,
    PatientDetailsHealthFundFormComponent,
    PatientDetailsDvaFormComponent,
    PatientMedicalAlertDetailsComponent,
    MedicalAlertsPreviewComponent,
    MedicalHistoryAlertsConfigureComponent,
  ],
  exports: [
    MedicalHistoryFormComponent,
    MedicalHistoryDialogComponent,
    CustomFormComponent,
    PatientSubmittedFormsListComponent,
    PatientDetailsFormComponent,
    PatientMedicalAlertDetailsComponent,
    MedicalAlertsPreviewComponent,
    MedicalHistoryAlertsConfigureComponent,
  ],
  providers: [
    FormDataProviderService,
    PatientDetailsHistoryListStore,
    MedicalHistoryListStore,
    PatientSubmittedFormsListStore,
  ],
})
export class NgCustomFormsModule {}
