import {
  roundTo2Decimals,
  TaxRate,
  TaxStrategy,
} from '@principle-theorem/accounting';
import { initVersionedSchema } from '@principle-theorem/editor';
import {
  ADA_CODE_TREATMENT_CONFIG_ID,
  Brand,
  CalculateTreatmentWeight,
  ChartedItemScopeResolver,
  ChartedSurface,
  ChartedTreatment,
  ClinicalNote,
  determineTaxFromStrategy,
  FeeSchedule,
  getQuadrant,
  getQuadrantIndex,
  hasMergeConflicts,
  PricedServiceCodeEntry,
  stafferToNamedDoc,
  TreatmentConfiguration,
  TreatmentPlan,
  TreatmentStep,
} from '@principle-theorem/principle-core';
import {
  AppointmentStatus,
  ChartableSurface,
  DestinationEntityRecordStatus,
  IHasSourceIdentifier,
  IMigratedDataSummary,
  ITranslationMap,
  ITreatmentCategory,
  TreatmentPlanStatus,
  TreatmentStepStatus,
  type FailedDestinationEntityRecord,
  type IChartedRef,
  type IChartedTreatment,
  type IDestinationEntity,
  type IDestinationEntityRecord,
  type IFeeSchedule,
  type IGetRecordResponse,
  type IPatient,
  type IPracticeMigration,
  type ISourceEntityRecord,
  type IStaffer,
  type IToothRef,
  type ITreatmentConfiguration,
  type ITreatmentPlan,
  type ITreatmentStep,
  type MergeConflictDestinationEntityRecord,
  type ToothNumber,
} from '@principle-theorem/principle-core/interfaces';
import {
  asyncForEach,
  Firestore,
  getDoc$,
  getError,
  HISTORY_DATE_FORMAT,
  isINamedDocument,
  ISO_DATE_FORMAT,
  ISO_DATE_TIME_FORMAT,
  isSameRef,
  multiSwitchMap,
  safeCombineLatest,
  snapshot,
  Timezone,
  toISODate,
  toMoment,
  toMomentTz,
  toNamedDocument,
  toTimestamp,
  type DocumentReference,
  type INamedDocument,
  type ISODateType,
  type Timestamp,
  type WithRef,
} from '@principle-theorem/shared';
import { compact, first, groupBy, kebabCase, omit, sortBy } from 'lodash';
import * as moment from 'moment-timezone';
import { combineLatest, from, of, type Observable } from 'rxjs';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import { BaseDestinationEntity } from '../../../destination/base-destination-entity';
import { FirestoreMigrate } from '../../../destination/destination';
import { DestinationEntity } from '../../../destination/destination-entity';
import {
  ITreatmentPlanSuccessData,
  PATIENT_TREATMENT_PLAN_CUSTOM_MAPPING_TYPE,
  PATIENT_TREATMENT_STEP_CUSTOM_MAPPING_TYPE,
} from '../../../destination/entities/patient-treatment-plans';
import { resolveFeeSchedule } from '../../../mappings/fee-schedules';
import { ItemCodeResourceMapType } from '../../../mappings/item-codes-to-xlsx';
import { PracticeMigration } from '../../../practice-migrations';
import { buildSkipMigratedQuery } from '../../../source/source-entity-record';
import { type TranslationMapHandler } from '../../../translation-map';
import {
  PatientAppointmentProcedureSourceEntity,
  type IPraktikaAppointmentProcedure,
  type IPraktikaAppointmentProcedureFilters,
  type IPraktikaAppointmentProcedureTranslations,
} from '../../source/entities/appointment-procedure';
import {
  PATIENT_RESOURCE_TYPE,
  PatientSourceEntity,
  type IPraktikaPatient,
  type IPraktikaPatientFilters,
  type IPraktikaPatientTranslations,
} from '../../source/entities/patient';
import {
  PatientAppointmentSourceEntity,
  PraktikaAppointmentStatus,
  PraktikaInAppointmentStatus,
  type IPraktikaAppointment,
  type IPraktikaAppointmentTranslations,
} from '../../source/entities/patient-appointment';
import {
  TOOTH_SURFACES_MAP,
  type PraktikaToothSurfaces,
} from '../../source/entities/patient-tooth-conditions';
import {
  PraktikaItemCodeMappingHandler,
  resolveMappedCode,
} from '../mappings/item-code';
import {
  PraktikaItemCodeToTreatmentMappingHandler,
  resolveMappedTreatmentConfiguration,
} from '../mappings/item-code-to-treatment';
import { PraktikaPracticeMappingHandler } from '../mappings/practices';
import { PraktikaStafferMappingHandler } from '../mappings/staff';
import { PatientDestinationEntity } from './patients';
import { StafferDestinationEntity } from './staff';
import { STAFFER_RESOURCE_TYPE } from '../../../destination/entities/staff';

export const PATIENT_TREATMENT_PLAN_DESTINATION_ENTITY = DestinationEntity.init(
  {
    metadata: {
      key: 'patientTreatmentPlans',
      label: 'Patient Treatment Plans',
      description: `This migrates the appointment procedures for each patient's appointment and creates a single step on a single plan for each appointment.`,
    },
  }
);

interface IPatientTreatmentPlanJobData {
  sourcePatient: IGetRecordResponse<
    IPraktikaPatient,
    IPraktikaPatientTranslations,
    IPraktikaPatientFilters
  >;
  defaultTreatmentConfiguration: WithRef<ITreatmentConfiguration>;
  treatmentConfigurations: WithRef<ITreatmentConfiguration>[];
  treatmentConfigurationMappings: WithRef<
    ITranslationMap<ITreatmentConfiguration>
  >[];
  sourceItemCodes: WithRef<ITranslationMap<object, ItemCodeResourceMapType>>[];
  staff: WithRef<ITranslationMap<IStaffer>>[];
  practitioners: WithRef<IStaffer>[];
  treatmentCategories: WithRef<ITreatmentCategory>[];
}

export interface IPatientTreatmentPlanMigrationData {
  patientRef: DocumentReference<IPatient>;
  sourcePatientId: string;
  plan: ITreatmentPlanStepPair['plan'];
  steps: ITreatmentPlanStepPair['steps'];
}

export class PatientTreatmentPlanDestinationEntity extends BaseDestinationEntity<
  ITreatmentPlanSuccessData,
  IPatientTreatmentPlanJobData,
  IPatientTreatmentPlanMigrationData
> {
  destinationEntity = PATIENT_TREATMENT_PLAN_DESTINATION_ENTITY;

  sourceCountComparison = new PatientSourceEntity();

  override sourceEntities = {
    patients: new PatientSourceEntity(),
    appointments: new PatientAppointmentSourceEntity(),
    appointmentProcedures: new PatientAppointmentProcedureSourceEntity(),
  };

  override destinationEntities = {
    patients: new PatientDestinationEntity(),
    staff: new StafferDestinationEntity(),
  };

  customMappings = {
    staff: new PraktikaStafferMappingHandler(),
    practice: new PraktikaPracticeMappingHandler(),
    itemCodes: new PraktikaItemCodeMappingHandler(),
    itemCodeToTreatment: new PraktikaItemCodeToTreatmentMappingHandler(),
  };

  sourceCountDataAccessor(
    data: IPatientTreatmentPlanJobData
  ): DocumentReference<ISourceEntityRecord> {
    return data.sourcePatient.record.ref;
  }

  getMigratedData$(
    record: IDestinationEntityRecord<ITreatmentPlanSuccessData>
  ): Observable<IMigratedDataSummary[]> {
    if (record.status !== DestinationEntityRecordStatus.Migrated) {
      return of([]);
    }

    return combineLatest([
      Firestore.getDoc(record.data.sourceRef),
      safeCombineLatest(
        record.data.treatmentPlanRefs.map((treatmentPlanRef) =>
          Firestore.getDoc(treatmentPlanRef)
        )
      ),
      safeCombineLatest(
        record.data.treatmentStepRefs.map((treatmentStepRef) =>
          Firestore.getDoc(treatmentStepRef)
        )
      ),
    ]).pipe(
      map(([sourceAppointment, treatmentPlans, treatmentSteps]) => [
        {
          label: 'Source Appointment',
          data: sourceAppointment,
        },
        ...treatmentPlans.map((treatmentPlan) => ({
          label: `Treatment Plan - ${treatmentPlan.name}`,
          data: treatmentPlan,
        })),
        ...treatmentSteps.map((treatmentStep) => ({
          label: `Treatment Step - ${treatmentStep.name}`,
          data: treatmentStep,
        })),
      ])
    );
  }

  buildJobData$(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    skipMigrated: boolean,
    _fromDate?: Timestamp,
    _toDate?: Timestamp
  ): Observable<IPatientTreatmentPlanJobData[]> {
    const brand$ = PracticeMigration.brand$(migration);
    const staff$ = combineLatest([
      this.customMappings.staff.getRecords$(translationMap),
      translationMap.getByType$<IStaffer>(STAFFER_RESOURCE_TYPE),
    ]).pipe(map(([staff, mappedStaff]) => [...staff, ...mappedStaff]));
    const sourceItemCodes$ =
      this.customMappings.itemCodes.getRecords$(translationMap);
    const treatmentConfigurationMappings$ =
      this.customMappings.itemCodeToTreatment.getRecords$(translationMap);
    const defaultTreatmentConfiguration$ = brand$.pipe(
      switchMap((brand) =>
        getDoc$(TreatmentConfiguration.col(brand), ADA_CODE_TREATMENT_CONFIG_ID)
      )
    );
    const treatmentConfigurations$ = brand$.pipe(
      switchMap((brand) => TreatmentConfiguration.all$(brand))
    );
    const practitioners$ = staff$.pipe(
      multiSwitchMap((staffer) =>
        staffer.destinationIdentifier
          ? Firestore.getDoc(staffer.destinationIdentifier)
          : of(undefined)
      ),
      map(compact)
    );
    const treatmentCategories$ = brand$.pipe(
      switchMap((brand) => Brand.treatmentCategories$(brand))
    );

    return this.sourceEntities.patients
      .getRecords$(
        migration,
        500,
        buildSkipMigratedQuery(skipMigrated, this.destinationEntity)
      )
      .pipe(
        withLatestFrom(
          staff$,
          practitioners$,
          defaultTreatmentConfiguration$,
          treatmentConfigurations$,
          sourceItemCodes$,
          treatmentConfigurationMappings$,
          treatmentCategories$
        ),
        map(
          ([
            sourcePatients,
            staff,
            practitioners,
            defaultTreatmentConfiguration,
            treatmentConfigurations,
            sourceItemCodes,
            treatmentConfigurationMappings,
            treatmentCategories,
          ]) =>
            sourcePatients.map((sourcePatient) => ({
              sourcePatient,
              staff,
              practitioners,
              defaultTreatmentConfiguration,
              treatmentConfigurations,
              sourceItemCodes,
              treatmentConfigurationMappings,
              treatmentCategories,
            }))
        )
      );
  }

  getDestinationEntityRecordUid(data: IPatientTreatmentPlanJobData): string {
    return data.sourcePatient.record.uid;
  }

  async buildMigrationData(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    data: IPatientTreatmentPlanJobData
  ): Promise<
    | IPatientTreatmentPlanMigrationData
    | (IDestinationEntityRecord & FailedDestinationEntityRecord)
  > {
    const sourcePatientId = this.sourceEntities.patients
      .getSourceRecordId(data.sourcePatient.data.data)
      .toString();

    const patientRef = await translationMap.getDestination<IPatient>(
      sourcePatientId,
      PATIENT_RESOURCE_TYPE
    );

    if (!patientRef) {
      return this._buildErrorResponse(data.sourcePatient, 'No patient');
    }

    const procedures =
      await this.sourceEntities.appointmentProcedures.filterRecords(
        migration,
        'patientId',
        data.sourcePatient.data.data.patient_id.toString()
      );

    const appointments = await this.sourceEntities.appointments.filterRecords(
      migration,
      'patientId',
      data.sourcePatient.data.data.patient_id.toString()
    );

    try {
      const { plan, steps } = await this._buildTreatmentPlanData(
        translationMap,
        data,
        procedures,
        appointments,
        migration,
        sourcePatientId
      );

      return {
        sourcePatientId,
        patientRef,
        plan,
        steps: sortBy(steps, (step) => toMoment(step.createdAt).format()),
      };
    } catch (error) {
      return this._buildErrorResponse(data.sourcePatient, getError(error));
    }
  }

  async hasMergeConflict(
    translationMap: TranslationMapHandler,
    data: IPatientTreatmentPlanMigrationData
  ): Promise<IPatientTreatmentPlanMigrationData | undefined> {
    try {
      const mergeConflictPlanPairs = await asyncForEach(
        [data],
        async ({ steps }) => {
          const planRef = await translationMap.getDestination(
            data.sourcePatientId,
            PATIENT_TREATMENT_PLAN_CUSTOM_MAPPING_TYPE
          );

          if (!planRef) {
            return;
          }

          const existingPlan = await Firestore.getDoc(
            planRef as DocumentReference<ITreatmentPlan>
          );

          const planMergeConflict = hasMergeConflicts(data.plan, {
            ...existingPlan,
            steps: [],
          });

          const existingSteps: ITreatmentPlanStepPair['steps'] = [];

          const stepMergeConflicts = await asyncForEach(steps, async (step) => {
            const stepRef = await translationMap.getDestination(
              step.sourceIdentifier,
              PATIENT_TREATMENT_STEP_CUSTOM_MAPPING_TYPE
            );

            if (!stepRef) {
              return false;
            }

            const existingStep = await Firestore.getDoc(
              stepRef as DocumentReference<ITreatmentStep>
            );
            existingSteps.push({
              ...omit(existingStep, 'ref'),
              sourceIdentifier: step.sourceIdentifier,
              createdAt: step.createdAt,
            });

            const stepMergeConflict = hasMergeConflicts(
              omit(step, ['sourceIdentifier']),
              omit(existingStep, ['sourceIdentifier']),
              ['chartedAt'],
              [
                {
                  key: 'chartedSurfaces',
                  sortByPath: [
                    'resolvedAt.seconds',
                    'chartedRef.wholeMouth',
                    'chartedRef.tooth.quadrant',
                    'chartedRef.tooth.quadrantIndex',
                    'chartedRef.tooth.surface',
                  ],
                },
                {
                  key: 'treatments',
                  sortByPath: [
                    'type',
                    'config.ref.id',
                    'scopeRef',
                    'price',
                    'attributedTo.ref.id',
                    'adaCodes[0].code.name',
                    'chartedSurfaces[0].resolvedAt.seconds',
                    'chartedSurfaces[0].chartedRef.wholeMouth',
                    'chartedSurfaces[0].chartedRef.tooth.quadrant',
                    'chartedSurfaces[0].chartedRef.tooth.quadrantIndex',
                    'chartedSurfaces[0].chartedRef.tooth.surface',
                  ],
                },
              ]
            );

            return stepMergeConflict;
          });

          const hasIncorrectStepCount =
            existingPlan.steps.length !== existingSteps.length;

          const hasMergeConflict = [
            hasIncorrectStepCount,
            planMergeConflict,
            ...stepMergeConflicts,
          ].some((stepMergeConflict) => stepMergeConflict);

          if (hasMergeConflict) {
            return {
              plan: {
                ...omit(existingPlan, 'ref'),
              },
              steps: sortBy(existingSteps, 'name'),
            };
          }
        }
      );

      if (compact(mergeConflictPlanPairs).length) {
        return {
          ...data,
          ...mergeConflictPlanPairs,
        };
      }
    } catch (error) {
      return;
    }
  }

  buildMergeConflictRecord(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    _translationMap: TranslationMapHandler,
    jobData: IPatientTreatmentPlanJobData,
    _migrationData: IPatientTreatmentPlanMigrationData
  ): IDestinationEntityRecord & MergeConflictDestinationEntityRecord {
    return {
      uid: jobData.sourcePatient.record.uid,
      label: jobData.sourcePatient.record.label,
      status: DestinationEntityRecordStatus.MergeConflict,
    };
  }

  async runJob(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    jobData: IPatientTreatmentPlanJobData,
    migrationData: IPatientTreatmentPlanMigrationData
  ): Promise<IDestinationEntityRecord> {
    try {
      const { planRef, stepRefs } = await this._upsertTreatmentPlan(
        migrationData,
        translationMap
      );

      return this._buildSuccessResponse(
        jobData.sourcePatient,
        [planRef],
        stepRefs
      );
    } catch (error) {
      return this._buildErrorResponse(jobData.sourcePatient, String(error));
    }
  }

  private _buildSuccessResponse(
    patient: IGetRecordResponse<IPraktikaPatient, IPraktikaPatientTranslations>,
    treatmentPlanRefs: DocumentReference<ITreatmentPlan>[],
    treatmentStepRefs: DocumentReference<ITreatmentStep>[]
  ): IDestinationEntityRecord<ITreatmentPlanSuccessData> {
    return {
      uid: patient.record.uid,
      label: patient.record.label,
      data: {
        sourceRef: patient.record.ref,
        treatmentPlanRefs,
        treatmentStepRefs,
      },
      status: DestinationEntityRecordStatus.Migrated,
      migratedAt: toTimestamp(),
    };
  }

  private async _upsertTreatmentPlan(
    migrationData: IPatientTreatmentPlanMigrationData,
    translationMap: TranslationMapHandler
  ): Promise<ITreatmentPlanStepDocPair> {
    const planDestinationRef = await translationMap.getDestination(
      migrationData.sourcePatientId,
      PATIENT_TREATMENT_PLAN_CUSTOM_MAPPING_TYPE
    );

    const planRef = await FirestoreMigrate.upsertDoc(
      TreatmentPlan.col({
        ref: migrationData.patientRef,
      }),
      migrationData.plan,
      planDestinationRef?.id
    );

    if (!planDestinationRef) {
      await translationMap.upsert({
        sourceIdentifier: migrationData.sourcePatientId,
        destinationIdentifier: planRef,
        resourceType: PATIENT_TREATMENT_PLAN_CUSTOM_MAPPING_TYPE,
      });
    }

    const stepRefs = await asyncForEach(migrationData.steps, async (step) => {
      const stepDestinationRef = await translationMap.getDestination(
        step.sourceIdentifier,
        PATIENT_TREATMENT_STEP_CUSTOM_MAPPING_TYPE
      );

      const stepRef = await FirestoreMigrate.upsertDoc(
        TreatmentPlan.treatmentStepCol({
          ref: planRef,
        }),
        step,
        stepDestinationRef?.id
      );

      if (!stepDestinationRef) {
        await translationMap.upsert({
          sourceIdentifier: step.sourceIdentifier,
          destinationIdentifier: stepRef,
          resourceType: PATIENT_TREATMENT_STEP_CUSTOM_MAPPING_TYPE,
        });
      }

      await this._updateAppointmentPlanSteps(
        migrationData,
        translationMap,
        planDestinationRef
      );

      return stepRef;
    });

    await FirestoreMigrate.patchDoc(planRef, {
      steps: stepRefs,
    });

    return { planRef, stepRefs };
  }

  private async _updateAppointmentPlanSteps(
    migrationData: IPatientTreatmentPlanMigrationData,
    translationMap: TranslationMapHandler,
    planDestinationRef: DocumentReference<unknown> | undefined
  ): Promise<void> {
    const planUid = `${migrationData.sourcePatientId}-appointments`;
    const appointmentPlanRef =
      await translationMap.getDestination<ITreatmentPlan>(
        planUid,
        PATIENT_TREATMENT_PLAN_CUSTOM_MAPPING_TYPE
      );

    if (appointmentPlanRef) {
      const appointmentPlanSteps = await snapshot(
        from(Firestore.getDoc(appointmentPlanRef)).pipe(
          switchMap((plan) => TreatmentPlan.orderedSteps$(plan))
        )
      );

      await FirestoreMigrate.patchDoc(appointmentPlanRef, {
        steps: appointmentPlanSteps.map((step) => step.ref),
      });

      await FirestoreMigrate.upsertDoc(
        TreatmentPlan.col({
          ref: migrationData.patientRef,
        }),
        migrationData.plan,
        planDestinationRef?.id
      );
    }
  }

  private async _buildTreatmentPlanData(
    translationMap: TranslationMapHandler,
    data: IPatientTreatmentPlanJobData,
    procedures: IGetRecordResponse<
      IPraktikaAppointmentProcedure,
      IPraktikaAppointmentProcedureTranslations,
      IPraktikaAppointmentProcedureFilters
    >[],
    appointments: IGetRecordResponse<
      IPraktikaAppointment,
      IPraktikaAppointmentTranslations
    >[],
    migration: WithRef<IPracticeMigration>,
    sourcePatientId: string
  ): Promise<ITreatmentPlanStepPair> {
    const appointmentProcedures = procedures.filter(
      (procedure) => procedure.record.filters.isScheduled
    );

    const unscheduledStepProcedures = groupBy(
      procedures.filter(
        (procedure) => procedure.record.filters.isUnscheduledAcceptedTreatment
      ),
      (procedure) => procedure.data.data.visit_number
    );

    const defaultFeeSchedule = await FeeSchedule.getPreferredOrDefault(
      migration.configuration.organisation
    );

    const scheduledSteps = await asyncForEach(
      appointments,
      async (appointment) => {
        const stepName = appointment.data.translations.from
          ? toMomentTz(
              appointment.data.translations.from,
              migration.configuration.timezone
            ).format(HISTORY_DATE_FORMAT)
          : '';

        const practitionerMap = data.staff.find((staffer) => {
          if (!staffer.sourceIdentifier) {
            return false;
          }
          const comparisonStaffer =
            appointment.data.data.appointment_providerid?.toString()
              ? appointment.data.data.appointment_providerid.toString()
              : data.sourcePatient.data.data.patient_preferredproviderid?.toString();
          return staffer.sourceIdentifier === comparisonStaffer;
        });

        if (!practitionerMap?.destinationIdentifier) {
          throw new Error(
            `No practitioner with id ${
              appointment.data.data.appointment_providerid?.toString() ?? ''
            }`
          );
        }

        const practitioner = data.practitioners.find((practitionerSearch) =>
          isSameRef(practitionerSearch, practitionerMap.destinationIdentifier)
        );

        if (!practitioner) {
          throw new Error(
            `Can't find practitioner for ref ${practitionerMap.destinationIdentifier.id}`
          );
        }

        const treatments = await getChartedTreatments(
          appointmentProcedures
            .filter(
              (procedure) =>
                procedure.data.data.appointment_id ===
                appointment.data.data.appointment_id
            )
            .map((procedure) => procedure.data.data),
          practitioner,
          resolveFeeSchedule(translationMap, defaultFeeSchedule),
          data,
          migration.configuration.timezone
        );

        const step = TreatmentStep.init({
          name: stepName,
          status: getTreatmentStepStatus(
            appointment.data.data,
            appointment.data.translations,
            migration.configuration.backupDate,
            migration.configuration.timezone
          ),
          treatments,
          schedulingRules: {
            duration: appointment.data.data.appointment_duration * 15,
          },
        });

        const treatmentCategory =
          await CalculateTreatmentWeight.getPrimaryCategory(
            step,
            data.treatmentCategories
          );

        return {
          createdAt: appointment.data.translations.from ?? toTimestamp(),
          sourceIdentifier: `${sourcePatientId}-${appointment.data.data.appointment_id}`,
          ...step,
          display: {
            ...step.display,
            primaryTreatmentCategory: treatmentCategory?.ref,
          },
        };
      }
    );

    const unscheduledSteps = await asyncForEach(
      Object.values(unscheduledStepProcedures),
      async (stepProcedures) => {
        const stepName = `Visit ${
          first(stepProcedures)?.data.data.visit_number ?? ''
        }`;
        const createdAt =
          first(stepProcedures)?.data.translations.createdDate ?? toTimestamp();
        const providerId = first(stepProcedures)?.data.data.provider_id;

        const practitionerMap = data.staff.find((staffer) => {
          if (!staffer.sourceIdentifier) {
            return false;
          }
          const comparisonStaffer = providerId?.toString()
            ? providerId.toString()
            : data.sourcePatient.data.data.patient_preferredproviderid?.toString();
          return staffer.sourceIdentifier === comparisonStaffer;
        });

        if (!practitionerMap?.destinationIdentifier) {
          throw new Error(
            `No practitioner with id ${providerId?.toString() ?? ''}`
          );
        }

        const practitioner = data.practitioners.find((practitionerSearch) =>
          isSameRef(practitionerSearch, practitionerMap.destinationIdentifier)
        );

        if (!practitioner) {
          throw new Error(
            `Can't find practitioner for ref ${practitionerMap.destinationIdentifier.id}`
          );
        }

        const treatments = await getChartedTreatments(
          stepProcedures.map((procedure) => procedure.data.data),
          practitioner,
          resolveFeeSchedule(translationMap, defaultFeeSchedule),
          data,
          migration.configuration.timezone
        );

        if (!treatments.length) {
          return;
        }

        const step = TreatmentStep.init({
          name: stepName,
          status: TreatmentStepStatus.Incomplete,
          treatments,
        });

        const treatmentCategory =
          await CalculateTreatmentWeight.getPrimaryCategory(
            step,
            data.treatmentCategories
          );

        return {
          createdAt,
          sourceIdentifier: `${sourcePatientId}-${kebabCase(stepName)}`,
          ...step,
          display: {
            ...step.display,
            primaryTreatmentCategory: treatmentCategory?.ref,
          },
        };
      }
    );

    const steps = [...scheduledSteps, ...compact(unscheduledSteps)];

    const status = getPlanStatus(scheduledSteps.map((step) => step.status));

    const plan = TreatmentPlan.init({
      name: `Praktika - Migrated Treatments`,
      status,
    });

    return {
      plan,
      steps,
    };
  }

  private _buildErrorResponse(
    patient: IGetRecordResponse<
      IPraktikaPatient,
      IPraktikaPatientTranslations,
      IPraktikaPatientFilters
    >,
    errorMessage?: string
  ): IDestinationEntityRecord & FailedDestinationEntityRecord {
    return {
      uid: patient.record.uid,
      label: patient.record.label,
      status: DestinationEntityRecordStatus.Failed,
      errorMessage:
        errorMessage ?? 'Missing required properties for treatment plan',
      failData: {
        patientRef: patient.record.ref,
      },
    };
  }
}

export interface ITreatmentPlanStepPair {
  plan: ITreatmentPlan;
  steps: (ITreatmentStep & IHasSourceIdentifier & { createdAt: Timestamp })[];
}

export interface ITreatmentPlanStepDocPair {
  planRef: DocumentReference<ITreatmentPlan>;
  stepRefs: DocumentReference<ITreatmentStep>[];
}

export function getPlanStatus(
  stepStatuses: TreatmentStepStatus[]
): TreatmentPlanStatus {
  if (!stepStatuses.length) {
    return TreatmentPlanStatus.Draft;
  }

  return stepStatuses.filter(
    (stepStatus) => stepStatus === TreatmentStepStatus.Complete
  ).length
    ? TreatmentPlanStatus.InProgress
    : TreatmentPlanStatus.Completed;
}

export async function getChartedTreatments(
  procedures: IPraktikaAppointmentProcedure[],
  practitioner: WithRef<IStaffer>,
  getPreferredOrDefaultRef: (
    feeSheduleId?: string
  ) => Promise<INamedDocument<IFeeSchedule>>,
  data: Pick<
    IPatientTreatmentPlanJobData,
    | 'defaultTreatmentConfiguration'
    | 'sourceItemCodes'
    | 'treatmentConfigurationMappings'
    | 'treatmentConfigurations'
  >,
  timezone: Timezone
): Promise<IChartedTreatment[]> {
  const treatments: IChartedTreatment[] = [];

  await asyncForEach(procedures, async (procedure) => {
    const code = resolveMappedCode(data.sourceItemCodes, procedure);

    if (!code) {
      throw new Error(
        `Code ${procedure.code || procedure.ada_code} couldn't be resolved`
      );
    }

    if (code === ItemCodeResourceMapType.Omit) {
      return;
    }

    const feeSchedule = await getPreferredOrDefaultRef(
      procedure.fee_schedule_id?.toString()
    );

    const taxStatus = procedure.has_gst
      ? TaxStrategy.GSTApplicable
      : TaxStrategy.GSTFree;
    const price = roundTo2Decimals(parseFloat(procedure.total_fee));

    const chartedSurfaces = getChartedRefs({
      ...procedure,
      toothNumber: procedure.tooth_number,
    }).map((chartedRef) =>
      ChartedSurface.init({
        chartedBy: stafferToNamedDoc(practitioner),
        chartedAt: getChartedAt(procedure, timezone),
        resolvedBy: getResolvedBy(procedure, practitioner),
        resolvedAt: getResolvedAt(procedure, timezone),
        chartedRef,
      })
    );

    const scopeResolver = new ChartedItemScopeResolver();

    const notes = procedure.note
      ? [
          ClinicalNote.init({
            owner: stafferToNamedDoc(practitioner),
            content: initVersionedSchema(procedure.note),
            createdAt: getChartedAt(procedure, timezone),
            recordDate: getRecordedAt(procedure, timezone),
          }),
        ]
      : [];

    if (isINamedDocument<ITreatmentConfiguration>(code)) {
      const treatmentConfiguration = data.treatmentConfigurations.find(
        (config) => isSameRef(config, code)
      );

      if (!treatmentConfiguration) {
        throw new Error(
          `Treatment configuration ${code.name} not found in treatment configurations`
        );
      }

      const scopeRefs = scopeResolver.reduceChartedSurfacesToScope(
        treatmentConfiguration,
        chartedSurfaces
      );
      const scopeRef = first(scopeRefs)?.scopeRef ?? {
        scope: ChartableSurface.WholeMouth,
      };

      treatments.push(
        ChartedTreatment.init({
          basePrice: price,
          config: code,
          feeSchedule,
          serviceCodes: [],
          chartedSurfaces,
          notes,
          scopeRef,
          resolvedBy: stafferToNamedDoc(practitioner),
          resolvedAt: getResolvedAt(procedure, timezone),
        })
      );
      return;
    }

    // TODO: Need to attribute the correct provider to the treatment note
    // const owner = procedure.providerId

    const serviceCode = PricedServiceCodeEntry.init({
      code: code.code,
      type: code.type,
      taxStatus,
      price,
      tax: determineTaxFromStrategy(TaxRate.GST, {
        taxStatus: taxStatus,
        amount: price,
      }),
      chartedSurfaces,
    });

    const itemCodeId = procedure.code.toString();
    const treatmentConfiguration = resolveMappedTreatmentConfiguration(
      itemCodeId,
      data.treatmentConfigurationMappings,
      data.defaultTreatmentConfiguration,
      data.treatmentConfigurations
    );

    const scopeRefs = scopeResolver.reduceChartedSurfacesToScope(
      treatmentConfiguration,
      chartedSurfaces
    );
    const scopeRef = first(scopeRefs)?.scopeRef ?? {
      scope: ChartableSurface.WholeMouth,
    };

    treatments.push(
      ChartedTreatment.init({
        config: toNamedDocument(treatmentConfiguration),
        feeSchedule,
        serviceCodes: [serviceCode],
        chartedSurfaces,
        notes,
        scopeRef,
        resolvedBy: stafferToNamedDoc(practitioner),
        resolvedAt: getResolvedAt(procedure, timezone),
      })
    );
  });

  return treatments;
}

export function getChartedRefs(procedure: {
  toothNumber: ToothNumber | null;
  surfaces?: PraktikaToothSurfaces | null;
}): Partial<IChartedRef>[] {
  const quadrant = procedure.toothNumber
    ? getQuadrant(procedure.toothNumber)
    : undefined;
  const quadrantIndex = procedure.toothNumber
    ? getQuadrantIndex(procedure.toothNumber)
    : undefined;
  if (!procedure.toothNumber || !quadrant || !quadrantIndex) {
    return [
      {
        unscoped: true,
      },
    ];
  }

  const toothRef: IToothRef = {
    quadrant,
    quadrantIndex,
  };

  if (!procedure.surfaces) {
    return [
      {
        tooth: toothRef,
      },
    ];
  }

  return procedure.surfaces.split('').map((individualSurface) => {
    const surface =
      TOOTH_SURFACES_MAP[individualSurface as PraktikaToothSurfaces];

    return {
      tooth: {
        quadrant,
        quadrantIndex,
        surface,
      },
    };
  });
}

export function getChartedAt(
  procedure: IPraktikaAppointmentProcedure,
  timezone: Timezone
): Timestamp | undefined {
  return procedure.accepted_date
    ? toTimestamp(
        moment
          .tz(procedure.accepted_date, ISO_DATE_FORMAT, timezone)
          .startOf('day')
      )
    : undefined;
}

export function getRecordedAt(
  procedure: IPraktikaAppointmentProcedure,
  timezone: Timezone
): ISODateType {
  return procedure.accepted_date
    ? toISODate(moment.tz(procedure.accepted_date, ISO_DATE_FORMAT, timezone))
    : toISODate(
        moment.tz(procedure.created_date, ISO_DATE_TIME_FORMAT, timezone)
      );
}

export function getResolvedAt(
  procedure: IPraktikaAppointmentProcedure,
  timezone: Timezone
): Timestamp | undefined {
  return procedure.completed_date
    ? toTimestamp(
        moment
          .tz(procedure.completed_date, ISO_DATE_FORMAT, timezone)
          .startOf('day')
      )
    : undefined;
}

export function getResolvedBy(
  procedure: IPraktikaAppointmentProcedure,
  practitioner: WithRef<IStaffer>
): INamedDocument<IStaffer> | undefined {
  return procedure.completed_date ? stafferToNamedDoc(practitioner) : undefined;
}

export function getTreatmentStepStatus(
  appointment: IPraktikaAppointment,
  translations: IPraktikaAppointmentTranslations,
  backupDate: ISODateType,
  timezone: Timezone
): TreatmentStepStatus {
  const appointmentStatus = getAppointmentStatus(
    appointment,
    translations,
    backupDate,
    timezone
  );
  switch (appointmentStatus) {
    case AppointmentStatus.Arrived:
    case AppointmentStatus.CheckedIn:
    case AppointmentStatus.InProgress:
    case AppointmentStatus.CheckingOut:
      return TreatmentStepStatus.Current;
    case AppointmentStatus.Complete:
      return TreatmentStepStatus.Complete;
    default:
      return TreatmentStepStatus.Incomplete;
  }
}

export function getAppointmentStatus(
  appointment: IPraktikaAppointment,
  translations: IPraktikaAppointmentTranslations,
  backupDate: ISODateType,
  timezone: Timezone
): AppointmentStatus {
  const isAfterBackup =
    translations.from &&
    toMomentTz(translations.from, timezone).isAfter(
      toMomentTz(backupDate, timezone).endOf('day')
    );

  const isFta =
    appointment.appointment_arrivalstatusid === PraktikaInAppointmentStatus.Fta;
  if (isFta) {
    return AppointmentStatus.Cancelled;
  }

  if (isAfterBackup) {
    const isArrivedStatus =
      appointment.appointment_arrivalstatusid ===
        PraktikaInAppointmentStatus.Arrived &&
      [
        PraktikaAppointmentStatus.Scheduled,
        PraktikaAppointmentStatus.Modified,
      ].includes(appointment.appointment_statusid);

    if (isArrivedStatus) {
      return AppointmentStatus.Arrived;
    }

    const inProgressStatus =
      appointment.appointment_arrivalstatusid ===
        PraktikaInAppointmentStatus.AtSurgery &&
      [
        PraktikaAppointmentStatus.Scheduled,
        PraktikaAppointmentStatus.Modified,
      ].includes(appointment.appointment_statusid);

    if (inProgressStatus) {
      return AppointmentStatus.InProgress;
    }

    return AppointmentStatus.Scheduled;
  }

  const isCompleteStatus = [
    PraktikaAppointmentStatus.Completed,
    PraktikaAppointmentStatus.PaidInFull,
  ].includes(appointment.appointment_statusid);

  if (isCompleteStatus) {
    return AppointmentStatus.Complete;
  }

  return AppointmentStatus.Cancelled;
}
