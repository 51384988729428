import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';
import { OrganisationService } from '@principle-theorem/ng-principle-shared';
import {
  ChartItemDisplayType,
  CHART_ITEM_DISPLAY_TYPES,
  IBrand,
} from '@principle-theorem/principle-core/interfaces';
import { WithRef, filterUndefined } from '@principle-theorem/shared';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

interface IChartFilterGroup {
  label: string;
  displayTypes: ChartItemDisplayType[];
}

@Component({
  selector: 'pr-chart-filters-selector',
  templateUrl: './chart-filters-selector.component.html',
  styleUrls: ['./chart-filters-selector.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartFiltersSelectorComponent {
  baseFilterGroups = [
    {
      label: 'Conditions',
      displayTypes: [
        ChartItemDisplayType.CurrentCondition,
        ChartItemDisplayType.ResolvedCondition,
      ],
    },
    {
      label: 'Treatments',
      displayTypes: [
        ChartItemDisplayType.ProposedTreatment,
        ChartItemDisplayType.ExistingTreatment,
        ChartItemDisplayType.CompletedTreatment,
      ],
    },
  ];

  filterGroups$: Observable<IChartFilterGroup[]>;
  filters$ = new BehaviorSubject<ChartItemDisplayType[]>(
    CHART_ITEM_DISPLAY_TYPES
  );

  @Output() selectionChange = new EventEmitter<ChartItemDisplayType[]>();

  constructor(private _organisation: OrganisationService) {
    this.filterGroups$ = this._organisation.brand$.pipe(
      filterUndefined(),
      map((brand) => this._getFilterGroups(brand))
    );
  }

  select(filter: ChartItemDisplayType, event: MouseEvent): void {
    event.stopPropagation();
    const currentFilters = this.filters$.value;
    const index = currentFilters.indexOf(filter);
    if (index < 0) {
      this.filters$.next([...currentFilters, filter]);
    } else {
      this.filters$.next(
        currentFilters.filter((current) => current !== filter)
      );
    }
    this.selectionChange.emit(this.filters$.value);
  }

  isSelected$(filter: ChartItemDisplayType): Observable<boolean> {
    return this.filters$.pipe(map((filters) => filters.includes(filter)));
  }

  private _getFilterGroups(brand: WithRef<IBrand>): IChartFilterGroup[] {
    if (!brand.settings.displayMigratedChartFilter) {
      return this.baseFilterGroups;
    }

    return this.baseFilterGroups.map((group) =>
      group.label === 'Treatments'
        ? {
            ...group,
            displayTypes: [
              ...group.displayTypes,
              ChartItemDisplayType.MigratedCompletedTreatment,
            ],
          }
        : group
    );
  }
}
