import {
  ClinicalNote,
  hasMergeConflicts,
} from '@principle-theorem/principle-core';
import {
  DestinationEntityRecordStatus,
  FailedDestinationEntityRecord,
  IHasSourceIdentifier,
  IMigratedDataSummary,
  ISourceEntityHandler,
  ITranslationMap,
  MergeConflictDestinationEntityRecord,
  type IClinicalNote,
  type IDestinationEntity,
  type IDestinationEntityRecord,
  type IGetRecordResponse,
  type IPatient,
  type IPracticeMigration,
  type ISourceEntityRecord,
  type IStaffer,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  Firestore,
  asyncForEach,
  getError,
  safeCombineLatest,
  toTimestamp,
  type WithRef,
} from '@principle-theorem/shared';
import { Observable, combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslationMapHandler } from '../../translation-map';
import { BaseDestinationEntity } from '../base-destination-entity';
import { FirestoreMigrate } from '../destination';
import { DestinationEntity } from '../destination-entity';
import { PatientIdFilter } from '../filters/patient-id-filter';
import { ItemCodeResourceMapType } from '../../mappings/item-codes-to-xlsx';

export const PATIENT_CLINICAL_NOTE_CUSTOM_MAPPING_TYPE = 'patientClinicalNote';

export const PATIENT_CLINICAL_NOTE_DESTINATION_ENTITY = DestinationEntity.init({
  metadata: {
    key: PATIENT_CLINICAL_NOTE_CUSTOM_MAPPING_TYPE,
    label: 'Patient Clinical Notes',
    description: ``,
  },
});

interface IClinicalNoteSuccessData {
  sourceRef: DocumentReference<ISourceEntityRecord>;
  clinicalNoteRefs: DocumentReference<IClinicalNote>[];
}

export interface IClinicalNoteJobData<PatientRecord extends object> {
  sourcePatient: IGetRecordResponse<PatientRecord>;
  staff: WithRef<ITranslationMap<IStaffer>>[];
  sourceItemCodes: WithRef<ITranslationMap<object, ItemCodeResourceMapType>>[];
}

export interface IClinicalNoteMigrationData {
  sourcePatientId: string;
  patientRef: DocumentReference<IPatient>;
  clinicalNotes: (IClinicalNote & IHasSourceIdentifier)[];
}

export abstract class BasePatientClinicalNoteDestinationEntity<
  PatientRecord extends object,
> extends BaseDestinationEntity<
  IClinicalNoteSuccessData,
  IClinicalNoteJobData<PatientRecord>,
  IClinicalNoteMigrationData
> {
  destinationEntity = PATIENT_CLINICAL_NOTE_DESTINATION_ENTITY;
  abstract patientSourceEntity: ISourceEntityHandler<PatientRecord[]>;
  override canMigrateByIdRange = true;

  get sourceCountComparison(): ISourceEntityHandler<PatientRecord[]> {
    return this.patientSourceEntity;
  }

  override filters = [
    new PatientIdFilter<IClinicalNoteJobData<PatientRecord>>((jobData) =>
      this.patientSourceEntity
        .getSourceRecordId(jobData.sourcePatient.data.data)
        .toString()
    ),
  ];

  sourceCountDataAccessor(
    data: IClinicalNoteJobData<PatientRecord>
  ): DocumentReference<ISourceEntityRecord> {
    return data.sourcePatient.record.ref;
  }

  getDestinationEntityRecordUid(
    data: IClinicalNoteJobData<PatientRecord>
  ): string {
    return data.sourcePatient.record.uid;
  }

  getMigratedData$(
    record: IDestinationEntityRecord<IClinicalNoteSuccessData>
  ): Observable<IMigratedDataSummary[]> {
    if (record.status !== DestinationEntityRecordStatus.Migrated) {
      return of([]);
    }

    return combineLatest([
      Firestore.doc$(record.data.sourceRef),
      safeCombineLatest(
        record.data.clinicalNoteRefs.map((clinicalNoteRef) =>
          Firestore.doc$(clinicalNoteRef)
        )
      ),
    ]).pipe(
      map(([sourcePatient, clinicalNotes]) => {
        const data: IMigratedDataSummary[] = [
          {
            label: 'Source Patient',
            data: sourcePatient,
          },
        ];
        data.push(
          ...clinicalNotes.map((clinicalNote) => ({
            label: 'Clinical Notes',
            data: clinicalNote,
          }))
        );
        return data;
      })
    );
  }

  async hasMergeConflict(
    translationMap: TranslationMapHandler,
    data: IClinicalNoteMigrationData
  ): Promise<IClinicalNoteMigrationData | undefined> {
    const existingClinicalNotes: (IClinicalNote & IHasSourceIdentifier)[] = [];

    const clinicalNoteMergeConflicts = await asyncForEach(
      data.clinicalNotes,
      async (note) => {
        const noteRef = await translationMap.getDestination<IClinicalNote>(
          note.sourceIdentifier,
          PATIENT_CLINICAL_NOTE_CUSTOM_MAPPING_TYPE
        );
        if (!noteRef) {
          return;
        }

        const clinicalNote = await Firestore.getDoc(noteRef);
        existingClinicalNotes.push({
          ...clinicalNote,
          sourceIdentifier: note.sourceIdentifier,
        });

        return hasMergeConflicts(note, clinicalNote);
      }
    );

    if (clinicalNoteMergeConflicts.some((conflict) => conflict)) {
      return {
        ...data,
        clinicalNotes: existingClinicalNotes,
      };
    }
  }

  buildMergeConflictRecord(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    _translationMap: TranslationMapHandler,
    jobData: IClinicalNoteJobData<PatientRecord>,
    _migrationData: IClinicalNoteMigrationData
  ): IDestinationEntityRecord & MergeConflictDestinationEntityRecord {
    return {
      uid: jobData.sourcePatient.record.uid,
      label: jobData.sourcePatient.record.label,
      status: DestinationEntityRecordStatus.MergeConflict,
    };
  }

  async runJob(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    jobData: IClinicalNoteJobData<PatientRecord>,
    migrationData: IClinicalNoteMigrationData
  ): Promise<IDestinationEntityRecord> {
    try {
      const clinicalNoteRefs = await this._upsertClinicalNotes(
        migrationData.clinicalNotes,
        translationMap,
        migrationData.patientRef
      );

      return this._buildSuccessResponse(
        jobData.sourcePatient,
        clinicalNoteRefs
      );
    } catch (error) {
      return this._buildErrorResponse(
        {
          label: jobData.sourcePatient.record.label,
          uid: jobData.sourcePatient.record.uid,
          ref: jobData.sourcePatient.record.ref,
        },
        getError(error)
      );
    }
  }

  protected _buildSuccessResponse(
    patient: IGetRecordResponse<PatientRecord>,
    clinicalNoteRefs: DocumentReference<IClinicalNote>[]
  ): IDestinationEntityRecord<IClinicalNoteSuccessData> {
    return {
      uid: patient.record.uid,
      label: patient.record.label,
      data: {
        sourceRef: patient.record.ref,
        clinicalNoteRefs,
      },
      status: DestinationEntityRecordStatus.Migrated,
      migratedAt: toTimestamp(),
    };
  }

  protected _buildErrorResponse(
    patient: Pick<IGetRecordResponse['record'], 'label' | 'uid' | 'ref'>,
    errorMessage?: string
  ): IDestinationEntityRecord<IClinicalNoteSuccessData> &
    FailedDestinationEntityRecord {
    return {
      uid: patient.uid,
      label: patient.label,
      status: DestinationEntityRecordStatus.Failed,
      errorMessage: errorMessage ?? `Can't resolve data to generate charts`,
      failData: {
        patientRef: patient.ref,
      },
    };
  }
  private async _upsertClinicalNotes(
    clinicalNotes: (IClinicalNote & IHasSourceIdentifier)[],
    translationMap: TranslationMapHandler,
    patientRef: DocumentReference<IPatient>
  ): Promise<DocumentReference<IClinicalNote>[]> {
    return asyncForEach(clinicalNotes, async (clinicalNote) => {
      const noteDestinationRef = await translationMap.getDestination(
        clinicalNote.sourceIdentifier,
        PATIENT_CLINICAL_NOTE_CUSTOM_MAPPING_TYPE
      );

      const noteRef = await FirestoreMigrate.upsertDoc(
        ClinicalNote.col({
          ref: patientRef,
        }),
        clinicalNote,
        noteDestinationRef?.id
      );

      if (!noteDestinationRef) {
        await translationMap.upsert({
          sourceIdentifier: clinicalNote.sourceIdentifier,
          destinationIdentifier: noteRef,
          resourceType: PATIENT_CLINICAL_NOTE_CUSTOM_MAPPING_TYPE,
        });
      }
      return noteRef;
    });
  }
}
