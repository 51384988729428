<div class="page-container">
  <pr-form-skeleton *ngIf="store.loading$ | async" />

  <pr-empty-state
    *ngIf="store.emptyState$ | async"
    image="list"
    title="Patient Details Forms"
  />

  <ng-container *ngIf="store.allForms$ | async as forms">
    <ng-container *ngIf="forms.length">
      <mat-form-field>
        <mat-label>History</mat-label>
        <mat-select [formControl]="formSelectCtrl" [compareWith]="compareFn">
          <mat-option
            *ngFor="let form of forms; trackBy: trackByForm"
            [value]="form"
          >
            {{ form.form.date | moment | amDateFormat: dateFormat }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <ng-container *ngIf="store.patient$ | async as patient">
        <mat-card appearance="outlined" class="no-padding">
          <mat-card-content>
            <div
              fxLayout="row"
              fxLayoutAlign="start center"
              fxLayoutGap="16px"
              class="layout-padding"
            >
              <small class="mat-caption">
                Patient Details {{ formSelectCtrl.value.status | titlecase }}
                {{ formSelectCtrl.value.updatedAt | moment | amTimeAgo }}
              </small>
              <span fxFlex></span>
              <button
                *ngIf="(store.disabled$ | async) === false"
                mat-flat-button
                color="primary"
                [disabled]="patientFormDetails.form.invalid"
                (click)="confirmPatientDetails()"
              >
                Confirm & Update Patient
              </button>
            </div>

            <mat-divider />

            <div fxLayout="row" fxLayoutGap="16px">
              <div class="patient-details">
                <h4 class="mat-body-strong layout-padding">
                  Current Patient Details
                </h4>
                <mat-list dense>
                  <mat-list-item>
                    <div fxLayout="column" fxLayoutGap="8px">
                      <small>Full Name</small>
                      {{ patient.name }}
                    </div>
                  </mat-list-item>
                  <mat-list-item>
                    <div fxLayout="column" fxLayoutGap="8px">
                      <small>Gender</small>
                      {{ patient.gender | titlecase }}
                    </div>
                  </mat-list-item>
                  <mat-list-item>
                    <div fxLayout="column" fxLayoutGap="8px">
                      <small>Date of birth</small>
                      {{
                        patient.dateOfBirth
                          | moment
                          | amDateFormat: dateOfBirthFormat
                      }}
                    </div>
                  </mat-list-item>
                  <mat-list-item>
                    <div fxLayout="column" fxLayoutGap="8px">
                      <small>Address</small>
                      {{ patient.address }}
                    </div>
                  </mat-list-item>
                  <mat-list-item>
                    <div fxLayout="column" fxLayoutGap="8px">
                      <small>Email</small>
                      {{ patient.email }}
                    </div>
                  </mat-list-item>
                  <mat-list-item
                    *ngFor="
                      let contactNumber of patient.contactNumbers;
                      trackBy: trackByIndex
                    "
                  >
                    <div fxLayout="column" fxLayoutGap="8px">
                      <small>{{ contactNumber.label }}</small>
                      {{ contactNumber.number }}
                    </div>
                  </mat-list-item>
                  <ng-container *prIsRegion="[Region.Australia]">
                    <ng-container *ngIf="patient.healthFundCard as healthFund">
                      <mat-list-item class="!-mb-1">
                        Health Fund Card
                      </mat-list-item>
                      <mat-list-item
                        *ngIf="healthFund.fundCode as fundCode"
                        class="!mb-1"
                      >
                        <div class="flex flex-col">
                          <small>Health Fund Name</small>
                          {{ fundCode }}
                        </div>
                      </mat-list-item>
                      <mat-list-item class="!mb-1">
                        <div class="flex flex-col">
                          <small>Membership Number</small>
                          {{ patient.healthFundCard.membershipNumber }}
                        </div>
                      </mat-list-item>
                      <mat-list-item>
                        <div class="flex flex-col">
                          <small>Member Number</small>
                          {{ patient.healthFundCard.memberNumber }}
                        </div>
                      </mat-list-item>
                    </ng-container>
                    <ng-container *ngIf="patient.medicareCard as medicareCard">
                      <mat-list-item class="!-mb-1"
                        >Medicare Card</mat-list-item
                      >
                      <mat-list-item class="!mb-1">
                        <div class="flex flex-col">
                          <small>Card Number</small>
                          {{ patient.medicareCard.number }}
                        </div>
                      </mat-list-item>
                      <mat-list-item class="!mb-1">
                        <div class="flex flex-col">
                          <small>Patient IRN</small>
                          {{ patient.medicareCard.subNumerate }}
                        </div>
                      </mat-list-item>
                      <mat-list-item>
                        <div class="flex flex-col">
                          <small>Expiry Date</small>
                          {{
                            patient.medicareCard.expiryDate
                              | moment
                              | amDateFormat: expiryFormat
                          }}
                        </div>
                      </mat-list-item>
                    </ng-container>
                  </ng-container>
                  <ng-container *prIsRegion="[Region.NewZealand]">
                    <mat-list-item class="!mb-1">
                      <div class="flex flex-col">
                        <small>NHI Number</small>
                        {{ patient.nationalHealthIndexNumber }}
                      </div>
                    </mat-list-item>
                  </ng-container>
                </mat-list>
              </div>

              <div fxFlex>
                <h4 class="mat-body-strong layout-padding">
                  Submitted Patient Details
                </h4>
                <pr-patient-details-form-compare
                  #patientFormDetails
                  [ngClass]="{ disabled: store.disabled$ | async }"
                  class="layout-padding"
                  [patient]="store.patient$ | async"
                  [patientDetails]="formSelectCtrl.value.form.data"
                  (patientDetailsChange)="patientDetailsFormData$.next($event)"
                />
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
