import {
  ICoordinate,
  IPrintOptions,
} from '@principle-theorem/principle-core/interfaces';
import { jsPDF, jsPDFOptions } from 'jspdf';

export function createDocument(options?: jsPDFOptions): jsPDF {
  const pdfOptions: jsPDFOptions = {
    orientation: 'portrait',
    unit: 'mm',
    format: 'a4',
    ...options,
  };

  const doc = new jsPDF(pdfOptions);
  doc.setFontSize(8);
  return doc;
}

export function printText(
  doc: jsPDF,
  text: string,
  coordinates: ICoordinate,
  options: IPrintOptions = { fontStyle: 'normal' }
): void {
  const { fontStyle = 'normal', ...restOptions } = options;
  doc.setFont('helvetica', fontStyle);
  doc.text(text, coordinates.x, coordinates.y, restOptions);
}

export function printTextInColumns(
  doc: jsPDF,
  text: string,
  xPositions: number[],
  yPos: number,
  options: IPrintOptions = { fontStyle: 'normal' }
): void {
  const { fontStyle = 'normal', ...restOptions } = options;
  doc.setFont('helvetica', fontStyle);
  xPositions.forEach((xPos) => doc.text(text, xPos, yPos, restOptions));
}

export function splitTextToLines(
  doc: jsPDF,
  text: string,
  maxWidth: number,
  isBold: boolean = false
): string[] {
  const words = text.split(' ');
  const lines = [];
  let currentLine = '';

  if (isBold) {
    doc.setFont('helvetica', 'bold');
  }

  words.forEach((word) => {
    const line = currentLine + word + ' ';
    const width = doc.getTextWidth(line);
    if (width > maxWidth) {
      lines.push(currentLine.trim());
      currentLine = word + ' ';
    } else {
      currentLine = line;
    }
  });

  lines.push(currentLine.trim());
  doc.setFont('helvetica', 'normal');

  return lines;
}

export function addWatermark(
  doc: jsPDF,
  text: string,
  angle: number,
  imageSize = 200
): void {
  const scale = 0.5;
  const watermarkImage = createWatermarkImage(text, angle, scale);

  if (watermarkImage) {
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();

    doc.addImage(
      watermarkImage,
      'PNG',
      (pageWidth - imageSize) / 2,
      (pageHeight - imageSize * 1.5) / 2,
      imageSize,
      imageSize
    );
  }
}

export function createWatermarkImage(
  text: string,
  angle: number,
  scale: number
): string {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  if (!context) {
    return '';
  }

  canvas.width = 2000 * scale;
  canvas.height = 2000 * scale;
  context.font = `${200 * scale}px Helvetica`;
  context.fillStyle = 'rgba(244, 67, 54, 0.2)';
  context.textAlign = 'center';
  context.textBaseline = 'middle';

  context.translate(canvas.width / 2, canvas.height / 2);
  context.rotate((angle * Math.PI) / -180);
  context.fillText(text, 0, 0);

  return canvas.toDataURL('image/png');
}
