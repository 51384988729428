import {
  Brand,
  Contact,
  hasMergeConflicts,
} from '@principle-theorem/principle-core';
import {
  DestinationEntityRecordStatus,
  FailedDestinationEntityRecord,
  IMigratedDataSummary,
  MergeConflictDestinationEntityRecord,
  SourceEntityRecordStatus,
  type IBrand,
  type IContact,
  type IDestinationEntity,
  type IDestinationEntityRecord,
  type IGetRecordResponse,
  type IPracticeMigration,
  type IReferralSourceConfiguration,
  type ISourceEntityRecord,
  isContactDetails,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  asDocRef,
  doc$,
  getDoc,
  toTimestamp,
  type WithRef,
} from '@principle-theorem/shared';
import { Observable, of } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import { BaseDestinationEntity } from '../../../destination/base-destination-entity';
import { FirestoreMigrate } from '../../../destination/destination';
import { DestinationEntity } from '../../../destination/destination-entity';
import { PracticeMigration } from '../../../practice-migrations';
import { buildSkipMigratedQuery } from '../../../source/source-entity-record';
import { TranslationMapHandler } from '../../../translation-map';
import {
  CONTACT_RESOURCE_TYPE,
  ContactsSourceEntity,
  type IExactContact,
} from '../../source/entities/contacts';

export const CONTACT_DESTINATION_ENTITY = DestinationEntity.init({
  metadata: {
    key: 'contacts',
    label: 'Contacts',
    description: '',
  },
});

export interface IContactDestinationRecord {
  sourceRef: DocumentReference<ISourceEntityRecord<object>>;
  contactRef: DocumentReference<IContact | IReferralSourceConfiguration>;
}

export interface IContactErrorData {
  sourceRef: DocumentReference<ISourceEntityRecord<object>>;
}

export interface IContactMigrationData {
  sourceContactId: string;
  contact: IContact;
}

export interface IContactJobData {
  sourceContact: IGetRecordResponse<IExactContact>;
  brand: WithRef<IBrand>;
}

export class ContactDestinationEntity extends BaseDestinationEntity<
  IContactDestinationRecord,
  IContactJobData,
  IContactMigrationData
> {
  destinationEntity = CONTACT_DESTINATION_ENTITY;

  override sourceEntities = {
    contacts: new ContactsSourceEntity(),
  };

  sourceCountComparison = new ContactsSourceEntity();

  sourceCountDataAccessor(
    data: IContactJobData
  ): DocumentReference<ISourceEntityRecord> {
    return data.sourceContact.record.ref;
  }

  getMigratedData$(
    record: IDestinationEntityRecord<IContactDestinationRecord>
  ): Observable<IMigratedDataSummary[]> {
    if (record.status !== DestinationEntityRecordStatus.Migrated) {
      return of([]);
    }

    return doc$(record.data.contactRef).pipe(
      map((contact) => [
        {
          label: 'Contact',
          data: contact,
        },
      ])
    );
  }

  buildJobData$(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    _transaltionMap: TranslationMapHandler,
    skipMigrated: boolean
  ): Observable<IContactJobData[]> {
    const brand$ = PracticeMigration.brand$(migration);

    return this.sourceEntities.contacts
      .getRecords$(
        migration,
        1000,
        buildSkipMigratedQuery(skipMigrated, this.destinationEntity)
      )
      .pipe(
        withLatestFrom(brand$),
        map(([sourceContacts, brand]) =>
          sourceContacts.map((sourceContact) => ({ sourceContact, brand }))
        )
      );
  }

  buildMigrationData(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    _translationMap: TranslationMapHandler,
    data: IContactJobData
  ):
    | IContactMigrationData
    | (IDestinationEntityRecord & FailedDestinationEntityRecord) {
    if (data.sourceContact.record.status === SourceEntityRecordStatus.Invalid) {
      return this._buildErrorResponse(
        data.sourceContact,
        'Source contact is invalid'
      );
    }

    const contactData = data.sourceContact.data.data;
    const sourceContactId =
      this.sourceEntities.contacts.getSourceRecordId(contactData);

    const contactDetails = {
      name: contactData.name,
      email: contactData.email ?? undefined,
      phone: contactData.workphone ?? contactData.homephone ?? undefined,
      address: getContactAddress(contactData),
      mobileNumber: contactData.mobile ?? undefined,
    };

    if (!isContactDetails(contactDetails)) {
      return this._buildErrorResponse(
        data.sourceContact,
        'Missing Required contact properties'
      );
    }

    return {
      sourceContactId,
      contact: Contact.init(contactDetails),
    };
  }

  async runJob(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    jobData: IContactJobData,
    migrationData: IContactMigrationData
  ): Promise<IDestinationEntityRecord> {
    const existingContactRef = await translationMap.getDestination(
      migrationData.sourceContactId,
      CONTACT_RESOURCE_TYPE
    );

    const contactRef = await FirestoreMigrate.upsertDoc(
      Brand.contactCol(jobData.brand),
      {
        ...migrationData.contact,
        createdAt: toTimestamp(),
      },
      existingContactRef?.id
    );

    if (!existingContactRef) {
      await translationMap.upsert({
        sourceIdentifier: migrationData.sourceContactId,
        destinationIdentifier: contactRef,
        resourceType: CONTACT_RESOURCE_TYPE,
      });
    }

    return this._buildSuccessResponse(jobData.sourceContact, contactRef);
  }

  async hasMergeConflict(
    translationMap: TranslationMapHandler,
    data: IContactMigrationData
  ): Promise<IContactMigrationData | undefined> {
    const existingContactRef = await translationMap.getDestination(
      data.sourceContactId,
      CONTACT_RESOURCE_TYPE
    );
    if (!existingContactRef) {
      return;
    }

    try {
      const existingContact = await getDoc(
        asDocRef<IContact>(existingContactRef)
      );
      const hasMergeConflict = hasMergeConflicts(data.contact, existingContact);
      if (hasMergeConflict) {
        return {
          ...data,
          contact: existingContact,
        };
      }
    } catch (error) {
      //
    }
  }

  buildMergeConflictRecord(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    _translationMap: TranslationMapHandler,
    jobData: IContactJobData,
    _migrationData: IContactMigrationData
  ): IDestinationEntityRecord & MergeConflictDestinationEntityRecord {
    return {
      uid: jobData.sourceContact.record.uid,
      label: jobData.sourceContact.record.label,
      status: DestinationEntityRecordStatus.MergeConflict,
    };
  }

  getDestinationEntityRecordUid(data: IContactJobData): string {
    return data.sourceContact.record.uid;
  }

  private _buildErrorResponse(
    contact: IGetRecordResponse<IExactContact>,
    message?: string
  ): IDestinationEntityRecord & FailedDestinationEntityRecord {
    return {
      uid: contact.record.uid,
      label: contact.record.label,
      status: DestinationEntityRecordStatus.Failed,
      errorMessage: message ?? 'Missing Required contact properties',
      failData: {
        contactRef: contact.record.ref,
      },
    };
  }

  private _buildSuccessResponse(
    record: IGetRecordResponse<IExactContact>,
    contactRef: DocumentReference<IContact | IReferralSourceConfiguration>
  ): IDestinationEntityRecord<IContactDestinationRecord> {
    return {
      uid: record.record.uid,
      label: record.data.data.name,
      data: {
        sourceRef: record.record.ref,
        contactRef,
      },
      status: DestinationEntityRecordStatus.Migrated,
      migratedAt: toTimestamp(),
    };
  }
}

function getContactAddress(contact: IExactContact): string | undefined {
  const address =
    `${contact.address_1} ${contact.address_2} ${contact.city} ${contact.suburb} ${contact.state} ${contact.post_code}`.trim();
  return address.length > 0 ? address : undefined;
}
