import { OrganisationCache, Practice } from '@principle-theorem/principle-core';
import {
  type IBrand,
  type IPractice,
} from '@principle-theorem/principle-core/interfaces';
import { errorNil, type WithRef } from '@principle-theorem/shared';
import { type Observable, of, from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

export class StateBasedRouterLinkFactory {
  constructor(
    private _brand$: Observable<WithRef<IBrand> | undefined>,
    private _practice$: Observable<WithRef<IPractice> | undefined>
  ) {}

  organisation$(segments: string[] = []): Observable<string[]> {
    return of(['/', ...segments]);
  }

  brand$(
    segments: string[] = [],
    brandOverride?: WithRef<IBrand>
  ): Observable<string[]> {
    const brand$ = brandOverride ? of(brandOverride) : this._brand$;
    return brand$.pipe(
      errorNil(),
      map((brand) => [brand.slug, ...segments]),
      switchMap((route) => this.organisation$(route))
    );
  }

  practice$(
    segments: string[] = [],
    practiceOverride?: WithRef<IPractice>
  ): Observable<string[]> {
    const practice$ = practiceOverride ? of(practiceOverride) : this._practice$;
    return practice$.pipe(
      errorNil(),
      switchMap((practice) =>
        of([practice.slug, ...segments]).pipe(
          switchMap((route) =>
            from(
              OrganisationCache.brands.getDoc(Practice.brandRef(practice))
            ).pipe(switchMap((brand) => this.brand$(route, brand)))
          )
        )
      )
    );
  }
}
