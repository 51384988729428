<mat-toolbar color="accent">Print Barcodes</mat-toolbar>

<div class="flex h-full flex-col gap-4 p-4">
  <div class="flex flex-1 items-center justify-between gap-6">
    <form [formGroup]="form" class="flex items-center gap-2">
      <mat-form-field subscriptSizing="dynamic">
        <mat-label>Label Template</mat-label>
        <mat-select formControlName="template">
          @for (template of templates; track template.id) {
            <mat-option [value]="template">{{ template.name }}</mat-option>
          }
        </mat-select>
      </mat-form-field>
      <mat-form-field class="max-w-[80px]" subscriptSizing="dynamic">
        <mat-label>Sheets</mat-label>
        <input
          matInput
          formControlName="quantity"
          type="number"
          min="1"
          autocomplete="off"
        />
      </mat-form-field>
    </form>

    <div class="flex items-center gap-2">
      <button mat-stroked-button (click)="generatePreview()">
        Generate Preview
      </button>
      <button
        mat-flat-button
        color="primary"
        (click)="print()"
        [disabled]="!form.valid"
      >
        Print
      </button>
    </div>
  </div>

  @if (showPreview()) {
    @if (previewDimensions$ | async; as dimensions) {
      <span class="mt-2 font-bold opacity-80">Print Preview</span>
      <div
        class="self-center"
        [style.height.px]="dimensions.height"
        [style.width.px]="dimensions.width"
      >
        <object
          [data]="previewUrl()"
          type="application/pdf"
          height="100%"
          width="100%"
        ></object>
      </div>
    }
  } @else {
    <pr-empty-state title="generated barcodes" image="list" />
  }
</div>
