import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  computed,
  inject,
  signal,
} from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { PrincipleEditorModule } from '@principle-theorem/ng-interactions';
import {
  ContentContainerComponent,
  CurrentBrandScope,
  CurrentPatientScope,
  GlobalStoreService,
  InformationBoxComponent,
  OrganisationService,
  StatusLabelComponent,
} from '@principle-theorem/ng-principle-shared';
import { PipesModule, UserIconComponent } from '@principle-theorem/ng-shared';
import {
  Brand,
  PRESCRIPTION_STATUS_COLOUR_MAP,
  Prescription,
  PrescriptionPrintContext,
} from '@principle-theorem/principle-core';
import {
  IPrescription,
  IPrescriptionItem,
  IStaffer,
  PrescriptionStatus,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  Region,
  SHORT_DATE_TIME_WITH_YEAR_FORMAT,
  WithRef,
  filterUndefined,
  snapshot,
  sortByUpdatedAt,
} from '@principle-theorem/shared';
import { isString } from 'lodash';
import { MomentModule } from 'ngx-moment';
import { Observable, Subject } from 'rxjs';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';
import { PrescriptionFormGroup } from '../patient-prescription/patient-prescription-form';
import { PrescriptionActionsComponent } from '../prescription-actions/prescription-actions.component';
import { PrescriptionItemFormComponent } from '../prescription-item-form/prescription-item-form.component';
import {
  DeletePrescriptonData,
  PrescriptionService,
} from '../prescription.service';

@Component({
  selector: 'pr-patient-prescription-form',
  standalone: true,
  imports: [
    CommonModule,
    PipesModule,
    MomentModule,
    StatusLabelComponent,
    PrescriptionItemFormComponent,
    ContentContainerComponent,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    PrincipleEditorModule,
    MatIconModule,
    MatTooltipModule,
    MatCheckboxModule,
    InformationBoxComponent,
    PrescriptionActionsComponent,
    UserIconComponent,
  ],
  templateUrl: './patient-prescription-form.component.html',
  styleUrl: './patient-prescription-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PatientPrescriptionFormComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  private _currentBrand = inject(CurrentBrandScope);
  private _currentPatient = inject(CurrentPatientScope);
  private _global = inject(GlobalStoreService);
  public organisation = inject(OrganisationService);
  readonly dateFormat = SHORT_DATE_TIME_WITH_YEAR_FORMAT;
  prescription = inject(PrescriptionService);
  form = new PrescriptionFormGroup();
  configPrescriptionItems = toSignal(this._configPrescriptionItems$());
  selected = computed(() => this.prescription.selectedPrescription());
  printWarning = signal<string | undefined>(undefined);
  patientHasAddress = signal(true);
  riskOfOverPrint = signal(false);
  statusHistory = computed(() => this._sortStatusHistory(this.selected()));
  isDisabled = computed(() => this._isDisabled(this.selected()));
  Region = Region;
  @Input() compact = false;
  @Output() deleteChange = new EventEmitter<DeletePrescriptonData>();

  constructor() {
    toObservable(this.isDisabled)
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((isDisabled) => {
        isDisabled ? this.form.disable() : this.form.enable();
      });

    toObservable(this.prescription.selectedPrescription)
      .pipe(filterUndefined(), take(1), takeUntil(this._onDestroy$))
      .subscribe(({ items, ...data }) => {
        items.forEach((item) => this.form.addItem(item));
        this.form.patchValue({ ...data }, { emitEvent: false });
        this.form.markAsPristine();
        if (this._isDisabled({ items, ...data })) {
          this.form.disable();
        }
      });

    this._currentPatient.doc$
      .pipe(filterUndefined(), takeUntil(this._onDestroy$))
      .subscribe((patient) => {
        this.patientHasAddress.set(!!patient.address);
      });

    this.form.valueChanges
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(() =>
        this.riskOfOverPrint.set(this.form.value.items.length > 3)
      );
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  save(): void {
    if (!this.form.valid) {
      return;
    }
    const value = this.form.getRawValue();
    const prescription = this.prescription.selectedPrescription();
    if (prescription) {
      this.prescription.edit$.next({
        ...prescription,
        ...value,
        items: value.items as IPrescriptionItem[],
      });
      this.form.markAsPristine();
    }
  }

  statusColor(status: PrescriptionStatus | undefined): string {
    return status ? PRESCRIPTION_STATUS_COLOUR_MAP[status] : '';
  }

  async action(action: PrescriptionStatus): Promise<void> {
    const prescription = this.prescription.selectedPrescription();
    const region = await snapshot(this.organisation.region$);
    const { ref: actionedByRef } = await this._currentStaffer();

    if (!prescription) {
      return;
    }

    if (action === PrescriptionStatus.Delete) {
      this.deleteChange.emit({ prescription, actionedByRef });
      return;
    }

    if (action === PrescriptionStatus.Printed && region) {
      await this._print(prescription, actionedByRef, region);
      return;
    }

    this.prescription.edit$.next(
      Prescription.updateStatus(prescription, action, actionedByRef)
    );
  }

  getPrescriberDisplayName$(
    staffer: DocumentReference<IStaffer>
  ): Observable<string> {
    return this._global.getStafferName$({ ref: staffer }).pipe(
      filterUndefined(),
      map((name) => `Actioned By ${name}`)
    );
  }

  stafferProfileImage$(
    staffer: DocumentReference<IStaffer>
  ): Observable<string | undefined> {
    return this._global.getStafferImage$({ ref: staffer });
  }

  private _configPrescriptionItems$(): Observable<
    WithRef<IPrescriptionItem>[]
  > {
    return this._currentBrand.doc$.pipe(
      filterUndefined(),
      switchMap((brand) => Brand.prescriptionItems$(brand))
    );
  }

  private async _currentStaffer(): Promise<WithRef<IStaffer>> {
    return snapshot(this.organisation.staffer$.pipe(filterUndefined()));
  }

  private _isDisabled(
    prescription: WithRef<IPrescription> | undefined
  ): boolean {
    return prescription
      ? prescription.status !== PrescriptionStatus.Draft
      : false;
  }

  private _sortStatusHistory(
    prescription: WithRef<IPrescription> | undefined
  ): WithRef<IPrescription>['statusHistory'] {
    return prescription ? prescription.statusHistory.sort(sortByUpdatedAt) : [];
  }

  private async _print(
    prescription: WithRef<IPrescription>,
    actionedBy: DocumentReference<IStaffer>,
    region: Region
  ): Promise<void> {
    const update = await new PrescriptionPrintContext(region).printPrescription(
      prescription
    );
    if (isString(update)) {
      this.printWarning.set(update);
      return;
    }
    this.prescription.edit$.next(
      Prescription.updateStatus(update, PrescriptionStatus.Printed, actionedBy)
    );
  }
}
