<button mat-icon-button [matMenuTriggerFor]="filtersMenu">
  <mat-icon>filter_list</mat-icon>
</button>

<mat-menu
  #filtersMenu="matMenu"
  [overlapTrigger]="true"
  classList="chart-selector-menu"
>
  <ng-template matMenuContent>
    <h2>Filters</h2>

    @for (filterGroup of filterGroups$ | async; track filterGroup.label) {
      <mat-divider />
      <div class="pl-4 pt-2 text-gray-500">{{ filterGroup.label }}</div>

      @for (displayType of filterGroup.displayTypes; track displayType) {
        <button
          mat-menu-item
          class="flex items-center"
          (click)="select(displayType, $event)"
        >
          @if (displayType | map: isSelected$ : this | async) {
            <mat-icon color="primary">check_box</mat-icon>
          } @else {
            <mat-icon>check_box_outline_blank</mat-icon>
          }
          {{ displayType }}
        </button>
      }
    }
  </ng-template>
</mat-menu>
