import { Injectable } from '@angular/core';
import { Router, type NavigationExtras } from '@angular/router';
import { type IPractice } from '@principle-theorem/principle-core/interfaces';
import { snapshot, type WithRef } from '@principle-theorem/shared';
import { OrganisationService } from '../organisation.service';
import { StateBasedRouterLinkFactory } from './state-based-router-link-factory';

@Injectable()
export class StateBasedNavigationService {
  link: StateBasedRouterLinkFactory;

  constructor(
    organisation: OrganisationService,
    private _router: Router
  ) {
    this.link = new StateBasedRouterLinkFactory(
      organisation.brand$,
      organisation.practice$
    );
  }

  async organisation(
    segments: string[] = [],
    extras?: NavigationExtras
  ): Promise<void> {
    const route = await snapshot(this.link.organisation$(segments));
    await this._router.navigate(route, extras);
  }

  async brand(
    segments: string[] = [],
    extras?: NavigationExtras
  ): Promise<void> {
    const route = await snapshot(this.link.brand$(segments));
    await this._router.navigate(route, extras);
  }

  async practice(
    segments: string[] = [],
    extras?: NavigationExtras,
    practice?: WithRef<IPractice>
  ): Promise<void> {
    const route = await snapshot(this.link.practice$(segments, practice));
    await this._router.navigate(route, extras);
  }
}
