<div class="flex gap-2">
  <mat-form-field class="w-full">
    <mat-label>NHI Number</mat-label>
    <input
      matInput
      maxlength="7"
      [formControl]="nationalHealthIndexNumberCtrl"
    />
    <mat-hint align="end">
      Max {{ nationalHealthIndexNumberCtrl.value?.length || 0 }}/7
    </mat-hint>
    @if (nationalHealthIndexNumberCtrl.hasError('minlength')) {
      <mat-error> NHI Number must be 7 characters long </mat-error>
    }
  </mat-form-field>

  <button
    mat-icon-button
    matSuffix
    matTooltip="Remove Health Insurance Details"
    (click)="nationalHealthIndexNumberChange.emit(undefined)"
  >
    <mat-icon>close</mat-icon>
  </button>
</div>
