import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  inject,
  signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgMaterialModule } from '@principle-theorem/ng-material';
import {
  TypedFormControl,
  TypedFormGroup,
  formControlChanges$,
} from '@principle-theorem/ng-shared';
import { FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { BARCODE_PRINT_TEMPLATES, IBarcodeTemplate } from './barcode-template';
import { EmptyStateComponent } from '@principle-theorem/ng-principle-shared';
import { BarcodeSheetGenerator } from './barcode-sheet-generator';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Observable, Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { first } from 'lodash';
import { filterUndefined, shareReplayCold } from '@principle-theorem/shared';

export interface IBarcodeGeneratorFormData {
  template: IBarcodeTemplate;
  quantity: number;
}

interface IBarcodePreviewDimensions {
  height: number;
  width: number;
}

@Component({
  selector: 'pr-barcode-generator-dialog',
  standalone: true,
  imports: [
    CommonModule,
    NgMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    EmptyStateComponent,
  ],
  templateUrl: './barcode-generator-dialog.component.html',
  styleUrl: './barcode-generator-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BarcodeGeneratorDialogComponent implements OnDestroy {
  private _onDestroy$ = new Subject<void>();
  private _dialogRef = inject(MatDialogRef);
  private _sanitiser = inject(DomSanitizer);
  readonly templates = BARCODE_PRINT_TEMPLATES;
  form = new TypedFormGroup<IBarcodeGeneratorFormData>({
    template: new TypedFormControl<IBarcodeTemplate>(first(this.templates), [
      Validators.required,
    ]),
    quantity: new TypedFormControl<number>(1, [
      Validators.required,
      Validators.min(1),
    ]),
  });
  previewUrl = signal<SafeResourceUrl>('');
  showPreview = signal(false);
  previewDimensions$: Observable<IBarcodePreviewDimensions>;

  constructor() {
    this.previewDimensions$ = this.form.controls.template.valueChanges.pipe(
      startWith(first(this.templates)),
      filterUndefined(),
      map((template) => {
        const previewScale = template.orientation === 'portrait' ? 2 : 4;
        return {
          height: template.paperHeight * previewScale,
          width: template.paperWidth * previewScale,
        };
      }),
      shareReplayCold()
    );

    formControlChanges$(this.form.controls.template)
      .pipe(takeUntil(this._onDestroy$))
      .subscribe(() => this.showPreview.set(false));
  }

  generatePreview(): void {
    const { template } = this.form.getRawValue();
    if (!template) {
      return;
    }

    const generator = new BarcodeSheetGenerator(template, 1);
    const barcodeUids = generator.generateLabelUids();
    const dataUrl = generator.generateLabels(barcodeUids).getDataUrl();
    const safeUrl = this._sanitiser.bypassSecurityTrustResourceUrl(dataUrl);

    this.previewUrl.set(safeUrl);
    this.showPreview.set(true);
  }

  print(): void {
    const { template, quantity } = this.form.getRawValue();
    if (!template) {
      return;
    }

    const generator = new BarcodeSheetGenerator(template, quantity);
    const barcodeUids = generator.generateLabelUids();
    generator.generateLabels(barcodeUids).save();
    this._dialogRef.close();
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
