import { initVersionedSchema, toTextContent } from '@principle-theorem/editor';
import {
  Appointment,
  Event,
  Interaction,
  TreatmentPlan,
  hasMergeConflicts,
  stafferToNamedDoc,
} from '@principle-theorem/principle-core';
import {
  DestinationEntityRecordStatus,
  EventType,
  IMigratedDataSummary,
  ITranslationMap,
  ParticipantType,
  SkippedDestinationEntityRecord,
  type FailedDestinationEntityRecord,
  type IAppointment,
  type IDestinationEntity,
  type IDestinationEntityRecord,
  type IEvent,
  type IGetRecordResponse,
  type IInteractionV2,
  type IPatient,
  type IPractice,
  type IPracticeMigration,
  type ISourceEntityRecord,
  type IStaffer,
  type ITreatmentPlan,
  type ITreatmentStep,
  type MergeConflictDestinationEntityRecord,
} from '@principle-theorem/principle-core/interfaces';
import {
  Firestore,
  SystemActors,
  asDocRef,
  errorNil,
  getError,
  isObject,
  isSameRef,
  multiSwitchMap,
  toNamedDocument,
  toTimestamp,
  upsertBulk,
  type DocumentReference,
  type ISODateType,
  type Timestamp,
  type WithRef,
} from '@principle-theorem/shared';
import { compact, first, isString } from 'lodash';
import { combineLatest, of, type Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import { BaseDestinationEntity } from '../../../destination/base-destination-entity';
import { FirestoreMigrate } from '../../../destination/destination';
import { DestinationEntity } from '../../../destination/destination-entity';
import { STAFFER_RESOURCE_TYPE } from '../../../destination/entities/staff';
import { PracticeMigration } from '../../../practice-migrations';
import { buildSkipMigratedQuery } from '../../../source/source-entity-record';
import { type TranslationMapHandler } from '../../../translation-map';
import {
  PATIENT_RESOURCE_TYPE,
  PatientSourceEntity,
} from '../../source/entities/patient';
import {
  PatientAppointmentSourceEntity,
  type IPraktikaAppointment,
  type IPraktikaAppointmentFilters,
  type IPraktikaAppointmentTranslations,
} from '../../source/entities/patient-appointment';
import {
  PatientAppointmentNoteSourceEntity,
  type IPraktikaAppointmentNote,
  type IPraktikaAppointmentNoteTranslations,
} from '../../source/entities/patient-appointment-notes';
import { PraktikaPracticeMappingHandler } from '../mappings/practices';
import { PraktikaStafferMappingHandler } from '../mappings/staff';
import {
  PatientTreatmentPlanDestinationEntity,
  getAppointmentStatus,
} from './patient-treatment-plan';
import { PatientDestinationEntity } from './patients';
import { StafferDestinationEntity } from './staff';
import { PATIENT_APPOINTMENT_RESOURCE_TYPE } from '../../../destination/entities/patient-appointments';
import {
  PATIENT_TREATMENT_PLAN_CUSTOM_MAPPING_TYPE,
  PATIENT_TREATMENT_STEP_CUSTOM_MAPPING_TYPE,
} from '../../../destination/entities/patient-treatment-plans';

export const PATIENT_APPOINTMENT_DESTINATION_ENTITY = DestinationEntity.init({
  metadata: {
    key: PATIENT_APPOINTMENT_RESOURCE_TYPE,
    label: 'Patient Appointments',
    description: '',
  },
});

interface IAppointmentSuccessData {
  sourceRef: DocumentReference<ISourceEntityRecord>;
  appointmentRef: DocumentReference<IAppointment>;
}

interface IPatientAppointmentJobData {
  sourceAppointment: IGetRecordResponse<
    IPraktikaAppointment,
    IPraktikaAppointmentTranslations,
    IPraktikaAppointmentFilters
  >;
  staff: WithRef<ITranslationMap<IStaffer>>[];
  practitioners: WithRef<IStaffer>[];
  practice: WithRef<IPractice>;
}

export interface IPatientAppointmentMigrationData {
  patientRef: DocumentReference<IPatient>;
  sourcePatientId: string;
  sourceAppointmentId: string;
  appointment: IAppointment;
  interactions: IInteractionV2[];
  createdAt: Timestamp;
}

export class PatientAppointmentDestinationEntity extends BaseDestinationEntity<
  IAppointmentSuccessData,
  IPatientAppointmentJobData,
  IPatientAppointmentMigrationData
> {
  destinationEntity = PATIENT_APPOINTMENT_DESTINATION_ENTITY;
  override canMigrateByDateRange = true;

  sourceCountComparison = new PatientAppointmentSourceEntity();

  override sourceEntities = {
    patients: new PatientSourceEntity(),
    appointments: new PatientAppointmentSourceEntity(),
    appointmentNotes: new PatientAppointmentNoteSourceEntity(),
  };

  override destinationEntities = {
    patients: new PatientDestinationEntity(),
    treatmentPlans: new PatientTreatmentPlanDestinationEntity(),
    staff: new StafferDestinationEntity(),
  };

  customMappings = {
    staff: new PraktikaStafferMappingHandler(),
    practice: new PraktikaPracticeMappingHandler(),
  };

  sourceCountDataAccessor(
    data: IPatientAppointmentJobData
  ): DocumentReference<ISourceEntityRecord> {
    return data.sourceAppointment.record.ref;
  }

  getMigratedData$(
    record: IDestinationEntityRecord<IAppointmentSuccessData>
  ): Observable<IMigratedDataSummary[]> {
    if (record.status !== DestinationEntityRecordStatus.Migrated) {
      return of([]);
    }

    return combineLatest([
      Firestore.doc$(record.data.sourceRef),
      Firestore.doc$(record.data.appointmentRef),
    ]).pipe(
      map(([sourceAppointment, appointment]) => [
        {
          label: 'Source Appointment',
          data: sourceAppointment,
        },
        {
          label: 'Appointment',
          data: appointment,
        },
      ])
    );
  }

  buildJobData$(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMapHandler: TranslationMapHandler,
    skipMigrated: boolean,
    fromDate?: Timestamp,
    toDate?: Timestamp
  ): Observable<IPatientAppointmentJobData[]> {
    const practice$ = PracticeMigration.practices$(migration).pipe(
      map((practices) => first(practices)),
      errorNil('Practice not found')
    );
    const staff$ = combineLatest([
      this.customMappings.staff.getRecords$(translationMapHandler),
      translationMapHandler.getByType$<IStaffer>(STAFFER_RESOURCE_TYPE),
    ]).pipe(map(([staff, mappedStaff]) => [...staff, ...mappedStaff]));
    const practitioners$ = staff$.pipe(
      multiSwitchMap((staffer) =>
        staffer.destinationIdentifier
          ? Firestore.doc$(staffer.destinationIdentifier)
          : of(undefined)
      ),
      map(compact)
    );

    return this.sourceEntities.appointments
      .getRecords$(
        migration,
        200,
        buildSkipMigratedQuery(skipMigrated, this.destinationEntity),
        undefined,
        fromDate,
        toDate
      )
      .pipe(
        withLatestFrom(staff$, practitioners$, practice$),
        map(([sourceAppointments, staff, practitioners, practice]) =>
          sourceAppointments.map((sourceAppointment) => ({
            sourceAppointment,
            staff,
            practitioners,
            practice,
          }))
        )
      );
  }

  getDestinationEntityRecordUid(data: IPatientAppointmentJobData): string {
    return data.sourceAppointment.record.uid;
  }

  async buildMigrationData(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMapHandler: TranslationMapHandler,
    data: IPatientAppointmentJobData
  ): Promise<
    | IPatientAppointmentMigrationData
    | (IDestinationEntityRecord & FailedDestinationEntityRecord)
    | (IDestinationEntityRecord & SkippedDestinationEntityRecord)
  > {
    const sourcePatientId =
      data.sourceAppointment.data.data.appointment_patientid.toString();
    const sourceAppointmentId =
      data.sourceAppointment.data.data.appointment_id.toString();

    const patientRef = await translationMapHandler.getDestination<IPatient>(
      sourcePatientId,
      PATIENT_RESOURCE_TYPE
    );

    let practitionerMap = data.sourceAppointment.data.data
      .appointment_providerid
      ? data.staff.find((staffer) => {
          if (!staffer.sourceIdentifier) {
            return;
          }
          return (
            staffer.sourceIdentifier ===
            data.sourceAppointment.data.data.appointment_providerid?.toString()
          );
        })
      : undefined;

    if (!practitionerMap?.destinationIdentifier) {
      // Default provider is used from custom mapping with id: 0
      practitionerMap = data.staff.find(
        (staffer) => staffer.sourceIdentifier === '0'
      );
    }

    const planMap = await translationMapHandler.getDestination<ITreatmentPlan>(
      sourcePatientId,
      PATIENT_TREATMENT_PLAN_CUSTOM_MAPPING_TYPE
    );
    const stepMap = await translationMapHandler.getDestination<ITreatmentStep>(
      `${sourcePatientId}-${sourceAppointmentId}`,
      PATIENT_TREATMENT_STEP_CUSTOM_MAPPING_TYPE
    );

    if (
      !patientRef ||
      !practitionerMap?.destinationIdentifier ||
      !planMap ||
      !stepMap ||
      !data.practice
    ) {
      const message = [''];
      if (!practitionerMap?.destinationIdentifier) {
        message.push(
          `No practitioner with id ${
            data.sourceAppointment.data.data.appointment_providerid?.toString() ??
            ''
          }`
        );
      }

      if (!planMap?.id) {
        message.push('No plan');
      }

      if (!stepMap?.id) {
        message.push(`No step`);
      }

      return this._buildErrorResponse(
        data.sourceAppointment,
        message.join('; ')
      );
    }

    const createdAt = data.sourceAppointment.data.translations.createdAt;

    const appointmentNotes =
      await this.sourceEntities.appointmentNotes.filterRecords(
        migration,
        'appointmentId',
        sourceAppointmentId
      );

    try {
      const [appointment, interactions] = await this._buildAppointmentData(
        patientRef,
        practitionerMap.destinationIdentifier,
        planMap,
        stepMap,
        data.sourceAppointment,
        appointmentNotes,
        data.practitioners,
        data.practice,
        migration.configuration.backupDate
      );

      return {
        sourcePatientId,
        patientRef,
        sourceAppointmentId,
        createdAt,
        appointment,
        interactions,
      };
    } catch (error) {
      return this._buildErrorResponse(data.sourceAppointment, getError(error));
    }
  }

  async hasMergeConflict(
    translationMap: TranslationMapHandler,
    data: IPatientAppointmentMigrationData
  ): Promise<IPatientAppointmentMigrationData | undefined> {
    const existingAppointmentRef = await translationMap.getDestination(
      data.sourceAppointmentId,
      PATIENT_APPOINTMENT_RESOURCE_TYPE
    );

    if (!existingAppointmentRef) {
      return;
    }

    try {
      const existingAppointment = await Firestore.getDoc(
        asDocRef<IAppointment>(existingAppointmentRef)
      );

      if (existingAppointment.updatedBy !== SystemActors.Migration) {
        return {
          ...data,
          appointment: existingAppointment,
        };
      }

      const hasMergeConflict = hasMergeConflicts(
        data.appointment,
        existingAppointment,
        ['dateFrom', 'dateTo', 'treatmentPlan', 'statusHistory', 'ref'],
        [
          {
            key: 'tags',
            typeGuardFn: (item): item is unknown =>
              isObject(item) && isString(item.name),
            sortByPath: 'name',
          },
        ]
      );

      if (hasMergeConflict) {
        return {
          ...data,
          appointment: existingAppointment,
        };
      }
    } catch (error) {
      return;
    }
  }

  buildMergeConflictRecord(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    _translationMap: TranslationMapHandler,
    jobData: IPatientAppointmentJobData,
    _migrationData: IPatientAppointmentMigrationData
  ): IDestinationEntityRecord & MergeConflictDestinationEntityRecord {
    return {
      uid: jobData.sourceAppointment.record.uid,
      label: jobData.sourceAppointment.record.label,
      status: DestinationEntityRecordStatus.MergeConflict,
    };
  }

  async runJob(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMapHandler: TranslationMapHandler,
    jobData: IPatientAppointmentJobData,
    migrationData: IPatientAppointmentMigrationData
  ): Promise<IDestinationEntityRecord> {
    try {
      const appointmentRef = await this._upsertAppointment(
        migrationData.appointment,
        migrationData.sourceAppointmentId,
        translationMapHandler,
        migrationData.patientRef,
        migrationData.createdAt
      );

      await upsertBulk(
        Appointment.interactionCol({ ref: appointmentRef }),
        migrationData.interactions,
        'uid',
        SystemActors.Migration
      );

      return this._buildSuccessResponse(
        jobData.sourceAppointment,
        appointmentRef
      );
    } catch (error) {
      return this._buildErrorResponse(
        jobData.sourceAppointment,
        getError(error)
      );
    }
  }

  private _buildSuccessResponse(
    appointment: IGetRecordResponse<
      IPraktikaAppointment,
      IPraktikaAppointmentTranslations
    >,
    appointmentRef: DocumentReference<IAppointment>
  ): IDestinationEntityRecord<IAppointmentSuccessData> {
    return {
      uid: appointment.record.uid,
      label: appointment.record.label,
      data: {
        sourceRef: appointment.record.ref,
        appointmentRef,
      },
      status: DestinationEntityRecordStatus.Migrated,
      migratedAt: toTimestamp(),
    };
  }

  private async _upsertAppointment(
    appointment: IAppointment,
    appointmentUid: string,
    translationMap: TranslationMapHandler,
    patientRef: DocumentReference<IPatient>,
    createdAt: Timestamp
  ): Promise<DocumentReference<IAppointment>> {
    const patientAppointmentDestinationRef =
      await translationMap.getDestination(
        appointmentUid,
        PATIENT_APPOINTMENT_RESOURCE_TYPE
      );

    const appointmentRef = await FirestoreMigrate.upsertDoc(
      Appointment.col({
        ref: patientRef,
      }),
      { ...appointment, createdAt },
      patientAppointmentDestinationRef?.id
    );

    if (!patientAppointmentDestinationRef) {
      await translationMap.upsert({
        sourceIdentifier: appointmentUid,
        destinationIdentifier: appointmentRef,
        resourceType: PATIENT_APPOINTMENT_RESOURCE_TYPE,
      });
    }

    await FirestoreMigrate.patchDoc(
      appointment.treatmentPlan.treatmentStep.ref,
      {
        appointment: appointmentRef,
      }
    );

    return appointmentRef;
  }

  private async _buildAppointmentData(
    patientRef: DocumentReference<IPatient>,
    practitionerRef: DocumentReference<IStaffer>,
    planRef: DocumentReference<ITreatmentPlan>,
    stepRef: DocumentReference<ITreatmentStep>,
    appointment: IGetRecordResponse<
      IPraktikaAppointment,
      IPraktikaAppointmentTranslations
    >,
    appointmentNotes: IGetRecordResponse<
      IPraktikaAppointmentNote,
      IPraktikaAppointmentNoteTranslations
    >[],
    practitioners: WithRef<IStaffer>[],
    practice: WithRef<IPractice>,
    backupDate: ISODateType
  ): Promise<[IAppointment, IInteractionV2[]]> {
    const patient = await Firestore.getDoc(patientRef);
    const treatmentPlan = await Firestore.getDoc(planRef);
    const treatmentStep = await Firestore.getDoc(stepRef);
    const practitioner = practitioners.find((practitionerSearch) =>
      isSameRef(practitionerSearch, practitionerRef)
    );
    if (!practitioner) {
      throw new Error(`Can't find practitioner for ref ${practitionerRef.id}`);
    }

    const interactions = appointmentNotes.map((note) =>
      Interaction.init({
        uid: note.record.uid,
        title: [toTextContent(`Added a note`)],
        content: initVersionedSchema(note.data.data.note),
        createdAt: note.data.translations.date,
        pinned: note.data.data.note.startsWith('[') ? false : true,
      })
    );

    const newAppointment = Appointment.init({
      status: getAppointmentStatus(
        appointment.data.data,
        appointment.data.translations,
        backupDate,
        practice.settings.timezone
      ),
      event: getAppointmentEvent(
        appointment.data.translations,
        patient,
        practice,
        practitioner
      ),
      practice: toNamedDocument(practice),
      practitioner: stafferToNamedDoc(practitioner),
      treatmentPlan: TreatmentPlan.treatmentStepToAssociatedTreatment(
        treatmentPlan,
        treatmentStep
      ),
    });

    return [newAppointment, interactions];
  }

  private _buildErrorResponse(
    appointment: IGetRecordResponse<
      IPraktikaAppointment,
      IPraktikaAppointmentTranslations
    >,
    errorMessage?: string
  ): IDestinationEntityRecord & FailedDestinationEntityRecord {
    return {
      uid: appointment.record.uid,
      label: appointment.record.label,
      status: DestinationEntityRecordStatus.Failed,
      errorMessage:
        errorMessage ?? 'Missing required properties for appointment',
      failData: {
        appointmentRef: appointment.record.ref,
      },
    };
  }
}

export function getAppointmentEvent(
  translations: IPraktikaAppointmentTranslations,
  patient?: WithRef<IPatient>,
  practice?: WithRef<IPractice>,
  practitioner?: WithRef<IStaffer>
): IEvent | undefined {
  if (
    !translations.from ||
    !translations.to ||
    !practice ||
    !patient ||
    !practitioner
  ) {
    return;
  }
  return Event.init({
    from: translations.from,
    to: translations.to,
    practice: toNamedDocument(practice),
    type: EventType.Appointment,
    participants: [
      {
        ...toNamedDocument(patient),
        type: ParticipantType.Patient,
      },
      {
        ...stafferToNamedDoc(practitioner),
        type: ParticipantType.Staffer,
      },
    ],
  });
}
