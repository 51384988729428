<form
  [formGroup]="patientForm"
  novalidate
  (ngSubmit)="submit()"
  fxLayout="column"
>
  <mat-error
    *ngIf="patientForm.contactDetailsError$ | async as contactDetailsError"
  >
    {{ contactDetailsError }}
  </mat-error>

  @if (patient$ | async) {
    <div class="mb-6 mt-2 flex-1">
      <mat-form-field class="w-full">
        <mat-label>Name</mat-label>
        <input matInput formControlName="name" required />
        <mat-hint>
          {{ nameMatHint }}
        </mat-hint>
      </mat-form-field>
    </div>
  } @else {
    <ng-container
      *ngrxLet="suggestedPatientData$ | async as suggestedPatientData"
    >
      <div class="mb-6 mt-2">
        <pr-patient-selector
          placeholder="Name"
          [suggestedPatientData]="suggestedPatientData"
          [formControl]="patientSelectorCtrl"
          (clear)="clearPatient()"
          [disableAllPatients]="true"
          [matHint]="nameMatHint"
        />
      </div>
    </ng-container>
  }

  <pt-iso-date-selector
    class="mb-4"
    placeholder="Date of Birth"
    formControlName="dateOfBirth"
    [required]="'dateOfBirth' | map: isRequired$ : this | async"
    [max]="today"
  />

  <mat-form-field>
    <mat-label>Email</mat-label>
    <input
      matInput
      type="email"
      formControlName="email"
      [required]="'email' | map: isRequired$ : this | async"
    />
  </mat-form-field>

  <div formArrayName="contactNumbers" fxLayout="column">
    <ng-container
      *ngFor="
        let control of contactNumbers.controls;
        trackBy: trackByIndex;
        index as index;
        first as isFirst
      "
    >
      <div [ngStyle]="{ 'margin-bottom': isFirst ? '1.5rem' : '0' }">
        <pr-contact-number-input
          [form]="control"
          [isFirst]="isFirst"
          [required]="'contactNumbers' | map: isRequired$ : this | async"
        >
          <div class="flex flex-row">
            <button
              mat-icon-button
              (click)="contactNumberControls.addControl(index)"
            >
              <mat-icon>add</mat-icon>
            </button>
            <button
              mat-icon-button
              (click)="contactNumberControls.removeControl(index)"
            >
              <mat-icon>remove</mat-icon>
            </button>
          </div>
        </pr-contact-number-input>
      </div>
    </ng-container>
  </div>

  <pr-address-input class="mb-4" formControlName="address" />

  <div class="flex gap-4">
    <mat-form-field class="flex-1">
      <mat-label>Gender</mat-label>
      <mat-select formControlName="gender" required>
        <mat-option
          *ngFor="let gender of genders; trackBy: trackByGender"
          [value]="gender"
        >
          {{ gender | splitCamel | titlecase }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="flex-1">
      <mat-label>Status</mat-label>
      <mat-select formControlName="status" required>
        <mat-option
          *ngFor="let status of statuses; trackBy: trackByStatus"
          [value]="status"
        >
          {{ status | splitCamel | titlecase }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <pr-referrer-selector
    class="w-full flex-1"
    formControlName="referrer"
    [required]="'referrer' | map: isRequired$ : this | async"
  />

  <div class="flex gap-4">
    <mat-form-field class="flex-1">
      <mat-label>Preferred Dentist</mat-label>
      <mat-select
        formControlName="preferredDentist"
        [compareWith]="isSelectedNamedDocument"
      >
        <mat-option
          *ngFor="
            let practitioner of practitioners$ | async;
            trackBy: trackByPractitioner
          "
          [value]="practitioner"
        >
          {{ practitioner.name }}
        </mat-option>
        <mat-option [value]="undefined">None</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="flex-1">
      <mat-label>Preferred Hygienist</mat-label>
      <mat-select
        formControlName="preferredHygienist"
        [compareWith]="isSelectedNamedDocument"
      >
        <mat-option
          *ngFor="
            let practitioner of practitioners$ | async;
            trackBy: trackByPractitioner
          "
          [value]="practitioner"
        >
          {{ practitioner.name }}
        </mat-option>
        <mat-option [value]="undefined">None</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="flex gap-4">
    <mat-form-field class="flex-1">
      <mat-label>Preferred Practice</mat-label>
      <mat-select
        formControlName="preferredPractice"
        [compareWith]="isSelectedNamedDocument"
      >
        <mat-option
          *ngFor="let practice of practices$ | async; trackBy: trackByPractice"
          [value]="practice"
        >
          {{ practice.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="flex-1">
      <mat-label>Preferred Fee Schedule</mat-label>
      <mat-select
        formControlName="preferredFeeSchedule"
        [compareWith]="isSelectedNamedDocument"
      >
        <mat-option
          *ngFor="
            let feeSchedule of feeSchedules$ | async;
            trackBy: trackByFeeSchedule
          "
          [value]="feeSchedule"
        >
          {{ feeSchedule.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <pr-tags-input
    formControlName="tags"
    [tagCol]="tagCol$ | async"
    [tagType]="tagType"
  />
</form>
