import { initVersionedSchema, toTextContent } from '@principle-theorem/editor';
import {
  Appointment,
  Event,
  Interaction,
  TreatmentPlan,
  TreatmentStep,
  hasMergeConflicts,
  stafferToNamedDoc,
} from '@principle-theorem/principle-core';
import {
  AppointmentStatus,
  DestinationEntityRecordStatus,
  EventType,
  FailedDestinationEntityRecord,
  IDestinationEntity,
  IMigratedDataSummary,
  InteractionType,
  MergeConflictDestinationEntityRecord,
  ParticipantType,
  TreatmentPlanStatus,
  TreatmentStepStatus,
  type IAppointment,
  type IBrand,
  type IDestinationEntityRecord,
  type IEvent,
  type IGetRecordResponse,
  type IInteractionV2,
  type IPatient,
  type IPractice,
  type IPracticeMigration,
  type ISourceEntityRecord,
  type IStaffer,
  type ITreatmentPlan,
  type ITreatmentStep,
  ITranslationMap,
  ITreatmentCategory,
  isTag,
  ITag,
} from '@principle-theorem/principle-core/interfaces';
import {
  DocumentReference,
  HISTORY_DATE_FORMAT,
  Timestamp,
  Timezone,
  asDocRef,
  doc$,
  getDoc,
  getError,
  initFirestoreModel,
  omitByKeys,
  toMomentTz,
  toNamedDocument,
  toTimestamp,
  type INamedDocument,
  type WithRef,
  Firestore,
} from '@principle-theorem/shared';
import * as moment from 'moment-timezone';
import { Observable, combineLatest, of } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import { BaseDestinationEntity } from '../../../destination/base-destination-entity';
import { FirestoreMigrate } from '../../../destination/destination';
import { DestinationEntity } from '../../../destination/destination-entity';
import { buildSkipMigratedQuery } from '../../../source/source-entity-record';
import { TranslationMapHandler } from '../../../translation-map';
import {
  PATIENT_RESOURCE_TYPE,
  PatientSourceEntity,
} from '../../source/entities/patient';
import {
  AppointmentSourceEntity,
  type IExactAppointment,
  type IExactAppointmentFilters,
  type IExactAppointmentTranslations,
} from '../../source/entities/patient-appointment';
import { PatientNotesSourceEntity } from '../../source/entities/patient-notes';
import { AppointmentRoomToPractionerMappingHandler } from '../mappings/appointment-room-to-practitioner';
import {
  ExactStafferMappingHandler,
  resolveExactStaffer,
} from '../mappings/staff';
import { PatientDestinationEntity } from './patient';
import { PatientIdFilter } from '../../../destination/filters/patient-id-filter';
import { STAFFER_RESOURCE_TYPE } from '../../../destination/entities/staff';
import { PATIENT_APPOINTMENT_RESOURCE_TYPE } from '../../../destination/entities/patient-appointments';
import {
  PATIENT_TREATMENT_PLAN_CUSTOM_MAPPING_TYPE,
  PATIENT_TREATMENT_STEP_CUSTOM_MAPPING_TYPE,
} from '../../../destination/entities/patient-treatment-plans';
import { AppointmentCategoryDestination } from '../mappings/appointment-to-treatment-category-to-xslx';
import { AppointmentCategoryToTreatmentCategoryMappingHandler } from '../mappings/appointment-category-to-treatment-category';

export const PATIENT_APPOINTMENT_DESTINATION_ENTITY = DestinationEntity.init({
  metadata: {
    key: PATIENT_APPOINTMENT_RESOURCE_TYPE,
    label: 'Patient Appointments',
    description: '',
  },
});

interface IAppointmentSuccessData {
  sourceRef: DocumentReference<ISourceEntityRecord>;
  appointmentRef: DocumentReference<IAppointment>;
}

export interface IPatientAppointmentJobData {
  sourceAppointment: IGetRecordResponse<
    IExactAppointment,
    IExactAppointmentTranslations,
    IExactAppointmentFilters
  >;
  staff: WithRef<ITranslationMap<IStaffer>>[];
  brand: WithRef<IBrand>;
  appointmentRoomToPractitioner: WithRef<ITranslationMap<IStaffer>>[];
  appointmentToTreatmentCategory: WithRef<
    ITranslationMap<AppointmentCategoryDestination>
  >[];
}

export interface IPatientAppointmentMigrationData {
  patientRef: DocumentReference<IPatient>;
  sourcePatientId: string;
  appointmentUid: string;
  appointment: Omit<IAppointment, 'treatmentPlan'>;
  createdAt?: Timestamp;
  planUid: string;
  stepUid: string;
  plan: ITreatmentPlan;
  step: ITreatmentStep;
  interactions: IInteractionV2[];
}

export const EXACT_MIGRATED_APPOINTMENT_PLAN_NAME = `Exact - Migrated Appointments`;

export class PatientAppointmentDestinationEntity extends BaseDestinationEntity<
  IAppointmentSuccessData,
  IPatientAppointmentJobData,
  IPatientAppointmentMigrationData
> {
  destinationEntity = PATIENT_APPOINTMENT_DESTINATION_ENTITY;

  sourceCountComparison = new AppointmentSourceEntity();

  override canMigrateByDateRange = true;

  override filters = [
    new PatientIdFilter<IPatientAppointmentJobData>((jobData) =>
      jobData.sourceAppointment.data.data.patient_id.toString()
    ),
  ];

  override sourceEntities = {
    patients: new PatientSourceEntity(),
    appointments: new AppointmentSourceEntity(),
    notes: new PatientNotesSourceEntity(),
  };

  override destinationEntities = {
    patients: new PatientDestinationEntity(),
  };

  customMappings = {
    staff: new ExactStafferMappingHandler(),
    appointmentRoomToPractitioner:
      new AppointmentRoomToPractionerMappingHandler(),
    appointmentToTreatmentCategory:
      new AppointmentCategoryToTreatmentCategoryMappingHandler(),
  };

  sourceCountDataAccessor(
    data: IPatientAppointmentJobData
  ): DocumentReference<ISourceEntityRecord> {
    return data.sourceAppointment.record.ref;
  }

  getDestinationEntityRecordUid(data: IPatientAppointmentJobData): string {
    return data.sourceAppointment.record.uid;
  }

  getMigratedData$(
    record: IDestinationEntityRecord<IAppointmentSuccessData>
  ): Observable<IMigratedDataSummary[]> {
    if (record.status !== DestinationEntityRecordStatus.Migrated) {
      return of([]);
    }

    return combineLatest([
      doc$(record.data.sourceRef),
      doc$(record.data.appointmentRef),
    ]).pipe(
      map(([sourceAppointment, appointment]) => [
        {
          label: 'Source Appointment',
          data: sourceAppointment,
        },
        {
          label: 'Appointment',
          data: appointment,
        },
      ])
    );
  }

  buildJobData$(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    skipMigrated: boolean,
    fromDate?: Timestamp,
    toDate?: Timestamp
  ): Observable<IPatientAppointmentJobData[]> {
    const brand$ = doc$(migration.configuration.brand.ref);
    const staff$ = combineLatest([
      this.customMappings.staff.getRecords$(translationMap),
      translationMap.getByType$<IStaffer>(STAFFER_RESOURCE_TYPE),
    ]).pipe(map(([staff, mappedStaff]) => [...staff, ...mappedStaff]));
    const appointmentRoomToPractitioner$ =
      this.customMappings.appointmentRoomToPractitioner.getRecords$(
        translationMap
      );
    const appointmentToTreatmentCategory$ =
      this.customMappings.appointmentToTreatmentCategory.getRecords$(
        translationMap
      );

    return this.sourceEntities.appointments
      .getRecords$(
        migration,
        1000,
        buildSkipMigratedQuery(skipMigrated, this.destinationEntity),
        undefined,
        fromDate,
        toDate
      )
      .pipe(
        withLatestFrom(
          brand$,
          staff$,
          appointmentRoomToPractitioner$,
          appointmentToTreatmentCategory$
        ),
        map(
          ([
            appointments,
            brand,
            staff,
            appointmentRoomToPractitioner,
            appointmentToTreatmentCategory,
          ]) =>
            appointments.map((sourceAppointment) => ({
              sourceAppointment,
              brand,
              staff,
              appointmentRoomToPractitioner,
              appointmentToTreatmentCategory,
            }))
        )
      );
  }

  async buildMigrationData(
    migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    data: IPatientAppointmentJobData
  ): Promise<
    | IPatientAppointmentMigrationData
    | (IDestinationEntityRecord & FailedDestinationEntityRecord)
  > {
    const appointmentUid = this.sourceEntities.appointments.getSourceRecordId(
      data.sourceAppointment.data.data
    );
    const sourcePatientId = data.sourceAppointment.data.data.patient_id;

    const patientRef = await translationMap.getDestination<IPatient>(
      sourcePatientId,
      PATIENT_RESOURCE_TYPE
    );

    if (!patientRef) {
      return this._buildErrorResponse(
        data.sourceAppointment,
        `No patient with id ${sourcePatientId}`
      );
    }

    const practitioner = await this.resolvePractitioner(
      data,
      translationMap,
      data.sourceAppointment.data.data
    );
    if (!practitioner) {
      return this._buildErrorResponse(
        data.sourceAppointment,
        `No practitioner found for id ${data.sourceAppointment.data.data.practitioner_initials}`
      );
    }

    try {
      const [appointment, interactions] = await this._buildAppointmentData(
        patientRef,
        data.sourceAppointment,
        practitioner,
        migration
      );

      const planUid = `${sourcePatientId}-appointments`;
      const stepUid = `${sourcePatientId}-${appointmentUid}-appointments`;
      const plan = TreatmentPlan.init({
        name: EXACT_MIGRATED_APPOINTMENT_PLAN_NAME,
        status: TreatmentPlanStatus.InProgress,
      });

      const timezone = migration.configuration.timezone;
      const appointmentDate = toMomentTz(
        data.sourceAppointment.data.translations.appointmentDateTime,
        timezone
      ).format(HISTORY_DATE_FORMAT);

      const categoryDestinationIdentifier =
        data.appointmentToTreatmentCategory.find(
          (categoryMap) =>
            categoryMap.sourceIdentifier ===
            data.sourceAppointment.data.data.appointment_category
        )?.destinationIdentifier;

      const categoryDestination = categoryDestinationIdentifier
        ? await Firestore.getDoc(categoryDestinationIdentifier)
        : undefined;

      const overrideTreatmentCategory = isTag(categoryDestination)
        ? undefined
        : (categoryDestination?.ref as DocumentReference<ITreatmentCategory>);

      const appointmentTag = isTag(categoryDestination)
        ? (categoryDestination as INamedDocument<ITag>)
        : undefined;
      if (appointmentTag) {
        appointment.tags.push(appointmentTag);
      }

      const step = TreatmentStep.init({
        name: `Exact Appointment - ${appointmentDate}`,
        status:
          appointment.status === AppointmentStatus.Complete
            ? TreatmentStepStatus.Complete
            : TreatmentStepStatus.Incomplete,
        schedulingRules: {
          duration: appointment.event ? Event.duration(appointment.event) : 0,
        },
        display: {
          overrideTreatmentCategory,
        },
      });

      return {
        sourcePatientId,
        patientRef,
        appointmentUid,
        appointment,
        planUid,
        plan,
        stepUid,
        step,
        interactions,
        createdAt: data.sourceAppointment.data.translations.appointmentDateTime,
      };
    } catch (error) {
      return this._buildErrorResponse(data.sourceAppointment, getError(error));
    }
  }

  async resolvePractitioner(
    data: IPatientAppointmentJobData,
    translationMap: TranslationMapHandler,
    sourceAppointment: IExactAppointment
  ): Promise<WithRef<IStaffer> | undefined> {
    if (sourceAppointment.room_id) {
      const appointmentBookPractitionerMap =
        data.appointmentRoomToPractitioner.find(
          (practitionerMap) =>
            practitionerMap.sourceIdentifier === sourceAppointment.room_id
        );

      if (appointmentBookPractitionerMap?.destinationIdentifier) {
        return getDoc(appointmentBookPractitionerMap.destinationIdentifier);
      }
    }

    return resolveExactStaffer(
      sourceAppointment.practitioner_initials,
      translationMap,
      data.staff
    );
  }

  async runJob(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    translationMap: TranslationMapHandler,
    jobData: IPatientAppointmentJobData,
    migrationData: IPatientAppointmentMigrationData
  ): Promise<IDestinationEntityRecord> {
    try {
      const appointmentRef = await this._upsertAppointment(
        migrationData,
        translationMap
      );

      await FirestoreMigrate.upsertBulk(
        Appointment.interactionCol({ ref: appointmentRef }),
        migrationData.interactions,
        'uid'
      );

      return this._buildSuccessResponse(
        jobData.sourceAppointment,
        appointmentRef
      );
    } catch (error) {
      return this._buildErrorResponse(
        jobData.sourceAppointment,
        getError(error)
      );
    }
  }

  async hasMergeConflict(
    translationMap: TranslationMapHandler,
    data: IPatientAppointmentMigrationData
  ): Promise<IPatientAppointmentMigrationData | undefined> {
    const existingAppointmentRef = await translationMap.getDestination(
      data.appointmentUid,
      PATIENT_APPOINTMENT_RESOURCE_TYPE
    );

    if (!existingAppointmentRef) {
      return;
    }

    try {
      const existingAppointment = await getDoc(
        asDocRef<IAppointment>(existingAppointmentRef)
      );

      const hasMergeConflict = hasMergeConflicts(
        data.appointment,
        omitByKeys(existingAppointment, ['treatmentPlan']),
        ['dateFrom', 'dateTo']
      );
      if (!hasMergeConflict) {
        return;
      }

      return {
        ...data,
        appointment: existingAppointment,
      };
    } catch (error) {
      return;
    }
  }

  buildMergeConflictRecord(
    _migration: WithRef<IPracticeMigration>,
    _destinationEntity: WithRef<IDestinationEntity>,
    _translationMap: TranslationMapHandler,
    jobData: IPatientAppointmentJobData,
    _migrationData: IPatientAppointmentMigrationData
  ): IDestinationEntityRecord & MergeConflictDestinationEntityRecord {
    return {
      uid: jobData.sourceAppointment.record.uid,
      label: jobData.sourceAppointment.record.label,
      status: DestinationEntityRecordStatus.MergeConflict,
    };
  }

  private async _upsertAppointment(
    data: IPatientAppointmentMigrationData,
    translationMap: TranslationMapHandler
  ): Promise<DocumentReference<IAppointment>> {
    const planDestinationRef = await translationMap.getDestination(
      data.planUid,
      PATIENT_TREATMENT_PLAN_CUSTOM_MAPPING_TYPE
    );

    const planRef = await FirestoreMigrate.upsertDoc(
      TreatmentPlan.col({ ref: data.patientRef }),
      data.plan,
      planDestinationRef?.id
    );

    if (!planDestinationRef) {
      await translationMap.upsert({
        sourceIdentifier: data.planUid,
        destinationIdentifier: planRef,
        resourceType: PATIENT_TREATMENT_PLAN_CUSTOM_MAPPING_TYPE,
      });
    }

    const stepDestinationRef = await translationMap.getDestination(
      data.stepUid,
      PATIENT_TREATMENT_STEP_CUSTOM_MAPPING_TYPE
    );
    const stepRef = await FirestoreMigrate.upsertDoc(
      TreatmentPlan.treatmentStepCol({
        ref: planRef,
      }),
      data.step,
      stepDestinationRef?.id
    );

    if (!stepDestinationRef) {
      await translationMap.upsert({
        sourceIdentifier: data.stepUid,
        destinationIdentifier: stepRef,
        resourceType: PATIENT_TREATMENT_STEP_CUSTOM_MAPPING_TYPE,
      });
    }

    const treatmentPlan = await getDoc(planRef);
    const treatmentStep = await getDoc(stepRef);

    const patientAppointmentDestinationRef =
      await translationMap.getDestination(
        data.appointmentUid,
        PATIENT_APPOINTMENT_RESOURCE_TYPE
      );
    const appointmentRef = await FirestoreMigrate.upsertDoc(
      Appointment.col({
        ref: data.patientRef,
      }),
      {
        ...data.appointment,
        treatmentPlan: TreatmentPlan.treatmentStepToAssociatedTreatment(
          treatmentPlan,
          treatmentStep
        ),
        createdAt: data.createdAt ?? toTimestamp(),
      },
      patientAppointmentDestinationRef?.id
    );

    if (!patientAppointmentDestinationRef) {
      await translationMap.upsert({
        sourceIdentifier: data.appointmentUid,
        destinationIdentifier: appointmentRef,
        resourceType: PATIENT_APPOINTMENT_RESOURCE_TYPE,
      });
    }

    await FirestoreMigrate.patchDoc(stepRef, {
      appointment: appointmentRef,
    });
    return appointmentRef;
  }

  private async _buildAppointmentData(
    patientRef: DocumentReference<IPatient>,
    sourceAppointment: IGetRecordResponse<
      IExactAppointment,
      IExactAppointmentTranslations
    >,
    practitioner: WithRef<IStaffer>,
    migration: WithRef<IPracticeMigration>
  ): Promise<[Omit<IAppointment, 'treatmentPlan'>, IInteractionV2[]]> {
    // Their table does not contain a created_at date so we will use the appointment date
    const appointment = sourceAppointment.data.data;
    const patient = await getDoc(patientRef);
    const createdAt = sourceAppointment.data.translations.appointmentDateTime;
    const status = sourceAppointment.data.translations.appointmentStatus;
    const interactions: IInteractionV2[] = await this._buildInteractions(
      sourceAppointment,
      migration
    );
    const practice = migration.configuration.practices[0];

    if (appointment.appointment_note) {
      interactions.unshift(
        Interaction.init({
          type: InteractionType.Note,
          title: [toTextContent('Appointment note')],
          content: initVersionedSchema(appointment.appointment_note),
          pinned: true,
          createdAt,
        })
      );
    }

    const isUnscheduled =
      status === AppointmentStatus.Unscheduled ||
      status === AppointmentStatus.Cancelled;

    const appointmentEvent = this._getAppointmentEvent(
      sourceAppointment,
      patient,
      practice,
      practitioner,
      migration.configuration.timezone
    );

    const eventHistory = isUnscheduled
      ? [
          {
            event: appointmentEvent,
            createdAt,
          },
        ]
      : [];

    const builtAppointment = {
      event: isUnscheduled ? undefined : appointmentEvent,
      eventHistory,
      status,
      statusHistory: [],
      practice: toNamedDocument(practice),
      practitioner: stafferToNamedDoc(practitioner),
      cancellationHistory: [],
      dependencies: [],
      tags: [],
      ...initFirestoreModel(),
    };

    return [builtAppointment, interactions];
  }

  private async _buildInteractions(
    sourceAppointment: IGetRecordResponse<
      IExactAppointment,
      IExactAppointmentTranslations
    >,
    migration: WithRef<IPracticeMigration>
  ): Promise<IInteractionV2[]> {
    const notes = await this.sourceEntities.notes.filterRecords(
      migration,
      'appointmentId',
      sourceAppointment.data.data.appointment_id
    );
    return notes.map((note) => ({
      ...Interaction.init({
        type: InteractionType.Note,
        title: [toTextContent('Appointment note')],
        content: initVersionedSchema(note.data.data.note),
        createdAt: note.data.translations.entryDate,
      }),
    }));
  }

  private _getAppointmentEvent(
    sourceAppointment: IGetRecordResponse<
      IExactAppointment,
      IExactAppointmentTranslations
    >,
    patient: WithRef<IPatient>,
    practice: INamedDocument<IPractice>,
    practitioner: WithRef<IStaffer>,
    timezone: Timezone
  ): IEvent {
    const to = toTimestamp(
      toMomentTz(
        sourceAppointment.data.translations.appointmentDateTime,
        timezone
      ).add(moment.duration(sourceAppointment.data.data.appointment_duration))
    );
    return Event.init({
      from: sourceAppointment.data.translations.appointmentDateTime,
      to,
      practice: toNamedDocument(practice),
      type: EventType.Appointment,
      participants: [
        {
          ...toNamedDocument(patient),
          type: ParticipantType.Patient,
        },
        {
          ...stafferToNamedDoc(practitioner),
          type: ParticipantType.Staffer,
        },
      ],
    });
  }

  private _buildSuccessResponse(
    appointment: IGetRecordResponse<
      IExactAppointment,
      IExactAppointmentTranslations
    >,
    appointmentRef: DocumentReference<IAppointment>
  ): IDestinationEntityRecord<IAppointmentSuccessData> {
    return {
      uid: appointment.record.uid,
      label: appointment.record.label,
      data: {
        sourceRef: appointment.record.ref,
        appointmentRef,
      },
      status: DestinationEntityRecordStatus.Migrated,
      migratedAt: toTimestamp(),
    };
  }

  private _buildErrorResponse(
    appointment: IGetRecordResponse<
      IExactAppointment,
      IExactAppointmentTranslations
    >,
    errorMessage?: string
  ): IDestinationEntityRecord & FailedDestinationEntityRecord {
    return {
      uid: appointment.record.uid,
      label: appointment.record.label,
      status: DestinationEntityRecordStatus.Failed,
      errorMessage:
        errorMessage ?? 'Missing required properties for appointment',
      failData: {
        appointmentRef: appointment.record.ref,
      },
    };
  }
}
