import { Injectable, inject } from '@angular/core';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import {
  ChartView,
  type IPractice,
  type IStaffByPractice,
  type IStaffer,
  type IStafferSettings,
  PatientMediaGroupBy,
  PatientMediaSize,
  PatientMediaViewType,
  PlanStepPairStatus,
  TimelineDisplayOrientation,
  TimelineDisplayZoom,
  DEFAULT_PERIO_SETTINGS,
  SortStepOptions,
} from '@principle-theorem/principle-core/interfaces';
import {
  AtLeast,
  filterUndefined,
  getEnumValues,
  isSameRef,
  patchDoc,
} from '@principle-theorem/shared';
import { type DocumentReference } from '@principle-theorem/shared';
import { type Observable } from 'rxjs';
import {
  auditTime,
  concatMap,
  map,
  skip,
  take,
  withLatestFrom,
} from 'rxjs/operators';
import { OrganisationService } from './organisation.service';
import { MediaObserver } from '@angular/flex-layout';

export interface IStafferSettingsState extends IStafferSettings {
  charting: Required<IStafferSettings['charting']>;
  display: Required<IStafferSettings['display']>;
  interactions: Required<IStafferSettings['interactions']>;
  timeline: Required<IStafferSettings['timeline']>;
  conversations: Required<IStafferSettings['conversations']>;
  chats: AtLeast<IStafferSettings['chats'], 'showAllPractices'>;
  patientMedia: Required<IStafferSettings['patientMedia']>;
}

@Injectable({
  providedIn: 'root',
})
export class StafferSettingsStoreService extends ComponentStore<IStafferSettingsState> {
  private _organisation = inject(OrganisationService);
  readonly charting$ = this.select((state) => state.charting);
  readonly display$ = this.select((state) => state.display);
  readonly interactions$ = this.select((state) => state.interactions);
  readonly timeline$ = this.select((state) => state.timeline);
  readonly conversations$ = this.select((state) => state.conversations);
  readonly chats$ = this.select((state) => state.chats);
  readonly orientation$ = this.select((state) => state.timeline.orientation);
  readonly zoom$ = this.select((state) => state.timeline.zoom);
  readonly thickTracks$ = this.select((state) => state.timeline.thickTracks);
  readonly showGridlines$ = this.select(
    (state) => state.timeline.showGridlines
  );
  readonly showRosteredOffStaff$ = this.select(
    (state) => state.timeline.showRosteredOffStaff
  );
  readonly hideEmptyDays$ = this.select(
    (state) => state.timeline.hideEmptyDays
  );
  readonly hideCancelledAppointments$ = this.select(
    (state) => state.timeline.hideCancelledAppointments
  );
  readonly filteredStaffByPractice$ = this.select(
    (state) => state.timeline.filteredStaffByPractice
  );
  readonly orderedStaffByPractice$ = this.select(
    (state) => state.timeline.orderedStaffByPractice
  );
  readonly treatmentPlan$ = this.select((state) => state.treatmentPlan);
  readonly patientMedia$ = this.select((state) => state.patientMedia);

  readonly loadStafferSettings = this.effect(
    (settings$: Observable<Partial<IStafferSettings>>) =>
      settings$.pipe(
        filterUndefined(),
        tapResponse(
          (settings) =>
            this.patchState((state) =>
              this._deepMergeSettings(state, settings)
            ),
          // eslint-disable-next-line no-console
          console.error
        )
      )
  );

  readonly updateStafferSettings = this.effect(
    (settings$: Observable<Partial<IStafferSettings>>) =>
      settings$.pipe(
        tapResponse(
          (settings) =>
            this.patchState((state) =>
              this._deepMergeSettings(state, settings)
            ),
          // eslint-disable-next-line no-console
          console.error
        )
      )
  );

  readonly saveStafferSettings = this.effect(
    (settings$: Observable<Partial<IStafferSettings>>) =>
      settings$.pipe(
        auditTime(1000),
        withLatestFrom(this._organisation.staffer$.pipe(filterUndefined())),
        concatMap(([settings, staffer]) =>
          patchDoc(staffer.ref, {
            settings,
          })
        )
      )
  );

  constructor(mediaObserver: MediaObserver) {
    super({
      charting: {
        view: ChartView.Adult,
        perio: { ...DEFAULT_PERIO_SETTINGS },
      },
      display: {
        sidebarClosed: mediaObserver.isActive('gt-lg') ? false : true,
      },
      interactions: {
        filters: [],
        tagFilters: [],
      },
      timeline: {
        orientation: TimelineDisplayOrientation.Vertical,
        zoom: TimelineDisplayZoom.Regular,
        thickTracks: true,
        showGridlines: false,
        filteredStaffByPractice: [],
        orderedStaffByPractice: [],
        showRosteredOffStaff: false,
        hideEmptyDays: false,
        hideCancelledAppointments: false,
      },
      conversations: {
        showAllPractices: false,
      },
      chats: {
        showAllPractices: false,
        notificationSoundOverride: undefined,
      },
      treatmentPlan: {
        filters: getEnumValues(PlanStepPairStatus),
        sortBy: SortStepOptions.Ascending,
      },
      patientMedia: {
        viewType: PatientMediaViewType.Grid,
        size: PatientMediaSize.Medium,
        groupBy: PatientMediaGroupBy.None,
        imageEditMode: true,
      },
    });

    this.loadStafferSettings(
      this._organisation.stafferSettings$.pipe(filterUndefined(), take(1))
    );

    this.saveStafferSettings(this.state$.pipe(skip(1)));
  }

  getStaffByPractice$(
    practice: DocumentReference<IPractice>
  ): Observable<DocumentReference<IStaffer>[]> {
    return this.filteredStaffByPractice$.pipe(
      map((staffByPractices) => {
        const found = staffByPractices.find((staffByPractice) =>
          isSameRef(staffByPractice.practice, practice)
        );
        if (!found) {
          return [];
        }
        return found.staff;
      })
    );
  }

  getOrderedStaffByPractice$(
    practice: DocumentReference<IPractice>
  ): Observable<DocumentReference<IStaffer>[]> {
    return this.orderedStaffByPractice$.pipe(
      map((staffByPractices) => {
        const found = staffByPractices.find((staffByPractice) =>
          isSameRef(staffByPractice.practice, practice)
        );
        if (!found) {
          return [];
        }
        return found.staff;
      })
    );
  }

  private _deepMergeSettings(
    state: IStafferSettingsState,
    settings: Partial<IStafferSettings>
  ): Partial<IStafferSettingsState> {
    return {
      ...state,
      charting: {
        ...state.charting,
        ...settings.charting,
      },
      display: {
        ...state.display,
        ...settings.display,
      },
      interactions: {
        ...state.interactions,
        ...settings.interactions,
      },
      timeline: {
        ...state.timeline,
        ...settings.timeline,
        filteredStaffByPractice: this._getFilteredStaff(state, settings),
        orderedStaffByPractice: this._getOrderedStaff(state, settings),
      },
      conversations: {
        ...state.conversations,
        ...settings.conversations,
      },
      chats: {
        ...state.chats,
        ...settings.chats,
      },
      treatmentPlan: {
        ...state.treatmentPlan,
        ...settings.treatmentPlan,
      },
      patientMedia: {
        ...state.patientMedia,
        ...settings.patientMedia,
      },
    };
  }

  private _getFilteredStaff(
    state: IStafferSettingsState,
    settings: Partial<IStafferSettings>
  ): IStaffByPractice[] {
    if (!state.timeline.filteredStaffByPractice.length) {
      return settings.timeline?.filteredStaffByPractice ?? [];
    }

    if (!settings.timeline?.filteredStaffByPractice?.length) {
      return state.timeline.filteredStaffByPractice;
    }

    return [
      ...state.timeline.filteredStaffByPractice,
      ...(settings.timeline.filteredStaffByPractice ?? []),
    ].reduce((staffByPractice: IStaffByPractice[], current) => {
      const index = staffByPractice.findIndex((item) =>
        isSameRef(item.practice, current.practice)
      );
      if (index === -1) {
        return [...staffByPractice, current];
      }
      staffByPractice[index].staff = current.staff;
      return staffByPractice;
    }, []);
  }

  private _getOrderedStaff(
    state: IStafferSettingsState,
    settings: Partial<IStafferSettings>
  ): IStaffByPractice[] {
    if (!state.timeline.orderedStaffByPractice.length) {
      return settings.timeline?.orderedStaffByPractice ?? [];
    }

    if (!settings.timeline?.orderedStaffByPractice?.length) {
      return state.timeline.orderedStaffByPractice;
    }

    return [
      ...state.timeline.orderedStaffByPractice,
      ...(settings.timeline.orderedStaffByPractice ?? []),
    ].reduce((staffByPractice: IStaffByPractice[], current) => {
      const index = staffByPractice.findIndex((item) =>
        isSameRef(item.practice, current.practice)
      );
      if (index === -1) {
        return [...staffByPractice, current];
      }
      staffByPractice[index].staff = current.staff;
      return staffByPractice;
    }, []);
  }
}
